import React, { useState } from "react";

function Tape(props) {
  const [dataLogo] = useState([
    {
      id: 1,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 2,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 3,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 4,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 5,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 6,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 7,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 8,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 9,
      name: "DUBX NFT MARKETPLACE",
    },
    {
      id: 10,
      name: "DUBX NFT MARKETPLACE",
    },
  ]);
  return (
    <section className="logo-slider">
      <div className="logo-slider-wrap">
        <div className="logo-slider-inner">
          {dataLogo.map((idx) => (
            <h3 key={idx.id} className="font-Bakbak">
              {idx.name}
            </h3>
          ))}
        </div>
        <div className="logo-slider-inner style-2">
          {dataLogo.map((idx) => (
            <h3 key={idx.id} className="font-Bakbak">
              {idx.name}
            </h3>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Tape;
