import React from "react";
import "./CookingLoader.css"; // Import the corresponding CSS file

const CookingLoader = () => {
  return (
    <div className="cream">
      <h1>Cooking NFTs ...</h1>
      <div id="cooking">
        {[...Array(5)].map((_, index) => (
          <div key={index} className="bubble"></div>
        ))}
        <div id="area">
          <div id="sides">
            <div id="handle"></div>
            <div id="pan"></div>
          </div>
          <div id="pancake">
            <div id="pastry"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookingLoader;
