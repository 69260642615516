import React, { useState } from "react";

const NewAccordion = ({ button, contents }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div>
      {" "}
      <div className={`accordion md:px-5 sm:px-0 ${isOpen ? "open" : ""}`}>
        <p className="rounded-md accordionPadding" onClick={toggleAccordion}>
          {button}
        </p>
        <p className="w-full">{contents}</p>
      </div>
    </div>
  );
};

export default NewAccordion;
