import React, { useState, useEffect, useMemo } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { useGlobalState, getGlobalState } from "../store";
import { useParams, Link, useNavigate } from "react-router-dom";
import { shortenAddress, loadNfts, loadAllNfts } from "../Blockchain.Services";
import CookingLoader from "../components/CookingLoader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const categoryOptions = [
  { value: "all", label: "Select Category" },
  { value: "art", label: "Art" },
  { value: "animation", label: "Animation" },
  { value: "collectibles", label: "Collectibles" },
  { value: "gaming", label: "Gaming" },
  { value: "illustration", label: "Illustration" },
  { value: "music", label: "Music" },
  { value: "real-estate", label: "Real Estate" },
  { value: "sport", label: "Sport" },
  { value: "3d", label: "3D" },
  { value: "other", label: "Other" },
];

const tagOptions = [
  { value: "all", label: "Select Tag" },
  { value: "cyber", label: "Cyber" },
  { value: "cosmic", label: "Cosmic" },
  { value: "digital", label: "Digital" },
  { value: "magic", label: "Magic" },
  { value: "electric", label: "Electric" },
  { value: "animal", label: "Animal" },
  { value: "pixel", label: "Pixel" },
  { value: "virtualreality", label: "VirtualReality" },
  { value: "nature", label: "Nature" },
  { value: "underwater", label: "Underwater" },
  { value: "vinylrecords", label: "VinylRecords" },
  { value: "icons", label: "Icons" },
  { value: "extreme", label: "Extreme" },
  { value: "portrait", label: "Portrait" },
  { value: "legend", label: "Legend" },
  { value: "retro", label: "Retro" },
  { value: "anime", label: "Anime" },
  { value: "fantasy", label: "Fantasy" },
  { value: "abstract", label: "Abstract" },
  { value: "characters", label: "Characters" },
  { value: "interactive", label: "Interactive" },
  { value: "virtual", label: "Virtual" },
  { value: "mistery", label: "Mistery" },
  { value: "food", label: "Food" },
  { value: "ai", label: "AI" },
  { value: "recipe", label: "Recipe" },
  { value: "clothes", label: "Clothes" },
  { value: "landscape", label: "Landscape" },
  { value: "celebrities", label: "Celebrities" },
  { value: "robot", label: "Robot" },
  { value: "vehicles", label: "Vehicles" },
  { value: "luxury", label: "Luxury" },
  { value: "accessory", label: "Accessory" },
  { value: "toys", label: "Toys" },
  { value: "fitness", label: "Fitness" },
  { value: "remix", label: "Remix" },
];

const sortOptions = [
  { value: "PostingDate", label: "Posting Date (Newest)" },
  { value: "lowToHigh", label: "Price Low to High" },
  { value: "highToLow", label: "Price High to Low" },
  { value: "IsListedForSale", label: "Listed For Sale" },
  { value: "IsRemovedFromSale", label: "Removed From Sale" },
];

const rangeInputStyles = `
  select option:hover {
    background-color: #1BA1E2 !important;
  }
  select {
    background-color: #0d0f10 !important;
  }
`;

const NFTCard = (props) => {
  const { nft, maxTitleLength } = props;
  const {
    contract,
    imageURI,
    title,
    SK,
    owner,
    purchaseCost,
    collection_image,
    collection_contract,
  } = nft;
  // console.log(nft);
  const link = `/n/${contract}/${SK}`;
  const linkToCollection = `/collection/${collection_contract}`;
  return (
    <div
      className="w-[330px] mt-6 flex flex-col mx-2 relative"
      style={{ minHeight: "330px" }}
    >
      {collection_image && collection_contract && (
        <div
          className="w-[70px] h-[70px] bg-transparent absolute right-[-30px] top-[-30px]"
          style={{ zIndex: "5" }}
        >
          <Link to={linkToCollection}>
            <figure className="max-h-[70px] max-w-[70px] rounded-md">
              <LazyLoadImage
                src={collection_image}
                loading="lazy"
                decoding="async"
                alt={title}
                className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105 rounded-md"
              />
            </figure>
          </Link>
        </div>
      )}
      <div className="block w-full h-full tf-work tf-product style-2 overflow-hidden bg-[#141b22] shadow-lg p-[5px] group ">
        <Link to={link}>
          <figure className="coverIMG">
            <LazyLoadImage
              src={imageURI}
              loading="lazy"
              decoding="async"
              alt={title}
              className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105"
            />
          </figure>

          <div className="flex mt-3 border-b">
            <h2 className="font-bold mt-2 text-xl mb-3 text-center text-[#21e786] twoCol">
              <span>
                {title.length > maxTitleLength
                  ? title.substring(0, maxTitleLength) + "..."
                  : title}
              </span>{" "}
              <span>#{SK}</span>
            </h2>
          </div>
        </Link>
        <div className="tooltip-container">
          <Link to={`/author/${owner}`}>
            <div className="flex mt-3 justify-between border-b pb-2">
              <p className="text-white font-Bakbak flex items-center">
                {shortenAddress(owner)}
                <span className="ml-2">
                  <FaExternalLinkAlt />
                </span>
              </p>
              <p className="text-white">{purchaseCost} DUBX</p>
            </div>
          </Link>
          <div className="tooltip-content">{owner}</div>
        </div>
        <Link to={link}>
          <div className="text-center text-lg mt-3 bg-[#21e786] text-black font-Bakbak hover:bg-[#f10cea] hover:text-white transition-all duration-300">
            <button className="font-Bakbak py-2">DETAILS</button>
          </div>
        </Link>
      </div>
    </div>
  );
};

const Explore = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const { urlCategory } = useParams();
  const initialCategory =
    categoryOptions.find(
      (categoryOpt) => categoryOpt.value === urlCategory.toLowerCase()
    ) ?? categoryOptions[0];
  const [category, setCategory] = useState(initialCategory.value);

  const [nfts] = useGlobalState("nfts");
  const [minMax, setMinMax] = useState({ min: 0, max: 10000 });
  const [tags, setTags] = useState(["all"]);
  const [sortBy, setSortBy] = useState("PostingDate");
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [sliderValues, setSliderValues] = useState({
    min: 0,
    max: 10000,
  });
  const [isLoadingNfts] = useGlobalState("isLoadingNfts");
  const [allNftsLoaded] = useGlobalState("allNftsLoaded");

  const handleSliderChange = (e, slider) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [slider]: parseFloat(e.target.value),
    }));
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    if (newCategory === "") {
      setCategory("all");
      navigate(`/explore/all`);
    } else {
      setCategory(newCategory.toLowerCase());
      navigate(`/explore/${newCategory.toLowerCase()}`);
    }
  };

  const handleTagsChange = (e) => {
    setTags([e.target.value]);
  };

  const handleLoadMore = () => {
    loadNfts(50);
  };

  /* Initial Loading of nfts, either paged or all depending on filters */
  useEffect(() => {
    if (
      category === "all" &&
      (tags.length === 0 || tags.includes("all")) &&
      searchTerm === ""
    ) {
    } else {
      loadAllNfts();
    }
  }, [category, tags, searchTerm]);

  /* Update of slider min, max values after fetching nfts */
  useEffect(() => {
    // useGlobalState("nfts");
    if (nfts.length === 0) {
      return;
    }
    const min = nfts.reduce((prev, current) => {
      return Math.min(prev, current.purchaseCost);
    }, Infinity);
    const max = nfts.reduce((prev, current) => {
      return Math.max(prev, current.purchaseCost);
    }, 0);

    setMinMax({ min, max });
    setSliderValues({ min, max });
  }, [nfts]);

  const filteredNfts = useMemo(() => {
    // console.log("nfts", nfts);
    const filtered = nfts.filter((nft) => {
      const categoryMatch =
        category === "all" ? true : nft.category.toLowerCase() === category;

      const tagsMatch =
        tags.length === 0 || tags.includes("all")
          ? true
          : tags.some((tag) => {
              return nft.tags.some((nftTag) => {
                return nftTag.value.toLowerCase() === tag;
              });
            });

      const searchMatch =
        searchTerm === ""
          ? true
          : nft.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
            nft.contract.includes(searchTerm) ||
            nft.owner.toString().includes(searchTerm);

      const { min, max } = sliderValues;
      const priceMatch = nft.purchaseCost >= min && nft.purchaseCost <= max;

      if (categoryMatch && tagsMatch && searchMatch && priceMatch) {
        return true;
      } else {
        return false;
      }
    });

    if (sortBy === "PostingDate") {
      return filtered;
    }

    return filtered.sort((a, b) => {
      switch (sortBy) {
        case "PostingDate":
          return b.timestamp - a.timestamp;
        case "lowToHigh":
          return parseFloat(a.purchaseCost) - parseFloat(b.purchaseCost);
        case "highToLow":
          return parseFloat(b.purchaseCost) - parseFloat(a.purchaseCost);
        case "IsListedForSale":
          return b.isListedForSale - a.isListedForSale;
        case "IsRemovedFromSale":
          return a.isListedForSale - b.isListedForSale;
        default:
          return b.timestamp - a.timestamp;
      }
    });
  }, [nfts, category, tags, searchTerm, sliderValues, sortBy]);

  return (
    <div className="w-full py-40 planetBg">
      <div className="flex-grow mb-8">
        <div className="relative flex flex-col sm:flex-row items-center w-full m-auto my-[15px] justify-center">
          <input
            type="search"
            id="default-search"
            className="block w-2/3 p-2 text-lg text-[#21e786] border rounded-lg bg-transparent"
            placeholder="Search Nft by Title, ID or Author address..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            required
          />
          <button
            type="submit"
            className="shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-2 px-[45px] ml-5
                    cursor-pointer font-Bakbak mt-6 sm:mt-0"
          >
            Search
          </button>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row z-50 pb-0 min-[1202px]:pb-[250px]">
        <div className="md:flex lg:w-[20%] min-[1202px]:flex mt-20 mx-12 lg:mr-0">
          <style>{rangeInputStyles}</style>
          <nav className="flex flex-col lg:flex-row shadow-md filterVideo items-start min-[1202px]:items-start w-[80%] lg:w-[20%]">
            {/*  ---------------- FILTER ---------------- */}

            <div className="w-full px-6 md:px-24 lg:px-0 lg:w-64">
              <div className="px-4 flex flex-col items-center">
                {/* CATEGORY */}
                <div className="flex-grow w-full">
                  <label
                    htmlFor="category"
                    className="text-sm font-medium text-white"
                  >
                    Category
                  </label>
                  <select
                    id="category"
                    className="block w-full text-sm text-slate-500 mt-2 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                    name="category"
                    onChange={handleCategoryChange}
                    value={category}
                    required
                  >
                    {categoryOptions.map(({ value, label }) => {
                      return (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* TAGS */}
                <div className="flex-grow w-full my-8">
                  <label
                    htmlFor="tags"
                    className="text-sm font-medium text-white"
                  >
                    Tags
                  </label>
                  <select
                    id="tags"
                    className="block w-full mt-2 text-sm text-slate-500 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                    name="tags"
                    onChange={handleTagsChange}
                    value={tags[0]}
                    required
                  >
                    {tagOptions.map(({ value, label }) => {
                      return (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* SORT BY */}
                <div className="flex-grow w-full mb-8">
                  <label
                    htmlFor="sort"
                    className="text-sm font-medium text-white"
                  >
                    Sort by
                  </label>
                  <select
                    id="sort"
                    className="block w-full text-sm mt-2 text-slate-500 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                    name="sort"
                    onChange={(e) => setSortBy(e.target.value)}
                    value={sortBy}
                    required
                  >
                    {sortOptions.map(({ value, label }) => {
                      return (
                        <option value={value} key={value}>
                          {label}
                        </option>
                      );
                    })}
                  </select>
                </div>

                {/* PRICE RANGE */}
                <div className="range-slider">
                  <p className="text-white">Price Range</p>
                  <span className="text-white" id="rangeValues"></span>
                  <input
                    value={sliderValues.min}
                    min={minMax.min}
                    max={minMax.max}
                    step="0.1"
                    type="range"
                    onChange={(e) => handleSliderChange(e, "min")}
                  />
                  <input
                    value={sliderValues.max}
                    min={minMax.min}
                    max={minMax.max}
                    step="0.1"
                    type="range"
                    onChange={(e) => handleSliderChange(e, "max")}
                  />
                  <div className="flex justify-between mt-[10px]">
                    <span className="text-sm text-gray-500">
                      {sliderValues.min} DUBX
                    </span>
                    <span className="text-sm text-gray-500">
                      {sliderValues.max} DUBX
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </div>
        {/* ---------------- FILTER ---------------- */}
        <div className="w-full flex flex-col text-white mt-12 mb-12">
          {/* ---------------- NFTS ---------------- */}
          {isLoadingNfts ? (
            <div className="h-[540px] ml-[-100px] md:ml-0">
              <CookingLoader />
            </div>
          ) : (
            <div className="w-full flex flex-wrap justify-evenly">
              {filteredNfts?.map((nft) => {
                return (
                  <NFTCard
                    nft={nft}
                    key={nft.PK + nft.SK}
                    maxTitleLength={20}
                  />
                );
              })}
              {!allNftsLoaded ? (
                <div className="flex items-center justify-center mt-8 w-full">
                  <button
                    className="shadow-xl shadow-black text-[#000] hover:text-white
            bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-3 px-[15px]
              cursor-pointer font-Bakbak w-[250px] z-[20]"
                    onClick={handleLoadMore}
                  >
                    Load More
                  </button>
                </div>
              ) : null}
            </div>
          )}
          {filteredNfts?.length === 0 ? (
            <div className="h-[540px] flex items-center justify-center flex-col">
              <p className="lg:ml-[-50px] xl:ml-[-100px] text-4xl font-semibold">
                No NFTs available
              </p>
              {category !== "all" ||
              tags.length !== 0 ||
              tags.inicludes("all") ? (
                <></>
              ) : null}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Explore;

/*
            <div className="w-full flex flex-wrap justify-evenly">
              {nfts.Items && nfts.Items.length > 0 ? (
                exploreNftsFiltered
                  .sort((a, b) => {
                    switch (sortBy) {
                      case "PostingDate":
                        return b.timestamp - a.timestamp;
                      case "lowToHigh":
                        return (
                          parseFloat(a.purchaseCost) -
                          parseFloat(b.purchaseCost)
                        );
                      case "highToLow":
                        return (
                          parseFloat(b.purchaseCost) -
                          parseFloat(a.purchaseCost)
                        );
                      default:
                        return b.timestamp - a.timestamp;
                    }
                  })
                  .map((nft) => {
                    const isCategoryMatch =
                      category === "all" ||
                      nft.category.toLowerCase() === category.toLowerCase();
                    const isTagMatch =
                      tags === "all" ||
                      (nft.tags &&
                        Array.isArray(nft.tags) &&
                        nft.tags.some(
                          (tag) =>
                            tag &&
                            tag.value &&
                            tag.value.toLowerCase() === tags.toLowerCase()
                        ));

                    const isPriceInRange =
                      nft.purchaseCost >= sliderValues.slide1 &&
                      nft.purchaseCost <= sliderValues.slide2;

                    if (isCategoryMatch && isTagMatch && isPriceInRange) {
                      return (
                        <div
                          key={nft.newNftid}
                          className="w-[330px] mt-6 flex flex-col "
                          style={{ minHeight: "330px" }}
                        >
                          <div className="block w-full h-full tf-work tf-product style-2 overflow-hidden bg-[#141b22] shadow-lg p-[5px] group ">
                            <Link
                              to={`/n/${nft.contract}/${nft.newNftid.slice(
                                42
                              )}`}
                            >
                              <figure className="coverIMG">
                                <LazyLoadImage
                                  src={nft.imageURI}
                                  loading="lazy"
                                  decoding="async"
                                  alt={nft.title}
                                  className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105"
                                />
                              </figure>

                              <div className="flex mt-3 border-b">
                                <h2 className="font-bold mt-2 text-xl mb-3 text-center text-[#21e786] twoCol">
                                  <span>
                                    {nft.title.length > maxTitleLength
                                      ? nft.title.substring(0, maxTitleLength) +
                                        "..."
                                      : nft.title}
                                  </span>{" "}
                                  <span>#{nft.newNftid.slice(42)}</span>
                                </h2>
                              </div>
                            </Link>
                            <div className="tooltip-container">
                              <Link to={`/author/${nft.owner}`}>
                                <div className="flex mt-3 justify-between border-b pb-2">
                                  <p className="text-white font-Bakbak flex items-center">
                                    {shortenAddress(nft.owner)}
                                    <span className="ml-2">
                                      <FaExternalLinkAlt />
                                    </span>
                                  </p>
                                  <p className="text-white">
                                    {nft.purchaseCost} DUBX
                                  </p>
                                </div>
                              </Link>
                              <div className="tooltip-content">{nft.owner}</div>
                            </div>
                            <Link
                              to={`/n/${nft.contract}/${nft.newNftid.slice(
                                42
                              )}`}
                            >
                              <div className="text-center text-lg mt-3 bg-[#21e786] text-black font-Bakbak hover:bg-[#f10cea] hover:text-white transition-all duration-300">
                                <button className="font-Bakbak py-2">
                                  DETAILS
                                </button>
                              </div>
                            </Link>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
              ) : (
                <p>No NFTs available</p>
              )}
              {displayedNftsCount < visibleNFTsCount && (
                <div className="flex items-center justify-center mt-8 w-full">
                  <button
                    className="shadow-xl shadow-black text-[#000] hover:text-white
              bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-3 px-[15px]
                cursor-pointer font-Bakbak w-[250px] z-[20]"
                    onClick={handleLoadMore}
                  >
                    Load More
                  </button>
                </div>
              )}
            </div>
            */
