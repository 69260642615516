import React, { useState, useEffect } from "react";
import DummyBannerImg from "../assets/293545.png&text=1903x645";
import TopSeller6 from "../assets/ai5.jpg";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { create as ipfsHttpClient } from "ipfs-http-client";
import MessageModal from "../components/MessageModal";
import ConfettiContainer from "../components/ConfettiContainer";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  useGlobalState,
  setGlobalState,
  getGlobalState,
  setLoadingMsg,
  truncate,
} from "../store";
import {
  shortenAddress,
  verifyUserAccount,
  getAuthorData,
} from "../Blockchain.Services";

const EditProfile = () => {
  const { address } = useParams();
  const navigate = useNavigate();
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);
  const [showConfettiContainer, setShowConfettiContainer] = useState(false);
  const [showUlMessageModal, setShowUlMessageModal] = useState(true);
  const [showConfMessageModal, setShowConfMessageModal] = useState(false);
  const [mailExist, setMailExist] = useState(false);

  const [isAccountVerified, setIsAccountVerified] =
    useGlobalState("isAccountVerified");
  const [avatar, setAvatar] = useGlobalState("avatar");
  const [author, setAuthor] = useState({
    account: address.toLowerCase(),
    timestamp: "",
    verified: false,
    authorPhoto: "",
    bannerImage: "",
    name: "",
    email: "",
    externalLink: "",
    smartCode: "",
    bio: "",
    facebook: "",
    twitter: "",
    instagram: "",
    skype: "",
    nftCount: 0,
    collectionCount: 0,
  });
  const { ethereum } = window;

  useEffect(() => {
    const getExistedAuthor = async () => {
      try {
        const accData = await getAuthorData(address.toLowerCase());
        // console.log("accData", accData);
        if (accData && accData.Item) {
          setAuthor(accData.Item);
          setImgBase64(accData.Item.authorPhoto);
          setImgBase64Banner(accData.Item.bannerImage);
          if (accData.Item.email) {
            setMailExist(true);
          }
        } else {
          setAuthor({
            account: address.toLowerCase(),
            timestamp: "",
            verified: false,
            authorPhoto: "",
            bannerImage: "",
            name: "",
            email: "",
            externalLink: "",
            smartCode: "",
            bio: "",
            facebook: "",
            twitter: "",
            instagram: "",
            skype: "",
            nftCount: 0,
            collectionCount: 0,
          });
          setImgBase64(TopSeller6);
          setImgBase64Banner(DummyBannerImg);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getExistedAuthor();
  }, [connectedAccount, address]);

  const handleShowMessageModal = (
    newMessage,
    showUl = true,
    showConf = false
  ) => {
    setInfoMessage(newMessage);
    setShowMessageModal(true);
    setShowUlMessageModal(showUl);
    setShowConfMessageModal(showConf);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  // const ipfs = ipfsHttpClient({
  //   url: "http://localhost:5001/api/v0",
  // });

  useEffect(() => {
    const handleAccountChange = (accounts) => {
      const newAccount = accounts[0]?.toLowerCase() || null;
      navigate(`/edit/${newAccount}`);
    };

    ethereum.on("accountsChanged", handleAccountChange);

    return () => {
      ethereum.off("accountsChanged", handleAccountChange);
    };
  }, [connectedAccount, navigate, isAccountVerified]);

  const [imageNameSizeType, setimageNameSizeType] = useState({
    name: "",
    size: "",
    type: "",
  });
  const [imageNameSizeTypeBanner, setimageNameSizeTypeBanner] = useState({
    name: "",
    size: "",
    type: "",
  });
  const [imgBase64, setImgBase64] = useState(null);
  const [imgBase64Banner, setImgBase64Banner] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const [selectedFileBanner, setSelectedFileBanner] = useState(null);
  const [fileUrlBanner, setFileUrlBanner] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAuthor((prevAuthor) => ({
      ...prevAuthor,
      [name]: value,
    }));
  };
  const changeImage = async (e) => {
    const reader = new FileReader();
    if (e.target.files[0]) reader.readAsDataURL(e.target.files[0]);

    const newUploadedImage = e.target.files[0];
    if (newUploadedImage && newUploadedImage.size > 200 * 1024) {
      alert(
        "File size exceeds the maximum limit of 200 KB. Your image will not be saved."
      );
      return;
    }

    reader.onload = async (readerEvent) => {
      const base64String = readerEvent.target.result;
      setImgBase64(base64String);

      const img = new Image();
      img.src = base64String;

      img.onload = async () => {
        console.log(img.width, img.height);
        if (img.width !== img.height) {
          alert(
            "Image dimensions must be square ( width = height ). Your image will not be saved."
          );
          return;
        }

        if (
          img.width > 400 ||
          img.height > 400 ||
          img.width < 150 ||
          img.height < 150
        ) {
          alert(
            "Image dimensions must be min 150x150 pixels and max 400x400 pixels. Your image will not be saved."
          );
          return;
        }

        setSelectedFile(newUploadedImage);
        setFileUrl("");

        // const uploadedPhoto = await ipfs.add(newUploadedImage);
        // const imageURI = `${process.env.REACT_APP_DOMAIN}/ipfs/${uploadedPhoto.path}`;
        const logoFormData = new FormData();
        logoFormData.append("file", newUploadedImage);
        const logoOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
          },
          body: logoFormData,
        };
        const logoResponse = await fetch(
          `${process.env.REACT_APP_PINATA_PIN_FILE}`,
          logoOptions
        );
        const logoDataPinata = await logoResponse.json();
        const imageURI = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${logoDataPinata.IpfsHash}`;

        setAuthor((prevAuthor) => ({
          ...prevAuthor,
          authorPhoto: imageURI,
        }));

        setimageNameSizeType({
          name: newUploadedImage.name,
          size: newUploadedImage.size,
          type: newUploadedImage.type,
        });

        setGlobalState("avatar", author.authorPhoto);
        setAvatar(author.authorPhoto);
      };
    };
  };

  const changeImageBanner = async (e) => {
    const reader = new FileReader();
    if (e.target.files[0]) reader.readAsDataURL(e.target.files[0]);

    const newUploadedBanner = e.target.files[0];
    if (newUploadedBanner && newUploadedBanner.size > 400 * 1024) {
      alert(
        "File size exceeds the maximum limit of 400 KB. Your image will not be saved."
      );
      return;
    }

    reader.onload = async (readerEvent) => {
      const fileBanner = readerEvent.target.result;
      setImgBase64Banner(fileBanner);
      setSelectedFileBanner(e.target.files[0]);
      setFileUrlBanner("");

      // const uploadedBanner = await ipfs.add(e.target.files[0]);
      // const imageURIBanner = `${process.env.REACT_APP_DOMAIN}/ipfs/${uploadedBanner.path}`;
      const bannerFormData = new FormData();
      const bannerImage = e.target.files[0];
      bannerFormData.append("file", bannerImage);
      const bannerOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
        },
        body: bannerFormData,
      };
      const bannerResponse = await fetch(
        `${process.env.REACT_APP_PINATA_PIN_FILE}`,
        bannerOptions
      );
      const bannerDataPinata = await bannerResponse.json();
      const imageURIBanner = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${bannerDataPinata.IpfsHash}`;

      setAuthor((prevAuthor) => ({
        ...prevAuthor,
        bannerImage: imageURIBanner,
      }));
      setimageNameSizeTypeBanner({
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
      });
    };
  };
  const handleVerifyAccount = async () => {
    try {
      const getExistedAuthor = async () => {
        try {
          const accData = await getAuthorData(address.toLowerCase());

          if (accData && accData.Item) {
            setAuthor(accData.Item);
            setImgBase64(accData.Item.authorPhoto);
            setImgBase64Banner(accData.Item.bannerImage);
            if (accData.Item.email) {
              return true;
            } else {
              return false;
            }
          } else {
            setAuthor({
              account: address.toLowerCase(),
              timestamp: "",
              verified: false,
              authorPhoto: "",
              bannerImage: "",
              name: "",
              email: "",
              externalLink: "",
              smartCode: "",
              bio: "",
              facebook: "",
              twitter: "",
              instagram: "",
              skype: "",
              nftCount: 0,
              collectionCount: 0,
            });
            setImgBase64(TopSeller6);
            setImgBase64Banner(DummyBannerImg);
          }
        } catch (error) {
          console.log(error);
        }
      };

      if (author.email !== "") {
        const isEmail = await getExistedAuthor();

        if (isEmail) {
          handleShowMessageModal("Verifying account...", true, false);
          const result = await verifyUserAccount();
          if (
            result.success &&
            result.receipt &&
            result.receipt.events[0].event === "AccountVerified"
          ) {
            console.log("User verified successfully", result);
            setIsAccountVerified(true);
            setGlobalState("isAccountVerified", true);
            handleShowMessageModal(
              "Account verified successfully.",
              false,
              true
            );
          }
        } else {
          handleShowMessageModal(
            "To verify account you must upload the correct email address.",
            false
          );
        }
      }
      if (author.email === "") {
        console.log("mail not Exist");
        setMailExist(false);
        handleShowMessageModal(
          "To verify account you must upload the correct email address.",
          false
        );
      }
    } catch (error) {
      console.error("Error verifying user:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const currentTimestampInSeconds = Math.floor(Date.now() / 1000);

    const requestUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/author`;

    const requestBody = {
      account: author.account,
      timestamp: currentTimestampInSeconds,
      verified: isAccountVerified,
      authorPhoto: author.authorPhoto,
      bannerImage: author.bannerImage,
      name: author.name,
      email: author.email,
      externalLink: author.externalLink,
      smartCode: author.smartCode,
      bio: author.bio,
      facebook: author.facebook,
      twitter: author.twitter,
      instagram: author.instagram,
      skype: author.skype,
      nftCount: author.nftCount,
      collectionCount: author.collectionCount,
    };
    console.log(requestBody);

    try {
      if (requestBody.authorPhoto === "" || requestBody.name === "") {
        alert(
          "Author photo and name are required. Please fill in all required fields."
        );
        return;
      } else {
        handleShowMessageModal("Updating data...");
        const response = await fetch(requestUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        });

        if (response.ok) {
          handleShowMessageModal("Author saved successfully!", false, true);

          const responseSingleItem = await response.json();
          console.log("Request sent successfully!", responseSingleItem);
          setGlobalState("avatar", responseSingleItem.Item.authorPhoto);
        } else {
          console.error("Failed to send request:", response.statusText);
        }
      }
    } catch (error) {
      console.error("Error sending request:", error.message);
    }

    setLoadingMsg("Author data saved successfully!");
  };

  // categoriesBG;
  return (
    <div className="flex items-center justify-center text-white relative py-32">
      <div className="absolute h-screen inset-0 z-[-1]"></div>

      {/* MY PROFILE */}
      {connectedAccount ? (
        <div className=" lg:px-32 lg:pt-16 bg-[#151c25] shadow-xl shadow-[#21e78787] rounded-xl p-6 overflow-y-auto relative">
          <h1 className="font-Bakbak text-3xl pb-12 cursor-default text-[#21e786] pt-8 lg:pt-0 text-center">
            Author page: {shortenAddress(address.toLowerCase())}
          </h1>
          {/* Verify */}
          <div className="relative flex justify-center md:absolute right-0 top-0">
            {isAccountVerified ? (
              <button className="ml-6 border border-[#21e787a9] font-Bakbak bg-[#21e786] flex items-center px-4 py-1 rounded-full text-[#000000b4] m-2 mt-4 mr-4">
                VERIFIED{" "}
                <span className="ml-2">
                  <MdOutlineVerifiedUser />
                </span>
              </button>
            ) : (
              <button
                onClick={handleVerifyAccount}
                className="ml-6 border border-[#21e787a9] font-Bakbak bg-[#21e786] flex items-center px-4 py-1 rounded-full text-[#000000b4] m-2 mt-4 mr-4"
              >
                VERIFY ACCOUNT
              </button>
            )}
          </div>
          <div className="flex flex-col max-[768px]:items-center md:flex-row md:justify-center">
            <div className="w-[300px] bg-[#151c25] rounded-lg bg-pink-light-shadow flex flex-col">
              {/*  ---------------- logo ---------------- */}
              <div className="py-8">
                <h1 className="text-center font-Bakbak pb-6 text-xl text-white">
                  Author photo
                </h1>
                <div className="twm-candidate-profile-pic">
                  <div className="w-full h-full">
                    {imgBase64 ? (
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        decoding="async"
                        className="h-28 w-28 rounded-lg"
                        src={imgBase64}
                        onChange={changeImage}
                        required
                      />
                    ) : (
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        decoding="async"
                        className="h-28 w-28 rounded-lg"
                        src={TopSeller6}
                        onChange={changeImage}
                        required
                      />
                    )}
                  </div>
                </div>
                <div className="upload-btn-wrapper">
                  <div className="text-center">
                    <label
                      htmlFor="fileInput"
                      type="button"
                      className="cursor-pointer inine-flex justify-between items-center focus:outline-none py-2 px-4  bg-gray-800 rounded-xl shadow-sm text-left text-gray-600 font-medium mt-4 sm:mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-flex flex-shrink-0 w-6 h-6 -mt-1 mr-1"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          stroke="none"
                        ></rect>
                        <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                        <circle cx="12" cy="13" r="3" />
                      </svg>
                      Choose File
                    </label>

                    {imageNameSizeType && imageNameSizeType.name !== "" ? (
                      <div className="mx-auto w-48 text-gray-500 text-xs left mt-1">
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          Name: {imageNameSizeType.name}
                        </div>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          Size: {imageNameSizeType.size}
                        </div>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          Type: {imageNameSizeType.type}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          ( max 200 KB square )
                        </div>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          ( min 150 x 150 px )
                        </div>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          ( max 400 x 400 px )
                        </div>
                      </>
                    )}

                    <input
                      name="nftImage"
                      id="fileInput"
                      accept="image/jpeg, image/png, image/gif, image/webp"
                      className="hidden"
                      type="file"
                      onChange={changeImage}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-[#151c25] rounded-lg bg-pink-light-shadow flex flex-col mx-[120px]">
              {/*  ---------------- logo ---------------- */}
              <div className="py-8 flex flex-col items-center">
                <h1 className="text-center font-Bakbak pb-6 text-xl text-white">
                  Cover Image
                </h1>
                <div className="mb-[30px] mx-auto mt-0 h-[120px]">
                  <div className="w-full h-full">
                    {imgBase64Banner ? (
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        decoding="async"
                        className="h-28 rounded-lg"
                        src={imgBase64Banner}
                        onChange={changeImageBanner}
                        required
                      />
                    ) : (
                      <LazyLoadImage
                        effect="blur"
                        loading="lazy"
                        decoding="async"
                        className="h-28 rounded-lg"
                        src="https://dummyimage.com/1903x645/1F2937/293545.png&text=1903x645"
                        onChange={changeImageBanner}
                        required
                      />
                    )}
                  </div>
                </div>
                <div className="upload-btn-wrapper">
                  <div className="text-center">
                    <label
                      htmlFor="fileBannerInput"
                      type="button"
                      className="cursor-pointer inine-flex justify-between items-center focus:outline-none py-2 px-4  bg-gray-800 rounded-xl shadow-sm text-left text-gray-600 font-medium mt-4 sm:mt-0"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="inline-flex flex-shrink-0 w-6 h-6 -mt-1 mr-1"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                          stroke="none"
                        ></rect>
                        <path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2" />
                        <circle cx="12" cy="13" r="3" />
                      </svg>
                      Choose Banner
                    </label>

                    {imageNameSizeTypeBanner &&
                    imageNameSizeTypeBanner.name !== "" ? (
                      <div className="mx-auto w-48 text-gray-500 text-xs left mt-1">
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          Name: {imageNameSizeTypeBanner.name}
                        </div>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          Size: {imageNameSizeTypeBanner.size}
                        </div>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          Type: {imageNameSizeTypeBanner.type}
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          ( max 400 KB )
                        </div>
                        <div className="mx-auto w-48 text-gray-500 text-xs text-center mt-1">
                          ( optimal 1903 x 645 px )
                        </div>
                      </>
                    )}

                    <input
                      name="nftBannerImage"
                      id="fileBannerInput"
                      accept="image/jpeg, image/png, image/gif, image/webp,
                video/mp4, video/webm, video/ogg, video/mov,
                audio/mpeg, audio/wav, audio/ogg, audio/flac, audio/glb, audio/mp3"
                      className="hidden"
                      type="file"
                      onChange={changeImageBanner}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* BANNER */}
          <div className="flex flex-col">
            <h1 className="pb-3">Update Your Information</h1>
            <hr />
            <div>
              <div className="flex mt-6">
                <input
                  name="name"
                  type="text"
                  required
                  className="bg-gray-800 p-3 rounded-md mr-6 w-full"
                  placeholder="Author Name"
                  value={author.name}
                  onChange={handleInputChange}
                />

                <input
                  name="email"
                  type="email"
                  required=""
                  className="bg-gray-800 p-3 rounded-md w-full"
                  placeholder="Email"
                  value={author.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mt-6 flex">
                <input
                  name="externalLink"
                  type="text"
                  required=""
                  className="bg-gray-800 p-3 rounded-md mr-6 w-full"
                  placeholder="external link ( optional )"
                  value={author.externalLink}
                  onChange={handleInputChange}
                />
                <input
                  name="smartCode"
                  type="text"
                  className="bg-gray-800 p-3 rounded-md w-full"
                  required=""
                  placeholder="****************"
                  readOnly
                  value={author.smartCode}
                  onChange={handleInputChange}
                />
              </div>
              {/* TEXTAREA BIO */}
              <p className="mt-6 mb-2">Bio</p>
              <div className="rounded-md text-white bg-gray-800 mb-6">
                <textarea
                  name="bio"
                  className="p-3 w-full h-24 resize-none bg-gray-800"
                  value={author.bio}
                  onChange={handleInputChange}
                  placeholder="Enter your bio here"
                ></textarea>
              </div>

              {/* ---------------- SOCIAL ---------------- */}
              <h3 className="font-bold">Your Social Media</h3>
              <p className="text-sm">
                This information will be displayed on your profile page,
                allowing other users to contact you through your social media
                accounts.
              </p>
              <div className="mt-6 flex">
                <input
                  name="facebook"
                  type="text"
                  className="bg-gray-800 p-3 rounded-md mr-6 w-full"
                  placeholder="Facebook Link ( optional )"
                  value={author.facebook}
                  onChange={handleInputChange}
                />
                <input
                  name="instagram"
                  type="text"
                  className="bg-gray-800 p-3 rounded-md w-full"
                  placeholder="Instagram Link ( optional )"
                  value={author.instagram}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mt-6 flex">
                <input
                  name="twitter"
                  type="text"
                  className="bg-gray-800 p-3 rounded-md mr-6 w-full"
                  placeholder="X Link ( optional )"
                  value={author.twitter}
                  onChange={handleInputChange}
                />
                <input
                  name="skype"
                  type="text"
                  className="bg-gray-800 p-3 rounded-md w-full"
                  placeholder="Skype Link ( optional )"
                  value={author.skype}
                  onChange={handleInputChange}
                />
              </div>
              {/* ---------------- SAVE AND UPDATE INFORMATIONS ---------------- */}
              <button
                type="submit"
                onClick={handleSubmit}
                className="flex flex-row justify-center items-center
                w-full text-[#000] font-bold text-[23px] bg-[#21e786]
                hover:bg-[#f10cea] py-2 px-5 rounded-[4px]
                drop-shadow-xl border border-transparent
                hover:bg-transparent hover:text-[#fff]
                hover:border hover:border-[#21e787a9]
                focus:outline-none focus:ring mt-5 font-Poppins transition-all ease-out duration-300"
              >
                Save & Update
              </button>
            </div>
          </div>
        </div>
      ) : (
        <p className="h-screen">No author available</p>
      )}
      <div>
        <MessageModal
          message={infoMessage}
          show={showMessageModal}
          showUl={showUlMessageModal}
          showConf={showConfMessageModal}
          onClose={handleCloseMessageModal}
        />
      </div>
      {showConfettiContainer && <ConfettiContainer />}
    </div>
  );
};

export default EditProfile;
