import React from "react";
import "./SectionLoader.css";

const SectionLoader = () => {
  return (
    <div>
      <section>
        <div className="loader">
          {[...Array(20)].map((_, index) => (
            <span key={index} style={{ "--i": index + 1 }}></span>
          ))}
        </div>
      </section>{" "}
    </div>
  );
};

export default SectionLoader;
