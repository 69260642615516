import React, { useState, useEffect } from "react";
import { AiOutlineGlobal } from "react-icons/ai";
import TopSeller6 from "../assets/ai5.jpg";
import { useParams, Link, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FaExternalLinkAlt } from "react-icons/fa";
import { BsArrowUpCircleFill, BsFillArrowDownCircleFill } from "react-icons/bs";
import {
  getAuthorData,
  getNftsData,
  shortenAddress,
  checkIsAuthorVerified,
  verifyUserAccount,
  getProvider,
  getRegistryContract,
  getEthersContract,
  getNFTsArray,
  getCollectionNftsData,
} from "../Blockchain.Services";
import moment from "moment/moment";
import { MdOutlineVerifiedUser } from "react-icons/md";
import SectionLoader from "../components/SectionLoader";
import { useGlobalState, setGlobalState, getGlobalState } from "../store";

const rangeInputStyles = `
  
  select option:hover {
    background-color: #1BA1E2 !important;
  }
  
  select {
    background-color: #0d0f10 !important;
  }
`;

const SingleCollection = () => {
  const { address } = useParams();
  const navigate = useNavigate();
  const [active, setActive] = useState(1);
  const [nfts, setNfts] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [minMax, setMinMax] = useState({ min: 0, max: 0 });
  const [category, setCategory] = useState("all");
  const [tags, setTags] = useState("all");
  const [sortBy, setSortBy] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const { urlCategory } = useParams();
  const [sliderValues, setSliderValues] = useState({
    slide1: 1000,
    slide2: 50000,
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAuthorVerified, setIsAuthorVerified] = useState(false);
  const [loading, setLoading] = useState(true);
  const [exploreNftsFiltered, setExploreNftsFiltered] = useState([]);
  const [visibleNFTsCount, setVisibleNFTsCount] = useState(0);
  const [displayedNftsCount, setDisplayedNftsCount] = useState(8);
  const [selectedTraits, setSelectedTraits] = useState([]);
  const [selectedTraitValues, setSelectedTraitValues] = useState([]);
  const [expandedTraits, setExpandedTraits] = useState([]);
  const [traitTypeVisibility, setTraitTypeVisibility] = useState({});
  const [uniqueTraitTypes, setUniqueTraitTypes] = useState([]);
  const [uniqueTraitValues, setUniqueTraitValues] = useState([]);
  const [matchingTraitsMap, setMatchingTraitsMap] = useState({});
  const [totalSupplyNfts, setTotalSupplyNfts] = useState([]);
  const [selectedTraitValuesByType, setSelectedTraitValuesByType] = useState(
    {}
  );
  const [isAccountVerified, setIsAccountVerified] =
    useGlobalState("isAccountVerified");
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const [collectionData, setCollectionData] = useState({
    owner: "",
  });
  const [collectionDBData, setCollectionDBData] = useState({
    hash: "",
    name: "",
    description: "",
    image: "",
    owner: "",
    banner: "",
    collectionContractAddr: "",
    externalLink: "",
    date: "",
    totalCost: 0,
    floor: 0,
    contract: "",
  });

  useEffect(() => {
    const getSingleCollection = async () => {
      try {
        if (collectionData && collectionData.owner) {
          const collOwner = collectionData.owner.toLocaleLowerCase();
          const requestUrlColl = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection?id=${collOwner}:${address}`;
          // console.log("requestUrlColl", requestUrlColl);
          const response = await fetch(requestUrlColl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });

          if (response.ok) {
            const data = await response.json();
            setCollectionDBData({
              hash: data.Item.hash,
              name: data.Item.title,
              description: data.Item.description,
              image: data.Item.imageURI,
              owner: data.Item.owner,
              banner: data.Item.bannerURI,
              collectionContractAddr: data.Item.contract,
              externalLink: data.Item.externalLink,
              date: data.Item.date,
              totalCost: data.Item.totalCost,
              floor: data.Item.floor,
            });
            // console.log(data.Item);
          } else {
            console.error("Failed to get requests:", response.statusText);
            return false;
          }
        } else {
          console.log("no collection data");
          // navigate(`/page-not-found`);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getSingleCollection();
  }, [collectionData, collectionData.owner]);

  const handleTraitCheckboxChange = (traitType) => {
    setExpandedTraits((prevExpandedTraits) => {
      if (prevExpandedTraits.includes(traitType)) {
        return prevExpandedTraits.filter((trait) => trait !== traitType);
      } else {
        return [...prevExpandedTraits, traitType];
      }
    });
  };

  const handleTraitValueCheckboxChange = (traitType, value) => {
    // Check if the trait value is already selected
    const traitKey = `${traitType}-${value}`;

    setSelectedTraitValues((prevSelectedTraitValues) => {
      if (prevSelectedTraitValues.includes(traitKey)) {
        // If selected, remove it
        return prevSelectedTraitValues.filter(
          (selectedValue) => selectedValue !== traitKey
        );
      } else {
        // If not selected, add it
        return [...prevSelectedTraitValues, traitKey];
      }
    });
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleSliderChange = (e, slider) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [slider]: parseFloat(e.target.value),
    }));
  };

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    // console.log(newCategory);
    setCategory(newCategory);
  };
  useEffect(() => {
    const fetchWallet = async () => {
      try {
        const nftsFromDb = await getCollectionNftsData(address);
        // console.log("nftsFromDb", nftsFromDb);
        const prices = nftsFromDb.map((nft) => parseFloat(nft.purchaseCost));
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);

        setMinMax({ min: minPrice, max: maxPrice });
        setSliderValues({ slide1: minPrice, slide2: maxPrice });
        setNfts(nftsFromDb);
      } catch (error) {
        console.error("Error getting requests:", error.message);
      }
    };
    fetchWallet();
  }, [category, tags, sortBy, urlCategory]);

  const uniqueTraits = [
    ...new Set(
      exploreNftsFiltered
        .flatMap((nft) => (nft.traits ? nft.traits : []))
        .map((trait) => trait.trait_type)
    ),
  ];

  const calculateExploreNftsCount = () => {
    if (nfts) {
      let filteredNfts = nfts;
      // Filter po searchTerm
      filteredNfts = filteredNfts.filter(
        (nft) =>
          nft.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          nft.SK.includes(searchTerm) ||
          nft.owner.toString().includes(searchTerm)
      );

      // Filter po isListedForSale
      if (sortBy === "IsListedForSale" || sortBy === "IsRemovedFromSale") {
        const isListed = sortBy === "IsListedForSale";
        filteredNfts = filteredNfts.filter(
          (nft) => nft.isListedForSale === isListed
        );
      }

      // Dodatno filtriranje po kategoriji, tagovima i ceni
      filteredNfts = filteredNfts.filter((nft) => {
        const isCategoryMatch =
          category === "all" ||
          nft.category.toLowerCase() === category.toLowerCase();

        const isTagMatch =
          tags === "all" ||
          (nft.tags &&
            Array.isArray(nft.tags) &&
            nft.tags.some(
              (tag) =>
                tag &&
                tag.value &&
                tag.value.toLowerCase() === tags.toLowerCase()
            ));
        const isPriceInRange =
          nft.purchaseCost >= sliderValues.slide1 &&
          nft.purchaseCost <= sliderValues.slide2;

        const hasSelectedTraits = (nft) => {
          if (selectedTraitValues.length === 0) {
            return true;
          }
          return selectedTraitValues.some((selectedValue) =>
            nft.traits.some((trait) => {
              if (trait) {
                const traitKey = `${trait.trait_type}-${trait.value}`;
                return selectedValue === traitKey;
              }
              return false;
            })
          );
        };

        const trVal = hasSelectedTraits(nft);

        return isCategoryMatch && isTagMatch && isPriceInRange && trVal;
      });

      const allTraits = filteredNfts
        .flatMap((nft) => (nft.traits ? nft.traits : []))
        .map((trait) => ({
          type: trait.trait_type,
          value: trait.value,
        }));

      const uniqueTraits = [...new Set(allTraits.map((trait) => trait.type))];
      const uniqueValues = [...new Set(allTraits.map((trait) => trait.value))];

      setUniqueTraitTypes(uniqueTraits);
      setUniqueTraitValues(uniqueValues);

      const newMatchingTraitsMap = {};
      uniqueTraits.forEach((traitType) => {
        const matchingTraits = allTraits.filter(
          (trait) => trait.type === traitType
        );

        newMatchingTraitsMap[traitType] = matchingTraits;
      });

      setMatchingTraitsMap(newMatchingTraitsMap);

      setVisibleNFTsCount(filteredNfts.length);
      const displayedNfts = filteredNfts.slice(0, displayedNftsCount);
      setExploreNftsFiltered(displayedNfts);
    }
  };

  useEffect(() => {
    calculateExploreNftsCount();
  }, [
    searchTerm,
    sortBy,
    category,
    tags,
    sliderValues,
    displayedNftsCount,
    selectedTraits,
    selectedTraitValues,
  ]);

  const handleLoadMore = () => {
    setDisplayedNftsCount((prevCount) => prevCount + 4);
  };

  const getCollectionInfo = async () => {
    // const provider = await getProvider();
    const registryContract = await getRegistryContract();
    const collectionContract = await getEthersContract(address);
    const colContactNftCount = await collectionContract.totalSupply();
    setTotalSupplyNfts(colContactNftCount);

    const getData = await registryContract.getContractByAddress(address);

    // const hashIpfs = getData.collectionHash;

    // const hashLinkUrl = `${process.env.REACT_APP_DOMAIN}/ipfs/${hashIpfs}`;

    const collectionOwner = getData.collectionOwner;

    // const info = await fetch(hashLinkUrl);
    // const infoJson = await info.json();

    // if (getData && infoJson) {
    //   setCollectionData({
    //     hash: hashIpfs,
    //     hashLinkUrl: hashLinkUrl,
    //     name: infoJson.name,
    //     description: infoJson.description,
    //     image: infoJson.image,
    //     owner: collectionOwner,
    //     banner: infoJson.banner,
    //     collectionContractAddr: address,
    //     externalLink: infoJson.external_link,
    //     date: infoJson.date,
    //   });
    // }
    if (getData) {
      setCollectionData({
        owner: collectionOwner,
      });
    }
  };

  useEffect(() => {
    const getNftsFromContract = async () => {
      const nftsArray = await getNFTsArray(address);
      return nftsArray;
    };
    getCollectionInfo();
    getNftsFromContract();
    setLoading(false);
  }, [address, navigate]);

  const handleVerifyAuthor = async () => {
    try {
      const result = await verifyUserAccount();
      if (
        result.success &&
        result.receipt &&
        result.receipt.events[0].event === "AccountVerified"
      ) {
        setIsAuthorVerified(true);
        setGlobalState("isAccountVerified", true);
        setIsAccountVerified(true);
      }
    } catch (error) {
      console.error("Error verifying user:", error);
    }
  };
  const getIsAuthorVerified = async () => {
    try {
      if (collectionData && collectionData.owner) {
        const result = await checkIsAuthorVerified(collectionData.owner);

        if (result === true) {
          setIsAuthorVerified(true);
        } else {
          setIsAuthorVerified(false);
        }
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };

  useEffect(() => {
    getIsAuthorVerified();
  }, [connectedAccount, address, collectionData, collectionDBData, navigate]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [sectionDUBX4, setSectionDUBX4] = useState(true);
  const handleSec4 = () => {
    setSectionDUBX4(!sectionDUBX4);
  };
  const [sectionDUBXFilter, setSectionDUBXFilter] = useState(false);
  const handleSecFilter = (traitType) => {
    setTraitTypeVisibility((prevVisibility) => ({
      ...prevVisibility,
      [traitType]: !prevVisibility[traitType],
    }));
  };
  return (
    <div className="relative bg-[#151c25] min-h-screen">
      {loading ? (
        // <p>Loading NFTs...</p>
        <div className="flex items-center justify-center h-screen">
          <SectionLoader />
        </div>
      ) : (
        <>
          {/* ---------------- COVER IMAGE ---------------- */}
          <div className="exploreBG">
            <div className="h-[300px] relative">
              <img
                loading="lazy"
                decoding="async"
                src={collectionDBData?.banner}
                alt="Collection Banner"
                className="w-full h-full object-cover"
                style={{ overflow: "hidden" }}
              />
            </div>
          </div>

          {/* COLLECTION NAME */}
          <div className="w-full flex flex-col items-center border-b border-t border-[#e32970] bg-[#000000e3] cursor-default relative justify-center">
            {/* COLLECTION NAME */}
            <div
              onClick={handleSec4}
              className="flex py-6 justify-center items-center cursor-pointer"
            >
              {/* ARROW */}
              <div
                onClick={handleSec4}
                className="block z-10 mr-12 cursor-pointer"
              >
                {sectionDUBX4 ? (
                  <BsArrowUpCircleFill size={40} style={{ fill: "#fff" }} />
                ) : (
                  <BsFillArrowDownCircleFill
                    size={40}
                    style={{ fill: "#fff" }}
                  />
                )}
              </div>

              <p className="text-3xl min-[500px]:text-5xl font-Bakbak text-[#e32970] pt-3 cursor-default text-center">
                {/* {authorInfo?.Item?.name && ( */}
                <span className="">{collectionDBData?.name}</span>
                {/* )} */}
              </p>

              {/* ARROW */}
              <div
                onClick={handleSec4}
                className="block z-10 ml-12 cursor-pointer"
              >
                {sectionDUBX4 ? (
                  <BsArrowUpCircleFill size={40} style={{ fill: "#fff" }} />
                ) : (
                  <BsFillArrowDownCircleFill
                    size={40}
                    style={{ fill: "#fff" }}
                  />
                )}
              </div>
            </div>
            <div
              className={sectionDUBX4 ? "flex justify-between py-6 " : "hidden"}
            >
              {/* INFORMATIONS */}
              <div className="flex flex-col xl:flex-row w-full justify-around items-center pt-5 bg-[#000000e3]">
                {/* BOX 1 */}
                <div className="tab w-[300px] md:w-[570px] mx-4">
                  <div
                    className={`tf-work style-2 wow fadeInUp h-[300px] myshadow flex flex-col items-center justify-center`}
                  >
                    <p className="text-xl font-bold text-[#e32970] mt-4">
                      Owned By:
                    </p>

                    <Link to={`/author/${collectionDBData?.owner}`}>
                      <p className="text-md font-bold text-white">
                        {window.innerWidth < 768
                          ? shortenAddress(collectionDBData?.owner)
                          : collectionDBData?.owner}
                      </p>
                    </Link>
                    {parseFloat(totalSupplyNfts).toString() > 0 && (
                      <p className="text-xl font-bold text-[#e32970] pt-3 cursor-default">
                        Total NFTs:
                        <span className="text-right text-white ml-[5px]">
                          {parseFloat(totalSupplyNfts).toString()}
                        </span>
                      </p>
                    )}
                    <p className="text-xl font-bold text-[#e32970] mt-4">
                      Collection contract:
                    </p>
                    <p className="text-md font-bold text-white">
                      {window.innerWidth < 768
                        ? shortenAddress(
                            collectionDBData?.collectionContractAddr
                          )
                        : collectionDBData?.collectionContractAddr}
                    </p>
                    <p className="text-xl font-bold text-[#e32970] pt-3 cursor-default">
                      <span>Created:</span>
                    </p>
                    <p className="flex items-center text-[#e32970] text-lg mb-5">
                      <span className="text-right text-white">
                        {moment(collectionDBData?.date * 1000).format(
                          " DD.MMM.YY HH:mm:ss"
                        )}{" "}
                      </span>
                    </p>
                    <div className="flex justify-center">
                      {collectionDBData?.externalLink && (
                        <a
                          href={`${collectionDBData?.externalLink}`}
                          className="text-xl font-bold w-full z-40 flex items-center mb-5"
                          target="_blank"
                          rel="nofollow noopener noreferrer external"
                        >
                          <span className="mr-[10px] text-3xl text-white">
                            <AiOutlineGlobal />
                          </span>
                          <p className="text-xl font-bold w-full text-white">
                            {window.innerWidth < 1768
                              ? `${collectionDBData?.externalLink.substring(
                                  0,
                                  10
                                )}...`
                              : collectionDBData?.externalLink}
                          </p>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                {/* BOX 2 */}
                <div className="tab w-[300px] md:w-[270px] mx-4">
                  <div
                    className={`tf-work1 style-2 wow fadeInUp h-[300px] myshadow  `}
                  >
                    <div className="text-[#000] flex items-center justify-center h-full">
                      <div
                        className="bg-[#040507] border-[#e32970] w-32 h-32 border-4 rounded-xl"
                        style={{
                          backgroundImage: `url('${
                            collectionDBData?.image || TopSeller6
                          }')`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                        }}
                      >
                        {" "}
                      </div>
                    </div>
                  </div>
                </div>
                {/* BOX 3 */}
                <div className="tab w-[300px] md:w-[570px] mx-4">
                  <div
                    className={`tf-work style-2 wow fadeInUp h-[300px] myshadow flex flex-col items-center justify-center`}
                  >
                    <p className="text-xl font-bold text-[#e32970] mt-4">
                      Floor:
                      <span className="text-right text-white ml-[5px]">
                        {collectionDBData?.floor?.toFixed(4)} DUBX
                      </span>
                    </p>
                    <p className="text-xl font-bold text-[#e32970] my-4">
                      Total:
                      <span className="text-right text-white ml-[5px]">
                        {collectionDBData?.totalCost?.toFixed(4)} DUBX
                      </span>
                    </p>
                    {/* CREATE NFT BUTTON */}
                    <Link to={"/create-nft"}>
                      <button
                        className="inline shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#e32970] md:text-[21px] py-2 mr-3 px-[15px]
                    cursor-pointer w-[200px] font-Bakbak transition-all ease-out duration-300"
                      >
                        Create NFT
                      </button>
                    </Link>
                    <div className="">
                      <button
                        type="button"
                        onClick={toggleModal}
                        className="shadow-xl shadow-black text-[#fff] hover:text-black
                    bg-[#e32970] hover:bg-[#21e786] md:text-[24px] py-2 px-[15px]
                        cursor-pointer font-Bakbak w-[300px] mt-3 transition-all ease-out duration-300"
                        style={{ margin: "20px auto" }}
                      >
                        Description
                      </button>
                    </div>
                    {/* Main modal */}
                    {isModalOpen && (
                      <div
                        className="fixed z-50 inset-0 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden"
                        tabIndex="-1"
                        aria-hidden="true"
                      >
                        <div className="relative p-4 w-full max-w-2xl max-h-full">
                          {/* Modal content */}
                          <div className="relative bg-[#e32970] text-white rounded-lg shadow dark:[#1e90ff]">
                            {/* Modal header */}
                            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                              <h3 className="text-xl font-semibold dark:text-white">
                                About Collection
                              </h3>

                              <button
                                type="button"
                                onClick={toggleModal}
                                className="text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                                data-modal-hide="static-modal"
                              >
                                <svg
                                  className="w-3 h-3"
                                  aria-hidden="true"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 14 14"
                                >
                                  <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                                  />
                                </svg>
                                <span className="sr-only">Close modal</span>
                              </button>
                            </div>
                            {/* Modal body */}
                            <div className="p-4 md:p-5 space-y-4">
                              <p className="text-base leading-relaxed text-gray-200 dark:text-gray-300">
                                {/* {authorInfo.Item.bio} */}
                                {collectionDBData?.description}
                              </p>
                            </div>
                            {/* Modal footer */}
                            <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 justify-end">
                              <button
                                onClick={toggleModal}
                                type="button"
                                className="ms-3 text-[18px] text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 font-Bakbak px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                              >
                                Close
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* SEARCH, FILTER, NFTS */}
          <div className="w-full planetBg relative mt-[2px]">
            {/* VERIFY */}
            <div className="absolute right-0 top-0">
              {isAuthorVerified && (
                <button className="ml-6 border rounded-lg font-Bakbak bg-[#e32970] border-[#e32970] flex items-center px-4 py-1 text-white m-2">
                  VERIFIED{" "}
                  <span className="inline-block p-1 text-sm font-semibold leading-none text-black bg-[#fff] hover:bg-[#000] hover:text-white rounded-full align-top ml-1 mt-[-5px]">
                    <MdOutlineVerifiedUser />
                  </span>
                </button>
              )}
            </div>
            <div className="flex-grow mb-8 pt-8">
              <div className="relative flex flex-col sm:flex-row items-center w-full m-auto my-[15px] justify-center">
                <input
                  type="search"
                  id="default-search"
                  className="block w-2/3 p-2 text-lg text-[#21e786] border rounded-lg bg-transparent"
                  placeholder="Search Nft by Title, ID or Author address..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-2 px-[45px] ml-5
                    cursor-pointer font-Bakbak mt-6 sm:mt-0"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row z-50 pb-0 min-[1202px]:pb-[250px]">
              {nfts && nfts.length > 0 && (
                <div className="md:flex w-full lg:w-[20%] min-[1202px]:flex mt-24">
                  <style>{rangeInputStyles}</style>
                  <nav className="flex flex-col lg:flex-row shadow-md filterVideo items-center min-[1202px]:items-start w-[80%] lg:w-[20%]">
                    {/*  ---------------- FILTER ---------------- */}

                    <div className="w-full px-6 md:px-24 lg:px-0 lg:w-64">
                      <div
                        className={`px-4 ${
                          expanded
                            ? "flex flex-col items-start"
                            : "flex flex-col items-start"
                        }
          `}
                      >
                        {/* CATEGORY */}
                        <div className="flex-grow w-full">
                          <label
                            htmlFor="category"
                            className="text-sm font-medium text-white"
                          >
                            Category
                          </label>
                          <select
                            id="category"
                            className="block w-full text-sm text-slate-500 mt-2 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                            name="category"
                            onChange={handleCategoryChange}
                            value={category}
                            required
                          >
                            <option value="all">Select Category</option>
                            <option value="Illustration">Illustration</option>
                            <option value="Art">Art</option>
                            <option value="Sport">Sport</option>
                            <option value="Music">Music</option>
                            <option value="Animation">Animation</option>
                            <option value="Gaming">Gaming</option>
                            <option value="3D">3D</option>
                            <option value="real-estate">Real Estate</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>

                        {/* TAGS */}
                        <div className="flex-grow w-full my-8">
                          <label
                            htmlFor="tags"
                            className="text-sm font-medium text-white"
                          >
                            Tags
                          </label>
                          <select
                            id="tags"
                            className="block w-full mt-2 text-sm text-slate-500 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                            name="tags"
                            onChange={(e) => setTags(e.target.value)}
                            value={tags}
                            required
                          >
                            <option value="all">Select Tag</option>
                            <option value="Cyber">Cyber</option>
                            <option value="Cosmic">Cosmic</option>
                            <option value="Digital">Digital</option>
                            <option value="Magic">Magic</option>
                            <option value="Electric">Electric</option>
                            <option value="Animal">Animal</option>
                            <option value="Pixel">Pixel</option>
                            <option value="VirtualReality">
                              VirtualReality
                            </option>
                            <option value="Nature">Nature</option>
                            <option value="Underwater">Underwater</option>
                            <option value="VinylRecords">VinylRecords</option>
                            <option value="Icons">Icons</option>
                            <option value="Extreme">Extreme</option>
                            <option value="Portrait">Portrait</option>
                            <option value="Legend">Legend</option>
                            <option value="Retro">Retro</option>
                            <option value="Anime">Anime</option>
                            <option value="Fantasy">Fantasy</option>
                            <option value="Abstract">Abstract</option>
                            <option value="Characters">Characters</option>
                            <option value="Interactive">Interactive</option>
                            <option value="Virtual">Virtual</option>
                            <option value="Mistery">Mistery</option>
                            <option value="Food">Food</option>
                            <option value="AI">AI</option>
                            <option value="Recipe">Recipe</option>
                            <option value="Clothes">Clothes</option>
                            <option value="Landscape">Landscape</option>
                            <option value="Celebrities">Celebrities</option>
                            <option value="Robot">Robot</option>
                            <option value="Vehicles">Vehicles</option>
                            <option value="Luxury">Luxury</option>
                            <option value="Accessory">Accessory</option>
                            <option value="Toys">Toys</option>
                            <option value="Fitness">Fitness</option>
                            <option value="Remix">Remix</option>
                          </select>
                        </div>

                        {/* SORT BY */}
                        <div className="flex-grow w-full mb-8">
                          <label
                            htmlFor="sort"
                            className="text-sm font-medium text-white"
                          >
                            Sort by
                          </label>
                          <select
                            id="sort"
                            className="block w-full text-sm mt-2 text-slate-500 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                            name="sort"
                            onChange={(e) => setSortBy(e.target.value)}
                            value={sortBy}
                            required
                          >
                            <option value="default">Sort by</option>
                            <option value="PostingDate">
                              Posting Date (Newest)
                            </option>
                            <option value="lowToHigh">Price Low to High</option>
                            <option value="highToLow">Price High to Low</option>
                            <option value="IsListedForSale">
                              Listed For Sale
                            </option>
                            <option value="IsRemovedFromSale">
                              Removed From Sale
                            </option>
                          </select>
                        </div>

                        {/* PRICE RANGE */}
                        <div className="range-slider">
                          <p className="text-white">Price Range</p>
                          <span className="text-white" id="rangeValues"></span>
                          <input
                            value={sliderValues.slide1}
                            min={minMax.min}
                            max={minMax.max}
                            step="0.0001"
                            type="range"
                            onChange={(e) => handleSliderChange(e, "slide1")}
                          />
                          <input
                            value={sliderValues.slide2}
                            min={minMax.min}
                            max={minMax.max}
                            step="0.0001"
                            type="range"
                            onChange={(e) => handleSliderChange(e, "slide2")}
                          />
                          <div className="flex justify-between mt-[10px]">
                            <span className="text-sm text-gray-500">
                              {sliderValues.slide1} DUBX
                            </span>
                            <span className="text-sm text-gray-500">
                              {sliderValues.slide2} DUBX
                            </span>
                          </div>
                        </div>
                        <div className="pt-5 flex flex-col">
                          {uniqueTraitTypes.map((traitType) => {
                            const matchingTraits =
                              matchingTraitsMap[traitType] || [];
                            const displayedValues = uniqueTraitValues.filter(
                              (value) =>
                                matchingTraits.some(
                                  (trait) => trait.value === value
                                )
                            );
                            return (
                              <div
                                key={traitType}
                                className="flex items-center flex-col justify-center"
                              >
                                <div className="flex text-2xl uppercase border-b w-full py-3 ">
                                  <input
                                    type="checkbox"
                                    className="hidden"
                                    id={traitType}
                                    checked={selectedTraits.includes(
                                      traitType.toLowerCase()
                                    )}
                                    onChange={() =>
                                      handleTraitCheckboxChange(traitType)
                                    }
                                  />
                                  <label
                                    htmlFor={traitType}
                                    onClick={() => handleSecFilter(traitType)}
                                    className="cursor-pointer "
                                  >
                                    {traitType}
                                  </label>

                                  <div
                                    onClick={() => handleSecFilter(traitType)}
                                    className="block z-10 ml-6 cursor-pointer"
                                  >
                                    {traitTypeVisibility[traitType] ? (
                                      <BsArrowUpCircleFill
                                        size={25}
                                        style={{ fill: "#fff" }}
                                      />
                                    ) : (
                                      <BsFillArrowDownCircleFill
                                        size={25}
                                        style={{ fill: "#fff" }}
                                      />
                                    )}
                                  </div>
                                </div>{" "}
                                <div
                                  className={`w-full flex flex-col justify-between ${
                                    traitTypeVisibility[traitType]
                                      ? "block"
                                      : "hidden"
                                  }`}
                                >
                                  {displayedValues.map((value) => {
                                    const count = matchingTraits.filter(
                                      (trait) => trait.value === value
                                    ).length;
                                    const traitKey = `${traitType}-${value}`;

                                    return (
                                      <div
                                        key={value}
                                        className="flex items-center text-xl"
                                      >
                                        <input
                                          type="checkbox"
                                          className="text-amber-500 form-tick appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-[#f10cea] checked:border-amber-500 mt-[3px]"
                                          id={traitKey}
                                          checked={selectedTraitValues.includes(
                                            traitKey
                                          )}
                                          onChange={() =>
                                            handleTraitValueCheckboxChange(
                                              traitType,
                                              value
                                            )
                                          }
                                          containerprops={{
                                            className: "mr-3",
                                          }}
                                        />

                                        <label
                                          htmlFor={traitKey}
                                          className="ml-[7px]"
                                        >
                                          {value} - {"("}
                                          {count}
                                          {")"}
                                        </label>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </nav>
                </div>
              )}
              {/* ---------------- FILTER ---------------- */}
              <div className="w-full flex flex-col text-white mt-12 mb-12 lg:ml-12">
                {/* ---------------- NFTS ---------------- */}
                <div className="w-full flex flex-wrap justify-evenly">
                  {nfts && nfts.length > 0 ? (
                    exploreNftsFiltered
                      .sort((a, b) => {
                        switch (sortBy) {
                          case "PostingDate":
                            return b.timestamp - a.timestamp;
                          case "lowToHigh":
                            return (
                              parseFloat(a.purchaseCost) -
                              parseFloat(b.purchaseCost)
                            );
                          case "highToLow":
                            return (
                              parseFloat(b.purchaseCost) -
                              parseFloat(a.purchaseCost)
                            );
                          default:
                            return b.timestamp - a.timestamp;
                        }
                      })
                      .map((nft) => {
                        const isCategoryMatch =
                          category === "all" ||
                          nft.category.toLowerCase() === category.toLowerCase();
                        const isTagMatch =
                          tags === "all" ||
                          (nft.tags &&
                            Array.isArray(nft.tags) &&
                            nft.tags.some(
                              (tag) =>
                                tag &&
                                tag.value &&
                                tag.value.toLowerCase() === tags.toLowerCase()
                            ));

                        const isPriceInRange =
                          nft.purchaseCost >= sliderValues.slide1 &&
                          nft.purchaseCost <= sliderValues.slide2;

                        if (isCategoryMatch && isTagMatch && isPriceInRange) {
                          return (
                            <div
                              key={nft.SK}
                              className="w-[330px] mt-6 flex flex-col "
                              style={{ minHeight: "330px" }}
                            >
                              <div className="block w-full h-full tf-work tf-product style-2 overflow-hidden bg-[#141b22] shadow-lg p-[5px] group ">
                                <Link to={`/n/${nft.contract}/${nft.SK}`}>
                                  <figure className="coverIMG">
                                    <LazyLoadImage
                                      src={nft.imageURI}
                                      loading="lazy"
                                      decoding="async"
                                      alt="collection img"
                                      className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105"
                                    />
                                  </figure>

                                  <div className="flex mt-3 border-b">
                                    <h2 className="font-bold mt-2 text-xl mb-3 text-center text-[#21e786]">
                                      {nft.title} #{nft.SK}
                                    </h2>
                                  </div>
                                </Link>
                                <div className="tooltip-container">
                                  <Link to={`/author/${nft.owner}`}>
                                    <div className="flex mt-3 justify-between border-b pb-2">
                                      <p className="text-white font-Bakbak flex items-center">
                                        {shortenAddress(nft.owner)}
                                        <span className="ml-2">
                                          <FaExternalLinkAlt />
                                        </span>
                                      </p>
                                      <p className="text-white">
                                        {nft.purchaseCost} DUBX
                                      </p>
                                    </div>
                                  </Link>
                                  <div className="tooltip-content">
                                    {nft.owner}
                                  </div>
                                </div>
                                <Link to={`/n/${address}/${nft.SK}`}>
                                  <div className="text-center text-lg mt-3 bg-[#21e786] text-black font-Bakbak hover:bg-[#f10cea] hover:text-white transition-all duration-300">
                                    <button className="font-Bakbak py-2">
                                      DETAILS
                                    </button>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })
                  ) : (
                    <p>No NFTs available</p>
                  )}
                  {displayedNftsCount < visibleNFTsCount && (
                    <div className="flex items-center justify-center mt-8 w-full">
                      <button
                        className="shadow-xl shadow-black text-[#000] hover:text-white
              bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-3 px-[15px]
                cursor-pointer font-Bakbak w-[250px] z-[20]"
                        onClick={handleLoadMore}
                      >
                        Load More
                      </button>
                    </div>
                  )}
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SingleCollection;
