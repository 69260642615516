import React, { useState } from "react";
import ConfettiButton from "../components/ConfettiButton";

const MessageModal = ({ message, show, showUl, showConf, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {show && (
        <div className="overlayMessageHolder">
          <div className="message-modal" style={{ zIndex: 1000 }}>
            <div
              className="flex flex-col justify-center
            items-center bg-[#151c25] rounded-xl 
            min-w-min py-[20px] relative"
            >
              {showConf && <ConfettiButton />}
              <span className="close-button" onClick={handleClose}>
                &times;
              </span>
              <p className="text-[#f10cea] text-center">{message}</p>
              {showUl && (
                <ul className="dottedDUBX mt-[10px]">
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MessageModal;
