import React, { useState, useEffect } from "react";
import Place from "../assets/contact/place.svg";
import Phone from "../assets/contact/phone.svg";
import Email from "../assets/contact/email.svg";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import MessageModal from "../components/MessageModal";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Contact = () => {
  const [loading, setLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);
  useEffect(() => {
    const stopLoader = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 5000));
        setLoading(false);
      } catch (error) {
        console.log(error);
      }
    };
    stopLoader();
  }, []);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleShowMessageModal = (newMessage) => {
    setInfoMessage(newMessage);
    setShowMessageModal(true);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    try {
      handleShowMessageModal("Message sent successfully!", false, true);
      const response = await fetch(
        "https://formsubmit.co/ajax/625c11fdeaf938dd1df3803d47ad771c",
        {
          method: "POST",
          body: formData,
        }
      );

      const data = await response.json();
      if (data.success) {
        handleShowMessageModal("Message sent successfully!", false, true);
      } else {
        handleShowMessageModal("Message sent successfully!", false, true);
      }
    } catch (error) {
      console.log(error);
      handleShowMessageModal("Message sent successfully!", false);
    }
  };

  return (
    <div className="backgroundHowItWorks border-b">
      {/* ----------------------------------- ANIMATED BG STARS ----------------------------------- */}
      <div>
        <div id="stars"></div>
        <div id="stars2"></div>
      </div>
      {loading && <Loader />}
      {/* ---------------- MAIN IMG ---------------- */}
      <div className="w-full h-[300px] flex flex-col justify-center pt-40 pb-12 border-b text-white items-center">
        {/* img */}
        <h1 className="text-7xl font-bold cursor-default">Contact</h1>
        <div className="flex mt-3">
          <Link to="/">
            <h2 className="border-r pr-4 cursor-pointer hover:text-[#21e786] text-sm">
              HOME
            </h2>
          </Link>
          <h2 className="pl-4 text-[#21e786] cursor-default text-sm">
            CONTACT
          </h2>
        </div>
      </div>

      {/* ---------------- CONTACT ---------------- */}
      <div className="w-full flex flex-col md:flex-row justify-center py-24 text-white cursor-default items-center md:px-12">
        <div className="w-[90%] md:w-[450px] h-[250px] border text-center flex flex-col items-center justify-center">
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            decoding="async"
            src={Place}
            className="mb-8 mt-[-10px]"
          />
          <h1 className="text-5xl">Location</h1>
          <p>Kingdom of Bahrain, Road 4612</p>
        </div>
        <div className="w-[90%] md:w-[450px] h-[250px] border text-center flex flex-col items-center justify-center my-3 md:my-0 md:mx-6">
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            decoding="async"
            src={Email}
            className="mb-8 mt-[-10px]"
          />

          <h1 className="text-5xl">Email</h1>
          <p>nft@arabianchain.org</p>
        </div>
        {/* <div className="w-[90%] md:w-[450px] h-[250px] border text-center flex flex-col items-center justify-center">
          <LazyLoadImage
            effect="blur"
            loading="lazy"
            decoding="async"
            src={Phone}
            className="mb-8 mt-[-10px]"
          />
          <h1 className="text-5xl">Phone</h1>
          <p>+1 666 8888</p>
        </div> */}
      </div>
      {/* ---------------- SOCIAL ---------------- */}
      {/* <div className="containerContact flex justify-center min-[951px]:pl-[22%]">
        <ul className="accordionContact">
          <li className="tabContact">
            <div className="socialContact youtubeContact">
              <a
                href="https://www.linkedin.com/company/arabianchain-technology-company/"
                target="_blank"
                rel="noopener noreferrer"
                className=""
              >
                Youtube
              </a>
            </div>
            <div className="contentContactPage">
              <h1>YouTube</h1>
              <p>
                Follow me on YouTube for tutorials with the latest technology in
                web development
              </p>
            </div>
          </li>
          <li className="tabContact">
            <div className="socialContact twitterContact">
              <a
                href="https://twitter.com/arabianchaintec"
                target="_blank"
                rel="noopener noreferrer"
              >
                X
              </a>
            </div>
            <div className="contentContactPage">
              <h1>X</h1>
              <p>
                Follow me on X for video and course updates, article & blog
                shares, and more
              </p>
            </div>
          </li>
          <li className="tabContact">
            <div className="socialContact facebookContact">
              <a
                href="https://twitter.com/arabianchaintec"
                target="_blank"
                rel="noopener noreferrer"
              >
                Facebook
              </a>
            </div>
            <div className="contentContactPage">
              <h1>Facebook</h1>
              <p>
                Follow me on Facebook to stay up to date with Traversy Media and
                get notified of new videos, publications, etc
              </p>
            </div>
          </li>
          <li className="tabContact">
            <div className="socialContact linkedinContact">
              <a
                href="https://www.linkedin.com/company/arabianchain-technology-company/"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>
            </div>
            <div className="contentContactPage">
              <h1>LinkedIn</h1>
              <p>Connect with me on a professional level on Linkedin</p>
            </div>
          </li>
          <li className="tabContact">
            <div className="socialContact instagramContact">
              <a
                href="https://github.com/DUBXCOIN/go-dubxcoin"
                target="_blank"
                rel="noopener noreferrer"
              >
                Instagram
              </a>
            </div>
            <div className="contentContactPage">
              <h1>Instagram</h1>
              <p>
                Follow me on Instagram for a more personal look into my life and
                work
              </p>
            </div>
          </li>
          <li className="tabContact">
            <div className="socialContact githubContact">
              <a
                href="https://github.com/DUBXCOIN/go-dubxcoin"
                target="_blank"
                rel="noopener noreferrer"
              >
                Github
              </a>
            </div>
            <div className="contentContactPage">
              <h1>Github</h1>
              <p>
                Check out my Github for tutorial code, my personal projects, and
                more
              </p>
            </div>
          </li>
        </ul>
      </div> */}

      {/* ---------------- TITLE / FORM ---------------- */}
      <div className="text-white pb-40 flex flex-col w-full items-center">
        <h1 className="text-center font-bold text-5xl pb-4 cursor-default pt-5">
          Get In Touch
        </h1>

        {/*FORM */}
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col w-full items-center justify-center pt-6">
            {/* USERNAME / EMAIL */}
            <div className="flex flex-col lg:flex-row w-[90%] md:w-[500px] lg:w-1/3 xl:w-[560px]">
              <div className="lg:w-72 lg:pr-3">
                <label
                  htmlFor="first_name"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  First name
                </label>
                <input
                  type="text"
                  id="first_name"
                  name="first_name"
                  className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="name"
                  required
                />
              </div>
              <div className="w-full pl-0 mt-6 lg:mt-0 lg:pl-3">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Enter your email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className="bg-transparent border border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="email"
                  required
                />
              </div>
            </div>

            {/* MESSAGE */}
            <div className="w-[90%] md:w-[500px] lg:w-1/3 xl:w-[560px] mt-6">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Your message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="block p-2.5 w-full text-sm text-white bg-transparent rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Write your message here..."
              ></textarea>
            </div>
          </div>
          <input type="hidden" name="_captcha" value="true" />
          {/* BUTTON */}
          <div className="flex justify-center pt-6">
            <button
              type="submit"
              className="shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] text-[20px] md:text-[24px] py-2 px-[45px]
                    cursor-pointer font-Bakbak"
            >
              SEND MESSAGE
            </button>
          </div>
        </form>
      </div>
      <div>
        <MessageModal
          message={infoMessage}
          show={showMessageModal}
          onClose={handleCloseMessageModal}
        />
      </div>
    </div>
  );
};

export default Contact;
