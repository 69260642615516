import React, { useRef } from "react";
import CategoryImg1 from "../assets/Office.png";
import CategoryImg2 from "../assets/categories/3d.png";
import CategoryImg3 from "../assets/categories/games.png";
import CategoryImg4 from "../assets/categories/animation.png";
import CategoryImg6 from "../assets/categories/music.png";
import CategoryImg7 from "../assets/categories/sport.png";
import CategoryImg8 from "../assets/categories/art.png";
import CategoryImg9 from "../assets/categories/illustration.png";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const CategoryMain = () => {
  return (
    <div className="flex flex-col bg-black text-white lg:p-12">
      <h1 className="text-5xl font-bold pb-24 cursor-default text-[#21e786] pt-12 lg:pt-0">
        CATEGORIES
      </h1>
      <div className="flex flex-wrap justify-around">
        {/* ----------- 1 ----------- */}
        <Link to="/explore/illustration" className="min-[1520px]:w-[25%] mb-4">
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">Illustration</h2>
              <p>50+ Items</p>
            </div>
            <div className="w-[100px] h-[100px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg9}
              />
            </div>
          </div>
        </Link>
        {/* ----------- 2 ----------- */}
        <Link to="/explore/art" className="min-[1520px]:w-[25%] mb-4 flex-wrap">
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">Art</h2>
              <p>120+ Items</p>
            </div>
            <div className="w-[100px] h-[100px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg8}
              />
            </div>
          </div>
        </Link>
        {/* ----------- 3 ----------- */}
        <Link
          to="/explore/sport"
          className="min-[1520px]:w-[25%] mb-4 flex-wrap"
        >
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">Sport</h2>
              <p>50+ Items</p>
            </div>
            <div className="w-[100px] h-[100px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg7}
              />
            </div>
          </div>
        </Link>
        {/* ----------- 4 ----------- */}
        <Link
          to="/explore/music"
          className="min-[1520px]:w-[25%] mb-4 flex-wrap"
        >
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">Music</h2>
              <p>40+ Items</p>
            </div>
            <div className="w-[100px] h-[100px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg6}
              />
            </div>
          </div>
        </Link>
        {/* ----------- 5 ----------- */}
        <Link
          to="/explore/animation"
          className="min-[1520px]:w-[25%] mb-4 flex-wrap"
        >
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">Animation</h2>
              <p>10+ Items</p>
            </div>
            <div className="w-[100px] h-[100px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg4}
              />
            </div>
          </div>
        </Link>
        {/* ----------- 6 ----------- */}
        <Link
          to="/explore/gaming"
          className="min-[1520px]:w-[25%] mb-4 flex-wrap"
        >
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">Gaming</h2>
              <p>150+ Items</p>
            </div>
            <div className="w-[100px] h-[100px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg3}
              />
            </div>
          </div>
        </Link>
        {/* ----------- 7 ----------- */}
        <Link to="/explore/3d" className="min-[1520px]:w-[25%] mb-4 flex-wrap">
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">3D</h2>
              <p>99+ Items</p>
            </div>
            <div className="w-[100px] h-[100px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg2}
              />
            </div>
          </div>
        </Link>
        {/* ----------- 8 ----------- */}
        <Link
          to="/explore/real-estate"
          className="min-[1520px]:w-[25%] mb-4 flex-wrap"
        >
          <div className="cursor-pointer border border-[#21e786] w-[350px] h-[140px] bgSortImgs rounded-lg flex items-center justify-around hover:shadow-md hover:shadow-[#21e786] transition-all duration-200">
            <div className="flex flex-col">
              <h2 className="font-semibold text-[30px]">Real Estate</h2>
              <p>50+ Items</p>
            </div>
            <div className="w-[90px] h-[90px]">
              <LazyLoadImage
                effect="blur"
                loading="lazy"
                decoding="async"
                className="imgHover w-full h-auto"
                src={CategoryImg1}
              />
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default CategoryMain;
