import React, { useState, useRef, useEffect } from "react";
import { useDrag, useDrop } from "react-dnd";
import { create as ipfsHttpClient } from "ipfs-http-client";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import MessageModal from "../components/MessageModal";
import ConfettiContainer from "../components/ConfettiContainer";
import { useGlobalState, setGlobalState, getGlobalState } from "../store";
import {
  shortenAddress,
  getProvider,
  getRegistryContract,
  adminCost,
  loadCollections,
} from "../Blockchain.Services";
import abiData from "../abis/CollectionERC721.json";

const CreateCollectionPage = () => {
  //step 1
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const [itemOne, setItemOne] = useState({ id: 1, image: null });
  const [itemTwo, setItemTwo] = useState({ id: 2, image: null });
  const [step, setStep] = useState(1);
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [collectionName, setCollectionName] = useState("");
  const [collectionDescription, setCollectionDescription] = useState("");
  const [collectionExternalLink, setCollectionExternalLink] = useState("");
  const [dbRegistryId, setDbRegistryId] = useState(null);
  const [dbContract, setDbContract] = useState(null);
  const [dbCollHash, setDbCollHash] = useState("");
  const [dbDate, setDbDate] = useState(0);
  const [dbImage, setDbImage] = useState("");
  const [dbBanner, setDbBanner] = useState("");
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [showConfettiContainer, setShowConfettiContainer] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [showUlMessageModal, setShowUlMessageModal] = useState(true);
  const [showConfMessageModal, setShowConfMessageModal] = useState(false);

  const { ethereum } = window;

  const [imageNameSizeType, setImageNameSizeType] = useState({
    name: "",
    size: "",
    type: "",
  });
  const [bannerNameSizeType, setBannerNameSizeType] = useState({
    name: "",
    size: "",
    type: "",
  });

  let fileInputRef = useRef(null);
  let file2InputRef = useRef(null);
  const navigate = useNavigate();

  const handleShowMessageModal = (
    newMessage,
    showUl = true,
    showConf = false
  ) => {
    setInfoMessage(newMessage);
    setShowMessageModal(true);
    setShowUlMessageModal(showUl);
    setShowConfMessageModal(showConf);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };

  // const ipfs = ipfsHttpClient({
  //   url: "http://localhost:5001/api/v0",
  // });
  const CreateCollection = () => {
    const [{ isDragging }, drag] = useDrag({
      type: "ITEM",
      itemOne: { id: 1, image: itemOne.image },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem) => {
        setItemOne({ id: 1, image: draggedItem.image });
      },
    });

    const handleImageOneUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        setImageOne(file);
        setItemOne({ id: 1, image: URL.createObjectURL(file) });
        setImageNameSizeType({
          name: e.target.files[0].name,
          size: e.target.files[0].size,
          type: e.target.files[0].type,
        });
      }
    };

    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          padding: "8px",
          margin: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <input
          type="file"
          onChange={handleImageOneUpload}
          style={{ display: "none" }}
          ref={(fileInput) => (fileInputRef = fileInput)}
        />
        <img
          src={
            itemOne.image ||
            "https://dummyimage.com/260x260/1F2937/293545.png&text=Upload"
          } // Zamenska slika ako slika nije odabrana
          alt={`Upload Logo `}
          onClick={() => fileInputRef.click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (file) {
              const originalName = file.name;
              const extension = originalName.split(".").pop();
              const limitedName =
                originalName.length > 30
                  ? originalName.substring(0, 30) + "... ." + extension
                  : originalName;
              setImageOne(file);
              setItemOne({ id: 1, image: URL.createObjectURL(file) });
              setImageNameSizeType({
                name: limitedName,
                size: file.size,
                type: file.type,
              });
            }
          }}
          style={{
            width: "auto",
            height: "200px",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
        {isDragging && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "24px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 999,
            }}
          >
            DROP AND DRAG LIKE IT'S HOT
          </div>
        )}
      </div>
    );
  };
  const CreateCollectionBanner = () => {
    const [{ isDragging }, drag] = useDrag({
      type: "ITEM",
      itemTwo: { id: 2, image: itemTwo.image },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem) => {
        setItemTwo({ id: 2, image: draggedItem.image });
      },
    });

    const handleImageTwoUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        setImageTwo(file);
        setItemTwo({ id: 2, image: URL.createObjectURL(file) });
        setBannerNameSizeType({
          name: e.target.files[0].name,
          size: e.target.files[0].size,
          type: e.target.files[0].type,
        });
      }
    };

    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          padding: "8px",
          margin: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <input
          type="file"
          onChange={handleImageTwoUpload}
          style={{ display: "none" }}
          ref={(fileInput) => (file2InputRef = fileInput)}
        />
        <img
          src={
            itemTwo.image ||
            "https://dummyimage.com/1903x645/1F2937/293545.png&text=Upload"
          }
          alt={`Upload Logo `}
          onClick={() => file2InputRef.click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (file) {
              const originalName = file.name;
              const extension = originalName.split(".").pop();
              const limitedName =
                originalName.length > 30
                  ? originalName.substring(0, 30) + "... ." + extension
                  : originalName;
              setImageTwo(file);
              setItemTwo({ id: 2, image: URL.createObjectURL(file) });
              setBannerNameSizeType({
                name: limitedName,
                size: file.size,
                type: file.type,
              });
            }
          }}
          style={{
            width: "auto",
            height: "200px",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
        {isDragging && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "24px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 999,
            }}
          >
            DROP AND DRAG LIKE IT'S HOT
          </div>
        )}
      </div>
    );
  };

  const prepareCollection = async () => {
    try {
      // const uploadedImage = await ipfs.add(imageOne);
      // const imageOneURI = `${process.env.REACT_APP_DOMAIN}/ipfs/${uploadedImage.path}`;

      handleShowMessageModal("Adding image to IPFS...", true);
      const imageOneFormData = new FormData();
      imageOneFormData.append("file", imageOne);
      const imageOneOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
        },
        body: imageOneFormData,
      };
      const imageOneResponse = await fetch(
        `${process.env.REACT_APP_PINATA_PIN_FILE}`,
        imageOneOptions
      );
      const imageOneDataPinata = await imageOneResponse.json();
      const imageOneURI = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${imageOneDataPinata.IpfsHash}`;
      setDbImage(imageOneURI);

      // const uploadedBanner = await ipfs.add(imageTwo);
      // const imageTwoURI = `${process.env.REACT_APP_DOMAIN}/ipfs/${uploadedBanner.path}`;

      handleShowMessageModal("Adding banner to IPFS...", true);
      const imageTwoFormData = new FormData();
      imageTwoFormData.append("file", imageTwo);
      const imageTwoOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
        },
        body: imageTwoFormData,
      };
      const imageTwoResponse = await fetch(
        `${process.env.REACT_APP_PINATA_PIN_FILE}`,
        imageTwoOptions
      );
      const imageTwoDataPinata = await imageTwoResponse.json();
      const imageTwoURI = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${imageTwoDataPinata.IpfsHash}`;
      setDbBanner(imageTwoURI);

      const currentTimestamp = Date.now();
      const unixTime = Math.floor(currentTimestamp / 1000);
      setDbDate(unixTime);
      const jsonCollection = {
        name: collectionName,
        description: collectionDescription,
        image: imageOneURI,
        banner: imageTwoURI,
        date: unixTime,
        external_link: collectionExternalLink,
      };

      // const colIPFSResult = await ipfs.add(colJson);
      // const colIPFSLink = `${process.env.REACT_APP_DOMAIN}/ipfs/${colIPFSResult.path}`;

      handleShowMessageModal("Adding metadata for collection...", true);

      const colJsonOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonCollection),
      };

      const colJsonResponse = await fetch(
        `${process.env.REACT_APP_PINATA_PIN_JSON}`,
        colJsonOptions
      );

      if (!colJsonResponse.ok) {
        throw new Error("Failed to pin JSON to IPFS");
      }

      const colJsonDataPinata = await colJsonResponse.json();

      const colJsonURIPinata = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${colJsonDataPinata.IpfsHash}`;
      setDbCollHash(colJsonURIPinata);
      const resultPrepare = {
        image: imageOneURI,
        banner: imageTwoURI,
        date: unixTime,
        hash: colJsonDataPinata.IpfsHash,
      };

      return resultPrepare;
    } catch (error) {
      console.log("Error uploading images.", error);
    }
  };
  const submitCollection = async () => {
    const newCollectionTitle = collectionName;

    const newCollectionSymbol = collectionName.substring(0, 3);
    handleShowMessageModal("Preparing data...", true);
    const newCollectionCost = await adminCost();

    const costFormatted = ethers.utils.parseUnits(
      newCollectionCost.toString(),
      "ether"
    );
    const formattedInWei = costFormatted.toString();

    const result = await prepareCollection();

    const provider = await getProvider();

    if (provider) {
      if (provider instanceof ethers.providers.Web3Provider) {
        const signer = provider.getSigner();
        const contractFactory = new ethers.ContractFactory(
          abiData.abi,
          abiData.bytecode,
          signer
        );
        const overrides = {
          gasLimit: 7000000,
          gasPrice: 20000000000,
        };

        try {
          const tx = await contractFactory.deploy(
            newCollectionTitle,
            newCollectionSymbol,
            formattedInWei,
            result.hash,
            overrides
          );

          handleShowMessageModal("Deploying collection...", true);

          const deployedContract = await tx.deployed();
          console.log(
            "Contract deployed to address:",
            deployedContract.address
          );

          handleShowMessageModal(
            "Collection deployed, wait for registration...",
            true
          );
          const registryContract = await getRegistryContract();

          const registryResponse = await registryContract.registerCollection(
            deployedContract.address.toLowerCase(),
            result.hash,
            newCollectionTitle,
            {
              gasPrice: 20000000000,
            }
          );

          const receipt = await registryResponse.wait();
          // console.log("receipt", receipt);
          if (
            receipt.status === 1 &&
            receipt.events[0].args.contractAddress === deployedContract.address
          ) {
            const dbData = {
              title: collectionName,
              description: collectionDescription,
              externalLink: collectionExternalLink,
              imageURI: result.image,
              bannerURI: result.banner,
              date: result.date,
              hash: `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${result.hash}`,
              contract: deployedContract.address,
              owner: connectedAccount,
            };

            const requestUrlAuthor = `${
              process.env.REACT_APP_AWS_API_GATEWAY
            }/author?account=${connectedAccount.toLowerCase()}`;
            const responseFromDbAuthor = await fetch(requestUrlAuthor, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            });
            if (responseFromDbAuthor.ok) {
              const resultAuthor = await responseFromDbAuthor.json();
              // console.log("resultAuthor", resultAuthor);
              if (!resultAuthor.Item) {
                const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
                console.log("there is no author in db");
                const requestAuthorBody = {
                  account: connectedAccount.toLowerCase(),
                  timestamp: currentTimestampInSeconds,
                  verified: false,
                  authorPhoto: "",
                  bannerImage: "",
                  name: "",
                  email: "",
                  externalLink: "",
                  smartCode: "",
                  bio: "",
                  facebook: "",
                  twitter: "",
                  instagram: "",
                  skype: "",
                  nftCount: 0,
                  collectionCount: 0,
                };
                const requestUrlAuthor = `${process.env.REACT_APP_AWS_API_GATEWAY}/author`;
                const insertAuthor = await fetch(requestUrlAuthor, {
                  method: "PUT",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify(requestAuthorBody),
                });
                if (insertAuthor.ok) {
                  console.log("author inserted");
                }
              } else {
                console.log("there is already author in db");
              }
            }
            const requestUrlCollection = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection`;
            const responseFromDb = await fetch(requestUrlCollection, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(dbData),
            });

            if (responseFromDb.ok) {
              const responseSingleCollection = await responseFromDb.json();

              const currentColl = getGlobalState("newestCollections");
              const newestCollections = [
                responseSingleCollection.Item,
                ...currentColl,
              ];
              setGlobalState("newestCollections", newestCollections);

              setShowConfettiContainer(true);
              await new Promise((resolve) => setTimeout(resolve, 3000));
              setShowConfettiContainer(false);
              handleShowMessageModal(
                "Minting completed, in a few seconds you will see your new COLLECTION page !!!",
                false,
                true
              );
              await new Promise((resolve) => setTimeout(resolve, 3000));
              handleCloseMessageModal();
              navigate(`/collection/${deployedContract.address}`, {
                replace: true,
              });
            } else {
              handleShowMessageModal(
                "Check your profile page. Some error occured, try again.",
                false
              );
            }
          } else {
            handleShowMessageModal(
              "Check your profile page. Some error occured, try again.",
              false
            );
          }
        } catch (error) {
          console.error("Error creating collection:", error);
          handleShowMessageModal(
            "Check your profile page. Some error occured, try again.",
            false
          );
        }
      } else if (provider instanceof ethers.providers.JsonRpcProvider) {
        console.log("Provider is a JSON-RPC provider");
        handleShowMessageModal(
          "Check your profile page. Some error occured, try again.",
          false
        );
      }
    } else {
      console.log("Provider is undefined");
      handleShowMessageModal("Some error occured, try again.", false);
    }
  };

  return (
    <div>
      {step === 1 && (
        <div className="flex flex-col items-center justify-center mt-32">
          <div className="text-center text-white w-full flex flex-col justify-around items-center m-auto cursor-default z-40">
            <h1 className="text-5xl font-bold text-[#21e786] mt-16">
              CREATE COLLECTION
            </h1>
            <p className="w-[80%] md:1/3 py-12 text-xl font-semibold leading-10">
              To create and register collection, you will have to execute 2
              transactions.
            </p>
          </div>
          <div className="flex flex-col lg:flex-row w-full justify-center items-center mt-6">
            <div className="flex flex-col justify-center items-center w-full lg:w-1/3 px-12 lg:px-0">
              <label
                htmlFor="collectionName"
                className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white"
              >
                Collection Name
              </label>
              <input
                type="text"
                id="collectionName"
                className="bg-transparent border w-full mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Name of The Collection"
                onChange={(e) => setCollectionName(e.target.value)}
                value={collectionName}
                required
              />
              <label
                htmlFor="descriptionCollection"
                className="block mb-2 text-2xl font-bold text-gray-900 dark:text-white"
              >
                Description
              </label>
              <textarea
                id="descriptionCollection"
                rows="4"
                className="block p-2.5 w-full text-sm text-white bg-transparent rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Short description"
                onChange={(e) => setCollectionDescription(e.target.value)}
                value={collectionDescription}
              ></textarea>
              <label
                htmlFor="externalLink"
                className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white mt-5"
              >
                External link
              </label>
              <input
                type="text"
                id="externalLink"
                className="bg-transparent border w-full mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="https://myproject.com"
                onChange={(e) => setCollectionExternalLink(e.target.value)}
                value={collectionExternalLink}
              />
            </div>
          </div>

          <div className="w-full flex justify-center pt-12 my-12">
            <button
              // onClick={() => setStep(2)}
              onClick={async () => {
                if (!ethereum) {
                  handleShowMessageModal(
                    "Please install Metamask to continue.",
                    false,
                    false
                  );
                  return;
                } else if (ethereum && ethereum._state.isUnlocked === false) {
                  handleShowMessageModal(
                    "Please unlock Metamask to continue.",
                    false,
                    false
                  );
                  return;
                } else if (
                  ethereum &&
                  ethereum._state.isUnlocked === true &&
                  !connectedAccount
                ) {
                  handleShowMessageModal(
                    "Please connect Metamask account to continue.",
                    false,
                    false
                  );
                  return;
                } else if (
                  ethereum &&
                  ethereum._state.isUnlocked === true &&
                  connectedAccount
                ) {
                  const chainId = await ethereum.request({
                    method: "eth_chainId",
                  });
                  if (chainId === "0xcc5") {
                    if (
                      collectionName.trim() !== "" &&
                      collectionDescription.trim() !== ""
                    ) {
                      setStep(2);
                    } else {
                      handleShowMessageModal(
                        "Please fill in both Collection Name and Description.",
                        false,
                        false
                      );
                    }
                  } else {
                    handleShowMessageModal(
                      "Switch chain to dubxcoin network to continue.",
                      false,
                      false
                    );
                    return;
                  }
                }
              }}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#f10cea] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak"
            >
              Next Step
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="flex flex-col lg:flex-row w-full justify-center items-center min-h-screen h-full pb-6">
          <div className="flex flex-col justify-center items-center w-full lg:w-[50%]">
            {/* IMG OF COLLECTION */}
            <div className="flex flex-col sm:flex-row justify-center ">
              <div className="sm:mr-3">
                <h2 className="text-white text-2xl py-3 mt-6 text-center">
                  Logo Image
                </h2>
                <div className="w-full flex justify-center items-center">
                  <div className="flex w-56 sm:w-full justify-center">
                    <CreateCollection />
                  </div>
                </div>
                {imageNameSizeType && imageNameSizeType.name !== "" ? (
                  <div className="mx-auto w-56 text-gray-500 text-xs left mt-1">
                    <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                      Name: {imageNameSizeType.name}
                    </div>
                    <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                      Size: {imageNameSizeType.size}
                    </div>
                    <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                      Type: {imageNameSizeType.type}
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                    NFT image ( max 100 KB )
                  </div>
                )}
              </div>
              <div className="sm:ml-3">
                <h2 className="text-white text-2xl py-3 mt-6 text-center">
                  Cover Image
                </h2>
                <CreateCollectionBanner />
                {bannerNameSizeType && bannerNameSizeType.name !== "" ? (
                  <div className="mx-auto w-56 text-gray-500 text-xs left mt-1">
                    <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                      Name: {bannerNameSizeType.name}
                    </div>
                    <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                      Size: {bannerNameSizeType.size}
                    </div>
                    <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                      Type: {bannerNameSizeType.type}
                    </div>
                  </div>
                ) : (
                  <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                    NFT image ( max 100 KB )
                  </div>
                )}
              </div>
            </div>
            <div className="w-full flex justify-center pt-12 mt-12">
              <button
                onClick={() => setStep(1)}
                className="shadow-xl shadow-black text-[#fff]
                  bg-[#f10cea] hover:bg-[#21e786] hover:text-white md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
              >
                Previous Step
              </button>
              <button
                onClick={() => {
                  if (imageOne && imageTwo) {
                    submitCollection();
                  } else {
                    handleShowMessageModal(
                      "Please upload both images.",
                      false,
                      false
                    );
                  }
                }}
                className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#21e786] hover:text-white md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
              >
                Create and Register Collection
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        <MessageModal
          message={infoMessage}
          show={showMessageModal}
          showUl={showUlMessageModal}
          showConf={showConfMessageModal}
          onClose={handleCloseMessageModal}
        />
      </div>
      {showConfettiContainer && <ConfettiContainer />}
    </div>
  );
};

export default CreateCollectionPage;
