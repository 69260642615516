import React, { useEffect } from "react";
import DubxLogo from "../assets/AuditFinance.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="px-12 md:px-24 text-white lg:w-[70%] w-[100%]">
      <div className="fixed right-[13%] mt-[50%] lg:mt-[29%] xl:mt-[20%] userBounceIMG opacity-10 md:opacity-20 w-96">
        <LazyLoadImage
          src={DubxLogo}
          loading="lazy"
          decoding="async"
          alt="dubx_logo"
        />
      </div>
      <h1 className="pt-48 text-4xl">Privacy Policy</h1>
      <p className="text-md mt-7">
        Thank you for using the Dubxcoin NFTs Marketplace ("the Marketplace").
        Protecting your privacy is important to us. This Privacy Policy outlines
        how we collect, use, disclose, and safeguard your information when you
        use our website and services.
      </p>

      <p className="text-2xl font-semibold mt-24 underline">
        1. Information Collection:
      </p>
      <p className="text-md mt-7">
        We may collect personal information when you register an account, make a
        purchase, or engage with our platform. This may include your name, email
        address, payment details, and any other information you provide
        voluntarily.
      </p>
      <p className="text-2xl font-semibold mt-24 underline">
        2. Information Usage:
      </p>
      <p className="text-md mt-7">
        We use the information collected to provide and improve our services,
        process transactions, communicate with you, and personalize your
        experience on the Marketplace. We may also use aggregated, non-personal
        data for analytical purposes.
      </p>

      <p className="text-2xl font-semibold mt-24 underline">
        3. Information Sharing:
      </p>
      <p className="text-md mt-7">
        We do not sell, trade, or rent your personal information to third
        parties. However, we may share your information with trusted service
        providers who assist us in operating our website, conducting our
        business, or servicing you, as long as they agree to keep this
        information confidential.
      </p>

      <p className="text-2xl font-semibold mt-24 underline">
        4. Data Security:
      </p>
      <p className="text-md mt-7">
        We employ industry-standard security measures to protect your
        information from unauthorized access, alteration, disclosure, or
        destruction. However, please be aware that no method of transmission
        over the internet or electronic storage is 100% secure, and we cannot
        guarantee absolute security.
      </p>

      <p className="text-2xl font-semibold mt-24 underline">
        5. Third-Party Links:
      </p>
      <p className="text-md mt-7">
        Our website may contain links to third-party websites or services. We
        are not responsible for the privacy practices or content of these third
        parties. We encourage you to review the privacy policies of those
        websites or services before providing any personal information.
      </p>

      <p className="text-2xl font-semibold mt-24 underline">
        6. Children's Privacy:
      </p>
      <p className="text-md mt-7">
        Our website may contain links to third-party websites or services. We
        are not responsible for the privacy practices or content of these third
        parties. We encourage you to review the privacy policies of those
        websites or services before providing any personal information.
      </p>

      <p className="text-2xl font-semibold mt-24 underline">
        7. Updates to Privacy Policy:
      </p>
      <p className="text-md mt-7">
        Our website may contain links to third-party websites or services. We
        are not responsible for the privacy practices or content of these third
        parties. We encourage you to review the privacy policies of those
        websites or services before providing any personal information.
      </p>

      <p className="text-2xl font-semibold mt-24 underline">8. Contact Us:</p>
      <p className="text-md mt-7 mb-24">
        If you have any questions or concerns about this Privacy Policy or our
        practices, please contact us at [nftsupport@arabianchain.org]. By using
        the Marketplace, you consent to the terms of this Privacy Policy. Last
        updated 03/18/2024.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
