import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import Typed from "react-typed";
import Img1 from "../assets/homeMain1.jfif";
import Img2 from "../assets/homeMain2.jfif";
import Img3 from "../assets/homeMain3.jfif";
import { setGlobalState, useGlobalState } from "../store";
import About from "./About";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import Faq from "../pages/FAQ";
import { Link } from "react-router-dom";
import TopCreators from "../components/TopCreators";
import Tape from "../components/Tape";
import NftsTape from "../components/NftsTape";
import ItemLoader from "../components/ItemLoader";
import Loading from "../components/Loading";
import CategoryMain from "../components/CategoryMain";
import moment from "moment/moment";
import { CustomScroll } from "react-custom-scroll";
import {
  getContractName,
  getAllCollections,
  shortenAddress,
  getAllCollectionsWithData,
  getCollectionsFromDB,
} from "../Blockchain.Services";
import EasySteps from "../components/EasySteps";

const HomePage = () => {
  const [collectionsInfo, setCollectionsInfo] = useState([]);
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const [nfts, setNfts] = useGlobalState("nfts");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const getNewAllCollectionsFromDB = async (limit) => {
    try {
      const isLoadingCollections = true;
      let getAllCollectionsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection?limit=${limit}`;

      const response = await fetch(getAllCollectionsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        if (data && data.Items) {
          // console.log("data", data);
          const filteredCollectionsPackage = data.Items.filter(
            (item) => item.nftCount !== 0
          );

          const limitedCollections = filteredCollectionsPackage.slice(0, 10);
          setCollectionsInfo(limitedCollections);
        } else {
          setCollectionsInfo([]);
        }
      } else {
        console.error(
          "Failed to get getNewAllCollectionsFromDB:",
          response.statusText
        );
        return false;
      }
    } catch (error) {
      console.error("Error getting getNewAllCollectionsFromDB:", error.message);
      return false;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getNewAllCollectionsFromDB(20);
    };
    fetchData();
  }, []);

  return (
    <div className="bg-black text-center text-white">
      {/* ----------------------------------- ANIMATED BG STARS ----------------------------------- */}
      <div>
        <div id="stars3"></div>
        <div id="stars4"></div>
      </div>
      {/* ---------------- HOME ----------------  */}
      <div className="w-full mb-[-60px] bgHome bg-black flex justify-between">
        <div className="flex w-full h-screen items-center">
          {/* LEFT SIDE */}
          <div className="w-full md:w-[80%]">
            <div className="flex flex-col w-full md:w-[80%] m-auto text-center md:text-left items-center md:items-start">
              <h1
                className="text-5xl sm:text-4xl lg:text-[70px] py-2 font-bold cursor-default text-white"
                style={{ fontWeight: "700!important" }}
              >
                DUBX <span className="text-[#21e786]">NFT</span>
              </h1>
              <h1 className="text-4xl lg:text-7xl font-bold cursor-default underline text-white pb-12">
                Marketplace
              </h1>
              <Typed
                className="text-xl sm:text-[50px] md:text-4xl py-2 font-bold text-[#21e786] cursor-default"
                strings={["Discover", "Collect", "Buy and Sell"]}
                typeSpeed={120}
                backSpeed={140}
                loop
              />
              <p className="text-white pb-12 font-semibold text-[25px] mt-3">
                Mint and collect the hottest NFTs around.
              </p>
              <div className="flex max-[895px]:flex-col ">
                <Link to={"/create-nft"}>
                  <button
                    className="inline relative shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] text-[26px] md:text-[21px] py-2 min-[896px]:mr-3 px-[15px]
                    cursor-pointer w-[200px] max-[767px]:w-[300px] font-Bakbak transition-all ease-out duration-300"
                  >
                    Create NFT
                    <span className="absolute top-[-12px] right-[-12px] bg-[#f10cea] text-white py-1 px-2 rounded-full text-xs w-[60px]">
                      4 DUBX
                    </span>
                  </button>
                </Link>
                <Link to={"/create-collection"} className="mt-6 md:mt-0">
                  <button
                    className="inline relative shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] md:text-[21px] max-[896px]:mt-3 min-[896px]:ml-3 py-2 px-[15px]
                    cursor-pointer w-[200px] max-[767px]:w-[300px] text-[26px] font-Bakbak transition-all ease-out duration-300"
                  >
                    Create Collection
                    <span className="absolute top-[-12px] right-[-12px] bg-[#483d8b] text-white py-1 px-2 rounded-full text-xs w-[60px]">
                      FREE
                    </span>
                  </button>
                </Link>
              </div>
            </div>
          </div>

          {/* RIGHT SIDE */}
          <div className="md:flex h-[600px] w-full justify-center xl:justify-between pr-12 hidden">
            <div className="rounded-lg transition-all duration-200">
              <LazyLoadImage
                src={Img1}
                loading="lazy"
                decoding="async"
                alt="Img1"
                className="w-[320px] object-cover h-full rounded-xl imgGlow2"
              />
            </div>
            <div className="rounded-lg transition-all duration-200 hidden xl:flex mx-3">
              <LazyLoadImage
                src={Img2}
                loading="lazy"
                decoding="async"
                alt="Img2"
                className="w-[320px] object-cover h-full rounded-xl imgGlow3"
              />
            </div>
            <div className="rounded-lg transition-all duration-200 hidden xl:flex">
              <LazyLoadImage
                src={Img3}
                loading="lazy"
                decoding="async"
                alt="Img3"
                className="w-[320px] object-cover h-full rounded-xl imgGlow4"
              />
            </div>
          </div>
        </div>
      </div>
      <Tape />
      <About />
      <CategoryMain />
      <TopCreators />

      {/* ---------------- EXPLORE ---------------- */}
      <div className=" border-[#21e786] mt-12 overflow-hidden">
        <Link to={"/explore/all"}>
          <h1 className="pt-12 pb-24 text-5xl font-bold text-[#21e786] hover:text-white transition-all duration-200">
            EXPLORE NFTS
          </h1>
        </Link>

        <NftsTape />
      </div>

      {/* ---------------- TOP COLLECTIONS ----------------  */}

      <div className="border-b border-t my-12 border-[#21e786]">
        <Link to={"/explore/collections"}>
          <h1 className="pt-12 text-5xl font-bold text-[#21e786] hover:text-white transition-all duration-200 relative z-[20] pointer">
            Top Collections
          </h1>
          <h2 className="pb-24 pt-2 text-lg font-Bakbak">
            Min 1 NFT in collection
          </h2>
        </Link>
        <CustomScroll>
          <div className="overflow-x-auto h-[480px] px-4">
            {loading && <ItemLoader />}
            <table className="min-w-full bg-[#0c0c0c] text-white border border-[#0c0c0c] w-full">
              <thead>
                <tr className="border-b border-t border-[#21e786]">
                  <th className="px-2 py-3 text-left">Image</th>
                  <th className="px-2 py-3 text-center">Name</th>
                  <th className="px-2 py-3 text-center">Count NFTs</th>
                  <th className="px-2 py-3 text-center">Floor price</th>
                  <th className="px-2 py-3 text-center">Owner</th>
                  <th className="px-2 py-3 text-center">Total</th>
                  <th className="px-2 py-3 text-center">Date</th>
                </tr>
              </thead>
              <tbody>
                {collectionsInfo.map((collection, index) => (
                  <tr key={index} className="border-b">
                    <td className="px-2 py-3">
                      <Link
                        to={`/collection/${collection.contract}`}
                        className="block"
                      >
                        <img
                          src={`${collection.imageURI}`}
                          className="image-container"
                        />{" "}
                      </Link>
                    </td>
                    <td className="px-2 py-3">
                      <Link to={`/collection/${collection.contract}`}>
                        {collection.title}
                      </Link>
                    </td>
                    <td className="px-2 py-3">
                      <Link to={`/collection/${collection.contract}`}>
                        {collection.nftCount}
                      </Link>
                    </td>
                    <td className="px-2 py-3">
                      <Link to={`/collection/${collection.contract}`}>
                        {collection.floor.toFixed(4)}
                      </Link>
                    </td>
                    <td className="px-2 py-3">
                      <Link to={`/collection/${collection.contract}`}>
                        {window.innerWidth < 768
                          ? shortenAddress(collection.owner)
                          : collection.owner}
                      </Link>
                    </td>
                    <td className="px-2 py-3">
                      <Link to={`/collection/${collection.contract}`}>
                        {collection.totalCost.toFixed(4)}
                      </Link>
                    </td>
                    <td className="px-2 py-3">
                      <Link to={`/collection/${collection.contract}`}>
                        {moment(collection.date * 1000).format(
                          " DD.MMM.YY HH:mm:ss"
                        )}{" "}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CustomScroll>
      </div>

      <EasySteps />
      <Faq />
      <Loading />
    </div>
  );
};

export default HomePage;
