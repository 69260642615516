import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { shortenAddress } from "../Blockchain.Services";
import ItemLoader from "../components/ItemLoader";
import { Link } from "react-router-dom";

const ExploreCollections = () => {
  const [collectionsInfo, setCollectionsInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxTitleLength, setMaxTitleLength] = useState(30);
  const [allCollectionsLoaded, setAllCollectionsLoaded] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getNewAllCollectionsFromDB = async (limit) => {
    try {
      const isLoadingCollections = true;
      let getAllCollectionsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection?limit=${limit}`;

      if (lastEvaluatedKey) {
        const { PK, SK, TIME_INDEX_SK } = lastEvaluatedKey;
        const key = `lastElement=${PK}:${SK}:${TIME_INDEX_SK}`;
        getAllCollectionsUrl = getAllCollectionsUrl + ";" + key;
      }

      const response = await fetch(getAllCollectionsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("data", data);
        if (lastEvaluatedKey) {
          const mergedCollections = [...collectionsInfo, ...data.Items];
          setCollectionsInfo(mergedCollections);
        } else {
          setCollectionsInfo(data.Items);
        }
        setLastEvaluatedKey(data.LastEvaluatedKey);
        if (data.Items.length < limit) {
          setAllCollectionsLoaded(true);
        }
      } else {
        console.error(
          "Failed to get getNewAllCollectionsFromDB:",
          response.statusText
        );
        setCollectionsInfo([]);
      }
    } catch (error) {
      console.error("Error getting getNewAllCollectionsFromDB:", error.message);
      setCollectionsInfo([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getNewAllCollectionsFromDB(40);
    };
    fetchData();
  }, []);

  const handleLoadMore = () => {
    setLoading(true);
    getNewAllCollectionsFromDB(40);
  };

  return (
    <div className="pb-[5rem] min-h-screen h-full">
      <h1 className="mt-64 text-center font-Bakbak text-5xl font-bold text-[#21e786] hover:text-white transition-all duration-200 cursor-pointer mb-[80px]">
        COLLECTIONS
      </h1>
      {collectionsInfo.length === 0 && (
        <div>
          <h2 className="text-center min-h-screen underline pt-24 font-Bakbak text-5xl font-bold text-[#21e786] hover:text-white transition-all duration-200 cursor-pointer">
            No Collections
          </h2>
        </div>
      )}
      <div className="w-full shadow-lg p-[5px] group flex justify-around items-center flex-wrap">
        {loading && <ItemLoader />}
        {collectionsInfo.map((collection) => (
          <Link
            to={`/collection/${collection.contract}`}
            key={collection.contract}
            className="min-w-[300px] flex items-center justify-center"
          >
            <div className="relative">
              <p
                style={{ zIndex: "1" }}
                className="absolute top-[-2px] right-[-12px] bg-[#f10cea] text-white py-1 px-2 rounded-full text-xs font-Bakbak flex justify-center items-center"
              >
                NFTS:{" "}
                <span className="text-[25px] ml-[5px]">
                  {collection.nftCount}
                </span>
              </p>
              <div
                className="w-[250px] mt-6 flex flex-col tf-work tf-product style-2 overflow-hidden bs"
                style={{ minHeight: "250px" }}
              >
                <figure className="coverIMG1">
                  <LazyLoadImage
                    src={collection.bannerURI}
                    loading="lazy"
                    decoding="async"
                    alt={collection.title}
                    className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105"
                  />
                </figure>
                <p className="border-[#21e786] border-b py-3 font-bold text-[#21e786] twoCol2">
                  <span>
                    {collection.title?.length > maxTitleLength
                      ? collection.title.substring(0, maxTitleLength) + "..."
                      : collection.title}
                  </span>
                </p>
                <p>{collection.a}</p>
                <p className="text-white pt-3 font-bold text-sm pb-2 hover:text-[#21e786] transition-all duration-200">
                  {shortenAddress(collection.owner)}
                </p>

                <p className="text-white pt-3 font-bold text-sm pb-2">
                  Floor: {collection.floor.toFixed(4)} DUBX
                </p>
                <p className="text-white pt-3 font-bold text-sm border-y pb-2 border-[#3e3e3e]">
                  Total: {collection.totalCost.toFixed(4)} DUBX
                </p>
                <p className="text-white pt-3 font-bold text-xs">
                  Created at -
                  {moment(collection.date * 1000).format(" DD.MMM.YY HH:mm:ss")}{" "}
                </p>
              </div>
            </div>
          </Link>
        ))}
        {!allCollectionsLoaded ? (
          <div className="flex items-center justify-center mt-8 w-full">
            <button
              className="shadow-xl shadow-black text-[#000] hover:text-white
            bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-3 px-[15px]
              cursor-pointer font-Bakbak w-[250px] z-[20] mb-[5rem]"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ExploreCollections;
