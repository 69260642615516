import React, { useEffect } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";
import {
  loadNfts,
  loadCollections,
  getAllAuthorsFromDB,
} from "./Blockchain.Services";
import "./App.css";
import Header from "./components/Header";
import DragDrop from "./components/DragDrop";
import HomePage from "./pages/HomePage";
import Footer from "./components/Footer";
import ItemDetail from "./pages/ItemDetail";
import Explore from "./pages/Explore";
import UserInfo from "./pages/UserInfo";
import EditProfile from "./pages/EditProfile";
import Contact from "./pages/Contact";
import CreateCollectionPage from "./pages/CreateCollectionPage";
import DndProviderWrapper from "./components//DndProviderWrapper";
import CreateNftPage from "./pages/CreateNftPage";
import SingleCollection from "./pages/SingleCollection";
import ExploreCollections from "./pages/ExploreCollections";
import Authors from "./pages/Authors";
import TermsAndConditions from "./pages/TermsAndConditions";
import Doc from "./pages/Doc";
import Documentation from "./pages/Documentation";
import PageNotFound from "./pages/PageNotFound";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import BackToTopButton from "./components/BackToTopButton";
// import Metamask from "./pages/Metamask";

function App() {
  useEffect(() => {
    loadNfts(40);
    loadCollections(40);
    getAllAuthorsFromDB(40);
  }, []);
  return (
    <div className="min-h-screen bg-[#131722] overflow-x-hidden">
      <HashRouter>
        <Header />
        <DndProviderWrapper>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route
              path="/n/:contractaddress/:tokenId"
              element={<ItemDetail />}
            />
            <Route
              path="/explore/collections"
              element={<ExploreCollections />}
            />
            <Route path="/explore/authors" element={<Authors />} />
            <Route path="/explore/:urlCategory" element={<Explore />} />
            <Route
              path="/create-collection"
              element={<CreateCollectionPage />}
            />
            <Route path="/author/:address" element={<UserInfo />} />
            <Route path="/edit/:address" element={<EditProfile />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/create-nft" element={<CreateNftPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditions />}
            />
            <Route path="/doc" element={<Doc />} />
            <Route path="/documentation" element={<Documentation />} />
            <Route path="/drag-drop" element={<DragDrop />} />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/collection/:address" element={<SingleCollection />} />
            {/* <Route path="/metamask-connect" element={<Metamask />} /> */}
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </DndProviderWrapper>
        <Footer />
        <BackToTopButton />
      </HashRouter>
    </div>
  );
}

export default App;
