import React from "react";
import EasyStepsIMG from "../assets/verify.png";
import ConnectWalletIMG from "../assets/metamask.png";
import CreateAccountIMG from "../assets/connectWallet.png";
import UploadIMG from "../assets/add_image.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const EasySteps = () => {
  return (
    <div className="backgroundFAQ border-b pt-12 cursor-default">
      <h1 className="pt-12 pb-24 text-4xl sm:text-5xl font-bold text-[#21e786] hover:text-white transition-all duration-200 px-2">
        3 EASY STEPS - HOW IT WORKS
      </h1>
      {/* LEFT SIDE */}
      <div className="flex lg:ml-32 justify-center">
        <div className="flex-col w-1/2">
          {/* CONNECT WALLET FULL */}
          <div className="flex flex-col lg:flex-row justify-center items-center">
            {/* ICON */}
            <div className="w-[125px] h-[140px] relative bgForSteps flex justify-center items-center">
              <LazyLoadImage
                effect="blur"
                src={ConnectWalletIMG}
                loading="lazy"
                decoding="async"
                alt="ConnectWalletIMG"
                className="lg:p-6"
              />
            </div>
            {/* TEXT */}
            <div className="w-[330px] mx-6 text-center mt-0 lg:text-left lg:mt-[-20px]">
              <h2 className="text-3xl">Connect Wallet</h2>
              <p className="text-white text-sm pt-4">
                Connect your MetaMask address, click 'Connect Wallet' in the
                upper right corner, and unlock the ability to effortlessly
                publish, sell, and buy your NFTs.
              </p>
            </div>
          </div>

          {/* CREATE ACCOUNT */}
          <div className="flex flex-col lg:flex-row justify-center items-center my-3">
            {/* ICON */}
            <div className="w-[125px] h-[140px] relative bgForSteps flex justify-center items-center">
              <LazyLoadImage
                effect="blur"
                src={CreateAccountIMG}
                loading="lazy"
                decoding="async"
                alt="CreateAccountIMG"
              />
            </div>
            {/* TEXT */}
            <div className="w-[330px] mx-6 text-center mt-0 lg:text-left lg:mt-[-20px]">
              <h2 className="text-3xl">Customise Account</h2>
              <p className="text-white text-sm pt-4">
                We suggest customizing your account first by clicking on your
                profile and going to Edit profile. This will increase your
                chances of making a positive impression on others!
              </p>
            </div>
          </div>

          {/* UPLOAD NFT */}
          <div className="flex flex-col lg:flex-row justify-center items-center">
            {/* ICON */}
            <div className="w-[125px] h-[140px] relative bgForSteps flex justify-center items-center">
              <LazyLoadImage
                effect="blur"
                src={UploadIMG}
                loading="lazy"
                decoding="async"
                alt="UploadIMG"
                className="scale-150 mt-3 ml-1"
              />
            </div>
            {/* TEXT */}
            <div className="w-[330px] mx-6 text-center mt-0 lg:text-left lg:mt-[-20px]">
              <h2 className="text-3xl">UPLOAD NFT</h2>
              <p className="text-white text-sm pt-4 pb-5 lg:pb-0">
                Having linked your wallet and customized your account, it's time
                to publish your inaugural NFT. Best of luck on your creative
                journey!
              </p>
            </div>
          </div>
        </div>
        {/* RIGHT SIDE */}
        <div className="userBounceIMG hidden lg:flex">
          <LazyLoadImage
            effect="blur"
            src={EasyStepsIMG}
            loading="lazy"
            decoding="async"
          />
        </div>
      </div>
    </div>
  );
};

export default EasySteps;
