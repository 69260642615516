import { createGlobalState } from "react-hooks-global-state";
import TopSeller6 from "../assets/ai5.jpg";

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState({
  modal: "scale-0",
  updateModal: "scale-0",
  showModal: "scale-0",
  alert: { show: false, msg: "", color: "" },
  loading: { show: false, msg: "" },
  connectedAccount: "",
  nft: null,
  nfts: [],
  newestCollections: [],
  currentCreators: [],
  transactions: [],
  contract: null,
  isAccountVerified: false,
  avatar: null,
  lastEvaluatedKey: null,
  allNftsLoaded: false,
  isLoadingNfts: false,
  lastEvaluatedKeyCollection: null,
  lastEvaluatedKeyCreators: null,
  allCollectionsLoaded: false,
  allCreatorsLoaded: false,
  isLoadingCollections: false,
  isLoadingAuthors: false,
});

const setLoadingMsg = (msg) => {
  console.log("setLoadingMsg", msg);
  const loading = getGlobalState("loading");
  setGlobalState("loading", { ...loading, msg });
};

const truncate = (text, startChars, endChars, maxLength) => {
  if (text.length > maxLength) {
    var start = text.substring(0, startChars);
    var end = text.substring(text.length - endChars, text.length);
    while (start.length + end.length < maxLength) {
      start = start + ".";
    }
    return start + end;
  }
  return text;
};

export {
  useGlobalState,
  setGlobalState,
  getGlobalState,
  setLoadingMsg,
  truncate,
};
