import React from "react";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <div className="h-screen w-full flex flex-col justify-center items-center bg-[#151c25]">
      <h1 className="text-9xl font-extrabold text-white tracking-widest font-Bakbak">
        404
      </h1>
      <div className="bg-[#21e786] px-2 text-sm rounded rotate-12 absolute">
        Page Not Found
      </div>
      <button className="mt-5">
        <Link
          to="/"
          className="relative inline-block text-[30px] text-[#21e786] group active:text-[#f10cea]"
        >
          <span className="absolute inset-0 transition-transform translate-x-0.5 translate-y-0.5 bg-[#21e786] group-hover:translate-y-0 group-hover:translate-x-0"></span>
          <span className="relative block px-8 py-3 bg-[#1A2238] border border-current font-Bakbak">
            Go Home
          </span>
        </Link>
      </button>
    </div>
  );
};

export default PageNotFound;
