// import React, { useEffect, useRef } from "react";
// import anime from "animejs";

// const BubblesFire = ({ count }) => {
//   const bubblesRef = useRef(null);

//   useEffect(() => {
//     const bubblesCount = 70;
//     const bubbles = bubblesRef.current;

//     const getRandomInt = (min, max) => {
//       min = Math.ceil(min);
//       max = Math.floor(max);
//       return Math.floor(Math.random() * (max - min + 1)) + min;
//     };

//     for (let i = 0; i < bubblesCount; i++) {
//       let circle = document.createElement("circle");

//       circle.setAttribute("r", getRandomInt(1, 5));
//       circle.setAttribute("cy", getRandomInt(10, 40));
//       circle.setAttribute("cx", getRandomInt(10, 40));
//       circle.setAttribute("fill", "#f10cea");
//       bubbles.append(circle);
//     }

//     let html = bubblesRef.current.innerHTML;
//     bubblesRef.current.innerHTML = html;

//     let circles = bubblesRef.current.querySelectorAll("circle");

//     for (let i = 0; i < circles.length; i++) {
//       let del = getRandomInt(0, 1000 * 3);
//       let xv = getRandomInt(-20, 20);

//       anime({
//         targets: circles[i],
//         r: {
//           value: 0,
//           duration: 500,
//           delay: del + 1000,
//         },
//         translateX: {
//           value: xv,
//           duration: 800,
//           delay: del,
//         },
//         translateY: {
//           value: [0, -25],
//           duration: 2000,
//           delay: del,
//         },
//         loop: true,
//         easing: "linear",
//       });
//     }
//   }, []);

//   return (
//     <div
//       className="box"
//       style={{ position: "relative", width: "100%", height: "100%" }}
//     >
//       <span className="count">{count}</span>
//       <svg
//         width="50"
//         height="50"
//         xmlns="http://www.w3.org/2000/svg"
//         fill="#21e786"
//         ref={bubblesRef}
//         style={{ position: "relative", top: 0, left: 0 }}
//       >
//         <g filter="url(#goo)" className="bubbles"></g>
//       </svg>
//     </div>
//     // <svg
//     //   width="50"
//     //   height="50"
//     //   xmlns="http://www.w3.org/2000/svg"
//     //   fill="#21e786"
//     //   ref={bubblesRef}
//     //   // style={{ width: "100%", height: "100%" }}
//     // >
//     //   <g filter="url(#goo)" className="bubbles"></g>
//     // </svg>
//   );
// };

// export default BubblesFire;

import React, { useEffect, useRef } from "react";
import anime from "animejs";

const BubblesFire = ({ count }) => {
  const bubblesRef = useRef(null);

  useEffect(() => {
    const bubblesCount = 70;
    //     const bubbles = document.querySelector(".bubbles");
    const bubbles = bubblesRef.current;

    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min + 1)) + min;
    };

    for (let i = 0; i < bubblesCount; i++) {
      let circle = document.createElement("circle");

      circle.setAttribute("r", getRandomInt(1, 5)); // Smanjena veličina krugova
      circle.setAttribute("cy", getRandomInt(10, 40)); // Nasumično postavljanje y koordinate
      circle.setAttribute("cx", getRandomInt(10, 40)); // Nasumično postavljanje x koordinate
      circle.setAttribute("fill", "#f10cea");
      bubbles.append(circle);
    }

    //     let html = document.querySelector(".bubbles").innerHTML;
    //     document.querySelector(".bubbles").innerHTML = html;
    let html = bubblesRef.current.innerHTML;
    bubblesRef.current.innerHTML = html;

    //     let circles = document.querySelectorAll(".bubbles circle");
    let circles = bubblesRef.current.querySelectorAll("circle");

    for (let i = 0; i < circles.length; i++) {
      let del = getRandomInt(0, 1000 * 3);
      let xv = getRandomInt(-20, 20); // Smanjena amplituda animacije

      anime({
        targets: circles[i],
        r: {
          value: 0,
          duration: 500,
          delay: del + 1000,
        },
        translateX: {
          value: xv,
          duration: 800,
          delay: del,
        },
        translateY: {
          value: [0, -25], // Smanjena visina animacije
          duration: 2000,
          delay: del,
        },
        loop: true,
        easing: "linear",
      });
    }
  }, []);

  return (
    <div className="box" style={{ width: "50px", height: "50px" }}>
      <span className="count">{count}</span>

      <svg
        id="organic-blob"
        width="50"
        height="50"
        xmlns="http://www.w3.org/2000/svg"
        fill="#21e786"
        ref={bubblesRef}
        style={{ width: "100%", height: "100%" }} // Promenjena veličina SVG elementa
      >
        <g filter="url(#goo)" className="bubbles"></g>
      </svg>
    </div>
  );
};

export default BubblesFire;
