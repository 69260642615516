import React, { useEffect } from "react";
import DubxLogo from "../assets/verify.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div className="px-12 md:px-24 text-white lg:w-[70%] w-[100%]">
      <div className="fixed right-[13%] mt-[50%] lg:mt-[29%] xl:mt-[20%] userBounceIMG opacity-10 md:opacity-20">
        <LazyLoadImage
          src={DubxLogo}
          loading="lazy"
          decoding="async"
          alt="DUBX LOGO"
        />
      </div>
      <h1 className="pt-48 text-2xl">ARABIANCHAIN NFTs' Marketplace </h1>
      <h2 className="text-2xl">Terms and Conditions </h2>

      <p className="text-md mt-7">
        Welcome to the <span>ARABIANCHAIN NFTs' Marketplace.</span> The terms
        and conditions of our Service are a legally binding agreement between
        our Company and every registered and unregistered User of ARABIANCHAIN
        TECHNOLOGIES. The ARABIANCHAIN TECHNOLOGIES Company is owned and
        operated by ARABIANCHAIN TECHNOLOGIES W.L.L. Please find below the{" "}
        <span>terms and conditions</span> for using the ARABIANCHAIN
        TECHNOLOGIES services, including:
      </p>
      <p className="text-md mt-7">
        - Accessing and using the ARABIANCHAIN NFTs' Marketplace.
      </p>
      <p className="text-md">
        - Creating, trading, or transferring any collectibles on the
        ARABIANCHAIN TECHNOLOGIES platform.
      </p>
      <p className="text-md">
        - Using, sending, or receiving tokens within the ARABIANCHAIN
        TECHNOLOGIES Dex system
      </p>
      <p className="text-md">
        - Using, selling, buying, or transferring any ARABIANCHAIN TECHNOLOGIES
        coin or tokens.
      </p>
      <p className="text-md">
        - Participating or accessing the ARABIANCHAIN TECHNOLOGIES Governance
        Module.
      </p>
      <p className="text-md mt-7 pb-3">
        The terms and conditions (also known as these Terms hereafter) have been
        established by the ARABIANCHAIN TECHNOLOGIES company to regulate the
        NFTs or similar digital assets. By undertaking any transactions, you
        will be legally deemed a "User" of ARABIANCHAIN NFTs' Marketplace who is
        legally bound to these Terms.
      </p>
      <p className="text-md">
        In these Terms, the word "User" "You" and "Your" refer to you, the
        service user. If you use the ARABIANCHAIN TECHNOLOGIES service on behalf
        of a company or any other entity, "user" "you" and "your" means you and
        the Company. You, as the User, sanction that you are an authorized
        representative of the Company who abides by these Terms, and you agree
        to these Terms on behalf of the Company.
      </p>
      <p className="text-md mt-7">
        PLEASE READ THE TERMS OF SERVICE GIVEN BELOW CAREFULLY AS THEY CONTAIN
        IMPORTANT INFORMATION. THIS INFORMATION AFFECTS YOUR LEGAL RIGHTS. AS
        OUTLINED IN THIS DOCUMENT, OUR TERMS INCLUDE A MANDATORY ARBITRATION
        CLAUSE (WITH CERTAIN EXCEPTIONS). UNDER THE ARBITRATION CLAUSE, YOU AND
        YOUR COMPANY ARE REQUIRED TO AGREE THAT ALL DISPUTES BETWEEN
        ARABIANCHAIN TECHNOLOGIES AND YOU WILL BE RESOLVED THROUGH INDIVIDUAL
        ARBITRATION. YOU WAIVE YOUR RIGHT TO PARTICIPATE IN A CLASS-ACTION
        LAWSUIT UNDER THIS AGREEMENT.
      </p>
      <p className="text-md mt-7">
        BY CLICKING YES, YOU ARE ACCEPTING ALL THESE TERMS AND ALL TERMS
        INCORPORATED BY REFERENCE HEREIN. YOU MAY NOT ACCESS OR USE THE
        ARABIANCHAIN TECHNOLOGIES SERVICE IF YOU DECLINE OR DO NOT AGREE WITH
        THESE TERMS. IF ANY PROVISION OF THESE TERMS IS CHANGED IN THE FUTURE
        AND THOSE CHANGES ARE UNACCEPTABLE TO YOU, PLEASE REFRAIN FROM USING OUR
        SERVICE. IF YOU CONTINUE TO ACCESS OR USE OUR SERVICE FOLLOWING THE
        NOTICE OF UPDATE TO THESE TERMS OF SERVICE, THEN IT SHALL CONSTITUTE
        YOUR AGREEMENT TO THE SAID CHANGES.
      </p>
      <p className="text-md">DEFINED TERMS AND ASSOCIATED MEANING:</p>
      <p className="text-2xl font-semibold mt-24 underline">ASSUMPTION OF RISK:</p>
      <p className="text-md mt-7">
        Any purchase or sale of an NFT that you make, accept, or facilitate
        outside of the ARABIANCHAIN NFTS' MARKETPLACE is totally at your own
        risk. You acknowledge that you have gathered enough information to make
        an informed decision to purchase an NFT, including thoroughly examining
        the code of the smart contract and the NFT and fully understanding and
        accepting its functionality. We have no control over or endorsement over
        the acquisition or selling of NFTs outside of the ARABIANCHAIN NFTS'
        MARKETPLACE platform.
      </p>
      <p className="text-md mb-4">
      We clearly reject and disclaim all duty to you, as
        well as any obligation to reimburse or hold you blameless for any losses
        you may experience because of transacting or facilitating transactions
        in NFTs outside of the ARABIANCHAIN NFTS; MARKETPLACE platform.
      </p>
      <p className="text-md mb-4">Third-party content, data, information, applications, or materials may
        be displayed, included, or made available in certain portions of the
        ARABIANCHAIN TECHNOLOGIES website ("Third Party Materials").</p>
      <p className="text-md mb-4"> By using
        the ARABIANCHAIN TECHNOLOGIES platform, you understand and accept that
        our Company is not responsible for inspecting or evaluating the
        Third-Party Materials' content, accuracy, completeness, availability,
        timeliness, validity, copyright compliance, legality, decency, quality,
        or any other aspect. </p>
      <p className="text-md mb-4">ARABIANCHAIN TECHNOLOGIES Company does not warrant
        or endorse any third-party services, Third Party Materials, or other
        third parties' materials, products, or services. We don't assume and
        will not have any liability or responsibility to you or any other person
        for any third-party services, including and not limited to Third Party
        Materials or any other materials, products, or third parties’ services.</p>
      <p className="text-md"></p>
      <p className="text-md">
        
      </p>
      <p className="text-md">
        There can be no warranty or assurance of any Collectible or Collectible
        Metadata's uniqueness, originality, or quality. In the absence of a
        legal agreement between the creator of an NFT Collectible and purchasers
        of the Collectible, there can be no guarantee or assurance that
        purchasing or holding the Collectible grants any license to or ownership
        of the Collectible Metadata or other intellectual property associated
        with the NFT Collectible, or any other right or entitlement, even if
        "User" may rightfully own or possess the NFT associated with the
        Collectible.
      </p>
      <p className="text-md">
        ARABIANCHAIN TECHNOLOGIES Company may, in its sole and absolute
        discretion, remove certain Collectibles from the ARABIANCHAIN NFTS’
        MARKETPLACE platform or restrict the creation of NFT Collectibles on the
        ARABIANCHAIN NFTS’ MARKETPLACE platform, including if ARABIANCHAIN
        TECHNOLOGIES Company believes that such NFT Collectibles violates these
        Terms of the ARABIANCHAIN NFTS' MARKETPLACE platform. ARABIANCHAIN
        TECHNOLOGIES Company does not commit to and shall not be liable for
        failing to display or offer any NFT Collectible for trading through the
        ARABIANCHAIN NFTS' MARKETPLACE platform.
      </p>
      <p className="text-2xl font-semibold mt-24 underline">ACCESSING THE SERVICE:</p>
      <p className="text-2xl font-semibold mt-1 underline">SURVIVAL:</p>
      <p className="text-2xl font-semibold mt-1 underline mb-7">MISCELLANEOUS:</p>
      <p className="text-md mt-7 mb-4">
        Arabianchain Technologies takes no responsibility or liability for your
        use of a wallet and makes no guarantees or assurances about how the
        Service will work with any specific wallet. You are completely
        responsible for the security of your wallet, and you should never reveal
        your wallet credentials or seed phrase with anybody. Please get in touch
        with your wallet provider if you detect a problem with your wallet.
        Similarly, you are entirely responsible for your Account and any
        associated wallet. We will not be held liable for any acts or omissions
        by you in connection with your Account or due to your Account or wallet
        being hacked. You must tell us immediately if you discover or suspect
        any security concerns with the Service or your Account.
      </p>
      <p className="text-md">
        You also represent and warrant that when using the Service, you will
        comply with all applicable laws, including local, state, federal, and
        other laws. If you use or access the Service from a country, territory,
        or jurisdiction other than the Kingdom of Saudi Arabia, you are solely
        responsible for ensuring that your access and use of the Service in that
        country, territory, or jurisdiction does not violate any applicable
        laws.
      </p>
      <p className="text-md">
        In some instances, such as at the request of government authority, as
        required by any applicable law or regulation, or to investigate a
        potential violation of these Terms, ARABIANCHAIN TECHNOLOGIES may compel
        you to supply additional information and documentation. In such
        situations, ARABIANCHAIN TECHNOLOGIES may disable your Account and
        prevent you from accessing the Service until ARABIANCHAIN TECHNOLOGIES
        processes such additional information and documents. If you do not
        respond to such a request with complete and accurate information,
        ARABIANCHAIN TECHNOLOGIES has the right to refuse to restore your access
        to the Service.
      </p>
      <p className="text-md mt-4">
        Your access to and use of the ARABIANCHAIN TECHNOLOGIES Service may be
        stopped from time to time for numerous reasons, including equipment
        malfunction, periodic upgrading, maintenance, or repair of the Service,
        or other measures taken at ARABIANCHAIN TECHNOLOGIES's sole discretion.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">OWNERSHIP:</p>
      <p className="text-md">
        The ARABIANCHAIN TECHNOLOGIES Service, including its elements such as
        text, images, graphics, logos, button icons, page headers, and scripts,
        information, proprietary content, and other materials, and all content
        and other materials contained therein, including, without limitation,
        the ARABIANCHAIN TECHNOLOGIES logo and all designs, text, graphics,
        pictures, sound files, data, software, and any other files, and the
        arrangement thereof, are the exclusive property of ARABIANCHAIN
        TECHNOLOGIES Company. We, our affiliates, licensors, and users, as
        applicable, reserve all rights in connection with the Service and its
        content, including the sole right to do derivative works.
      </p>
      <p className="text-md my-3">
        The name, logo, trademarks, and any ARABIANCHAIN TECHNOLOGIES product or
        service names, designs, logos, and slogans are the intellectual property
        of ARABIANCHAIN TECHNOLOGIES or its affiliates or licensors and may not
        be imitated, duplicated, or utilized in part or whole without our prior
        written consent in each instance. Without our prior written consent, you
        cannot use any metatags or other "hidden text" containing "ARABIANCHAIN
        TECHNOLOGIES" or any other name, product, service name, or trademark of
        ARABIANCHAIN TECHNOLOGIES and its affiliates and licensors. Furthermore,
        the "look and feel" of the Service is ARABIANCHAIN TECHNOLOGIES's
        service mark, trademark, or trade dress and cannot be duplicated,
        imitated, or utilized without ARABIANCHAIN TECHNOLOGIES's prior written
        permission.
      </p>
      <p className="text-md">
        All other registered trademarks, third-party trademarks, and product
        names mentioned on the Service, or any content associated with or linked
        to the NFTs displayed on the Service are the property of their
        respective owners. Therefore, these assets may not be copied, imitated,
        or utilized, in whole or in part, without the permission of the relevant
        copyright’s holder. The mention of any products, services, processes, or
        other information by name, trademark, manufacturer, supplier, or
        otherwise does not indicate or imply ARABIANCHAIN TECHNOLOGIES's
        endorsement, sponsorship, or recommendation.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">
        LICENSE TO ACCESS AND USE OF OUR SERVICE AND CONTENT:
      </p>
      <p className="text-md">
        Subject to and conditioned on your affirmation and adherence to these
        Terms, you are hereby conferred a non-transferable, non-sublicensable,
        personal license to use the ARABIANCHAIN NFTS’ MARKETPLACE platform and
        ARABIANCHAIN TECHNOLOGIES Apps for their intended purposes. You are the
        exclusive creators of all content on the ARABIANCHAIN NFTS’ MARKETPLACE
        platform. ARABIANCHAIN TECHNOLOGIES Company makes no representations or
        assurances about the quality, origin, or ownership of any content found
        in the Service.
      </p>
      <p className="text-2xl">
        ARABIANCHAIN TECHNOLOGIES Company shall not be accountable for any
        errors, misrepresentations, or omissions in, of, or about the content,
        nor for its availability. ARABIANCHAIN TECHNOLOGIES Company shall not be
        liable for any losses, injuries, or damages resulting from the purchase,
        display, inability to purchase, or use of content.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">
        THIRD-PARTY CONTENT AND SERVICES:
      </p>
      <p className="text-md">
        In conjunction with the Service, you may be supplied with references,
        referrals, or links to third-party products, resources, services, or
        information, including smart contracts produced or maintained by third
        parties. Third parties may also offer promotions relating to the
        Service. ARABIANCHAIN TECHNOLOGIES Company does not promote or accept
        responsibility for any third-party activities, resources, goods,
        services, content, or promotions that are owned, managed, operated, or
        sponsored. Users access such resources, products, services, or material,
        or engage in such campaigns, at their own risk.
      </p>
      <p className="text-md">
        Each User expressly waives and releases ARABIANCHAIN TECHNOLOGIES
        Company from any and all liability arising from the use of such
        resources, products, services, content, or participation in any such
        campaigns. User further understands and agrees that ARABIANCHAIN
        TECHNOLOGIES Company is not liable or responsible, directly or
        indirectly, for any damages or losses caused by third-party resources,
        goods, services, content, or promotions.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">USER CONDUCT:</p>
      <p className="text-md mt-4">
        All users of the Service must be at least 18 years old. If Users are at
        least 13 years old but under 18, they may only use ARABIANCHAIN NFTS’
        MARKETPLACE through a parent or guardian's Account with their consent
        and supervision. The account holder is liable for your behavior while
        using the Account. If you are under the age of 18, you are not permitted
        to use our Service.
      </p>
      <p className="text-md my-4">
        The ARABIANCHAIN TECHNOLOGIES Company reserves the right to act, with or
        without prior notice, if we feel you have broken these terms to protect
        our community and comply with our legal duties. This may include
        eliminating the ability to view certain NFTs on the Service or use our
        Service to interact with the NFTs; restricting the ability to utilize
        the Service in combination with buying/selling/transferring NFTs
        available on blockchains; blocking your access to our Service; and/or
        other actions.
      </p>
      <p className="text-md my-4">
        You agree that you will not break any law, contract, intellectual
        property, or other third-party rights while accessing or using the
        Service. You, as the User, are entirely responsible for your conduct and
        content. You also agree not to do the following:
      </p>
      <p className="text-md">
        Use or effort to use another user's Account without the User's
        permission;
      </p>
      <p className="text-md my-4">
        Take on the identity of another person or entity;
      </p>
      <p className="text-md">
        Claim a ARABIANCHAIN NFTS' MARKETPLACE username with the intent of
        reselling it or engaging in other forms of name squatting;
      </p>
      <p className="text-md my-4">
        Access the ARABIANCHAIN NFTS' MARKETPLACE Service from a different
        blockchain address if we've blocked any of your other blockchain
        addresses from accessing the Service, you receive our explicit
        permission beforehand;
      </p>
      <p className="text-md">
        Dissemination of spam, including sending unwanted NFTs to other users on
        the platform;
      </p>
      <p className="text-md my-4">
        Use the Service in any way that could damage, disable, overburden, or
        impair the Service's functionality, including dissemination of any
        harmful software; Bypass or disobey instructions that regulate access to
        the Service, such as attempting to circumvent rate-limiting systems by
        using numerous API keys, diverting traffic through different I.P.
        addresses, or otherwise obscuring the source of traffic you send to
        ARABIANCHAIN TECHNOLOGIES; 
      </p>
      <p className="text-md">Use any data mining, crawler, robot, script,
        spider, scraper, offline reader, browser extension, or other automated
        means or interface that we have not approved to access the Service,
        gather data, or interfere with or change the rendering of Service pages
        or functionality; Reverse engineer, replicate, decompile, disassemble,
        or decode any aspect of the Service, or do anything else that may lead
        to the discovery of source code, or bypass or circumvent any measures
        used to block or limit access to any service, area, or code of the
        Service; Sell or resell the Service, or try to avoid any ARABIANCHAIN
        NFTS' MARKETPLACE fee systems; </p>
      <p className="text-md my-4">Engage in actions with the goal or effect
        of falsely causing an item or Collectible to be at the top of the search
        results or unethically raising view counts, likes, or other metrics that
        ARABIANCHAIN NFTS’ MARKETPLACE may use to sort search results; Use the
        Service or data collected from it for any advertising or direct
        marketing activity (including, but not limited to, email marketing,
        telemarketing, and SMS marketing); Use the Service for terrorist
        financing, money laundering, or other illegal financial transactions;</p>
      <p className="text-md">
        Use the Service from a nation sanctioned by the Saudi Arabian government
        or to assist transactions involving people sanctioned by the Saudi
        Arabian government or situated in sanctioned countries; Use the Service
        to engage in any financial activity that requires registration or
        license, including, but not limited to, the creation, sale, or purchase
        of securities, commodities, options, or debt instruments. Use the
        Service to produce, sell, or buy NFTs or other products that provide
        owners with the right to participate in an ICO or other securities
        offering or that are redeemable for securities, commodities, or other
        financial instruments. </p>
      <p className="text-md my-4">Use the Service for price manipulation, fraud, or
        any other deceptive, misleading, or manipulative behavior; Use the
        Service to sell, buy, or transfer fraudulently obtained, stolen, or
        unlawfully obtained items, as well as any other illegally obtained
        products; Infringe or violate someone else's intellectual property or
        other rights; Prohibition of Explicit Content: You agree not to upload,
        publish, or sell any NFTs that contain explicit or offensive content.
        This includes but is not limited to pornography, hate speech, violence,
        or any material that is harmful or offensive. </p>
      <p className="text-md">Make or exhibit NFTs or
        other products that encourage suicide or self-harm, instigate hatred or
        violence against others, or doxes another person; Use the Service for
        any unlawful or unauthorized purpose, such as creating or displaying
        illegal content, such as content involving child sexual exploitation, or
        encouraging or promoting any activity that violates the Terms of
        Service; Use the Service in any way that interferes with, disrupts,
        negatively affects, or inhibits other users from thoroughly enjoying the
        Service; By utilizing the Service, you recognize the significance of
        DYOR - do your own research. </p>
      <p className="text-md my-4">You are solely responsible for validating
        the authenticity, legitimacy, identity, and other facts of any NFT,
        collection, or Account that you access or interact with using our
        Service. We offer no guarantees or claims about any NFT, collection, or
        Account of the Service's identity, legitimacy, or authenticity.</p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">INTELLECTUAL PROPERTY RIGHTS</p>
      <p className="text-2xl font-semibold">LICENSE TO YOUR CONTENT:</p>
      <p className="text-md my-4">
        Except for the rights expressly provided below, you retain all rights to
        Your Content that you post, upload, submit, or otherwise make accessible
        through the ARABIANCHAIN NFTS’ MARKETPLACE Platform or App. To operate
        on ARABIANCHAIN NFTS' MARKETPLACE, we must obtain certain license rights
        in Your Content from you so that our acts in operating the ARABIANCHAIN
        TECHNOLOGIES Company are not considered legal violations. As a result,
        by using the ARABIANCHAIN NFTS' MARKETPLACE Platform and uploading or
        otherwise making Your Content available, you grant ARABIANCHAIN NFTS’
        MARKETPLACE the license to use, access, host, reproduce, display, cache,
        store, copy, transmit, publish, distribute, adapt, and modify Your
        Content in all media or distribution methods (now known or later
        developed) but solely as required. 
      </p>
      <p className="text-md">You agree that this license grants us
        the right to provide, market, and improve the ARABIANCHAIN NFTS’
        MARKETPLACE Platform, as well as to make Your Content available to other
        companies, organizations, or people for distribution, promotion, or
        publication on other media and services. </p>
      <p className="text-md my-4">You explicitly agree that these
        rights and licenses are royalty-free, transferable, sub-licensable,
        worldwide, and irrevocable (for as long as Your Content is stored with
        us) and include the right for ARABIANCHAIN TECHNOLOGIES Company to make
        Your Content accessible to and pass these rights along to anyone with
        whom the Service has contractual relationships related to the provision
        of the ARABIANCHAIN TECHNOLOGIES Company. </p>
      <p className="text-md">We may provide access to Your
        Content to third parties if we believe it is required to comply with our
        legal responsibilities. As part of the initial license grant, you
        acknowledge that other Users of the ARABIANCHAIN TECHNOLOGIES App or
        Platform may comment on and/or tag Your Content, as well as use,
        display, publish, modify, or include copies of Your Content for their
        own use of the ARABIANCHAIN NFTS' MARKETPLACE Platform; provided that
        the foregoing shall not be applicable to any of Your Content that you
        post privately for non-public display on the ARABIANCHAIN NFTS'
        MARKETPLACE Platform. </p>
      <p className="text-md my-4">By publishing or submitting Your Content to the
        ARABIANCHAIN NFTS' MARKETPLACE Platform, you represent and warrant that
        you have or have obtained all necessary rights, licenses, consents,
        permissions, power, and/or authority to grant the rights granted herein
        for Your Content. You agree that Your Content will not include any
        material protected by copyright or other property rights unless you have
        the relevant authorization or are otherwise legally permitted to post
        the material and grant us the license described above. </p>
      <p className="text-md">If you sell an
        NFT through the ARABIANCHAIN NFTS' MARKETPLACE Platform, you grant the
        buyer a worldwide, non-exclusive, non-transferable, royalty-free right
        to use, copy, and display the NFT Media for such purchased NFT for the
        following purposes: <br />(a) for the buyer's personal use; (<br />b) as part of a
        marketplace that allows the purchase and sale of such NFTs, provided
        that the marketplace cryptographically verifies each NFT's owner's
        rights to display the NFT Media for their NFTs, ensuring that only the
        actual owner can display the NFT Media; or <br />(c) as part of a third-party
        website or application that allows your NFT to be included, involved, or
        participated in, provided that the website/application cryptographically
        verifies each NFT's owner's right to display the NFT Media for their
        NFTs to ensure that only the actual copyright owner can display the NFT
        Media. </p>
      <p className="text-md my-4">The NFT Media will no longer be visible once the owner of the
        NFTs leaves the website/application. If you purchase NFTs, you
        understand and accept that the NFT Purchase License described above is
        only valid if you are the legal owner and holder of the NFT connected
        with the licensed NFT Media. </p>
      <p className="text-md">Therefore, if you sell the NFT to another
        user, the advantages of this NFT Purchase License will be transferred to
        the new owner or holder of the NFT, and you will no longer be able to
        utilize it hereafter. Unless otherwise indicated in writing by the
        seller of an NFT, your purchase of an NFT does not give you the legal
        right to publicly display, perform, distribute, sell, or otherwise
        reproduce the NFT or its connected NFT Media for commercial purposes.</p>
      <p className="text-2xl font-semibold mt-7 underline mb-7">COPYRIGHT POLICY:</p>
      <p className="text-md">
        The ARABIANCHAIN TECHNOLOGIES Company may, but is not required to,
        monitor the NFTs, NFT Media, and Your Content uploaded to the NFT
        Marketplace for infringement of a third party's intellectual property
        rights. However, the Company cannot guarantee that all such content will
        be reviewed before it is put on the Service, nor can it guarantee that
        objectionable content will be removed immediately after being uploaded.
        As a result, the Company accepts no responsibility for any action taken
        in response to transmissions, conversations, or content provided by any
        user or third party. If you feel that any text, images, videos, audio,
        photographs, or other materials uploaded, downloaded, or appearing on
        the Services have been copied in a way that violates copyright, you may
        notify our legal department in writing by giving the following
        information: Identification of the allegedly infringed copyrighted work.
        Identification of the allegedly infringing material to be removed,
        including a description of where it is located on the ARABIANCHAIN NFTS’
        MARKETPLACE Platform. Contact information such as telephone or mobile
        number, address, and email address for our copyright agents to reach out
        to you. A written statement that you have a good faith belief that the
        identified, alleged infringing use is not authorized by the actual
        copyright owner, its associates, or the law. The electronic or physical
        signature of a person authorized to act on behalf of the owner of the
        allegedly infringed copyright or exclusive right. Please keep in mind
        that if you materially misrepresent that content or an action does not
        infringe on the copyrights of others, you will be held accountable for
        damages.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">MONITORING:</p>
      <p className="text-md">
        The ARABIANCHAIN TECHNOLOGIES Company reserves the right, but not the
        duty, to monitor the content, evaluate compliance with this TOU and
        other operating rules set by ARABIANCHAIN TECHNOLOGIES Company, and
        comply with any law, regulation, or approved government request. In its
        sole discretion, ARABIANCHAIN TECHNOLOGIES Company reserves the right to
        refuse to post, edit, or remove any objectionable material submitted to
        or posted on the Service. Without limiting the above, ARABIANCHAIN
        TECHNOLOGIES Company has the right to remove any material that it deems,
        in its sole discretion, has violated the conditions of this Agreement or
        is otherwise objectionable.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">COMMUNICATION PREFERENCES:</p>
      <p className="text-md">
        By acknowledging these Terms, you agree to receive electronic
        communications from ARABIANCHAIN TECHNOLOGIES by creating an Account
        (e.g., via text messages, email, push notification, or other forms of
        communication). These communications, which are part of your
        relationship with us, may include notices concerning your Account (e.g.,
        transactional information). We may send you promotional emails that we
        believe will be of interest to you. You are, however, not required to
        provide this consent as a condition of using the Service, and you may
        opt-out of these communications via the Service or through the operating
        system of your mobile device (except for important service announcements
        and administrative messages) by following the unsubscribe instructions
        provided.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">APP TERMS:</p>
      <p className="text-md">
        You, as the User, must provide the mobile device, cellular service plan,
        software, Internet connections, and/or other equipment or services
        required to download, install, and use the App. We make no guarantees
        that the App may be accessed and utilized on any specific device or with
        any specific service plan. The ARABIANCHAIN TECHNOLOGIES Company makes
        no promises that the App or Service will be available in any specific
        geographic location.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">INDEMNIFICATION:</p>
      <p className="text-md">
        You agree to defend, indemnify, compensate, reimburse, and hold harmless
        ARABIANCHAIN TECHNOLOGIES Company (including each of its directors,
        officers, employees, members, agents, and affiliates) from any claim,
        demand, action, damage, loss, cost, or expense, including attorneys'
        fees or other legal fees, arising out of or relating to (a) Your use of,
        and/or conduct in connection with, the Service; or (b) Your violation of
        these Terms or any other applicable ARABIANCHAIN TECHNOLOGIES policy or
        contract. You agree to notify ARABIANCHAIN TECHNOLOGIES of any Claims as
        soon as possible and to cooperate with the ARABIANCHAIN TECHNOLOGIES
        Parties in defending such Claims. You further agree that the
        ARABIANCHAIN TECHNOLOGIES Parties will have sole authority over the
        defense or settlement of any Claims. THIS INDEMNITY IS IN SUPPLEMENT TO,
        NOT IN LIEU OF, ANY OTHER INDEMNITIES OUTLINED IN A WRITTEN AGREEMENT
        BETWEEN YOU AND ARABIANCHAIN TECHNOLOGIES.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">DISCLAIMERS:</p>
      <p className="text-md">
        YOU ACCESS AND USE THE SERVICE AT YOUR OWN RISK. <br />YOU UNEQUIVOCALLY
        UNDERSTAND AND AGREE THAT THE SERVICE IS PROVIDED "AS IS" AND "AS
        AVAILABLE" AND ARABIANCHAIN TECHNOLOGIES EXPRESSLY DISCLAIMS ANY AND ALL
        WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED. <br />ARABIANCHAIN TECHNOLOGIES
        AND ITS SUPPLIERS MAKE NO WARRANTY OR REPRESENTATION AND DISCLAIM ALL
        RESPONSIBILITY FOR WHETHER THE SERVICE WILL: <br />(A) MEET YOUR REQUIREMENTS;
        <br />(B) BE AVAILABLE ON AN UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE
        BASIS; OR <br />(C) BE ACCURATE, RELIABLE, COMPLETE, LEGAL OR SAFE.
        <br /><br />ARABIANCHAIN TECHNOLOGIES DISCLAIMS ALL OTHER EXPRESS OR IMPLIED
        WARRANTIES OR CONDITIONS, INCLUDING, BUT NOT LIMITED TO, IMPLIED
        WARRANTIES OF MERCHANTABILITY, TITLE, NON-INFRINGEMENT, AND FITNESS FOR
        A PARTICULAR PURPOSE. <br />WE CAN NOT ENSURE THE SECURITY OF ANY DATA YOU
        SHARE ONLINE. <br />NO ADVICE OR INFORMATION OBTAINED FROM THE ARABIANCHAIN
        TECHNOLOGIES PARTIES OR ITS SERVICE PROVIDERS, WHETHER ORAL OR WRITTEN,
        WILL GENERATE ANY WARRANTY OR REPRESENTATION NOT EXPRESSLY DEFINED
        HEREIN. <br />YOU ACCEPT THE SECURITY RISKS INHERENT IN PROVIDING INFORMATION
        AND DEALING ONLINE OVER THE INTERNET AND WILL NOT HOLD THE ARABIANCHAIN
        TECHNOLOGIES COMPANY RESPONSIBLE FOR ANY SECURITY BREACH. <br /><br />ARABIANCHAIN
        TECHNOLOGIES WILL NOT BE RESPONSIBLE OR LIABLE TO YOU FOR ANY LOSS
        INCURRED AND TAKE NO RESPONSIBILITY FOR ANY USE OF THE NFTS, SERVICE
        CONTENT, AND/OR CONTENT LINKED TO OR ASSOCIATED WITH THE NFTS, INCLUDING
        BUT NOT LIMITED TO ANY LOSSES, DAMAGES, OR CLAIMS EMERGING FROM: USER
        ERROR, INACCURATE TRANSACTIONS, OR MISTYPED ADDRESSES. <br /><br />SERVER FAILURE OR
        DATA LOSS. UNAUTHORIZED ACCESS OR USE. ANY UNSANCTIONED THIRD-PARTY
        ACTIVITIES, INCLUDING WITHOUT LIMITATION THE USE OF VIRUSES, BRUTE
        FORCING, PHISHING, OR OTHER MEANS OF ATTACK AGAINST THE SERVICE. <br /><br />NFTS
        ONLY EXIST DUE TO THE OWNERSHIP RECORD KEPT IN THE ASSOCIATED BLOCKCHAIN
        (E.G., ETHEREUM NETWORK). <br />ON THE ASSOCIATED BLOCKCHAIN, ANY TRANSFERS OR
        SALES TAKE PLACE (E.G., ETHEREUM). <br /><br />ARABIANCHAIN TECHNOLOGIES AND/OR ANY
        OTHER ARABIANCHAIN TECHNOLOGIES PARTY CAN NOT IMPACT OR OTHERWISE
        CONTROL THE TRANSFER OF TITLE OR RIGHT IN ANY NFTS, UNDERLYING OR
        ASSOCIATED CONTENT, OR ITEMS. NO ARABIANCHAIN TECHNOLOGIES PARTY IS
        RESPONSIBLE OR LIABLE FOR ANY LOSSES OR INJURY CAUSED BY VULNERABILITY,
        ABNORMAL BEHAVIOR OF SOFTWARE, ANY KIND OF FAILURE (E.G., WALLET, SMART
        CONTRACT), BLOCKCHAINS, OR OTHER NFTS FEATURES. ADDITIONALLY, NO
        ARABIANCHAIN TECHNOLOGIES PARTY IS LIABLE FOR LOSSES OR INJURY AS A
        RESULT OF LATE REPORTING BY DEVELOPERS OR NO REPORT AT ALL OF ANY ISSUES
        WITH THE BLOCKCHAIN SUPPORTING THE NFTS, INCLUDING TECHNICAL NODE
        ISSUES, FORKS, OR ANY OTHER ISSUES CAUSING LOSSES OR INJURY. Because
        specific jurisdictions do not permit the exclusion of implied guarantees
        in consumer transactions, the foregoing exclusion may not apply to you.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">ASSUMPTION OF RISK:</p>
      <p className="text-md">
        Any purchase or sale of an NFT that you make, accept, or facilitate
        outside of the ARABIANCHAIN NFTS’ MARKETPLACE is totally at your own
        risk. You acknowledge that you have gathered enough information to make
        an informed decision to purchase an NFT, including thoroughly examining
        the code of the smart contract and the NFT and fully understanding and
        accepting its functionality. We have no control over or endorsement over
        the acquisition or selling of NFTs outside of the ARABIANCHAIN NFTS’
        MARKETPLACE platform. We clearly reject and disclaim all duty to you, as
        well as any obligation to reimburse or hold you blameless for any losses
        you may experience because of transacting or facilitating transactions
        in NFTs outside of the ARABIANCHAIN NFTS’ MARKETPLACE platform.
        Third-party content, data, information, applications, or materials may
        be displayed, included, or made available in certain portions of the
        ARABIANCHAIN TECHNOLOGIES website ("Third Party Materials"). By using
        the ARABIANCHAIN TECHNOLOGIES platform, you understand and accept that
        our Company is not responsible for inspecting or evaluating the
        Third-Party Materials' content, accuracy, completeness, availability,
        timeliness, validity, copyright compliance, legality, decency, quality,
        or any other aspect. ARABIANCHAIN TECHNOLOGIES Company does not warrant
        or endorse any third-party services, Third Party Materials, or other
        third parties' materials, products, or services. We don't assume and
        will not have any liability or responsibility to you or any other person
        for any third-party services, including and not limited to Third Party
        Materials or any other materials, products, or third parties’ services.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">LIABILITY LIMITATIONS:</p>
      <p className="text-md">
        TO THE EXTENT AS LEGALLY PERMISSIBLE, YOU AGREE THAT ARABIANCHAIN
        TECHNOLOGIES OR ITS SERVICE PROVIDERS WILL NOT BE LIABLE TO YOU, ANY
        ENTITY, OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT,
        INCIDENTAL, CONSEQUENTIAL, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES
        ARISING FROM THESE TERMS OR THE SERVICE, PRODUCTS, OR THIRD-PARTY SITES
        AND PRODUCT; OR FOR ANY OTHER CLAIM, DEMAND, OR DAMAGES ARISING OUT OF
        OR IN CONNECTION WITH THESE TERMS OF DELIVERY, USE, OR PERFORMANCE OF
        THE SERVICE. ACCESS TO AND/OR USE OF THE SERVICE, PRODUCTS, OR
        THIRD-PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK. YOU
        WILL BE SOLELY RESPONSIBLE FOR ANY IMPAIRMENT TO YOUR COMPUTER SYSTEM OR
        MOBILE DEVICE OR LOSS OF DATA AS A RESULT. NOTWITHSTANDING ANYTHING TO
        THE CONTRARY CONTAINED HEREIN, ARABIANCHAIN TECHNOLOGIES'S MAXIMUM
        AGGREGATE LIABILITY RELATED TO THESE TERMS, THE ACCESS TO AND USE OF THE
        SERVICE, ITS CONTENT, NFTS, OR ANY ARABIANCHAIN TECHNOLOGIES PRODUCTS OR
        SERVICES SHALL EXCEED THE GREATER OF (A) 375 SAUDI RIYAL OR (B) THE
        AMOUNT RECEIVE THE ABOVE LIMITATIONS APPLY EVEN IF THE REMEDY MENTIONED
        ABOVE FAILS OF ITS ESSENTIAL PURPOSE. Because some jurisdictions may not
        permit the exclusion or limitation of incidental or consequential
        damages, the foregoing limitation or exclusion may not be applicable to
        you. Because some jurisdictions restrict disclaimers or limitations of
        liability for the personal harm caused by consumer products, this
        limitation may not apply to personal injury claims.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">PRIVACY POLICY:</p>
      <p className="text-md">
        Please see our Privacy Policy for details on how we gather, utilize, and
        share personal information about you. We deem that you agree to the
        terms of our Privacy Policy by submitting personal data through our
        Service, and you expressly consent to the collection, use, and
        dissemination of your personal data per the Privacy Policy.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">MODIFICATIONS TO THE SERVICE:</p>
      <p className="text-md">
        ARABIANCHAIN TECHNOLOGIES company reserves the right, in its sole
        discretion, to change, suspend, or cancel the Service (or any features
        or components thereof) at any time and without liability.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">
        DISPUTE RESOLUTION AND ARBITRATION:
      </p>
      <p className="text-md">
        Please read the arbitration agreement in this section ("Arbitration
        Agreement") carefully. It forces you to arbitrate conflicts with
        ARABIANCHAIN TECHNOLOGIES and restricts your ability to seek remedy from
        us. This section explicitly states that ARABIANCHAIN TECHNOLOGIES does
        not govern disputes between users or between users and third parties.
        ARABIANCHAIN TECHNOLOGIES does not provide dispute resolution services
        for such disagreements, and the parties must handle them personally.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">SETTLEMENT NEGOTIATIONS:</p>
      <p className="text-md">
        If you have a potential legal dispute, claim, or cause of action against
        ARABIANCHAIN TECHNOLOGIES Company, you must first contact ARABIANCHAIN
        TECHNOLOGIES Company by sending an email to disputes@arabianchain.org
        describing the nature of the potential dispute, claim, or cause of
        action in addition to providing all relevant documentation and evidence
        thereof (before initiating any litigation proceedings). If ARABIANCHAIN
        TECHNOLOGIES Company so elects, User agrees to exercise commercially
        reasonable efforts to resolve all such legal dispute, claim, or cause of
        action within 60 days of the transmission of such email.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">
        AGREEMENT TO MANDATORY ARBITRATION:
      </p>
      <p className="text-md">
        Except as provided in Section 16.1, all claims, disputes, and
        controversies directly or indirectly emerging from or concerning these
        Terms or any of the matters or transactions contemplated by these Terms,
        which includes the avoidance of doubt, any claim seeking to invalidate,
        or alleging that some or all parts of these Terms are unenforceable,
        void or voidable shall be resolved only by the arbitrator, not by any
        federal, state, or local court, agency, or other governmental entity.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">WAIVER OF JURY TRIAL:</p>
      <p className="text-md">
        YOU, THE USER, HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS TO
        SUE IN COURT AND HAVE A TRIAL BEFORE A COURT. Except as provided in
        Section 16.2 above ("Agreement to Mandatory Arbitration"), you and
        ARABIANCHAIN TECHNOLOGIES elect all claims and disputes to be handled
        through arbitration under this Arbitration Agreement. An arbitrator can
        award the same damages and relief as a court on an individual basis and
        must follow these Terms in the same way that a court would. However, in
        arbitration, there is no judge or jury, and a court's review of an
        arbitration ruling is limited.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">
        WAIVER OF CLASS ACTION AND CLASS ARBITRATION:
      </p>
      <p className="text-md">
        ALL CLAIMS AND DISPUTES UNDER THE PURVIEW OF THE AFOREMENTIONED
        ARBITRATION AGREEMENT MUST BE ARBITRATED INDIVIDUALLY, NOT
        REPRESENTATIVELY OR COLLECTIVELY. ONLY INDIVIDUAL RELIEF IS AVAILABLE
        UNDER THIS CLAUSE, AND CLAIMS OF MORE THAN ONE USER, PERSON, OR ENTITY
        MAY NOT BE ARBITRATED OR CONSOLIDATED WITH THE CLAIMS OF ANY OTHER USER,
        PERSON, OR ENTITY. As a result, an arbitrator may not combine or
        consolidate more than one party's claims under the arbitration
        procedures provided in this section without the written approval of all
        affected parties to an arbitration process. Notwithstanding the
        foregoing, you and ARABIANCHAIN TECHNOLOGIES agree that no dispute will
        be resolved by class arbitration unless all affected parties provide
        written approval.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">GOVERNING LAW AND VENUE:</p>
      <p className="text-md">
        These Terms, as well as your access to and use of the Service, will be
        governed by, construed, and enforced in conformity with the Law of Saudi
        Arabia (without regard to conflict of law rules or principles of the
        Kingdom of Saudi Arabia, or any other legal authority that would induce
        the application of the laws of that jurisdiction). Any disagreement
        between the parties that is not subject to arbitration under Section 16
        or cannot be adjudged in small claims court shall be addressed in the
        state or federal courts of Saudi Arabia.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">TERMINATION:</p>
      <p className="text-md">
        If you, the User, violate any of the conditions of these Terms, all
        licenses granted by ARABIANCHAIN TECHNOLOGIES will be automatically
        terminated. Furthermore, notwithstanding anything in these Terms,
        ARABIANCHAIN TECHNOLOGIES reserves the right, with or without notice and
        in its sole discretion, to suspend, disable, terminate, or delete your
        Account as well as your ability to access or use the Service at any time
        and for any or no reason. You understand and agree that we shall have no
        liability or obligation to you in such an event and that you will not be
        entitled to a refund of any amounts that you have paid.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">SEVERABILITY:</p>
      <p className="text-md">
        If any Terms are deemed invalid or unenforceable, that term, provision,
        or clause will be deemed severable from these Terms. However, it will
        not affect the validity or enforceability of any remaining part of that
        term, provision, or clause; or any other term, provision, or clause of
        these Terms.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">INJUNCTIVE RELIEF:</p>
      <p className="text-md">
        You, as the User, agree that a breach of these Terms will cause
        ARABIANCHAIN TECHNOLOGIES irreparable harm for which monetary damages
        would be deficient, and ARABIANCHAIN TECHNOLOGIES shall be entitled to
        equitable relief in conjunction with any other remedies available to it
        under these Terms or at law, without the need for a bond, other
        security, or proof of damages.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">SURVIVAL:</p>
      <p className="text-md">
        All sections that, by definition, should survive the termination of
        these Terms shall remain in full force and effect following and
        notwithstanding any termination of these Terms by ARABIANCHAIN
        TECHNOLOGIES or you. The termination will not affect any of ARABIANCHAIN
        TECHNOLOGIES's other legal, equitable rights or remedies.
      </p>
      <p className="text-2xl font-semibold mt-24 underline mb-7">MISCELLANEOUS:</p>
      <p className="text-md pb-32">
        These Terms, represent the complete Agreement between you and
        ARABIANCHAIN TECHNOLOGIES regarding your access to and use of the
        ARABIANCHAIN TECHNOLOGIES Services and Content. The User may not
        transfer or allocate these Terms, or any rights and licenses granted
        herein, without the prior written Agreement of ARABIANCHAIN
        TECHNOLOGIES, and ARABIANCHAIN TECHNOLOGIES's failure to assert any
        right or provision under these Terms shall not embody a waiver of such
        right or provision. Unless otherwise specified, these Terms are solely
        for the benefit of the parties. These Terms are not intended to confer
        third-party beneficiary rights on any other person or entity.
      </p>
    </div>
  );
};

export default TermsAndConditions;
