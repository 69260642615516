import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TopSeller6 from "../assets/ai5.jpg";
import { useGlobalState } from "../store";
import { getAuthorData } from "../Blockchain.Services";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

function ProfileSelectNav({ account, closeNav }) {
  const [avatar, setAvatar] = useGlobalState("avatar");
  const [loading, setLoading] = useState(true);
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const { ethereum } = window;

  const getInfoAuthor = async () => {
    try {
      if (connectedAccount) {
        const authorResult = await getAuthorData(
          connectedAccount.toLowerCase()
        );
        if (authorResult && authorResult.Item) {
          setAvatar(authorResult?.Item?.authorPhoto);
          setLoading(false);
        } else {
          setAvatar(TopSeller6);
        }
      } else {
        setAvatar(TopSeller6);
      }

      // setGlobalState("avatar", authorResult?.Item?.authorPhoto);
    } catch (error) {
      console.error("Error loading avatar:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (ethereum && connectedAccount) {
      getInfoAuthor();
    }
    if (!ethereum || !connectedAccount) {
      setAvatar(TopSeller6);
    }
  }, [account, connectedAccount, avatar]);

  return (
    <div className="z-10">
      <ul className="nav-ul">
        <li className="nav-li">
          {loading ? (
            <LazyLoadImage
              effect="blur"
              loading="lazy"
              decoding="async"
              src={TopSeller6}
              alt="Loading..."
              className="profile rounded-full"
            />
          ) : (
            <LazyLoadImage
              effect="blur"
              loading="lazy"
              decoding="async"
              src={avatar || TopSeller6}
              alt="Profile"
              className="profile rounded-full"
            />
          )}
          {ethereum && connectedAccount && (
            <ul className="sub-list border border-[#434343] flex justify-center">
              <Link
                to={`/author/${account.toLowerCase()}`}
                activeclassname="active-link"
                className="text-white sub-item flex justify-center lg:justify-start text-[1.2rem]"
                onClick={closeNav}
              >
                My Profile
              </Link>

              <Link
                to={`/edit/${account.toLowerCase()}`}
                activeclassname="active-link"
                className="text-white sub-item flex justify-center lg:justify-start text-[1.2rem]"
                onClick={closeNav}
              >
                Edit Profile
              </Link>
            </ul>
          )}
        </li>
      </ul>
    </div>
  );
}

export default ProfileSelectNav;
