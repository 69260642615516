import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <div>
        <div id="stars1"></div>
        <div id="stars3"></div>
        <div id="stars4"></div>
      </div>
      <div id="wrapper">
        <div id="rocket"></div>
        <div className="rocket__body__part">
          <p>D U B X</p>
        </div>
        <div id="advanced" className="circlee"></div>
        <div id="advancedd" className="circleee"></div>
        <div className="loadcontainer">
          <div className="red flame"></div>
          <div className="orange flame"></div>
          <div className="yellow flame"></div>
          <div className="white flame"></div>
          <div className="blue circle"></div>
          <div className="black circle"></div>
        </div>
        <div className="wings">
          <div id="left"></div>
          <div id="right"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
