import React, { useState, useRef, useEffect } from "react";
import { useGlobalState, setGlobalState, getGlobalState } from "../store";
import { FaTimes } from "react-icons/fa";
import { create as ipfsHttpClient } from "ipfs-http-client";
import axios from "axios";
import MessageModal from "../components/MessageModal";
import { Link } from "react-router-dom";
import { ethers } from "ethers";
import ReactPlayer from "react-player";
import ReactAudioPlayer from "react-audio-player";
import {
  adminCost,
  convertToWei,
  getEthersContract,
} from "../Blockchain.Services";
import Select from "react-select";
import Loader from "../components/Loader";
import { useNavigate } from "react-router-dom";
import abi from "../abis/DubxNFTMarketplace.json";
import { useDrag, useDrop } from "react-dnd";

import ConfettiContainer from "../components/ConfettiContainer";

const FaqItem = ({ question, answer }) => {
  const [isOpenFaq, setIsOpenFaq] = useState(false);

  const handleToggle = () => {
    setIsOpenFaq(!isOpenFaq);
  };

  return (
    <div className={`faq-item ${isOpenFaq ? "open" : ""}`}>
      <div className="faq-question" onClick={handleToggle}>
        <h3 className="text-[#21e786]">{question}</h3>
        <span>{isOpenFaq ? "▲" : "▼"}</span>
      </div>
      {isOpenFaq && (
        <div className="faq-answer bg-transparent w-full">{answer}</div>
      )}
    </div>
  );
};

const CreateNftPage = () => {
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  //step 1
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [step, setStep] = useState(0);
  const [royaltyFee, setRoyaltyFee] = useState(0);
  const [royaltyReceiver, setRoyaltyReceiver] = useState(
    "0x0000000000000000000000000000000000000000"
  );
  const [unlockableContent, setUnlockableContent] = useState("");
  const [audioFile, setAudioFile] = useState("");
  const [videoFile, setVideoFile] = useState("");
  const [audioLinkSelfhost, setAudioLinkSelfhost] = useState("");
  const [videoLinkSelfhost, setVideoLinkSelfhost] = useState("");

  const [externalLink, setExternalLink] = useState("");
  const [category, setCategory] = useState("all");
  const [imgBase64, setImgBase64] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(null);
  const [imageNameSizeType, setImageNameSizeType] = useState({
    name: "",
    size: "",
    type: "",
  });
  const [audioNameSizeType, setAudioNameSizeType] = useState({
    name: "",
    size: "",
    type: "",
  });
  const [videoNameSizeType, setVideoNameSizeType] = useState({
    name: "",
    size: "",
    type: "",
  });
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [showUlMessageModal, setShowUlMessageModal] = useState(true);
  const [showConfMessageModal, setShowConfMessageModal] = useState(false);
  const [showConfettiContainer, setShowConfettiContainer] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [traits, setTraits] = useState([]);
  const [newTraitName, setNewTraitName] = useState("");
  const [newTraitValue, setNewTraitValue] = useState("");
  const [newTraitTrait_count, setNewTraitTrait_count] = useState("");
  const [item, setItem] = useState({ id: 1, image: null });
  const inputRef = useRef();
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState("");
  const [collectionTitle, setCollectionTitle] = useState("");
  const [collectionImage, setCollectionImage] = useState("");
  const [collections, setCollections] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState("");
  const [confirmedLink, setConfirmedLink] = useState("");
  const [allTags, setAllTags] = useState([
    "Cyber",
    "Cosmic",
    "Digital",
    "Magic",
    "Electric",
    "Animal",
    "Pixel",
    "VirtualReality",
    "Nature",
    "Underwater",
    "VinylRecords",
    "Icons",
    "Extreme",
    "Portrait",
    "Legend",
    "Retro",
    "Anime",
    "Fantasy",
    "Abstract",
    "Characters",
    "Interactive",
    "Virtual",
    "AI",
    "Mistery",
    "Food",
    "Recipe",
    "Clothes",
    "Landscape",
    "Celebrities",
    "Robot",
    "Vehicles",
    "Luxury",
    "Accessory",
    "Toys",
    "Fitness",
    "Remix",
  ]);
  const { ethereum } = window;

  const navigate = useNavigate();

  const handleShowMessageModal = (
    newMessage,
    showUl = true,
    showConf = false
  ) => {
    setInfoMessage(newMessage);
    setShowMessageModal(true);
    setShowUlMessageModal(showUl);
    setShowConfMessageModal(showConf);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };

  // const ipfs = ipfsHttpClient({
  //   url: "http://localhost:5001/api/v0",
  // });

  const nftOwnerInfo = async () => {
    try {
      const getCollectionsByAuthor = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection?owner=${connectedAccount}`;
      // console.log(getCollectionsByAuthor);

      const response = await fetch(getCollectionsByAuthor, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      let getData;

      if (response.ok) {
        getData = await response.json();
        // console.log(getData);
      } else {
        console.error("No collections");
        getData = [];
      }

      if (getData) {
        const promises = getData.Items.map(async (collection) => {
          const title = collection.title;
          const collImage = collection.imageURI;
          return { ...collection, title, collImage };
        });

        const updatedCollections = await Promise.all(promises);
        // console.log("updatedCollections", updatedCollections);
        setCollections(updatedCollections);
      } else {
        console.log("No collections");
      }
    } catch (error) {
      console.error("Error fetching collection owner info.");
    }
  };

  // const fetchCollectionTitle = async (collectionHash) => {
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_DOMAIN}/ipfs/${collectionHash}`
  //     );
  //     const data = await response.json();
  //     return data.name;
  //   } catch (error) {
  //     console.error("Error fetching collection title:", error);
  //     return "Unknown Title";
  //   }
  // };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const toTopPage = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    nftOwnerInfo();
  }, [connectedAccount]);

  useEffect(() => {
    const setCollectionTitleandImage = async () => {
      if (selectedCollection && collections.length > 0) {
        const selectedColl = collections.find(
          (collection) => collection.contract === selectedCollection
        );

        if (selectedColl) {
          // console.log(
          //   "Selected coll:",
          //   selectedColl.title,
          //   selectedColl.imageURI
          // );
          setCollectionTitle(selectedColl.title);
          setCollectionImage(selectedColl.imageURI);
        } else {
          console.error("Selected collection not found");
        }
      } else {
        setCollectionTitle("");
        setCollectionImage("");
      }
    };
    setCollectionTitleandImage();
  }, [selectedCollection, collections]);

  const mintNFT = async (
    title,
    description,
    metadataURI,
    unlockable = "",
    price,
    receiver,
    fee,
    contrA
  ) => {
    try {
      price = convertToWei(price);
      const contract = await getEthersContract(contrA.toLowerCase());
      const mintAmount = await adminCost();
      const mintcostInWei = convertToWei(mintAmount);

      if (!receiver || receiver == "") {
        setRoyaltyReceiver("0x0000000000000000000000000000000000000000");
      }
      if (!fee || fee == "") {
        setRoyaltyFee(0);
      }

      // console.log("Minting parameters:", {
      //   title,
      //   description,
      //   metadataURI,
      //   unlockable,
      //   price,
      //   receiver,
      //   fee,
      //   mintcostInWei,
      // });

      const result = await contract.payToMint(
        title,
        description,
        metadataURI,
        unlockable,
        price,
        receiver,
        fee,
        { value: mintcostInWei, gasPrice: 20000000000 }
      );
      handleShowMessageModal("Minting is in progress...", true);
      const receipt = await result.wait();

      return receipt;
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        handleShowMessageModal("Transaction rejected by user.", false);
        console.log("User rejected the transaction.");
      } else {
        console.error("Error minting NFT:", error.message);
      }
      return false;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (unlockableContent && !unlockableContent.startsWith("https")) {
        handleShowMessageModal(
          "Unlockable Content link must start with 'https'.",
          false,
          false
        );
        return;
      }
      if (externalLink && !externalLink.startsWith("https")) {
        handleShowMessageModal(
          "External link must start with 'https'.",
          false,
          false
        );
        return;
      }
      handleShowMessageModal("Preparing...", true);
      let tempContract;

      if (selectedTab === "singleNft") {
        // tempContract = abi.networks[3270].address;
        tempContract = abi.networks[3269].address;
      } else if (selectedTab === "selectCollection") {
        tempContract = selectedCollection;
      }
      handleShowMessageModal("Adding image to IPFS...", true);

      // const uploadedFile = await ipfs.add(selectedFile);
      // const imageURI = `${process.env.REACT_APP_DOMAIN}/ipfs/${uploadedFile.path}`;

      const imageFormData = new FormData();
      imageFormData.append("file", selectedFile);
      const imageOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
        },
        body: imageFormData,
      };
      const imageResponse = await fetch(
        `${process.env.REACT_APP_PINATA_PIN_FILE}`,
        imageOptions
      );
      const imageDataPinata = await imageResponse.json();
      const imageURI = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${imageDataPinata.IpfsHash}`;

      let fileCount = 0;
      let audioURI = null;
      if (audioFile && audioFile !== "") {
        // const uploadedAudioFile = await ipfs.add(audioFile);
        // audioURI = `${process.env.REACT_APP_DOMAIN}/ipfs/${uploadedAudioFile.path}`;

        const audioFormData = new FormData();
        audioFormData.append("file", audioFile);
        const audioOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
          },
          body: audioFormData,
        };
        const audioResponse = await fetch(
          `${process.env.REACT_APP_PINATA_PIN_FILE}`,
          audioOptions
        );
        const audioDataPinata = await audioResponse.json();
        audioURI = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${audioDataPinata.IpfsHash}`;
        fileCount++;
      }

      let videoURI = null;
      if (videoFile && videoFile !== "") {
        // const uploadedVideoFile = await ipfs.add(videoFile);
        // videoURI = `${process.env.REACT_APP_DOMAIN}/ipfs/${uploadedVideoFile.path}`;

        const videoFormData = new FormData();
        videoFormData.append("file", videoFile);
        const videoOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
          },
          body: videoFormData,
        };
        const videoResponse = await fetch(
          `${process.env.REACT_APP_PINATA_PIN_FILE}`,
          videoOptions
        );
        const videoDataPinata = await videoResponse.json();
        videoURI = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${videoDataPinata.IpfsHash}`;
        fileCount++;
      }

      if (audioLinkSelfhost) {
        fileCount++;
      }

      if (videoLinkSelfhost) {
        fileCount++;
      }

      if (!royaltyReceiver) {
        setRoyaltyReceiver("0x0000000000000000000000000000000000000000");
      }
      if (!royaltyFee) {
        setRoyaltyFee(0);
      }
      const nft = {
        title,
        price,
        description,
        imageURI,
        royaltyFee,
        royaltyReceiver,
        externalLink,
        category,
        traits: traits,
        tags: selectedTags,
      };
      // setSingleNft(nft);
      const currentTimestamp = Date.now();
      const unixTime = Math.floor(currentTimestamp / 1000);

      const attributes = traits.map((trait) => ({
        trait_type: trait.trait_type,
        value: trait.value,
        trait_count: trait.trait_count || 0,
      }));
      let jsonNft = {};

      if (fileCount > 1) {
        // Display an error message or handle the validation as needed
        handleShowMessageModal(
          "You can upload only 1 audio or video file or link",
          false,
          false
        );
        console.error("You can only upload 1 file or 1 link.");
        return;
      } else {
        jsonNft = {
          name: title,
          description: description,
          image: imageURI,
          ...(audioURI && { audio: audioURI }),
          ...(videoURI && { video: videoURI }),
          ...(audioLinkSelfhost && { audio_external: audioLinkSelfhost }),
          ...(videoLinkSelfhost && { video_external: videoLinkSelfhost }),
          date: unixTime,
          attributes: attributes,
          royalty_fee_percentage: royaltyFee,
          external_link: externalLink,
          category: category,
          tags: selectedTags.map((tag) => tag.value),
          ...(selectedCollection && {
            collection_contract: selectedCollection,
          }),
          ...(collectionTitle && { collection_title: collectionTitle }),
          ...(collectionImage && { collection_image: collectionImage }),
        };
      }

      const nftJSON = JSON.stringify(jsonNft);
      // console.log(nftJSON);
      // const nftIPFSResult = await ipfs.add(nftJSON);
      // const nftIPFSLink = `${process.env.REACT_APP_DOMAIN}/ipfs/${nftIPFSResult.path}`;
      handleShowMessageModal("Adding metadata to IPFS...", true);

      const jsonOptions = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_PINATA_SECRET_ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(jsonNft),
      };

      const jsonResponse = await fetch(
        `${process.env.REACT_APP_PINATA_PIN_JSON}`,
        jsonOptions
      );

      if (!jsonResponse.ok) {
        throw new Error("Failed to pin JSON to IPFS");
      }

      const jsonDataPinata = await jsonResponse.json();
      const nftIPFSLink = `${process.env.REACT_APP_PINATA_DOMAIN}/ipfs/${jsonDataPinata.IpfsHash}`;

      if (!royaltyReceiver || royaltyReceiver === "") {
        setRoyaltyReceiver("0x0000000000000000000000000000000000000000");
      }
      if (!royaltyFee || royaltyFee !== 0) {
        setRoyaltyFee(0);
      }
      if (royaltyReceiver) {
        const newMintedNft = await mintNFT(
          title,
          description,
          jsonDataPinata.IpfsHash,
          unlockableContent,
          price,
          royaltyReceiver,
          royaltyFee,
          tempContract
        );

        if (newMintedNft && newMintedNft.events[0].args.tokenId) {
          const temporaryTokenId =
            newMintedNft.events[0].args.tokenId.toString();
          const createNftCost = await adminCost();
          const requestUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft`;

          const requestBody = {
            //newNftid: `${tempContract}${temporaryTokenId}`,
            contract: tempContract,
            collectionId: parseInt(temporaryTokenId),
            owner: connectedAccount,
            title: title,
            description: description,
            metadataURI: nftIPFSLink,
            imageURI: imageURI,
            ...(audioURI && { audio: audioURI }),
            ...(videoURI && { video: videoURI }),
            ...(audioLinkSelfhost && { audio_external: audioLinkSelfhost }),
            ...(videoLinkSelfhost && { video_external: videoLinkSelfhost }),
            purchaseCost: price,
            royaltyReceivers: royaltyReceiver,
            royaltyFee: royaltyFee,
            originalArtist: connectedAccount,
            mintingCost: createNftCost,
            isListedForSale: true,
            externalLink: externalLink,
            category: category,
            traits: traits,
            tags: selectedTags,
            timestamp: unixTime,
            nftViews: 0,
            ...(selectedCollection && {
              collection_contract: selectedCollection,
            }),
            ...(collectionTitle && { collection_title: collectionTitle }),
            ...(collectionImage && { collection_image: collectionImage }),
          };
          const requestUrlAuthor = `${
            process.env.REACT_APP_AWS_API_GATEWAY
          }/author?account=${connectedAccount.toLowerCase()}`;
          const responseFromDbAuthor = await fetch(requestUrlAuthor, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          });
          if (responseFromDbAuthor.ok) {
            const resultAuthor = await responseFromDbAuthor.json();
            // console.log("resultAuthor", resultAuthor);
            if (!resultAuthor.Item) {
              const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
              console.log("there is no author in db");
              const requestAuthorBody = {
                account: connectedAccount.toLowerCase(),
                timestamp: currentTimestampInSeconds,
                verified: false,
                authorPhoto: "",
                bannerImage: "",
                name: "",
                email: "",
                externalLink: "",
                smartCode: "",
                bio: "",
                facebook: "",
                twitter: "",
                instagram: "",
                skype: "",
                nftCount: 0,
                collectionCount: 0,
              };
              const requestUrlAuthor = `${process.env.REACT_APP_AWS_API_GATEWAY}/author`;
              const insertAuthor = await fetch(requestUrlAuthor, {
                method: "PUT",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(requestAuthorBody),
              });
              if (insertAuthor.ok) {
                console.log("author inserted");
              }
            } else {
              console.log("there is already author in db");
            }
          }

          try {
            // console.log("requestBodysssssss", requestBody);
            const response = await fetch(requestUrl, {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(requestBody),
            });
            if (response.ok) {
              const responseSingleNft = await response.json();
              // console.log("Response from API:", responseSingleNft);
              const currentNfts = getGlobalState("nfts");
              const nfts = [responseSingleNft.Item, ...currentNfts];
              setGlobalState("nfts", nfts);
            } else {
              console.error("Failed to send request:", response.statusText);
            }
          } catch (innerError) {
            console.error("Inner error:", innerError.message);
          }

          resetForm();
          handleShowMessageModal(
            "Minting completed, in a few seconds you will see your new NFT !!!",
            false,
            true
          );
          await new Promise((resolve) => setTimeout(resolve, 3000));
          handleCloseMessageModal();
          setShowConfettiContainer(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          setShowConfettiContainer(false);
          navigate(`/n/${tempContract}/${temporaryTokenId}`, { replace: true });
        }
      } else {
        console.log("no receiver");
      }
    } catch (error) {
      console.log("Error uploading file: ", error);
      handleShowMessageModal("Minting failed...Try again!", false);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      handleCloseMessageModal();
    }
  };
  const CreateNftSingle = () => {
    const [{ isDragging }, drag] = useDrag({
      type: "ITEM",
      item: { id: 1, image: item.image },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [, drop] = useDrop({
      accept: "ITEM",
      hover: (draggedItem) => {
        setItem({ id: 1, image: draggedItem.image });
      },
    });

    const handleImageUpload = (e) => {
      const file = e.target.files[0];
      if (file) {
        const originalName = file.name;
        const extension = originalName.split(".").pop();
        const limitedName =
          originalName.length > 30
            ? originalName.substring(0, 30) + "... ." + extension
            : originalName;
        setImgBase64(file);
        setSelectedFile(e.target.files[0]);
        setImageNameSizeType({
          name: limitedName,
          size: e.target.files[0].size,
          type: e.target.files[0].type,
        });
        setImageOne(file);
        setItem({ id: 1, image: URL.createObjectURL(file) });
      }
    };

    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{
          opacity: isDragging ? 0.5 : 1,
          cursor: "move",
          padding: "8px",
          margin: "8px",
          border: "1px solid #ccc",
          borderRadius: "4px",
          position: "relative",
        }}
      >
        <input
          type="file"
          onChange={handleImageUpload}
          style={{ display: "none" }}
          ref={(fileInput) => (fileInputRef = fileInput)}
        />
        <img
          src={
            item.image ||
            "https://dummyimage.com/260x260/1F2937/293545.png&text=Upload"
          }
          alt={`Upload Logo `}
          onClick={() => fileInputRef.click()}
          onDragOver={(e) => e.preventDefault()}
          onDrop={(e) => {
            e.preventDefault();
            const file = e.dataTransfer.files[0];
            if (file) {
              const originalName = file.name;
              const extension = originalName.split(".").pop();
              const limitedName =
                originalName.length > 30
                  ? originalName.substring(0, 30) + "... ." + extension
                  : originalName;
              setImgBase64(file);
              setSelectedFile(file);
              setImageNameSizeType({
                name: limitedName,
                size: file.size,
                type: file.type,
              });
              setImageOne(file);
              setItem({ id: 1, image: URL.createObjectURL(file) });
            }
          }}
          style={{
            width: "360px",
            height: "auto",
            objectFit: "cover",
            borderRadius: "4px",
          }}
        />
        {isDragging && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              fontSize: "24px",
              backgroundColor: "rgba(0, 0, 0, 0.7)",
              zIndex: 999,
            }}
          >
            DROP AND DRAG LIKE IT'S HOT
          </div>
        )}
      </div>
    );
  };
  const closeModal = () => {
    setGlobalState("modal", "scale-0");
    resetForm();
  };

  const resetForm = () => {
    setImgBase64(null);
    setTitle("");
    setPrice("");
    setDescription("");
    setCategory("");

    setSelectedFile(null);
    setImageNameSizeType({
      name: "",
      size: "",
      type: "",
    });
    setAudioNameSizeType({
      name: "",
      size: "",
      type: "",
    });
    setVideoNameSizeType({
      name: "",
      size: "",
      type: "",
    });
    setTraits([]);
    setSelectedTags([]);
    setAudioFile("");
    setVideoFile("");
  };

  let fileInputRef = useRef(null);
  let file2InputRef = useRef(null);

  // step 2
  const [itemNft, setItemNft] = useState({ id: 2, image: null });

  const addTrait = () => {
    if (newTraitName && newTraitValue) {
      setTraits([
        ...traits,
        {
          trait_type: newTraitName.toUpperCase(),
          value: newTraitValue,
          trait_count: newTraitTrait_count || 0,
        },
      ]);
      setNewTraitName("");
      setNewTraitValue("");
      setNewTraitTrait_count("");
    }
  };
  const handleAudioUpload = async (e) => {
    e.preventDefault();
    const audio = e.target.files[0];

    if (e.target.files[0].size > 210500000) {
      handleShowMessageModal(
        "Size of the file must be less than 200 MB",
        false,
        false
      );
      return;
    }
    if (!audio.type.startsWith("audio/")) {
      handleShowMessageModal(
        "Invalid file type. Please upload an audio file.",
        false,
        false
      );
      return;
    }
    if (audio) {
      setAudioFile(audio);
      setAudioNameSizeType({
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
      });
    }
  };

  const handleVideoUpload = async (e) => {
    e.preventDefault();
    const video = e.target.files[0];
    if (e.target.files[0].size > 210500000) {
      handleShowMessageModal(
        "Size of the file must be less than 200 MB",
        false,
        false
      );
      return;
    }
    if (video) {
      setVideoFile(video);
      setVideoNameSizeType({
        name: e.target.files[0].name,
        size: e.target.files[0].size,
        type: e.target.files[0].type,
      });
    }
  };

  const handleTagChange = (selectedOptions) => {
    setSelectedTags(selectedOptions);
  };
  const handleAudioTabClick = async (platform) => {
    if (platform === "soundcloud") {
      if (!audioLinkSelfhost.startsWith("https://w.soundcloud.com/")) {
        handleShowMessageModal(
          "Enter correct SoundCloud link which must start with 'https://w.soundcloud.com/'.",
          false,
          false
        );
      } else {
        handleShowMessageModal(
          "Loading Soundcloud, please wait...",
          true,
          false
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
        handleCloseMessageModal();
      }
    } else if (platform === "audiocom") {
      if (!audioLinkSelfhost.startsWith("https://audio.com/")) {
        handleShowMessageModal(
          "Enter correct Audio.com link which must start with 'https://audio.com/'.",
          false,
          false
        );
      } else {
        handleShowMessageModal(
          "Loading Audio.com, please wait...",
          true,
          false
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
        handleCloseMessageModal();
      }
    } else if (platform === "amazon") {
      if (!audioLinkSelfhost.includes("amazonaws.com")) {
        handleShowMessageModal(
          "Enter correct Amazon AWS link which must contain 'amazonaws.com'.",
          false,
          false
        );
      } else {
        handleShowMessageModal(
          "Loading Audio.com, please wait...",
          true,
          false
        );
        await new Promise((resolve) => setTimeout(resolve, 3000));
        handleCloseMessageModal();
      }
    }
    setSelectedPlatform(platform);
  };

  const handleVideoTabClick = async (platform) => {
    if (platform === "youtube") {
      if (!videoLinkSelfhost.startsWith("https://www.youtube.com/embed/")) {
        handleShowMessageModal(
          "Enter correct Youtube link which must start with 'https://www.youtube.com/embed/'.",
          false,
          false
        );
      } else {
        handleShowMessageModal("Loading Youtube, please wait...", true, false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        handleCloseMessageModal();
      }
    } else if (platform === "rumble") {
      if (!videoLinkSelfhost.startsWith("https://rumble.com/embed/")) {
        handleShowMessageModal(
          "Enter correct Rumble link which must start with 'https://rumble.com/embed/'.",
          false,
          false
        );
      } else {
        handleShowMessageModal("Loading Rumble, please wait...", true, false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        handleCloseMessageModal();
      }
    } else if (platform === "vimeo") {
      if (!videoLinkSelfhost.startsWith("https://player.vimeo.com/video/")) {
        handleShowMessageModal(
          "Enter correct Vimeo link which must start with 'https://player.vimeo.com/video/'.",
          false,
          false
        );
      } else {
        handleShowMessageModal("Loading Vimeo, please wait...", true, false);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        handleCloseMessageModal();
      }
    }
    setSelectedPlatform(platform);
  };

  const handleAudioLinkFocus = () => {
    setVideoLinkSelfhost("");
  };
  const handleVideoLinkFocus = () => {
    setAudioLinkSelfhost("");
  };

  return (
    <div className="py-12">
      {step === 0 && (
        <div className="flex flex-col w-full justify-center items-center mt-32">
          <div className="text-center text-white w-full flex flex-col justify-around items-center m-auto cursor-default z-40">
            <h1 className="text-5xl font-bold text-[#21e786] mt-16">
              CREATE NFT
            </h1>
            <p className="w-[80%] md:1/3 py-12 text-xl font-semibold leading-10">
              If you wish to associate this NFT with a new collection, start by
              clicking{" "}
              {/* <span className="flex items-center px-4 py-2 bg-[#21e786] text-black m-2 font-bold"> */}
              <Link
                to="/create-collection"
                className="items-center px-4 py-1 bg-[#21e786] text-black m-2 font-bold"
              >
                HERE
              </Link>
              {/* </span>{" "} */}
              to create the collection. After creating the collection, you can
              proceed to add this NFT to it.
            </p>
            <div className="tabs-container flex flex-col xl:flex-row justify-around pb-12 cursor-pointer items-center">
              {/* ---------------- 1 ---------------- */}
              <div
                className="tab w-[300px] md:w-[570px] mx-4"
                onClick={() => setSelectedTab("singleNft")}
              >
                <div
                  className={`tf-work style-2 wow fadeInUp h-[300px] myshadow ${
                    selectedTab === "singleNft" ? "selectedCollectionBg" : ""
                  }`}
                >
                  <h2 className="text-5xl md:text-6xl text-[#21e786] font-bold pt-2 mb-[-80px]">
                    INDIVIDUAL
                  </h2>
                  <h3 className="text-center text-white font-bold text-2xl pt-24 pb-6 imgHover"></h3>
                  <p className="px-2 md:px-12">
                    Selecting this option means your NFT will stand alone, not
                    belonging to any specific collection. It will be a unique
                    and independent piece.
                  </p>
                </div>
              </div>
              {/* ---------------- 2 ---------------- */}
              <div
                className="tab w-[300px] md:w-[570px] mx-4"
                onClick={() => setSelectedTab("selectCollection")}
              >
                <div
                  className={`tf-work style-2 wow fadeInUp h-[300px] myshadow ${
                    selectedTab === "selectCollection"
                      ? "selectedCollectionBg"
                      : ""
                  }`}
                >
                  <h2 className="text-5xl md:text-6xl text-[#21e786] font-bold pt-2 mb-[-80px] text-center">
                    COLLECTION
                  </h2>
                  <h3 className="text-center text-white font-bold text-2xl pt-24 pb-6 imgHover"></h3>
                  <p className="px-2 md:px-12">
                    By choosing this option, your NFT will be part of a specific
                    collection, contributing to a curated set of artworks.
                  </p>
                  {selectedTab === "selectCollection" && (
                    <div>
                      {collections.length > 0 ? (
                        <div className="flex flex-col justify-between items-center bg-gray-800 rounded-xl mt-5">
                          <select
                            className="block w-full text-[25px] text-slate-500 bg-gray-800 border-0 focus:outline-none focus:ring-0 p-[13px]"
                            onChange={(e) => {
                              const selectedValue = e.target.value;
                              setSelectedCollection(
                                collections.some(
                                  (collection) =>
                                    collection.SK === selectedValue
                                )
                                  ? selectedValue
                                  : ""
                              );
                            }}
                            value={selectedCollection}
                          >
                            <option value="">Select Collection</option>
                            {collections.map((collection, index) => (
                              <option
                                key={index}
                                value={collection.SK}
                                className="hover:bg-green-500"
                              >
                                {collection.title}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div className="flex flex-col justify-between items-center rounded-xl mt-5">
                          <Link to="/create-collection">
                            <div className="flex items-center px-4 py-2 bg-[#21e786] rounded-full text-black m-2 font-bold">
                              <p className="text-black">NEW COLLECTION</p>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex justify-center pt-6">
            <button
              onClick={async () => {
                if (!ethereum) {
                  handleShowMessageModal(
                    "Please install Metamask to continue.",
                    false,
                    false
                  );
                  return;
                } else if (ethereum && ethereum._state.isUnlocked === false) {
                  handleShowMessageModal(
                    "Please unlock Metamask to continue.",
                    false,
                    false
                  );
                  return;
                } else if (
                  ethereum &&
                  ethereum._state.isUnlocked === true &&
                  !connectedAccount
                ) {
                  handleShowMessageModal(
                    "Please connect Metamask account to continue.",
                    false,
                    false
                  );
                  return;
                } else if (
                  ethereum &&
                  ethereum._state.isUnlocked === true &&
                  connectedAccount
                ) {
                  const chainId = await ethereum.request({
                    method: "eth_chainId",
                  });
                  if (chainId === "0xcc5") {
                    if (selectedTab === "singleNft") {
                      setStep(1);
                      toTopPage();
                    } else if (
                      selectedTab === "selectCollection" &&
                      selectedCollection &&
                      selectedCollection !== ""
                    ) {
                      setStep(1);
                      toTopPage();
                    } else if (
                      (selectedTab === "selectCollection" &&
                        collections.length > 0 &&
                        selectedCollection === "") ||
                      collections.length === 0
                    ) {
                      handleShowMessageModal(
                        "Please select a collection or create a new one by clicking the button 'NEW COLLECTION'.",
                        false,
                        false
                      );
                    }
                  } else {
                    handleShowMessageModal(
                      "Switch chain to dubxcoin network to continue.",
                      false,
                      false
                    );
                    return;
                  }
                }
              }}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#21e786] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak"
            >
              Next Step
            </button>
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="h-screen flex justify-center items-center flex-col">
          <div className="flex flex-col lg:flex-row w-full justify-center items-center">
            <div className="w-full lg:w-1/2 flex flex-col items-center">
              <label
                htmlFor="createNftName"
                className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white"
              >
                Title
              </label>
              <input
                type="text"
                id="createNftName"
                className="bg-transparent border w-2/3 mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="NFT Name"
                onChange={(e) => setTitle(e.target.value)}
                value={title}
                required
              />
              <label
                htmlFor="descriptionCreateNft"
                className="block mb-2 text-2xl font-bold text-gray-900 dark:text-white"
              >
                Description
              </label>
              <textarea
                id="descriptionCreateNft"
                rows="4"
                className="block p-2.5 w-2/3 text-sm text-white bg-transparent rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Write your thoughts here..."
                onChange={(e) => setDescription(e.target.value)}
                value={description}
                required
              ></textarea>
            </div>
          </div>
          <div className="w-full flex justify-center pt-6">
            <button
              onClick={() => {
                setStep(0);
                toTopPage();
              }}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#21e786] hover:bg-[#f10cea] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
            >
              Previous
            </button>
            <button
              // onClick={() => setStep(2)}
              onClick={() => {
                if (title.trim() !== "" && description.trim() !== "") {
                  setStep(2);
                  toTopPage();
                } else {
                  handleShowMessageModal(
                    "Please fill in both Title and Description.",
                    false,
                    false
                  );
                }
              }}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#21e786] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
            >
              Next Step
            </button>
          </div>
        </div>
      )}
      {step === 2 && (
        <div className="min-h-screen h-full">
          <h2 className="text-white text-3xl pt-20 pb-8 mt-6 text-center font-Bakbak">
            Upload NFT
          </h2>
          <p className="text-white text-center px-2">
            If your NFT is AUDIO or VIDEO file, on this page upload image and go
            to the next step to upload actual audio or video file.
          </p>
          <div className="flex flex-col lg:flex-row w-full justify-center items-center">
            {/* LOGO OF COLLECTION */}
            <div className="flex flex-col">
              <h2 className="text-white text-2xl py-3 mt-6 text-center">
                Upload Image
              </h2>

              <CreateNftSingle />
              {imageNameSizeType && imageNameSizeType.name !== "" ? (
                <div className="mx-auto w-56 text-gray-500 text-xs left mt-1">
                  <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                    Name: {imageNameSizeType.name}
                  </div>
                  <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                    Size: {imageNameSizeType.size}
                  </div>
                  <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                    Type: {imageNameSizeType.type}
                  </div>
                </div>
              ) : (
                <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                  NFT image ( max 10 MB )
                </div>
              )}
            </div>
          </div>

          <div className="w-full flex justify-center pt-6">
            <button
              onClick={() => {
                setStep(1);
                toTopPage();
              }}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#21e786] hover:bg-[#f10cea] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
            >
              Previous
            </button>
            <button
              // onClick={() => setStep(3)}
              onClick={() => {
                const isImageFile =
                  selectedFile && selectedFile.type.startsWith("image/");
                if (selectedFile && isImageFile) {
                  setStep(3);
                  toTopPage();
                } else {
                  handleShowMessageModal(
                    "Please upload an image.",
                    false,
                    false
                  );
                }
              }}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#21e786] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
            >
              Next Step
            </button>
          </div>
        </div>
      )}
      {step === 3 && (
        <div className="h-full">
          <div className="flex flex-col justify-center items-center pt-32 pb-12 w-full">
            <div className="w-full flex flex-col items-center">
              <h1 className="text-white text-center text-3xl px-5">
                If your NFT is not audio or video file, leave all empty.
              </h1>
              <div className="flex flex-col">
                <h2 className="text-[#21e786] text-2xl py-3 mt-6 text-center">
                  Upload Audio/Video file
                </h2>
                <p className="text-white text-center mb-16">
                  Choose between those 4 options:
                </p>
              </div>
              <div className="flex flex-col min-[1204px]:flex-row items-center justify-center">
                {/* --------------------- AUDIO/VIDEO ---------------- */}
                <div className="tab w-[300px] min-[480px]:w-full md:w-[570px] mx-4">
                  <div className="tf-work style-2 wow fadeInUp h-[300px] myshadow">
                    <h2 className="text-white text-2xl md:text-3xl py-3 text-center">
                      We host your files:
                    </h2>

                    <div className="w-full flex flex-col min-[480px]:flex-row justify-center pt-2">
                      <div className="flex flex-col mb-4 min-[480px]:mb-0">
                        <label className="relative bg-[#21e786] hover:bg-[#f10cea] text-white md:text-[24px] py-2 lg:py-3 px-[15px] cursor-pointer font-Bakbak mx-3">
                          Upload Audio
                          <input
                            type="file"
                            className="hidden"
                            onChange={handleAudioUpload}
                            accept="audio/*"
                          />
                        </label>
                        {audioNameSizeType && audioNameSizeType.name !== "" ? (
                          <div className="mx-auto w-56 text-gray-500 text-xs left mt-1">
                            <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                              Name: {audioNameSizeType.name}
                            </div>
                            <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                              Size: {audioNameSizeType.size}
                            </div>
                            <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                              Type: {audioNameSizeType.type}
                            </div>
                          </div>
                        ) : (
                          <div className="mx-auto w-56 text-gray-500 text-xs mt-1">
                            NFT audio ( max 200 MB )
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col">
                        <label
                          className="textBlack z-20 bg-[#fff] hover:bg-[#21e786] relative cursor-pointer shadow-md shadow-black 
                          md:text-[24px] py-2 lg:py-3 px-[15px] font-Bakbak mx-3"
                        >
                          Upload Video
                          <input
                            type="file"
                            className="hidden"
                            onChange={handleVideoUpload}
                            accept="video/*"
                          />
                        </label>
                        {videoNameSizeType && videoNameSizeType.name !== "" ? (
                          <div className="mx-auto w-56 text-gray-500 text-xs left mt-1">
                            <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                              Name: {videoNameSizeType.name}
                            </div>
                            <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                              Size: {videoNameSizeType.size}
                            </div>
                            <div className="mx-auto w-56 text-gray-500 text-xs text-left mt-1 ml-2">
                              Type: {videoNameSizeType.type}
                            </div>
                          </div>
                        ) : (
                          <div className="mx-auto w-56 text-gray-500 text-xs mt-1">
                            NFT video ( max 200 MB )
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* ----------------- YOU GOST YOUR FILES ----------------- */}
                <div className="tab w-[300px] min-[480px]:w-full md:w-[570px] mx-4">
                  <div className="tf-work style-2 wow fadeInUp h-[300px] myshadow">
                    <h2 className="text-white text-2xl md:text-3xl py-2 text-center">
                      You host your files:
                    </h2>

                    <div className="w-full flex flex-col justify-center pt-2">
                      <div className="flex flex-col">
                        <label
                          htmlFor="nftCreatePrice"
                          className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white"
                        >
                          Audio File Link
                        </label>
                        <input
                          type="text"
                          id="nftAudioLink"
                          className="bg-transparent border w-full mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Enter link url"
                          onChange={(e) => setAudioLinkSelfhost(e.target.value)}
                          onFocus={handleAudioLinkFocus}
                          value={audioLinkSelfhost}
                          required
                        />
                      </div>
                      <div className="flex flex-col">
                        <label
                          htmlFor="nftCreatePrice"
                          className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white"
                        >
                          Video File Link
                        </label>
                        <input
                          type="text"
                          id="nftAudioLink"
                          className="bg-transparent border w-full mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Enter link url"
                          onChange={(e) => setVideoLinkSelfhost(e.target.value)}
                          onFocus={handleVideoLinkFocus}
                          value={videoLinkSelfhost}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <h2 className="text-[#21e786] text-3xl py-3 mt-6 text-center">
                  Test Audio/Video file
                </h2>
                <p className="text-white text-center mb-6">
                  Enter link url and click your favourite platform:
                </p>
                <div className="tabs-container flex flex-col xl:flex-row justify-around pb-6 cursor-pointer items-center">
                  {/* Audio Tabs */}
                  <div
                    className="tab mx-4"
                    onClick={() => handleAudioTabClick("soundcloud")}
                  >
                    <div
                      className={`tf-work style-2 wow fadeInUp myshadow ${
                        selectedPlatform === "soundcloud"
                          ? "selectedCollectionBg"
                          : ""
                      }`}
                    >
                      <h2 className="text-white text-2xl py-3 text-center">
                        SOUNDCLOUD
                      </h2>
                    </div>
                  </div>
                  <div
                    className="tab mx-4"
                    onClick={() => handleAudioTabClick("audiocom")}
                  >
                    <div
                      className={`tf-work style-2 wow fadeInUp myshadow ${
                        selectedPlatform === "audiocom"
                          ? "selectedCollectionBg"
                          : ""
                      }`}
                    >
                      <h2 className="text-white text-2xl py-3 text-center">
                        AUDIO.COM
                      </h2>
                    </div>
                  </div>
                  <div
                    className="tab mx-4"
                    onClick={() => handleAudioTabClick("amazon")}
                  >
                    <div
                      className={`tf-work style-2 wow fadeInUp myshadow ${
                        selectedPlatform === "amazon"
                          ? "selectedCollectionBg"
                          : ""
                      }`}
                    >
                      <h2 className="text-white text-2xl py-3 text-center">
                        AWS
                      </h2>
                    </div>
                  </div>
                </div>
                {selectedPlatform === "soundcloud" &&
                  audioLinkSelfhost.startsWith("https://w.soundcloud.com") && (
                    <div className="iframe-container mb-[50px]">
                      <iframe
                        width="100%"
                        src={audioLinkSelfhost}
                        title="Nft audio"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        autoPlay={false}
                        sandbox="allow-same-origin allow-scripts"
                      ></iframe>
                    </div>
                  )}
                {selectedPlatform === "audiocom" &&
                  audioLinkSelfhost.startsWith("https://audio.com/") && (
                    <div className="iframe-container mb-[50px]">
                      <iframe
                        width="100%"
                        src={audioLinkSelfhost}
                        title="Nft audio"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        autoPlay={false}
                        sandbox="allow-same-origin allow-scripts"
                      ></iframe>
                    </div>
                  )}
                {selectedPlatform === "amazon" &&
                  audioLinkSelfhost.includes("amazonaws.com") && (
                    <div className="iframe-container mb-[50px]">
                      <ReactAudioPlayer
                        src={audioLinkSelfhost}
                        controls
                        width={100}
                        height={400}
                        style={{ width: "100%", background: "#21e786" }}
                        color="#00000"
                        volumeOrientationDown
                      ></ReactAudioPlayer>
                    </div>
                  )}
              </div>
              {/* FAQ Section */}
              <div className="faq-section lg:w-[500px] w-full bg-transparent border mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <FaqItem
                  question="How to create a SoundCloud link?"
                  answer="Copy this base link - https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1512758497 and instead of 1512758497 add your ID at the end. This ID you will find when you click SHARE and then EMBED. Inside Code => iframe there is a src link, at the end there is /trucks/[YourID]."
                />
                <FaqItem
                  question="Generating Audio.com link, how to?"
                  answer="Copy this base link - https://audio.com/embed/audio/1765622305651451 and instead of 1765622305651451 add your ID at the end. This ID you will find when you click SHARE and then EMBED. Inside Code => iframe there is a src link, at the end there is /trucks/[YourID]."
                />
                <FaqItem
                  question="How to host link on Amazon - AWS?"
                  answer="Copy this base link - https://homedine-asset.s3.ap-south-1.amazonaws.com/1619081865764.mpeg and instead add your link and ID and try is it work. If not, try to host somewhere else."
                />
              </div>
              <div className="mt-8">
                <div className="flex flex-col xl:flex-row justify-around pb-12 cursor-pointer items-center">
                  {/* Video Tabs */}
                  <div
                    className="tab mx-4"
                    onClick={() => handleVideoTabClick("youtube")}
                  >
                    <div
                      className={`tf-work style-2 wow fadeInUp myshadow ${
                        selectedPlatform === "youtube"
                          ? "selectedCollectionBg"
                          : ""
                      }`}
                    >
                      <h2 className="text-white text-2xl py-3 text-center">
                        YOUTUBE
                      </h2>
                    </div>
                  </div>
                  <div
                    className="tab mx-4"
                    onClick={() => handleVideoTabClick("rumble")}
                  >
                    <div
                      className={`tf-work style-2 wow fadeInUp myshadow text-[12px] text-lg ${
                        selectedPlatform === "rumble"
                          ? "selectedCollectionBg"
                          : ""
                      }`}
                    >
                      <h2 className="text-white text-2xl py-3 text-center">
                        RUMBLE
                      </h2>
                    </div>
                  </div>
                  <div
                    className="tab mx-4"
                    onClick={() => handleVideoTabClick("vimeo")}
                  >
                    <div
                      className={`tf-work style-2 wow fadeInUp myshadow ${
                        selectedPlatform === "vimeo"
                          ? "selectedCollectionBg"
                          : ""
                      }`}
                    >
                      <h2 className="text-white text-2xl py-3 text-center">
                        VIMEO
                      </h2>
                    </div>
                  </div>
                </div>
                {selectedPlatform === "youtube" &&
                  videoLinkSelfhost.startsWith(
                    "https://www.youtube.com/embed/"
                  ) && (
                    <div className="iframe-container mb-[50px]">
                      <ReactPlayer
                        url={videoLinkSelfhost}
                        playing={false}
                        controls={true}
                      />
                    </div>
                  )}

                {selectedPlatform === "rumble" &&
                  videoLinkSelfhost.startsWith("https://rumble.com/embed/") && (
                    <div className="iframe-container mb-[50px]">
                      <iframe
                        width="560"
                        height="560"
                        src={videoLinkSelfhost}
                        title="Nft video"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        autoPlay={false}
                        sandbox="allow-scripts"
                      ></iframe>
                    </div>
                  )}
                {selectedPlatform === "vimeo" &&
                  videoLinkSelfhost.startsWith(
                    "https://player.vimeo.com/video/"
                  ) && (
                    <div className="iframe-container mb-[50px]">
                      <ReactPlayer
                        url={videoLinkSelfhost}
                        playing={false}
                        controls={true}
                      />
                    </div>
                  )}
              </div>
              {/* FAQ Section */}
              <div className="faq-section lg:w-[500px] w-full bg-transparent border w-full mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                <FaqItem
                  question="How to generate a YouTube Link?"
                  answer="Copy this base link - https://www.youtube.com/embed/h0PJl8yyYS4 and instead of h0PJl8yyYS4 add your ID at the end. This ID you will find when you click SHARE and then EMBED. Inside Code => iframe there is a src link, at the end there is /embed/[YourID]."
                />
                <FaqItem
                  question="Creating a Rumble Link Explained"
                  answer="Copy this base link - https://rumble.com/embed/vi3825/ and instead of vi3825 add your ID at the end. This ID you will find when you click Embed. Inside Embed IFRAME URL there is a src link https://rumble.com/embed/vi3825/?pub=4, and you use only /embed/[YourID]."
                />
                <FaqItem
                  question="How to create a Vimeo link?"
                  answer="Copy this base link - https://player.vimeo.com/video/110874487 and instead of 110874487 add your ID at the end. This ID you will find when you click Share and then EMBED. Inside Code => iframe there is a src link, at the end there is /video/[YourID]."
                />
              </div>

              <div className="w-full flex justify-center pt-6">
                {audioLinkSelfhost || videoLinkSelfhost ? (
                  <div className="flex items-center">
                    <label className="flex items-center cursor-pointer space-x-2">
                      <input
                        type="checkbox"
                        className="text-amber-500 form-tick appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-[#f10cea] checked:border-amber-500"
                        id="linkConfirmation"
                        checked={confirmedLink}
                        onChange={(e) => setConfirmedLink(e.target.checked)}
                      />
                      <span className="text-white">
                        I confirm that I tested the link and that it is working
                        successfully.
                      </span>
                    </label>
                  </div>
                ) : null}
              </div>
              <div className="w-full flex justify-center pt-6">
                <button
                  onClick={() => {
                    setStep(2);
                    toTopPage();
                  }}
                  className="shadow-xl shadow-black text-[#000]
                  bg-[#21e786] hover:bg-[#f10cea] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    const isAudioFile =
                      audioFile && audioFile.type.startsWith("audio/");
                    const isGoodAudioPlatform =
                      audioLinkSelfhost.startsWith(
                        "https://w.soundcloud.com"
                      ) ||
                      audioLinkSelfhost.startsWith("https://audio.com/") ||
                      audioLinkSelfhost.includes("amazonaws.com");

                    const isVideoFile =
                      videoFile && videoFile.type.startsWith("video/");
                    const isGoodVideoPlatform =
                      videoLinkSelfhost.startsWith(
                        "https://www.youtube.com/embed/"
                      ) ||
                      videoLinkSelfhost.startsWith(
                        "https://rumble.com/embed/"
                      ) ||
                      videoLinkSelfhost.startsWith(
                        "https://player.vimeo.com/video/"
                      );
                    const isAudioLink =
                      audioLinkSelfhost &&
                      audioLinkSelfhost.startsWith("https://");
                    const isVideoLink =
                      videoLinkSelfhost &&
                      videoLinkSelfhost.startsWith("https://");
                    const optionsCount = [
                      isAudioFile,
                      isVideoFile,
                      isAudioLink,
                      isVideoLink,
                    ].filter(Boolean).length;

                    if (optionsCount > 1) {
                      handleShowMessageModal(
                        "You can upload only 1 file or link.",
                        false,
                        false
                      );
                      return;
                    }
                    if (audioLinkSelfhost && !isGoodAudioPlatform) {
                      handleShowMessageModal(
                        "Audio link is not correct.",
                        false,
                        false
                      );
                      return;
                    }
                    if (videoLinkSelfhost && !isGoodVideoPlatform) {
                      handleShowMessageModal(
                        "Video link is not correct.",
                        false,
                        false
                      );
                      return;
                    }

                    if (audioFile && !audioFile.type.startsWith("audio/")) {
                      handleShowMessageModal(
                        "Please upload a valid audio format.",
                        false,
                        false
                      );
                      return;
                    } else if (
                      videoFile &&
                      !videoFile.type.startsWith("video/")
                    ) {
                      handleShowMessageModal(
                        "Please upload a valid video format.",
                        false,
                        false
                      );
                      return;
                    } else if (
                      audioLinkSelfhost &&
                      !audioLinkSelfhost.startsWith("https")
                    ) {
                      handleShowMessageModal(
                        "Upload link must start with 'https'.",
                        false,
                        false
                      );
                      return;
                    } else if (
                      videoLinkSelfhost &&
                      !videoLinkSelfhost.startsWith("https")
                    ) {
                      handleShowMessageModal(
                        "Upload link must start with 'https'.",
                        false,
                        false
                      );
                      return;
                    } else if (isAudioLink || isVideoLink) {
                      if (!confirmedLink) {
                        handleShowMessageModal(
                          "Please confirm that the link is working.",
                          false,
                          false
                        );
                        return;
                      } else {
                        // console.log(confirmedLink);
                        setStep(4);
                        toTopPage();
                      }
                    } else if (isAudioFile || isVideoFile) {
                      setStep(4);
                      toTopPage();
                    } else if (
                      !isAudioFile &&
                      !isVideoFile &&
                      !isAudioLink &&
                      !isVideoLink
                    ) {
                      setStep(4);
                      toTopPage();
                    }
                  }}
                  className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#21e786] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
                >
                  Next Step
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {step === 4 && (
        <div className="min-h-screen h-full pb-12">
          <div className="flex flex-col justify-center items-center pt-32 pb-12 w-full">
            <div className="w-[80%] md:w-1/2 flex flex-col items-center">
              <div className="w-full flex flex-col items-center textLeftImp">
                <label
                  htmlFor="nftCreatePrice"
                  className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white"
                >
                  Price
                </label>
                <div className="text-gray-500 text-xs mb-4">
                  Must be a number up to 4 decimals.
                </div>
                <input
                  type="text"
                  id="nftCreatePrice"
                  className="bg-transparent border w-full md:w-2/3 mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="14.2356"
                  onChange={(e) => setPrice(e.target.value)}
                  value={price}
                  required
                />
              </div>
              <div className="w-full flex flex-col items-center textLeftImp">
                <label
                  htmlFor="royaltyFeeCreateNft"
                  className="block mb-2 font-bold text-2xl text-gray-900 dark:text-white"
                >
                  Royalty fee %
                </label>
                <div className="text-gray-500 text-xs mb-4">
                  Whole number from 0-100, if you don't charge fee, leave 0.
                </div>
                <input
                  type="text"
                  id="royaltyFeeCreateNft"
                  className="bg-transparent border w-full md:w-2/3  mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="3"
                  onChange={(e) => setRoyaltyFee(e.target.value)}
                  value={royaltyFee}
                />
              </div>
              <div className="w-full flex flex-col items-center textLeftImp">
                <label
                  htmlFor="royaltyReceiverAddressCreateNFT"
                  className="block mb-2 font-bold text-xl md:text-2xl text-gray-900 dark:text-white"
                >
                  Royalty Receiver Address
                </label>
                <div className="text-gray-500 text-xs mb-4">
                  Must be valid DUBX address or 0x000..000
                </div>
                <input
                  type="text"
                  id="royaltyReceiverAddressCreateNFT"
                  className="bg-transparent border w-full md:w-2/3  mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="0x"
                  onChange={(e) => setRoyaltyReceiver(e.target.value)}
                  value={royaltyReceiver}
                />
              </div>
              <label className="block mb-[-10px] font-bold text-xl md:text-2xl text-gray-900 dark:text-white">
                Select Category
              </label>
              <div className="flex flex-row justify-between items-center bg-gray-800 rounded-lg my-5 mb-12 w-full md:w-2/3 p-[3px] border border-gray-300">
                <select
                  className="block w-full text-sm text-slate-500 bg-gray-800 border-0 focus:outline-none focus:ring-0 p-[7px]"
                  name="category"
                  onChange={(e) => setCategory(e.target.value)}
                  value={category}
                  required
                >
                  <option value="all">Select Category</option>
                  <option value="Illustration">Illustration</option>
                  <option value="Art">Art</option>
                  <option value="Sport">Sport</option>
                  <option value="Music">Music</option>
                  <option value="Animation">Animation</option>
                  <option value="Gaming">Gaming</option>
                  <option value="3D">3D</option>
                  <option value="real-estate">Real Estate</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="flex">
                <button
                  onClick={() => {
                    setStep(3);
                    toTopPage();
                  }}
                  className="shadow-xl shadow-black text-[#000]
                  bg-[#21e786] hover:bg-[#f10cea] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
                >
                  Previous
                </button>
                <button
                  onClick={() => {
                    const decimalRegex = /^[0-9]+(\.[0-9]{1,4})?$/;
                    const isValidAddress =
                      ethers.utils.isAddress(royaltyReceiver);

                    const isValidReceiver =
                      royaltyReceiver ==
                        "0x0000000000000000000000000000000000000000" ||
                      isValidAddress;

                    if (!price) {
                      handleShowMessageModal(
                        "Please enter a price",
                        false,
                        false
                      );
                      return;
                    }
                    if (isNaN(price) || parseFloat(price) <= 0) {
                      handleShowMessageModal(
                        "Price must be a number and grater than 0.",
                        false,
                        false
                      );
                      return;
                    }
                    if (!decimalRegex.test(price)) {
                      handleShowMessageModal(
                        "Price can have up to 4 decimals.",
                        false,
                        false
                      );
                      return;
                    }
                    if (isNaN(royaltyFee) || parseFloat(royaltyFee) < 0) {
                      handleShowMessageModal(
                        "Royalty fee must be a number.",
                        false,
                        false
                      );
                      return;
                    }
                    if (!Number.isInteger(parseFloat(royaltyFee))) {
                      handleShowMessageModal(
                        "Royalty fee must be a whole number.",
                        false,
                        false
                      );
                      return;
                    }
                    if (royaltyFee < 0 || royaltyFee > 100) {
                      handleShowMessageModal(
                        "Royalty fee must be > 0 and < 100.",
                        false,
                        false
                      );
                      return;
                    }
                    if (!isValidReceiver) {
                      // console.log(isValidReceiver);
                      handleShowMessageModal(
                        "Reciver must be '0x000...' or a valid DUBX address.",
                        false,
                        false
                      );
                      return;
                    }

                    if (
                      royaltyFee > 0 &&
                      royaltyReceiver ===
                        "0x0000000000000000000000000000000000000000"
                    ) {
                      handleShowMessageModal(
                        "If royalty fee is > 0, royalty receiver can not be ziro address ('0x000...').",
                        false,
                        false
                      );
                      return;
                    }
                    if (
                      royaltyFee == 0 &&
                      royaltyReceiver !=
                        "0x0000000000000000000000000000000000000000"
                    ) {
                      handleShowMessageModal(
                        "If royalty fee is 0%, royalty receiver must be ziro address ('0x000...').",
                        false,
                        false
                      );
                      return;
                    }

                    if (category === "all") {
                      handleShowMessageModal(
                        "Please select a category ",
                        false,
                        false
                      );
                      return;
                    } else if (
                      !isNaN(price) &&
                      parseFloat(price) > 0 &&
                      decimalRegex.test(price) &&
                      !isNaN(royaltyFee) &&
                      royaltyFee >= 0 &&
                      royaltyFee <= 100 &&
                      Number.isInteger(parseFloat(royaltyFee)) &&
                      isValidReceiver
                    ) {
                      setStep(5);
                      toTopPage();
                    } else {
                      handleShowMessageModal(
                        "Please enter a valid price (greater than 0, up to 4 decimals) and a whole number royalty fee between 0 and 100, royalty receiver address must be '0x0000000000000000000000000000000000000000' or a valid dubx address, category must be selected...",
                        false,
                        false
                      );
                    }
                  }}
                  className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#21e786] hover:text-white text-[20px] text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
                >
                  Next Step
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {step === 5 && (
        <div className="min-h-screen h-full pb-12">
          <div className="flex flex-col justify-center items-center pt-32 pb-12 w-full">
            <div className="w-full lg:w-1/3 flex flex-col items-center px-12 md:px-32 lg:px-0">
              <label
                htmlFor="unlockableContentLinkCreateNFT"
                className="block mb-2 text-2xl font-bold text-gray-900 dark:text-white"
              >
                Unlockable Content Link
              </label>
              <input
                type="text"
                id="unlockableContentLinkCreateNFT"
                className="bg-transparent border w-full mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="https://"
                onChange={(e) => setUnlockableContent(e.target.value)}
                value={unlockableContent}
              />
              <label
                htmlFor="nftnExternalLink"
                className="block mb-2 mt-6 font-bold text-2xl text-gray-900 dark:text-white"
              >
                External link
              </label>
              <input
                type="text"
                id="nftnExternalLink"
                className="bg-transparent border w-full mb-6 border-gray-300 text-white text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="https://"
                onChange={(e) => setExternalLink(e.target.value)}
                value={externalLink}
              />
              {traits.length > 0 && (
                <div className="mt-3">
                  <p className="text-gray-500 text-sm">Traits:</p>
                  <div className="flex flex-wrap">
                    {traits.map((trait, index) => (
                      <div
                        key={index}
                        className="bg-[#21e786] text-[#000] px-3 py-1 rounded-full mr-2 mt-2 flex items-center"
                      >
                        <span className="mr-1">{trait.trait_type}: </span>
                        <span> {trait.value} </span>
                        {trait.trait_count > 0 && (
                          <span>: {trait.trait_count}</span>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="flex flex-col lg:flex-row justify-start items-center rounded-xl mt-5 w-full">
                <div className=" p-[3px] border border-gray-300 rounded-lg mx-3">
                  <input
                    className="block w-full rounded-md text-sm
                  text-slate-500 bg-[#1F2937] border-0
                  focus:outline-none focus:ring-0 p-[7px] px-3"
                    type="text"
                    name="newTraitName"
                    placeholder="Trait Name"
                    onChange={(e) => setNewTraitName(e.target.value)}
                    value={newTraitName}
                  />
                </div>
                <div className=" p-[3px] border border-gray-300 rounded-lg mx-3">
                  <input
                    className="block w-full rounded-md text-sm
                    text-slate-500 bg-[#1F2937] border-0
                    focus:outline-none focus:ring-0 p-[7px] px-3"
                    type="text"
                    name="newTraitValue"
                    placeholder="Trait Value"
                    onChange={(e) => setNewTraitValue(e.target.value)}
                    value={newTraitValue}
                  />
                </div>
                <div className=" p-[3px] border border-gray-300 rounded-lg mx-3">
                  <input
                    className="block w-full rounded-md text-sm
                    text-slate-500 bg-[#1F2937] border-0
                    focus:outline-none focus:ring-0 p-[7px] px-3"
                    type="number"
                    name="newTraitTrait_count"
                    placeholder="Trait Count (optional)"
                    onChange={(e) => setNewTraitTrait_count(e.target.value)}
                    value={newTraitTrait_count}
                  />
                </div>
              </div>
              <button
                type="button"
                onClick={addTrait}
                className="bg-[#21e786] my-6 text-[#000] px-24 lg:px-3 py-1 rounded-md"
              >
                Add Trait
              </button>
            </div>
            <div className="flex flex-row justify-between items-center bg-gray-800 rounded-xl mt-5 w-full md:w-1/3">
              <Select
                className="block w-full text-sm text-slate-500 bg-gray-800 border-0 focus:outline-none focus:ring-0 p-[7px]"
                isMulti
                name="tags"
                options={allTags.map((tag) => ({ value: tag, label: tag }))}
                value={selectedTags}
                onChange={handleTagChange}
                placeholder="Select Tags"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: "#2d3748",
                    border: "none",
                    boxShadow: "none",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "#2d3748", // Set the options container background color
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isSelected ? "#4a5568" : "#2d3748", // Set background color for selected and non-selected options
                    color: state.isSelected ? "#ffffff" : "#cbd5e0", // Set text color for selected and non-selected options
                    ":hover": {
                      backgroundColor: "#4a5568", // Set background color on hover
                    },
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: "#4a5568", // Set background color for selected tags
                  }),
                  multiValueRemove: (css) => ({
                    ...css,
                    ":hover": { background: "#21E786" },
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: "#ffffff", // Set text color for selected tags
                  }),
                }}
              />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <button
              onClick={() => {
                setStep(4);
                toTopPage();
              }}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#21e786] hover:bg-[#f10cea] hover:text-white text-[20px] md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
            >
              Previous
            </button>
            <button
              type="submit"
              onClick={handleSubmit}
              className="shadow-xl shadow-black text-[#000]
                  bg-[#fff] hover:bg-[#21e786] hover:text-white md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak mx-3"
            >
              Mint Now
            </button>
          </div>
        </div>
      )}
      <div>
        <MessageModal
          message={infoMessage}
          show={showMessageModal}
          showUl={showUlMessageModal}
          showConf={showConfMessageModal}
          onClose={handleCloseMessageModal}
        />
      </div>
      {showConfettiContainer && <ConfettiContainer />}
    </div>
  );
};

export default CreateNftPage;
