import React from "react";
// import "./ConfettiContainer.css";

const ConfettiContainer = () => {
  return (
    <div className="confetti-container">
      <div className="confetti">
        <i style={{ "--speed": 10, "--bg": "yellow" }} className="square"></i>
        <i style={{ "--speed": 18, "--bg": "white" }} className="pentagram"></i>
        <i style={{ "--speed": 29, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 17, "--bg": "blue" }} className="hexagram"></i>
        <i style={{ "--speed": 33, "--bg": "pink" }} className="pentagram"></i>
        <i
          style={{ "--speed": 26, "--bg": "yellow" }}
          className="dodecagram"
        ></i>
        <i style={{ "--speed": 24, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 5, "--bg": "blue" }} className="wavy-line"></i>
        <i style={{ "--speed": 40, "--bg": "white" }} className="square"></i>
        <i style={{ "--speed": 17, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 25, "--bg": "white" }} className="square"></i>
        <i style={{ "--speed": 18, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 15, "--bg": "cyan" }} className="wavy-line"></i>
        <i
          style={{ "--speed": 32, "--bg": "yellow" }}
          className="pentagram"
        ></i>
        <i style={{ "--speed": 45, "--bg": "white" }} className="square"></i>
        <i style={{ "--speed": 18, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 37, "--bg": "red" }} className="dodecagram"></i>
        <i style={{ "--speed": 23, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 37, "--bg": "red" }} className="dodecagram"></i>
        <i style={{ "--speed": 37, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 26, "--bg": "white" }} className="hexagram"></i>
        <i style={{ "--speed": 32, "--bg": "cyan" }} className="wavy-line"></i>
        <i
          style={{ "--speed": 32, "--bg": "yellow" }}
          className="pentagram"
        ></i>
        <i style={{ "--speed": 45, "--bg": "white" }} className="square"></i>
        <i style={{ "--speed": 18, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 37, "--bg": "red" }} className="dodecagram"></i>
        <i style={{ "--speed": 23, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 37, "--bg": "red" }} className="dodecagram"></i>
        <i style={{ "--speed": 37, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 26, "--bg": "white" }} className="hexagram"></i>
        <i style={{ "--speed": 32, "--bg": "cyan" }} className="wavy-line"></i>
        <i
          style={{ "--speed": 32, "--bg": "yellow" }}
          className="pentagram"
        ></i>
        <i style={{ "--speed": 45, "--bg": "white" }} className="square"></i>
        <i style={{ "--speed": 18, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 37, "--bg": "red" }} className="dodecagram"></i>
        <i style={{ "--speed": 23, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 50, "--bg": "pink" }} className="rectangle"></i>
        <i style={{ "--speed": 30, "--bg": "red" }} className="square"></i>
        <i style={{ "--speed": 18, "--bg": "red" }} className="pentagram"></i>
        <i style={{ "--speed": 19, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 16, "--bg": "blue" }} className="hexagram"></i>
        <i style={{ "--speed": 23, "--bg": "red" }} className="pentagram"></i>
        <i
          style={{ "--speed": 33, "--bg": "yellow" }}
          className="dodecagram"
        ></i>
        <i style={{ "--speed": 39, "--bg": "white" }} className="wavy-line"></i>
        <i style={{ "--speed": 40, "--bg": "orange" }} className="square"></i>
        <i style={{ "--speed": 21, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 14, "--bg": "white" }} className="square"></i>
        <i style={{ "--speed": 38, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 19, "--bg": "red" }} className="dodecagram"></i>
        <i style={{ "--speed": 29, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 34, "--bg": "white" }} className="hexagram"></i>
        <i
          style={{ "--speed": 17, "--bg": "indigo" }}
          className="wavy-line"
        ></i>
        <i
          style={{ "--speed": 32, "--bg": "yellow" }}
          className="pentagram"
        ></i>
        <i style={{ "--speed": 23, "--bg": "white" }} className="square"></i>
        <i style={{ "--speed": 18, "--bg": "green" }} className="rectangle"></i>
        <i style={{ "--speed": 37, "--bg": "red" }} className="dodecagram"></i>
        <i style={{ "--speed": 48, "--bg": "pink" }} className="wavy-line"></i>
        <i style={{ "--speed": 38, "--bg": "pink" }} className="rectangle"></i>
        <i style={{ "--speed": 13, "--bg": "red" }} className="pentagram"></i>
        <i
          style={{ "--speed": 49, "--bg": "yellow" }}
          className="dodecagram"
        ></i>
        <i
          style={{ "--speed": 19, "--bg": "purple" }}
          className="wavy-line"
        ></i>
        <i style={{ "--speed": 15, "--bg": "cyan" }} className="square"></i>
      </div>
    </div>
  );
};

export default ConfettiContainer;
