import React from "react";
import NewAccordion from "../components/NewAccordion";
import { BiLinkExternal } from "react-icons/bi";

import { Link } from "react-router-dom";

const FAQ = () => {
  return (
    <div className="flex flex-col items-center justify-center stars-container w-full bg-black pb-64 backgroundFAQ">
      <div className="flex flex-col items-center justify-center md:p-20 py-12 px-4 w-full lg:w-[90%] m-auto">
        {/* ---------------- Title ---------------- */}
        <h1
          className="text-3xl sm:text-5xl py-2 pb-12 font-bold text-[#21e786] border-[#21e786] cursor-default"
          style={{ fontWeight: "700!important" }}
        >
          FAQ
        </h1>

        {/* ---------------- QUESTIONS AND ANSWERS ---------------- */}
        <div className="flex flex-col xl:flex-row text-white border-[#21e786] border-b border-t">
          {/* ---------------- LEFT SIDE ---------------- */}
          <div className="w-full 2xl:w-1/2 accordion-container rounded-md md:mr-2">
            <NewAccordion
              button="What is NFT?"
              contents="NFTs (Non-Fungible Tokens) are one-of-a-kind tokens that represent a unique good or asset, like digital art. They can be sold in to collectors and the sale of NFTs. NFTs created on Mint are hosted on the Flow blockchain."
            />
            <NewAccordion
              button="How do I purchase NFT?"
              contents="When you've linked MetaMask, connected your wallet, and found an NFT you like, clicking the BUY button will initiate the purchase. Please wait while it is being minted. Once completed, a message will confirm your successful NFT acquisition.."
            />
            <NewAccordion
              button="How do I sell NFT?"
              contents="While creating an NFT, you'll have the option to set the price in DUBX. Once you complete all the steps to upload your NFT, it will be made public. Others can then buy it immediately at the listed price or place an OFFER. You can choose to accept or reject any offers you receive."
            />
          </div>

          {/* ---------------- RIGHT SIDE ---------------- */}
          <div className="w-full 2xl:w-1/2 accordion-container rounded-md md:ml-2">
            <NewAccordion
              button="Is there a NFT creation fee?"
              contents="Similar to most marketplaces, there is a fee for minting an NFT. You can always view the current fee when interacting with MetaMask, and the fee will also be publicly displayed, allowing every user to see the current cost before proceeding with the creation of their NFT. "
            />
            <NewAccordion
              button="When do I get paid for sales?"
              contents="The moment someone purchases your NFT, funds will be transferred to you immediately, so the answer is instant."
            />
            <NewAccordion
              button="NFT trading history location?"
              contents="On every single NFT page, beneath the image, you'll find the 'TRANSACTION HISTORY' section, visible to all users, providing an enhanced and transparent overview."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
