import React, { useState, useEffect } from "react";
import { FaBars, FaTimes } from "react-icons/fa";

const Doc = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState("Step1");
  const [selectedTitle, setSelectedTitle] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    const mobileSidebar = () => {
      if (window.innerWidth < 768 && isSidebarOpen) {
        console.log(window.innerWidth);
        setIsSidebarOpen(false);
      }
    };
    mobileSidebar();
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  useEffect(() => {
    const handleScroll = () => {
      const titles = document.querySelectorAll(
        ".text-2xl.font-bold.text-black"
      );
      let selected = "";

      titles.forEach((title) => {
        const rect = title.getBoundingClientRect();
        if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
          selected = title.textContent;
        }
      });

      setSelectedTitle(selected);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const Step1Content = () => (
    <div className="w-[270px] min-[400px]:w-[350px] min-[500px]:w-[450px] min-[600px]:w-[450px] md:w-full">
      <h2 className="text-2xl font-bold text-black">
        Welcome to the DUBXCOIN NFT Marketplace
      </h2>
      <p className="pt-16 pb-6">
        In a digital age where creativity knows no bounds, the DUBXCOIN NFT
        Marketplace emerges as a beacon of innovation and opportunity. With a
        seamless integration of blockchain technology and user-centric design,
        our platform redefines the landscape of digital ownership, offering a
        gateway to a world where imagination takes center stage.
      </p>
      <strong>Empowering Creativity, Unleashing Potential</strong>
      <p className="mt-1 pb-6">
        Step into a realm where creators and collectors converge to explore,
        create, and trade NFTs like never before. Whether you're an established
        artist seeking to showcase your masterpiece or an enthusiast eager to
        curate your digital collection, our marketplace provides the tools and
        resources to empower your journey every step of the way.
      </p>
      <strong>Join Us on the Journey</strong>
      <p>
        Whether you're a creator, collector, or enthusiast, the DUBXCOIN NFT
        Marketplace invites you to join us on this groundbreaking journey.
        Together, let's redefine the future of digital assets and unlock a world
        of endless possibilities. Welcome to the DUBXCOIN NFT Marketplace -
        where innovation meets imagination, and where the future of digital
        ownership begins.
      </p>
      <h2 className="text-2xl font-bold text-black pt-16">INSTALL METAMASK</h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black">
            Manage your digital assets securely with MetaMask's built-in wallet,
            which provides convenient access to cryptocurrencies and NFTs while
            ensuring that neither MetaMask nor any other party has access to
            your private keys, maintaining full control over your assets.
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            If you haven't installed Metamask yet, you can download it{" "}
            <a
              href="https://metamask.io/download/"
              target="_blank"
              rel="noreferrer"
              className="font-bold"
            >
              HERE
            </a>{" "}
            .
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">Unlock MetaMask. </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Create a new wallet or import an existing one.{" "}
          </p>
        </li>
      </ul>

      <h2 className="text-2xl font-bold text-black pt-24">CONNECT WALLET</h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black">
            To connect to the DUBXCOIN network, click the "CONNECT" button. You
            will be prompted to add a chain. Simply follow the instructions.
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Top up your account with DUBX so that you have assets to trade on
            the NFT Marketplace.{" "}
          </p>
        </li>
      </ul>

      <h2 className="text-2xl font-bold text-black pt-24">
        CREATE / CUSTOMIZE AUTHOR PAGE
      </h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black">
            In the top right corner, you'll find your default avatar. Click on
            the avatar image and select 'Edit Profile'.
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Your author photo serves as your profile image. Click on “Choose
            File” to upload your profile image. Please ensure it's a maximum of
            200KB, square-shaped, with a minimum size of 150 x 150px and a
            maximum of 400x400px.{" "}
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            The cover image is prominently displayed on your Author page. To set
            it, click on your avatar and then select 'Edit Profile' and 'Choose
            Banner'. Please ensure the banner is a maximum of 400KB, with an
            optimal size of 1903x645px.{" "}
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            The next step is to update your information. You can add a name and
            an email which are required, an external link if you have one,
            provide a bio, and include links to your social media profiles.{" "}
          </p>
        </li>
      </ul>

      <h2 className="text-2xl font-bold text-black pt-24">VERIFY ACCOUNT</h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black mb-12">
            On the 'Edit Profile' page, locate the 'Verify Account' button and
            click on it. For verification process be sure to have enough DUBX to
            pay a transaction fee. Verified accounts have more trust from
            buyers. Please note that the Author page must be set up and the
            Author name, email, and bio must be added for the account to be
            verified.
          </p>
        </li>{" "}
      </ul>
      {/* CREATE COLLECTION */}
      <h2 className="text-2xl font-bold text-black pt-16">CREATE COLLECTION</h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black">
            Navigate to the home page and click on the 'Create Collection'
            button.
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Fill in the 'Collection Name' (required), 'Description' (required),
            and 'External Link' (optional).
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Upload the collection's logo (maximum 100KB).
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Upload the collection's cover image (maximum 100KB).
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Click on 'Create and Register Collection' (FREE), only paying the
            transaction fee.
          </p>
        </li>
      </ul>
      <h2 className="text-2xl font-bold text-black pt-24">CREATE NFT</h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black">
            To create an NFT, the user must first decide whether to choose
            INDIVIDUAL or have their NFT belong to a specific collection.
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            In case of choosing a collection, the user needs to select an
            existing collection from the dropdown menu or choose an individual
            NFT.{" "}
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black">
            Next, it is necessary and mandatory to enter the following
            information:{" "}
          </p>
          <ul>
            <li className="text-[20px]">Title</li>
            <li className="text-[20px]">Description</li>
            <li className="text-[20px]">Image</li>
            <li className="text-[20px]">Price</li>
            <li className="text-[20px]">Category</li>
          </ul>
        </li>
        <li>
          <p className="text-[16px] text-black">Optionally, the user may: </p>
          <ul>
            <li className="text-[20px]">Add an audio or video link</li>
            <li className="text-[20px]">
              Specify Royalty fee and Royalty Receiver
            </li>
            <li className="text-[20px]">Add tags</li>
            <li className="text-[20px]">Include an external link</li>
            <li className="text-[20px]">Specify traits</li>
            <li className="text-[20px]">Provide unlockable content</li>
          </ul>
        </li>
      </ul>
      <h2 className="text-2xl font-bold text-black pt-16">UPLOAD IMG</h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          {" "}
          <p className="text-[16px] text-black">
            The maximum IMAGE size for your NFT is{" "}
            <span className="font-bold">10MB</span>.{" "}
          </p>
        </li>
      </ul>
      <h2 className="text-2xl font-bold text-black pt-24">
        UPLOAD AUDIO / VIDEO
      </h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          {" "}
          <p className="text-[16px] text-black">
            You can add audio or video content to your NFT if you want to
            showcase a portion of the content that will be hidden in the
            unlockable content. For example, you can display only a part of a
            composition or a video, and the rest will be visible if someone
            purchases the NFT.
          </p>
        </li>
        <li>
          {" "}
          <p className="text-[16px] text-black">
            To add an audio or video file, the user can choose between 4 options
            and select only ONE.
          </p>
        </li>
        <li>
          {" "}
          <p className="text-[16px] text-black">
            To upload an audio or video file or to add a file in the form of a
            link chosen only from existing options. Audio files can be hosted on
            the following platforms: <strong>SOUNDCLOUD</strong>,{" "}
            <strong>AUDIO.COM</strong>, and <strong>AWS</strong>, while video
            files can be hosted on <strong>YOUTUBE</strong>,{" "}
            <strong>RUMBLE</strong>, and <strong>VIMEO</strong>.
          </p>
        </li>
        <li>
          {" "}
          <p className="text-[16px] text-black">
            For music and video files the max upload is{" "}
            <span className="font-bold">200MB</span>.
          </p>
        </li>
        <li>
          <p className="bold underline">AUDIO</p>
          <p className="text-[16px] text-black">
            <strong>-How to create a SoundCloud link:</strong>
          </p>
          <p className="text-[16px] text-black">
            • Copy this base link -
            https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/1512758497
            and instead of 1512758497 add your ID at the end. This ID you will
            find when you click SHARE and then EMBED. Inside Code = iframe there
            is a src link, at the end there is /trucks/[YourID].
          </p>
          <p className="text-[16px] text-black">
            <strong>-Generating Audio.com link:</strong>
          </p>
          <p className="text-[16px] text-black">
            • Copy this base link -
            https://audio.com/embed/audio/1765622305651451 and instead of
            1765622305651451 add your ID at the end. This ID you will find when
            you click SHARE and then EMBED. Inside Code = iframe there is a src
            link, at the end there is /trucks/[YourID].
          </p>
          <p className="text-[16px] text-black">
            <strong>-How to host link on Amazon - AWS:</strong>
          </p>
          <p className="text-[16px] text-black">
            •Copy this base link -
            https://homedine-asset.s3.ap-south-1.amazonaws.com/1619081865764.mpeg
            and instead add your link and ID and try is it work. If not, try to
            host somewhere else.
          </p>
        </li>
        <li>
          <p className="bold underline pt-3">VIDEO</p>
          <p className="text-[16px] text-black">
            <strong>-How to generate a YouTube link:</strong>
          </p>
          <p className="text-[16px] text-black">
            •Copy this base link - https://www.youtube.com/embed/h0PJl8yyYS4 and
            instead of h0PJl8yyYS4 add your ID at the end. This ID you will find
            when you click SHARE and then EMBED. Inside Code = iframe there is a
            src link, at the end there is /embed/[YourID].
          </p>
          <p className="text-[16px] text-black">
            <strong>-Creating a Rumble link explained:</strong>
          </p>
          <p className="text-[16px] text-black">
            •Copy this base link - https://rumble.com/embed/vi3825/ and instead
            of vi3825 add your ID at the end. This ID you will find when you
            click Embed. Inside Embed IFRAME URL there is a src link
            https://rumble.com/embed/vi3825/?pub=4, and you use only
            /embed/[YourID].
          </p>
          <p className="text-[16px] text-black">
            <strong>-How to create a Vimeo link:</strong>
          </p>
          <p className="text-[16px] text-black mb-16">
            •Copy this base link - https://player.vimeo.com/video/110874487 and
            instead of 110874487 add your ID at the end. This ID you will find
            when you click Share and then EMBED. Inside Code = iframe there is a
            src link, at the end there is /video/[YourID].
          </p>
        </li>
      </ul>
      <h2 className="text-2xl font-bold text-black pt-12">
        UNLOCKABLE CONTENT
      </h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black">
            Unlockable Content is a unique feature offered by our marketplace,
            providing sellers with the opportunity to offer exclusive NFTs
            containing hidden content accessible only to buyers upon purchase.
            This content can range from any unique creation such as artistic ,
            3D objects, articles, recipes, website code, real estate
            descriptions, problem solutions, and more. Essentially, the
            possibilities are endless.{" "}
          </p>
        </li>
        <li>
          <p className="text-[16px] text-black mb-4">
            The unlockable content must be hosted by the seller, and the buyer
            is required to download the unlockable content at the time of
            purchase. Failure to do so relieves the seller of the obligation to
            host the content any longer. Should the buyer wish to further sell
            the content, they must create their own NFT featuring the unlockable
            content.{" "}
          </p>
        </li>
      </ul>

      <h2 className="text-2xl font-bold text-black pt-24">NFT DETAILS</h2>
      <ul className="text-2xl list-disc text-green-500 pl-12 pt-12">
        <li>
          <p className="text-[16px] text-black">
            On the page displaying individual NFT details, the{" "}
            <strong>OWNER</strong> can:
          </p>
          <ul className="list-disc">
            <li className="text-[16px] text-black ml-[15px]">
              Change the price,
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              Delist NFT (remove from sale),
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              Accept or reject OFFER for the NFT if someone has submitted one,
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              Delete the NFT.
            </li>
          </ul>
        </li>
        <li>
          <p className="text-[16px] text-black">
            The <strong>BUYER</strong> can:
          </p>
          <ul className="list-disc">
            <li className="text-[16px] text-black ml-[15px]">
              Purchase the NFT at the current price,
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              Send an offer at a different price. In this scenario, the buyer
              will lock the specified amount of DUBX until the NFT owner either
              accepts or rejects the offer. If the owner rejects the offer, the
              buyer will receive their money back. If the offer expires, the
              buyer can request a refund.
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              Additionally, the buyer can verify metadata and view all details,
              as well as all transactions associated with that NFT.
            </li>
          </ul>
        </li>
        <li>
          <p className="text-[16px] text-black">
            <strong>IMPORTANT NOTICE :</strong>
          </p>
          <ul className="list-disc pb-32">
            <li className="text-[16px] text-black ml-[15px]">
              Only one offer can be made for each NFT.
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              The OWNER can ACCEPT or REJECT an offer while it is active.
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              If the offer expires, the OWNER can only reject it.
            </li>
            <li className="text-[16px] text-black ml-[15px]">
              If the offer expires without being accepted or rejected by the
              owner, the BUYER who sent the offer can request a refund.
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
  return (
    <div className="flex bg-gray-100 lg:w-[80%] w-full">
      {/* Sidebar */}
      <div
        className={`${
          isSidebarOpen ? "block" : "hidden"
        } bg-gray-800 text-white w-64 p-4 pt-32 hidden md:block`}
      >
        <div className="mt-4 fixed">
          <h1 className="font-bold border-b text-center text-lg">
            DUBX NFT MARKETPLACE
          </h1>
          <p
            className="cursor-pointer pt-9"
            onClick={() => handleCategoryClick("Step1")}
          >
            {/* Documentation */}
          </p>
          {selectedCategory === "Step1" && (
            <div className="ml-4">
              <p
                className={
                  selectedTitle === "INSTALL METAMASK" ? "font-bold" : ""
                }
              >
                Install MetaMask
              </p>
              <p
                className={
                  selectedTitle === "CONNECT WALLET" ? "font-bold" : ""
                }
              >
                Connect Wallet
              </p>
              <p
                className={
                  selectedTitle === "CREATE / CUSTOMIZE AUTHOR PAGE"
                    ? "font-bold"
                    : ""
                }
              >
                Author page
              </p>
              <p
                className={
                  selectedTitle === "VERIFY ACCOUNT" ? "font-bold" : ""
                }
              >
                Verify Account
              </p>
              <p
                className={
                  selectedTitle === "CREATE COLLECTION" ? "font-bold" : ""
                }
              >
                Create Collection
              </p>
              <p className={selectedTitle === "CREATE NFT" ? "font-bold" : ""}>
                Create NFT
              </p>
              <p className={selectedTitle === "UPLOAD IMG" ? "font-bold" : ""}>
                Upload img
              </p>
              <p
                className={
                  selectedTitle === "UPLOAD AUDIO / VIDEO" ? "font-bold" : ""
                }
              >
                Upload audio/video
              </p>
              <p
                className={
                  selectedTitle === "UNLOCKABLE CONTENT" ? "font-bold" : ""
                }
              >
                Unlockable Content
              </p>
              <p className={selectedTitle === "NFT DETAILS" ? "font-bold" : ""}>
                NFT Details
              </p>
            </div>
          )}
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 p-8">
        {/* Toggle Button */}
        <button
          className="bg-gray-100 mt-16 text-black p-2 rounded-md ml-[-37px] hidden md:block"
          onClick={toggleSidebar}
        >
          {isSidebarOpen ? <FaTimes /> : <FaBars />}
        </button>

        {/* Title */}
        <div className="pt-16 text-black">
          {selectedCategory === "Step1" && <Step1Content />}
        </div>
      </div>
    </div>
  );
};

export default Doc;
