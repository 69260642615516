import { setGlobalState, getGlobalState, useGlobalState } from "./store";
import abi from "./abis/DubxNFTMarketplace.json";
import verify_abi from "./abis/VerifyUser.json";
import registry_abi from "./abis/CollectionRegistry.json";
import collection_abi from "./abis/CollectionERC721.json";

import { ethers } from "ethers";

const { ethereum } = window;

const getProvider = async () => {
  try {
    let ethersProvider;
    const connectedAccount = getGlobalState("connectedAccount");
    if (ethereum && ethereum._state.isUnlocked === true) {
      const chainId = await ethereum.request({ method: "eth_chainId" });
      if (chainId === "0xcc5" && connectedAccount) {
        ethersProvider = new ethers.providers.Web3Provider(ethereum);
      } else {
        // ethersProvider = new ethers.providers.JsonRpcProvider(
        //   "https://rpctestnet.arabianchain.org/",
        //   3270
        // );
        ethersProvider = new ethers.providers.JsonRpcProvider(
          "https://rpcmain.arabianchain.org/",
          3269
        );
      }
    } else if (ethereum && ethereum._state.isUnlocked === false) {
      ethersProvider = new ethers.providers.JsonRpcProvider(
        "https://rpcmain.arabianchain.org/",
        3269
      );
    } else {
      // ethersProvider = new ethers.providers.JsonRpcProvider(
      //   "https://rpctestnet.arabianchain.org/",
      //   3270
      // );
      ethersProvider = new ethers.providers.JsonRpcProvider(
        "https://rpcmain.arabianchain.org/",
        3269
      );
    }

    return ethersProvider;
  } catch (error) {
    console.error(error);
  }
};

const convertToDubx = (wei) => {
  try {
    if (typeof wei === "string" && /\d+\.?\d*e[+-]*\d+/i.test(wei)) {
      const normalNumberFormatted = parseFloat(wei).toLocaleString("fullwide", {
        useGrouping: false,
        maximumFractionDigits: 4,
      });

      return ethers.utils.formatUnits(normalNumberFormatted, "ether");
    }
    if (
      typeof wei === "number" &&
      /\d+\.?\d*e[+-]*\d+/i.test(wei.toExponential())
    ) {
      const normalNumberFormatted = parseFloat(wei).toLocaleString("fullwide", {
        useGrouping: false,
        maximumFractionDigits: 4,
      });

      return ethers.utils.formatUnits(normalNumberFormatted, "ether");
    }

    // If not in scientific notation, proceed with the original conversion
    const scientificNumber = ethers.utils.formatUnits(wei, "ether");
    return parseFloat(scientificNumber).toFixed(4);
  } catch (error) {
    console.error("Error converting to Dubx:", error);
    return null;
  }
};

const convertToWei = (dubx) => {
  const amountInWei = ethers.utils.parseUnits(dubx, "ether").toString();
  return amountInWei;
};

const getEthersContract = async (dubxOrCollectionContract) => {
  // const dubxCollectionAddr = abi.networks[3270].address;
  const dubxCollectionAddr = abi.networks[3269].address;
  const connectedAccount = getGlobalState("connectedAccount");

  if (
    dubxOrCollectionContract &&
    dubxOrCollectionContract.toLowerCase() === dubxCollectionAddr.toLowerCase()
  ) {
    try {
      const ethersProvider = await getProvider();
      // console.log(ethersProvider);
      if (ethersProvider && ethereum && ethereum._state.isUnlocked === true) {
        // const accounts = await ethereum.request({
        //   method: "eth_requestAccounts",
        // });

        if (connectedAccount) {
          // const networkData = abi.networks[3270];
          const networkData = abi.networks[3269];
          const contract = new ethers.Contract(
            networkData.address,
            abi.abi,
            ethersProvider.getSigner()
          );

          return contract;
        } else {
          const networkData = abi.networks[3269];
          const contract = new ethers.Contract(
            networkData.address,
            abi.abi,
            ethersProvider
          );

          return contract;
        }
      } else {
        // const networkData = abi.networks[3270];
        const networkData = abi.networks[3269];
        const contract = new ethers.Contract(
          networkData.address,
          abi.abi,
          ethersProvider
        );

        return contract;
      }
    } catch (error) {
      console.error("Error getting contract:", error);
      return { success: false, error: "Error in getEthersContract." };
    }
  } else {
    try {
      const ethersProvider = await getProvider();
      // console.log(ethersProvider);
      if (ethersProvider && ethereum && ethereum._state.isUnlocked === true) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        // const accounts = await ethereum.request({
        //   method: "eth_requestAccounts",
        // });

        // if (accounts && accounts.length > 0) {
        //   const contract = new ethers.Contract(
        //     dubxOrCollectionContract.toLowerCase(),
        //     collection_abi.abi,
        //     ethersProvider.getSigner()
        //   );
        //   return contract;
        // }
        if (chainId === "0xcc5") {
          if (connectedAccount) {
            // console.log(connectedAccount);
            const contract = new ethers.Contract(
              dubxOrCollectionContract.toLowerCase(),
              collection_abi.abi,
              ethersProvider.getSigner()
            );
            // console.log("contract if connectedAccount", contract);
            return contract;
          } else {
            const contract = new ethers.Contract(
              dubxOrCollectionContract.toLowerCase(),
              collection_abi.abi,
              ethersProvider
            );
            // console.log("contract2", contract);
            return contract;
          }
        } else {
          // console.log("not chain id 0xcc5");
          const contract = new ethers.Contract(
            dubxOrCollectionContract.toLowerCase(),
            collection_abi.abi,
            ethersProvider
          );
          // console.log("contract4", contract);
          return contract;
        }
      } else {
        const contract = new ethers.Contract(
          dubxOrCollectionContract.toLowerCase(),
          collection_abi.abi,
          ethersProvider
        );
        // console.log("contract3", contract);
        return contract;
      }
    } catch (error) {
      console.error("Error getting contract:", error);
      return { success: false, error: "Error in getEthersContract." };
    }
  }
};
const getCollectionContract = async (collectionContract) => {
  const connectedAccount = getGlobalState("connectedAccount");
  try {
    const ethersProvider = await getProvider();
    if (ethersProvider && ethereum && ethereum._state.isUnlocked === true) {
      // const accounts = await ethereum.request({
      //   method: "eth_requestAccounts",
      // });

      if (connectedAccount) {
        const contract = new ethers.Contract(
          collectionContract,
          collection_abi.abi,
          ethersProvider.getSigner()
        );
        return contract;
      } else {
        const contract = new ethers.Contract(
          collectionContract,
          collection_abi.abi,
          ethersProvider
        );
        return contract;
      }
    } else {
      const contract = new ethers.Contract(
        collectionContract,
        collection_abi.abi,
        ethersProvider
      );
      return contract;
    }
  } catch (error) {
    console.error("Error getting contract:", error);
    return { success: false, error: "Error in getEthersContract." };
  }
};

const getVerifyContract = async () => {
  try {
    let verify_contract;
    const ethersProvider = await getProvider();
    const connectedAccount = getGlobalState("connectedAccount");
    // console.log(ethersProvider);
    if (ethersProvider && ethereum && ethereum._state.isUnlocked === true) {
      const chainId = await ethereum.request({ method: "eth_chainId" });
      if (chainId === "0xcc5") {
        if (connectedAccount) {
          // const networkData = verify_abi.networks[3270];
          const networkData = verify_abi.networks[3269];
          verify_contract = new ethers.Contract(
            networkData.verify_address,
            verify_abi.verify_abi,
            ethersProvider.getSigner()
          );
          return verify_contract;
        } else {
          const networkData = verify_abi.networks[3269];
          verify_contract = new ethers.Contract(
            networkData.verify_address,
            verify_abi.verify_abi,
            ethersProvider
          );
          return verify_contract;
        }
      } else {
        // const networkData = verify_abi.networks[3270];
        const networkData = verify_abi.networks[3269];
        verify_contract = new ethers.Contract(
          networkData.verify_address,
          verify_abi.verify_abi,
          ethersProvider
        );
        return verify_contract;
      }
    } else {
      // const networkData = verify_abi.networks[3270];
      const networkData = verify_abi.networks[3269];
      verify_contract = new ethers.Contract(
        networkData.verify_address,
        verify_abi.verify_abi,
        ethersProvider
      );
      return verify_contract;
    }
  } catch (error) {
    console.error("Error in getVerifyContract:", error);
    return { success: false, error: "Error in getVerifyContract." };
  }
};
const getRegistryContract = async () => {
  try {
    let registry_contract;
    const ethersProvider = await getProvider();
    if (ethersProvider && ethereum && ethereum._state.isUnlocked === true) {
      const chainId = await ethereum.request({ method: "eth_chainId" });
      if (chainId === "0xcc5") {
        // const networkData = registry_abi.networks[3270];
        const networkData = registry_abi.networks[3269];
        registry_contract = new ethers.Contract(
          networkData.registry_address,
          registry_abi.collection_abi,
          ethersProvider.getSigner()
        );
        return registry_contract;
      } else {
        // const networkData = registry_abi.networks[3270];
        const networkData = registry_abi.networks[3269];
        registry_contract = new ethers.Contract(
          networkData.registry_address,
          registry_abi.collection_abi,
          ethersProvider
        );
        return registry_contract;
      }
    } else {
      // const networkData = registry_abi.networks[3270];
      const networkData = registry_abi.networks[3269];
      registry_contract = new ethers.Contract(
        networkData.registry_address,
        registry_abi.collection_abi,
        ethersProvider
      );
      return registry_contract;
    }
  } catch (error) {
    console.error("Error in getVerifyContract:", error);
    return { success: false, error: "Error in getVerifyContract." };
  }
};

const getAllCollections = async () => {
  try {
    const registry_c = await getRegistryContract();
    if (!registry_c) {
      console.error("Registry contract not available");
      return [];
    }
    const collections = await registry_c.getAllCollectionOwners();
    return collections;
  } catch (err) {
    console.error(err);
  }
};
const getCollectionsFromDB = async () => {
  try {
    const requestUrlAllCollections = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection`;
    const response = await fetch(requestUrlAllCollections, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      // console.log(data.Items);

      return data.Items;
    } else {
      console.error("Failed to get collections:", response.statusText);
      return false;
    }
  } catch (error) {
    console.error("Error getting collections:", error.message);
    return false;
  }
};

const getAllCollectionsWithData = async () => {
  try {
    const registryContract = await getRegistryContract();
    if (!registryContract) {
      console.error("Registry contract not available");
      return [];
    }
    const collectionOwners = await registryContract.getAllCollectionOwners();

    // Fetch data for each collection owner
    const collectionsWithData = await Promise.all(
      collectionOwners.map(async (collectionOwner) => {
        const collectionInfo = await registryContract.getCollectionOwnerInfo(
          collectionOwner
        );

        // Fetch NFTs for each collection
        const nftsPromises = collectionInfo.map(async (contractInfo) => {
          const collectionContract = await getCollectionContract(
            contractInfo.contractAddress
          );
          const nfts = await collectionContract.getAllNFTs();
          if (nfts.length === 0) {
            // No NFTs in this collection, skip it
            return null;
          }
          const formattedPurchaseCosts = nfts.map((nft) =>
            parseFloat(nft.purchaseCost.toString())
          );

          const nftFloorPrice = Math.min(...formattedPurchaseCosts);

          const collectionCost = formattedPurchaseCosts.reduce(
            (sum, cost) => sum + cost,
            0
          );
          const collectionPackage = await fetchCollectionImage(
            contractInfo.collectionHash
          );

          const collectionCostString = collectionCost.toString();
          const nftFloorPriceString = nftFloorPrice.toString();

          return {
            collectionHash: contractInfo.collectionHash,
            collectionOwner: contractInfo.collectionOwner,
            collectionTitle: contractInfo.collectionTitle,
            contractAddress: contractInfo.contractAddress,
            nfts,
            totalNftsCount: nfts.length,
            nftFloorPrice: convertToDubx(nftFloorPriceString),
            collectionCost: convertToDubx(collectionCostString),
            collectionImage: collectionPackage.image,
            collectionBanner: collectionPackage.banner,
            collectionCreated: collectionPackage.date,
            collectionDescription: collectionPackage.description,
            collectionWebsite: collectionPackage.external_link,
          };
        });

        const fetchCollectionImage = async (hash) => {
          try {
            const response = await fetch(
              `${process.env.REACT_APP_DOMAIN}/ipfs/${hash}`
            );
            const imageData = await response.json();
            return imageData;
          } catch (error) {
            console.error("Error fetching collection image:", error);
            return null;
          }
        };

        const nftsData = (await Promise.all(nftsPromises)).filter(Boolean);

        return nftsData;
      })
    );

    const filteredCollections = collectionsWithData
      .flat()
      .filter((collection) => collection !== null);

    filteredCollections.sort(
      (a, b) => b.collectionCreated - a.collectionCreated
    );

    return filteredCollections;
  } catch (error) {
    console.error("Error fetching collections with data and NFTs:", error);
    return [];
  }
};

const getAllCollectionsForAuthor = async (addressOwner) => {
  try {
    const requestUrlAllCollections = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection?owner=${addressOwner}&limit=40`;
    const response = await fetch(requestUrlAllCollections, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      // console.log(data.Items);

      return data.Items;
    } else {
      console.error("Failed to get collections:", response.statusText);
      return false;
    }
  } catch (error) {
    console.error("Error fetching collections from author:", error);
    return [];
  }
};

const verifyUserAccount = async () => {
  try {
    const contract = await getVerifyContract();
    const verification = await contract.verify({
      gasPrice: 20000000000,
    });
    if (verification) {
      const receipt = await verification.wait();
      return { success: true, receipt };
    }
  } catch (error) {
    console.error("Error during verification:", error);
    return { success: false, error: "Error during verification." };
  }
};

const checkIsAccVerified = async () => {
  try {
    const contract = await getVerifyContract();

    if (!ethereum) return false;
    if (ethereum._state.isUnlocked === false) {
      return false;
    }
    const chainId = await ethereum.request({ method: "eth_chainId" });
    if (chainId === "0xcc5") {
      const account = getGlobalState("connectedAccount");
      if (!account) {
        return false;
      } else {
        const isVerified = await contract.checkVerification(
          account.toLowerCase()
        );
        return isVerified;
      }
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error during verification:", error);
    return { success: false, error: "Error checkIsAccVerified." };
  }
};
const checkIsAuthorVerified = async (acc) => {
  try {
    const contract = await getVerifyContract();

    const isVerifiedAuthor = await contract.checkVerification(
      acc.toLowerCase()
    );
    return isVerifiedAuthor;
  } catch (error) {
    console.error("Error during verification:", error);
    return { success: false, error: "Error checkIsAccVerified." };
  }
};
const getVerificationString = async () => {
  const connectedAccount = getGlobalState("connectedAccount");
  try {
    if (!ethereum) return false;
    if (ethereum._state.isUnlocked === false) {
      return false;
    }
    // const accounts = await ethereum.request({ method: "eth_requestAccounts" });
    const contract = await getVerifyContract();
    if (!contract) {
      console.error(
        "Contract is undefined. Unable to proceed with verification."
      );
      return;
    }
    if (connectedAccount) {
      const isVerifiedString = await contract.getVerificationString(
        connectedAccount.toLowerCase()
      );
      return isVerifiedString;
    } else {
      return "";
    }
  } catch (error) {
    console.error("Error during verification:", error);
    return false;
  }
};

const getAuthorData = async (acc) => {
  const authorAcc = acc.toLowerCase();
  try {
    const getAuthorUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/author?account=${authorAcc}`;
    const response = await fetch(getAuthorUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      // console.log("getAuthorData", data);
      return data;
    } else {
      console.error("Failed to get requests:", response.statusText);
      return false;
    }
  } catch (error) {
    console.error("Error getting requests:", error.message);
    return false;
  }
};

const getNftsData = async (pageNumber, pageSize) => {
  return new Promise(async (resolve, reject) => {
    const getNftsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/nfts?pageNumber=${pageNumber}&pageSize=${pageSize}`;
    try {
      const response = await fetch(getNftsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // data.Items.sort((a, b) => b.timestamp - a.timestamp);
        setGlobalState("nfts", data);
        resolve(data);
      } else {
        console.error("Failed to get requests:", response.statusText);
        reject(response.statusText);
      }
    } catch (error) {
      console.error("Error getting requests:", error.message);
      reject(error.message);
    }
  });
};

const getNftsByAuthor = async (ownerAddr, limitNfts) => {
  const nftsOwner = ownerAddr.toLowerCase();
  return new Promise(async (resolve, reject) => {
    const limit = parseInt(limitNfts);
    const getNftsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?owner=${nftsOwner}&limit=${limit}`;
    // console.log("getNftsUrl", getNftsUrl);
    try {
      const response = await fetch(getNftsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // data.Items.sort((a, b) => b.timestamp - a.timestamp);

        resolve(data);
      } else {
        console.error("Failed to get requests:", response.statusText);
        reject(response.statusText);
      }
    } catch (error) {
      console.error("Error getting requests:", error.message);
      reject(error.message);
    }
  });
};

const loadAllNfts = async () => {
  const allNftsLoaded = getGlobalState("allNftsLoaded");
  if (allNftsLoaded) {
    return;
  }
  setGlobalState("allNftsLoaded", true);
  setGlobalState("isLoadingNfts", true);
  try {
    let url = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?all=true`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      setGlobalState("nfts", data.Items);
      setGlobalState("lastEvaluatedKey", data.LastEvaluatedKey);
    } else {
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.error("Error getting requests:", error.message);
    setGlobalState("allNftsLoaded", false);
  } finally {
    setGlobalState("isLoadingNfts", false);
  }
};
// const resetNfts = async () => {
//   setGlobalState("nfts", nfts)
//   setGlobalState()
//   setGlobalState()
// }

const loadNfts = async (limit) => {
  const currentNfts = getGlobalState("nfts");
  const lastEvaluatedKey = getGlobalState("lastEvaluatedKey");
  const allNftsLoaded = getGlobalState("allNftsLoaded");

  if (allNftsLoaded) {
    return;
  }
  setGlobalState("isLoadingNfts", true);
  try {
    let url = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?limit=${limit}`;
    //const allNftsLoaded = getGlobalState("allNftsLoaded");
    if (lastEvaluatedKey) {
      const { PK, SK, GSISK } = lastEvaluatedKey;
      const key = `lastElement=${PK}:${SK}:${GSISK}`;
      url = url + ";" + key;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      // console.log("globalNfts", data);
      if (lastEvaluatedKey) {
        const mergedNfts = [...currentNfts, ...data.Items];
        setGlobalState("nfts", mergedNfts);
      } else {
        setGlobalState("nfts", data.Items);
      }
      setGlobalState("lastEvaluatedKey", data.LastEvaluatedKey);
      if (data.Items.length < limit) {
        setGlobalState("allNftsLoaded", true);
      }
    } else {
      console.error("Failed to get requests:", response.statusText);
    }
  } catch (error) {
    console.error("Error getting requests:", error.message);
    // reportError({ message: "Error loading nft data" });
  } finally {
    // reportError({ message: "test error fake" });
    setGlobalState("isLoadingNfts", false);
  }
};
const loadCollections = async (limit) => {
  const currentCollections = getGlobalState("newestCollections");
  const lastEvaluatedKey = getGlobalState("lastEvaluatedKeyCollection");
  const allCollectionsLoaded = getGlobalState("allCollectionsLoaded");

  if (allCollectionsLoaded) {
    return;
  }
  setGlobalState("isLoadingCollections", true);
  try {
    let url = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection?limit=${limit}`;
    if (lastEvaluatedKey) {
      const { PK, SK, TIME_INDEX_SK } = lastEvaluatedKey;
      const key = `lastElement=${PK}:${SK}:${TIME_INDEX_SK}`;
      url = url + ";" + key;
    }

    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      // console.log("globalNfts", data);
      if (lastEvaluatedKey) {
        const mergedCollections = [...currentCollections, ...data.Items];
        setGlobalState("newestCollections", mergedCollections);
      } else {
        setGlobalState("newestCollections", data.Items);
      }
      setGlobalState("lastEvaluatedKeyCollection", data.LastEvaluatedKey);
      if (data.Items.length < limit) {
        setGlobalState("allCollectionsLoaded", true);
      }
    } else {
      console.error("Failed to get requests:", response.statusText);
    }
  } catch (error) {
    console.error("Error getting requests:", error.message);
    // reportError({ message: "Error loading nft data" });
  } finally {
    // reportError({ message: "test error fake" });
    setGlobalState("isLoadingCollections", false);
  }
};

const getAllAuthorsFromDB = async (limit) => {
  const currentCreators = getGlobalState("currentCreators");
  const lastEvaluatedKeyCreators = getGlobalState("lastEvaluatedKeyCreators");
  const allCreatorsLoaded = getGlobalState("allCreatorsLoaded");

  if (allCreatorsLoaded) {
    return;
  }
  setGlobalState("isLoadingAuthors", true);
  try {
    let getAllAuthorsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/author?limit=${limit}`;
    if (lastEvaluatedKeyCreators) {
      const { account, AUTHOR_SORT_KEY } = lastEvaluatedKeyCreators;
      const key = `lastElement=${account}:${AUTHOR_SORT_KEY}`;
      getAllAuthorsUrl = getAllAuthorsUrl + ";" + key;
    }

    const response = await fetch(getAllAuthorsUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      if (lastEvaluatedKeyCreators) {
        const mergedCreators = [...currentCreators, ...data.Items];
        setGlobalState("currentCreators", mergedCreators);
      } else {
        setGlobalState("currentCreators", data.Items);
      }
      setGlobalState("lastEvaluatedKeyCreators", data.LastEvaluatedKey);
      if (data.Items.length < limit) {
        setGlobalState("allCreatorsLoaded", true);
      }
    } else {
      console.error("Failed to get getAllAuthorsFromDB:", response.statusText);
      return false;
    }
  } catch (error) {
    console.error("Error getting getAllAuthorsFromDB:", error.message);
    return false;
  }
};

const getCollectionNftsData = async (collectionAddr) => {
  return new Promise(async (resolve, reject) => {
    try {
      const getNftsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?contractAddress=${collectionAddr}`;
      const response = await fetch(getNftsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        resolve(data.Items);
      } else {
        console.error("Failed to get requests:", response.statusText);
        reject(response.statusText);
      }
    } catch (error) {
      console.error("Error getting requests:", error.message);
      reject(error.message);
    }
  });
};
const getNftByKey = async ({ PK, SK }) => {
  const url = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?id=${PK}:${SK}`;
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      console.error("Failed to get requests:", response.statusText);
      throw new Error(response.statusText);
    }
  } catch (error) {
    console.error("Error getting requests:", error.message);
    throw error;
  }
};

const getNftTx = async (nftId, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);

    const nftTx = await contract.getAllTxByNFT(nftId);
    const formatedTx = nftTx.map((txn) => {
      return {
        txid: txn.txid.toString(),
        nftlk: txn.nftlk.toString(),
        from: txn.from,
        to: txn.to,
        amount: convertToDubx(txn.amount),
        timestamp: txn.timestamp.toNumber(),
        eventName: txn.eventName,
      };
    });
    const reversedTx = formatedTx.reverse();
    return reversedTx;
  } catch (error) {
    console.error("Error getting getNftTx", error.message);
    return [];
  }
};

const getNFTsArray = async (contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const nfts = await contract.getAllNFTs();
    if (nfts.length > 0) {
      const formattedNfts = await Promise.all(
        nfts.map(async (nft) => {
          const metadataUrl = `${process.env.REACT_APP_DOMAIN}/ipfs/${nft.metadataURI}`;
          const metadataResponse = await fetch(metadataUrl);
          const metadata = await metadataResponse.json();
          const imageUrl = metadata.image;

          return {
            id: nft.newNftid.toString(),
            owner: nft.owner,
            mintingCost: convertToDubx(nft.mintingCost),
            purchaseCost: convertToDubx(nft.purchaseCost),
            title: nft.title,
            metadataURI: nft.metadataURI,
            description: nft.description,
            timestamp: nft.timestamp.toNumber(),
            isListedForSale: nft.isListedForSale,
            royaltyFee: nft.royaltyFee.toNumber(),
            royaltyReceiver: nft.royaltyReceiver,
            originalArtist: nft.originalArtist,
            imageSrc: imageUrl,
          };
        })
      );

      return formattedNfts;
    } else {
      return [];
    }
  } catch (error) {
    return [];
  }
};

const getSingleNFT = async (id, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const singleNft = await contract.getNFT(id);
    const tokenOffers = await contract.tokenOffers(id);
    const formattedSingleNft = {
      id: singleNft.newNftid.toString(),
      owner: singleNft.owner,
      mintingCost: convertToDubx(singleNft.mintingCost),
      purchaseCost: convertToDubx(singleNft.purchaseCost),
      title: singleNft.title,
      metadataURI: singleNft.metadataURI,
      description: singleNft.description,
      timestamp: singleNft.timestamp.toNumber(),
      isListedForSale: singleNft.isListedForSale,
      royaltyFee: singleNft.royaltyFee.toNumber(),
      royaltyReceiver: singleNft.royaltyReceiver,
      originalArtist: singleNft.originalArtist,
    };
    const formattedTokenOffers = {
      amount: convertToDubx(tokenOffers.amount),
      buyer: tokenOffers.buyer,
      offerSent: tokenOffers.timestamp.toNumber(),
      expirationTime: tokenOffers.expirationTime.toNumber(),
      isActive: tokenOffers.isActive,
    };

    return {
      singleNft: formattedSingleNft,
      tokenOffers: formattedTokenOffers,
    };
  } catch (error) {
    console.error(error);
    return {
      singleNft: null,
      tokenOffers: null,
      error: "An error occurred while processing data.",
    };
  }
};
const makeOfferById = async (id, cost, offerDuration, contr) => {
  try {
    const contract = await getEthersContract(contr);
    const offerPrice = convertToWei(cost);
    const timestampDuration = offerDuration.toString();

    const result = await contract.makeOffer(id, offerPrice, timestampDuration, {
      value: offerPrice,
      gasPrice: 20000000000,
    });
    await result.wait();
    const offerData = await getOfferData(id, contr);
    return offerData;
  } catch (error) {
    console.error("Error getting makeOfferById", error.message);
    return false;
  }
};
const getOfferData = async (tokenId, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const offerData = await contract.tokenOffers(tokenId);
    if (offerData) {
      return offerData;
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error getting getOfferData", error.message);
    return false;
  }
};
const refundOfferById = async (id, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const result = await contract.refundOffer(id, {
      gasPrice: 20000000000,
    });
    await result.wait();
    const offerData = await getOfferData(id, contractAddr);
    const formattedTOfferData = {
      amount: convertToDubx(offerData.amount),
      buyer: offerData.buyer,
      offerSent: offerData.timestamp.toNumber(),
      expirationTime: offerData.expirationTime.toNumber(),
      isActive: offerData.isActive,
    };
    return formattedTOfferData;
  } catch (error) {
    console.error("Error getting refundOfferById:", error.message);
    return false;
  }
};
const acceptOfferById = async (id, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const offerData = await getOfferData(id, contractAddr);
    const formattedTOfferData = {
      amount: convertToDubx(offerData.amount),
      buyer: offerData.buyer,
      offerSent: offerData.timestamp.toNumber(),
      expirationTime: offerData.expirationTime.toNumber(),
      isActive: offerData.isActive,
    };
    const result = await contract.acceptOffer(id, {
      gasPrice: 20000000000,
    });
    const receipt = await result.wait();

    if (receipt && receipt.status) {
      return formattedTOfferData;
    } else {
      return {};
    }
  } catch (error) {
    console.error("Error getting acceptOfferById:", error.message);
    return false;
  }
};
const rejectOfferById = async (id, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const result = await contract.rejectOffer(id, {
      gasPrice: 20000000000,
    });
    await result.wait();
    const offerData = await getOfferData(id, contractAddr);
    const formattedTOfferData = {
      amount: convertToDubx(offerData.amount),
      buyer: offerData.buyer,
      offerSent: offerData.timestamp.toNumber(),
      expirationTime: offerData.expirationTime.toNumber(),
      isActive: offerData.isActive,
    };
    return formattedTOfferData;
  } catch (error) {
    console.error("Error getting rejectOfferById:", error.message);
    return false;
  }
};
const getContractName = async (contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const nftContactName = await contract.name();
    // console.log(nftContactName);
    return nftContactName;
  } catch (error) {
    console.error("Error getting getContractName:", error.message);
    return false;
  }
};
const getContractOwner = async (contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const nftContactOwner = await contract.owner();
    return nftContactOwner;
  } catch (error) {
    console.error("Error getting getContractOwner:", error.message);
    return false;
  }
};
const getOfferForNFTById = async (id, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const nftOffer = await contract.tokenOffers(id);
    if (nftOffer) {
      if (nftOffer.isActive === false) {
        return { isActive: false };
      } else {
        return {
          isActive: true,
          amount: nftOffer.amount,
          buyer: nftOffer.buyer,
          expirationTime: nftOffer.expirationTime,
          timestamp: nftOffer.timestamp,
          id: id,
        };
      }
    }
  } catch (error) {
    console.error("Error getting getOfferForNFTById:", error.message);
    return false;
  }
};

const adminCost = async () => {
  try {
    // const contractAdmin = abi.networks[3270].address;
    const contractAdmin = abi.networks[3269].address;
    const contract = await getEthersContract(contractAdmin);
    const mintAmount = await contract.getMintingCost();
    if (mintAmount) {
      return convertToDubx(mintAmount);
    } else {
      return false;
    }
  } catch (error) {
    console.error("Error getting adminCost:", error.message);
    return false;
  }
};

const mintNFT = async (
  title,
  description,
  metadataURI,
  unlockable = "",
  price,
  receiver = "0x0000000000000000000000000000000000000000",
  fee = 0,
  contractAddr
) => {
  try {
    price = convertToWei(price);
    const contract = await getEthersContract(contractAddr);
    const mintAmount = await contract.getMintingCost();

    // console.log("Minting parameters:", {
    //   title,
    //   description,
    //   metadataURI,
    //   unlockable,
    //   price,
    //   receiver,
    //   fee,
    //   mintAmount,
    // });

    const result = await contract.payToMint(
      title,
      description,
      metadataURI,
      unlockable,
      price,
      receiver,
      fee,
      { value: mintAmount, gasPrice: 20000000000 }
    );
    const receipt = await result.wait();

    return receipt;
  } catch (error) {
    console.error("Error getting mintNFT:", error.message);
    return false;
  }
};

const buyNFT = async (id, cost, contractAddr) => {
  try {
    const payingCost = convertToWei(cost);
    const contract = await getEthersContract(contractAddr);
    const result = await contract.payToBuy(id, {
      value: payingCost,
      gasPrice: 20000000000,
    });
    const receipt = await result.wait();
    if (receipt.status === 1) {
      const newOwner = await contract.ownerOf(id);
      return newOwner;
    } else {
      console.error("Transaction failed:", receipt);
      return false;
    }
  } catch (error) {
    console.error("Error getting buyNFT:", error.message);
    return false;
  }
};

const changePriceNFT = async (id, cost, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const offerPrice = convertToWei(cost);

    const result = await contract.changePrice(id, offerPrice, {
      gasPrice: 20000000000,
    });
    return result;
  } catch (error) {
    console.error("Error getting changePriceNFT:", error.message);
    return false;
  }
};

const getUnlockablebyTokenId = async (tokenid, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const account = getGlobalState("connectedAccount");
    const owner = await contract.ownerOf(tokenid);

    if (account.toLowerCase() === owner.toLowerCase()) {
      const unlockableCont = await contract.getUnlockableContent(tokenid);
      if (unlockableCont) {
        return unlockableCont;
      } else if (!unlockableCont) {
        return "You don't have unlockable content";
      }
    } else if (account.toLowerCase() !== owner.toLowerCase()) {
      return "You are not owner of NFT.";
    } else {
      console.error("Error getting getUnlockablebyTokenId:");
      return false;
    }
  } catch (error) {
    console.error("Error getting getUnlockablebyTokenId:", error.message);
    return false;
  }
};

const listNFTbyTokenId = async (tokenid, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const listing = await contract.listTokenForSale(tokenid, {
      gasPrice: 20000000000,
    });
    await listing.wait();
    const isForSale = await contract.isTokenListedForSale(tokenid);
    return isForSale;
  } catch (error) {
    console.error("Error getting listNFTbyTokenId:", error.message);
    return false;
  }
};
const removeNFTbyTokenId = async (tokenid, contractAddr) => {
  try {
    const contract = await getEthersContract(contractAddr);
    const listing = await contract.removeTokenForSale(tokenid, {
      gasPrice: 20000000000,
    });
    await listing.wait();
    const isForSale = await contract.isTokenListedForSale(tokenid);
    return isForSale;
  } catch (error) {
    console.error("Error getting removeNFTbyTokenId:", error.message);
    return false;
  }
};

const shortenAddress = (address) => {
  if (!address || address.length !== 42) {
    return address;
  }

  const start = address.substring(0, 6);
  const end = address.substring(address.length - 4);

  return `${start}...${end}`;
};
const pauseContract = async () => {
  try {
    // const contractAdmin = abi.networks[3270].address;
    const contractAdmin = abi.networks[3269].address;
    const contract = await getEthersContract(contractAdmin);
    const result = await contract.pause({ gasPrice: 20000000000 });
    return result;
  } catch (error) {
    console.error("Error pauseContract:", error.message);
    return false;
  }
};

const unpauseContract = async () => {
  try {
    // const contractAdmin = abi.networks[3270].address;
    const contractAdmin = abi.networks[3269].address;
    const contract = await getEthersContract(contractAdmin);
    const result = await contract.unpause({ gasPrice: 20000000000 });
    return result;
  } catch (error) {
    console.error("Error unpauseContract:", error.message);
    return false;
  }
};

const setBaseURI = async (newBaseURI) => {
  try {
    // const contractAdmin = abi.networks[3270].address;
    const contractAdmin = abi.networks[3269].address;
    const contract = await getEthersContract(contractAdmin);
    const result = await contract.setBaseURI(newBaseURI, {
      gasPrice: 20000000000,
    });
    const receipt = await result.wait();
    return receipt;
  } catch (error) {
    console.error("Error setBaseURI:", error.message);
    return false;
  }
};

const setMintingCost = async (newMintingCost) => {
  try {
    // const contractAdmin = abi.networks[3270].address;
    const contractAdmin = abi.networks[3269].address;
    const contract = await getEthersContract(contractAdmin);
    const newMintingCostFormatted = convertToWei(newMintingCost);
    const result = await contract.setMintingCost(newMintingCostFormatted, {
      gasPrice: 20000000000,
    });
    return result;
  } catch (error) {
    console.error("Error setMintingCost:", error.message);
    return false;
  }
};

export {
  getNFTsArray,
  mintNFT,
  buyNFT,
  changePriceNFT,
  getContractName,
  shortenAddress,
  getSingleNFT,
  makeOfferById,
  refundOfferById,
  acceptOfferById,
  rejectOfferById,
  pauseContract,
  unpauseContract,
  setBaseURI,
  setMintingCost,
  listNFTbyTokenId,
  removeNFTbyTokenId,
  getOfferForNFTById,
  getUnlockablebyTokenId,
  convertToDubx,
  getContractOwner,
  getNftTx,
  getVerifyContract,
  verifyUserAccount,
  checkIsAccVerified,
  getVerificationString,
  getAuthorData,
  adminCost,
  getNftsData,
  getAllAuthorsFromDB,
  checkIsAuthorVerified,
  getEthersContract,
  convertToWei,
  getOfferData,
  getNftByKey,
  getProvider,
  getRegistryContract,
  getCollectionContract,
  getCollectionNftsData,
  getAllCollections,
  getAllCollectionsWithData,
  getAllCollectionsForAuthor,
  loadNfts,
  loadAllNfts,
  getCollectionsFromDB,
  getNftsByAuthor,
  loadCollections,
};
