import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import RocketManMini from "../assets/BarGraph.png";
import RocketCenter from "../assets/Targetting.png";
import { setGlobalState, useGlobalState, truncate } from "../store";
import { shortenAddress, loadNfts } from "../Blockchain.Services";
import SectionLoader from "./SectionLoader";
import BubblesFire from "./BubblesFire";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const TopCreators = () => {
  const [randomNfts, setRandomNfts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [intervalId, setIntervalId] = useState(null);
  const [nfts] = useGlobalState("nfts");
  const [currentCreators] = useGlobalState("currentCreators");

  useEffect(() => {
    const fetchNfts = async () => {
      // const hundredNfts = async () => {
      //   try {
      //     let url = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?limit=100`;
      //     const response = await fetch(url, {
      //       method: "GET",
      //       headers: {
      //         "Content-Type": "application/json",
      //       },
      //     });
      //     console.log(response);
      //     if (response.ok) {
      //       const data = await response.json();
      //       return data.Items;
      //     }
      //   } catch (error) {
      //     console.error("Error fetching nfts:", error);
      //     return null;
      //   }
      // };

      // const hundredNftsData = await hundredNfts();

      if (nfts) {
        // console.log(nfts);
        const shuffledNfts = shuffleArray([...nfts]);
        const selectedNfts = shuffledNfts.slice(0, 6);
        setRandomNfts(selectedNfts);
        setLoading(false);
      }
    };
    fetchNfts();
    // const startInterval = async () => {
    //   await fetchNfts(); // Fetch initial data and set interval

    //   const newIntervalId = setInterval(async () => {
    //     await fetchNfts(); // Fetch new data every 2 minutes
    //   }, 1 * 60 * 1000); // 2 minutes in milliseconds

    //   setIntervalId(newIntervalId); // Store interval ID for cleanup
    // };

    // startInterval();

    // return () => {
    //   clearInterval(intervalId); // Cleanup interval on unmount
    // };
  }, [nfts]);

  return (
    <div className="pt-24 mt-12 pb-12 border-b border-[#21e786] itemDetailMainIMG1">
      <h1 className="text-5xl font-bold pb-16 cursor-default text-[#21e786]">
        TOP CREATORS
      </h1>
      <div className="absolute right-[13%] mt-[-7%] userBounceIMG w-32 max-[579px]:hidden">
        <LazyLoadImage
          src={RocketManMini}
          loading="lazy"
          decoding="async"
          alt="RocketMan"
        />
      </div>
      <div className="absolute left-[13%] mt-[-7%] userBounceIMG w-32 max-[579px]:hidden">
        <LazyLoadImage
          src={RocketCenter}
          loading="lazy"
          decoding="async"
          alt="RocketMan"
        />
      </div>
      {loading ? (
        <SectionLoader />
      ) : (
        <div className="flex">
          <div className="w-full flex pb-12 pt-24 justify-around flex-wrap">
            {randomNfts.map((nft, index) => (
              <div
                key={index}
                className="w-[290px] h-[500px] z-20 border border-[#21e786] rounded-lg hover:shadow-lg hover:shadow-[#21e786] transition-all duration-200 relative mb-8"
              >
                <Link
                  to={`/author/${nft.owner}`}
                  className="flex justify-center"
                >
                  <div className="relative w-[290px] h-[500px] aspect-w-1 aspect-h-1">
                    <div
                      className="absolute right-0 top-[-40px]"
                      style={{ zIndex: "3" }}
                    >
                      <div className="flex items-center">
                        {" "}
                        <BubblesFire count={nft.nftViews} />
                      </div>
                    </div>

                    <div className="absolute inset-0 rounded-lg overflow-hidden">
                      <LazyLoadImage
                        src={nft.imageURI}
                        loading="lazy"
                        decoding="async"
                        alt="NFT"
                        className="w-full h-full object-cover cursor-pointer imgHover m-0 p-0 rounded-xl"
                      />
                    </div>
                    <div
                      className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[100%]"
                      style={{ backgroundColor: "rgb(0 0 0 / 62%)" }}
                    >
                      <h3 className="pt-[10px] text-center text-white font-bold text-3xl">
                        {shortenAddress(nft.owner)}
                      </h3>
                      <h3 className="text-center text-white text-lg pt-3">
                        {nft.purchaseCost.toFixed(4)} DUBX
                      </h3>
                      <div className="flex justify-center">
                        <button
                          type="button"
                          className="w-full text-center text-[#000] font-Bakbak bg-[#21e786] px-4 py-2 mt-3 text-[24px]"
                        >
                          Visit Profile
                        </button>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
      <Link to={"/explore/authors"}>
        <div className="flex items-center justify-center mt-8">
          <button
            className="shadow-xl shadow-black text-[#fff] hover:text-white
              bg-[#f10cea] hover:bg-[#21e786] md:text-[24px] py-3 px-[15px]
                cursor-pointer font-Bakbak"
          >
            Load More Creators
          </button>
        </div>
      </Link>
    </div>
  );
};

export default TopCreators;
