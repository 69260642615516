import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MessageModal from "../components/MessageModal";
import ConfettiContainer from "../components/ConfettiContainer";

const OfferModal = ({ tokenId, onClose, onSubmit }) => {
  const [offerPrice, setOfferPrice] = useState("");
  const [offerEndDate, setOfferEndDate] = useState(new Date());
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState("");
  const [showUlMessageModal, setShowUlMessageModal] = useState(true);
  const [showConfMessageModal, setShowConfMessageModal] = useState(false);
  const [showConfettiContainer, setShowConfettiContainer] = useState(false);
  // const [offerDuration, setOfferDuration] = useState("");

  const handleShowMessageModal = (
    newMessage,
    showUl = true,
    showConf = false
  ) => {
    setInfoMessage(newMessage);
    setShowMessageModal(true);
    setShowUlMessageModal(showUl);
    setShowConfMessageModal(showConf);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };

  const handleSubmit = () => {
    // if (!offerPrice || !offerDuration) {
    if (!offerPrice || !offerEndDate || offerEndDate <= new Date()) {
      handleShowMessageModal("Price and future date are required", false);
      return;
    }
    const currentTime = Math.round(new Date().getTime() / 1000);
    const offerEndTime = Math.round(offerEndDate.getTime() / 1000);
    const offerDuration = Math.max(offerEndTime - currentTime, 0);

    onSubmit(tokenId, offerPrice, offerDuration);
    onClose();
  };
  const isFutureTime = (date) => {
    const currentTime = new Date();
    return date.getTime() >= currentTime.getTime();
  };

  return (
    <div className="fixed inset-0 bg-[rgba(36,42,57,0.8)] flex items-center justify-center">
      <div className="bg-[#131722] p-8 rounded-lg w-[300px]">
        <h2 className="text-3xl font-Bakbak mb-4 text-[#21e786]">
          Make an Offer
        </h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-[#7b7b7b] mb-1">
            Token ID
          </label>
          <input
            type="text"
            value={tokenId}
            readOnly
            className="form-input bg-gray-800 text-white rounded-md p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-[#7b7b7b] mb-1">
            Offer Price (DUBX)
          </label>
          <input
            type="number"
            value={offerPrice}
            onChange={(e) => setOfferPrice(e.target.value)}
            className="form-input bg-gray-800 text-white rounded-md p-2 w-full"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-[#7b7b7b] mb-1">
            Offer End Date and Time
          </label>
          <DatePicker
            selected={offerEndDate}
            onChange={(date) => setOfferEndDate(date)}
            minDate={new Date()}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MMMM d, yyyy h:mm aa"
            className="form-input bg-gray-800 text-white rounded-md p-2 w-full"
            filterTime={isFutureTime}
          />
        </div>
        <div className="flex justify-between mt-8">
          <button
            onClick={onClose}
            className="mr-4 px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
          >
            Submit Offer
          </button>
        </div>
      </div>
      <div>
        <MessageModal
          message={infoMessage}
          show={showMessageModal}
          showUl={showUlMessageModal}
          showConf={showConfMessageModal}
          onClose={handleCloseMessageModal}
        />
      </div>
      {showConfettiContainer && <ConfettiContainer />}
    </div>
  );
};

export default OfferModal;
