import React, { useState, useEffect } from "react";
import { FaInstagram, FaFacebookF, FaSkype } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { AiOutlineGlobal } from "react-icons/ai";
import TopSeller6 from "../assets/ai5.jpg";
import { useParams, useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  getAuthorData,
  getNftsData,
  shortenAddress,
  checkIsAuthorVerified,
  verifyUserAccount,
  // getAllCollectionsForAuthor,
} from "../Blockchain.Services";
import moment from "moment/moment";
import { Link } from "react-router-dom";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { useGlobalState, setGlobalState, getGlobalState } from "../store";
import { CustomScroll } from "react-custom-scroll";
import CategoryMain from "../components/CategoryMain";

const rangeInputStyles = `
  
  select option:hover {
    background-color: #1BA1E2 !important;
  }
  
  select {
    background-color: #0d0f10 !important;
  }
`;

const UserInfo = () => {
  const { address } = useParams();
  const navigate = useNavigate();
  const [collectionsInfo, setCollectionsInfo] = useState([]);
  const [minMax, setMinMax] = useState(100);
  const [maxTitleLength, setMaxTitleLength] = useState(20);
  const [nfts, setNfts] = useState([]);
  // console.log(nfts);
  const onCreatedNFT = () => {
    setGlobalState("modal", "scale-100");
  };

  const [isAccountVerified, setIsAccountVerified] =
    useGlobalState("isAccountVerified");
  const { ethereum } = window;
  const [expanded, setExpanded] = useState(false);
  const [isAuthorVerified, setIsAuthorVerified] = useState(false);
  const [category, setCategory] = useState("all");
  const [tags, setTags] = useState("all");
  const [sortBy, setSortBy] = useState("PostingDate");
  const [authorNftsFiltered, setAuthorNftsFiltered] = useState([]);
  const [visibleNFTsCount, setVisibleNFTsCount] = useState(0);
  const [displayedNftsCount, setDisplayedNftsCount] = useState(8);
  const [isLoadingNfts, setIsLoadingNfts] = useState(false);
  const [allNftsLoaded, setAllNftsLoaded] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  const [sliderValues, setSliderValues] = useState({
    slide1: 1000,
    slide2: 50000,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [sorting, setSorting] = useState("");
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const [authorInfo, setAuthorInfo] = useState({
    account: address,
    timestamp: "",
    verified: false,
    authorPhoto: "",
    bannerImage: "",
    name: "",
    externalLink: "",
    smartCode: "",
    bio: "",
    facebook: "",
    twitter: "",
    instagram: "",
    skype: "",
  });

  const handleSliderChange = (e, slider) => {
    setSliderValues((prevValues) => ({
      ...prevValues,
      [slider]: parseFloat(e.target.value),
    }));
  };
  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategory(newCategory);
  };
  const getNftsByAuthor = async (ownerAddr, limitNfts) => {
    const nftsOwner = ownerAddr.toLowerCase();
    return new Promise(async (resolve, reject) => {
      const limit = parseInt(limitNfts);
      let getNftsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?owner=${nftsOwner}&limit=${limit}`;
      // console.log("getNftsUrl", getNftsUrl);
      if (lastEvaluatedKey) {
        const { PK, SK, owner, timestamp } = lastEvaluatedKey;
        const key = `lastElement=${PK}:${SK}:${owner}:${timestamp}`;
        getNftsUrl = getNftsUrl + "&" + key;
      }
      try {
        const response = await fetch(getNftsUrl, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (response.ok) {
          const data = await response.json();

          if (lastEvaluatedKey) {
            const mergedNfts = [...nfts, ...data.Items];
            // console.log(mergedNfts);

            setNfts(mergedNfts);
          } else {
            setNfts(data.Items);
          }
          setLastEvaluatedKey(data.LastEvaluatedKey);

          if (data.Items.length < limit) {
            setAllNftsLoaded(true);
          }
          resolve(data);
        } else {
          console.error("Failed to get requests:", response.statusText);
          reject(response.statusText);
        }
      } catch (error) {
        console.error("Error getting requests:", error.message);
        reject(error.message);
      }
    });
  };
  const getAllCollectionsForAuthor = async (addressOwner) => {
    try {
      const requestUrlAllCollections = `${process.env.REACT_APP_AWS_API_GATEWAY}/collection?owner=${addressOwner}&limit=100`;
      const response = await fetch(requestUrlAllCollections, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log(data.Items);

        return data.Items;
      } else {
        console.error("Failed to get collections:", response.statusText);
        return false;
      }
    } catch (error) {
      console.error("Error fetching collections from author:", error);
      return [];
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const collectionsPackage = await getAllCollectionsForAuthor(address);

      if (collectionsPackage) {
        // console.log(collectionsPackage);
        setCollectionsInfo(collectionsPackage);
      }

      const nftsFromDb = await getNftsByAuthor(address, 40);

      // setNfts(nftsFromDb.Items);
    };

    fetchData();
  }, [address]);

  useEffect(() => {
    const calculatePrice = async () => {
      try {
        if (nfts.length > 0) {
          const min = nfts.reduce((prev, current) => {
            return Math.min(prev, current.purchaseCost);
          }, Infinity);
          const max = nfts.reduce((prev, current) => {
            return Math.max(prev, current.purchaseCost);
          }, 0);
          setMinMax({ min, max });
          setSliderValues({ slide1: min, slide2: max });
        } else {
          setMinMax({ min: 0, max: 0 });
          setSliderValues({ slide1: 0, slide2: 0 });
        }
      } catch (error) {
        console.error("Error getting requests:", error.message);
      }
    };
    calculatePrice();
  }, [nfts]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getInfoAuthor = async () => {
    try {
      const authorResult = await getAuthorData(address);
      if (authorResult && authorResult.Item) {
        if (authorResult.Item.email) {
          const { email, ...authorInfoWithout } = authorResult.Item;
          // console.log(authorInfoWithout);
          setAuthorInfo(authorInfoWithout);
        } else {
          setAuthorInfo(authorResult.Item);
        }
      } else {
        const authorEmptyObject = {
          account: address,
          timestamp: "",
          verified: false,
          authorPhoto: TopSeller6,
          bannerImage: "",
          name: "Waiting for verification",
          externalLink: "",
          smartCode: "",
          bio: "",
          facebook: "",
          twitter: "",
          instagram: "",
          skype: "",
          nftCount: 0,
          collectionCount: 0,
        };
        setAuthorInfo(authorEmptyObject);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getIsAuthorVerified = async () => {
    try {
      if (address) {
        console.log(address);
        const result = await checkIsAuthorVerified(address);
        if (result === true) {
          setIsAuthorVerified(true);
        } else {
          setIsAuthorVerified(false);
        }
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };

  useEffect(() => {
    getInfoAuthor();
    getIsAuthorVerified();
  }, [connectedAccount, navigate]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const calculateAuthorsNftsCount = () => {
    if (nfts) {
      let filteredNfts = [...nfts];

      // Filter po searchTerm
      filteredNfts = filteredNfts.filter(
        (nft) =>
          nft.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
          nft.SK.toString().includes(searchTerm)
      );
      // console.log("filteredNfts-1", filteredNfts);
      // Filter po isListedForSale
      if (sortBy === "IsListedForSale" || sortBy === "IsRemovedFromSale") {
        const isListed = sortBy === "IsListedForSale";
        filteredNfts = filteredNfts.filter(
          (nft) => nft.isListedForSale === isListed
        );
      }

      // console.log("filteredNfts-2", filteredNfts);
      filteredNfts = filteredNfts.sort((a, b) => {
        switch (sortBy) {
          case "PostingDate":
            return b.timestamp - a.timestamp;
          case "lowToHigh":
            return parseFloat(a.purchaseCost) - parseFloat(b.purchaseCost);
          case "highToLow":
            return parseFloat(b.purchaseCost) - parseFloat(a.purchaseCost);
          case "IsListedForSale":
            return b.isListedForSale - a.isListedForSale;
          case "IsRemovedFromSale":
            return a.isListedForSale - b.isListedForSale;
          default:
            return b.timestamp - a.timestamp;
        }
      });

      // Dodatno filtriranje po kategoriji, tagovima i ceni
      filteredNfts = filteredNfts.filter((nft) => {
        const isCategoryMatch =
          category === "all" ||
          nft.category.toLowerCase() === category.toLowerCase();

        const isTagMatch =
          tags === "all" ||
          (nft.tags &&
            Array.isArray(nft.tags) &&
            nft.tags.some(
              (tag) =>
                tag &&
                tag.value &&
                tag.value.toLowerCase() === tags.toLowerCase()
            ));
        const isPriceInRange =
          nft.purchaseCost >= sliderValues.slide1 &&
          nft.purchaseCost <= sliderValues.slide2;

        return isCategoryMatch && isTagMatch && isPriceInRange;
      });
      // console.log(filteredNfts);
      setVisibleNFTsCount(filteredNfts.length);
      const displayedNfts = filteredNfts.slice(0, displayedNftsCount);
      setAuthorNftsFiltered(filteredNfts);
    }
  };

  useEffect(() => {
    calculateAuthorsNftsCount();
  }, [
    searchTerm,
    sortBy,
    category,
    tags,
    sliderValues,
    displayedNftsCount,
    address,
  ]);

  const filterCollectionsBySearchTerm = (collections, searchTerm) => {
    const filteredCollections = collections.filter((collection) =>
      collection.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    return filteredCollections;
  };

  const handleLoadMore = () => {
    getNftsByAuthor(address, 40);
  };

  return (
    <div className="relative bg-[#151c25]">
      <div className="exploreBG">
        {/* ---------------- COVER AND INFO ---------------- */}
        <div className="h-[450px] relative">
          <img
            loading="lazy"
            decoding="async"
            src={authorInfo?.bannerImage}
            alt="Author Banner"
            className="w-full h-full object-cover"
          />

          {/* Background Overlay */}
          <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-[#040507] to-transparent opacity-70"></div>
        </div>

        {/* ---------------- SOCIAL ---------------- */}
        <div className="w-full flex flex-col sm:flex-row items-center sm:items-end sm:pl-12 border-b border-t border-[#21e786] bg-[#090c0cdb] py-6 cursor-default relative flex-wrap">
          <div className="z-[20] h-full flex justify-center md:justify-start items-start w-[37%]">
            {/* PROFILE IMG and ADDR */}

            <div className="rounded-full bg-[#040507] border-[#21e786] w-32 h-32 mt-[-50px] mb-[100px] border-4 z-[20]">
              <div className="text-[#fff] flex z-[20] items-center">
                <LazyLoadImage
                  src={authorInfo?.authorPhoto || TopSeller6}
                  loading="lazy"
                  effect="blur"
                  decoding="async"
                  alt="author photo"
                  className="rounded-full border border-[#040507]"
                />
              </div>
              <div className="w-full min-[700px]:w-[300px]">
                <p className="text-2xl md:text-4xl font-Bakbak text-[#fff] pt-3 cursor-default">
                  {authorInfo?.name.length > maxTitleLength
                    ? authorInfo?.name.substring(0, maxTitleLength) + "..."
                    : authorInfo?.name}
                </p>
                <p className="text-xl font-bold text-[#fff] pt-3 cursor-default w-[300px] sm:w-full">
                  <span>Total NFTs: {nfts.length}</span>
                </p>
                <p className="text-xl font-bold text-[#fff] pt-1 cursor-default w-[300px] sm:w-full">
                  <span>Total Collections: {collectionsInfo?.length}</span>
                </p>
              </div>
            </div>
          </div>
          {/* MIDDLE INFO AND SOCIAL */}
          <div className="w-full sm:w-1/2 justify-center sm:justify-between text-center text-white flex items-center flex-col min-[1322px]:flex-row ml-3">
            {/* ---- BIO ---- */}
            <div className="flex flex-col items-center justify-start">
              <div className="text-[#fff] text-center flex flex-col mt-2 w-full pb-3">
                <p className="text-md font-bold mt-12 sm:mt-0">
                  {window.innerWidth < 768 ? shortenAddress(address) : address}
                </p>
              </div>
              {/* Modal toggle */}
              {authorInfo?.bio && (
                <button
                  type="button"
                  onClick={toggleModal}
                  className="shadow-xl shadow-black text-[#fff] hover:text-black
                  bg-[#e32970] hover:bg-[#21e786] md:text-[24px] py-2 px-[15px]
                    cursor-pointer font-Bakbak w-[300px] mt-3 transition-all ease-out duration-300"
                >
                  Bio
                </button>
              )}
              <div className="w-full text-center">
                {connectedAccount &&
                  connectedAccount.toLocaleLowerCase() ===
                    address.toLocaleLowerCase() && (
                    <Link to={"/create-nft"}>
                      <button
                        className="shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#e32970] text-[20px] md:text-[24px] py-2 px-[15px]
                    cursor-pointer font-Bakbak w-[300px] mb-[-20px] mt-3 transition-all ease-out duration-300"
                      >
                        Create NFT
                      </button>
                    </Link>
                  )}
              </div>
              {/* Main modal */}
              {isModalOpen && (
                <div
                  className="fixed z-50 inset-0 flex justify-center items-center w-full md:inset-0 h-[calc(100%-1rem)] max-h-full overflow-y-auto overflow-x-hidden"
                  tabIndex="-1"
                  aria-hidden="true"
                >
                  <div className="relative p-4 w-full max-w-2xl max-h-full">
                    {/* Modal content */}
                    <div className="relative bg-[#1e90ff] text-white rounded-lg shadow dark:[#1e90ff]">
                      {/* Modal header */}
                      <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                        <h3 className="text-xl font-semibold dark:text-white">
                          Artist's Biography
                        </h3>

                        <button
                          type="button"
                          onClick={toggleModal}
                          className="text-white bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                          data-modal-hide="static-modal"
                        >
                          <svg
                            className="w-3 h-3"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 14 14"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                            />
                          </svg>
                          <span className="sr-only">Close modal</span>
                        </button>
                      </div>
                      {/* Modal body */}
                      <div className="p-4 md:p-5 space-y-4">
                        <p className="text-base leading-relaxed text-gray-200 dark:text-gray-300">
                          {authorInfo.bio}
                        </p>
                      </div>
                      {/* Modal footer */}
                      <div className="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600 justify-end">
                        <button
                          onClick={toggleModal}
                          type="button"
                          className="ms-3 text-[18px] text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 font-Bakbak px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600"
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="flex-col justify-center">
              <div className="flex flex-col items-center">
                {authorInfo?.externalLink && (
                  <Link
                    to={`${authorInfo?.externalLink}`}
                    className="text-xl font-bold w-full z-50 flex items-center mt-4 xl:mt-0"
                    target="_blank"
                    rel="nofollow noopener noreferrer external"
                  >
                    <span className="mr-[10px] text-3xl">
                      <AiOutlineGlobal />
                    </span>
                    <p className="text-xl font-bold w-full">
                      {window.innerWidth < 1759
                        ? `${authorInfo?.externalLink.substring(0, 10)}...`
                        : authorInfo?.externalLink}
                    </p>
                  </Link>
                )}

                {/* SOCIAL MEDIA*/}
                <div className="h-16 flex items-center cursor-pointer z-[20]">
                  {authorInfo?.instagram && (
                    <Link
                      className="sun mode-switch"
                      to={authorInfo?.Item?.instagram}
                      target="_blank"
                      rel="nofollow noopener noreferrer external"
                    >
                      <FaInstagram className="text-3xl text-[#fff] mr-3 hover:text-[#21e786] transition-all duration-300" />
                    </Link>
                  )}
                  {authorInfo?.twitter && (
                    <Link
                      className="sun mode-switch"
                      to={authorInfo?.twitter}
                      target="_blank"
                      rel="nofollow noopener noreferrer external"
                    >
                      <FaXTwitter className="text-3xl text-[#fff] ml-3 hover:text-[#21e786] transition-all duration-300" />
                    </Link>
                  )}
                  {authorInfo?.facebook && (
                    <Link
                      className="sun mode-switch"
                      to={authorInfo?.facebook}
                      target="_blank"
                      rel="nofollow noopener noreferrer external"
                    >
                      <FaFacebookF className="text-3xl text-[#fff] ml-3 hover:text-[#21e786] transition-all duration-300" />
                    </Link>
                  )}
                  {authorInfo?.skype && (
                    <Link
                      className="sun mode-switch"
                      to={authorInfo?.skype}
                      target="_blank"
                      rel="nofollow noopener noreferrer external"
                    >
                      <FaSkype className="text-3xl text-[#fff] ml-3 hover:text-[#21e786] transition-all duration-300" />
                    </Link>
                  )}
                </div>
              </div>
              <p className="flex items-center text-[#fff] text-lg">
                {authorInfo?.timestamp && (
                  <span className="text-right">
                    Joined -
                    {moment(authorInfo?.timestamp * 1000).format(
                      " DD.MMM.YY HH:mm:ss"
                    )}{" "}
                  </span>
                )}
              </p>
            </div>
          </div>
          <div className="flex flex-col"></div>
        </div>
      </div>
      <div className="planetBg relative">
        {isAuthorVerified && (
          <button className="ml-6  border rounded-lg font-Bakbak bg-[#21e786] border-[#21e786] flex items-center px-4 py-1 text-black m-2 absolute right-[0]">
            VERIFIED{" "}
            <span className="inline-block p-1 text-sm font-semibold leading-none text-white bg-[#483d8b] hover:bg-[#322a61] rounded-full align-top ml-1 mt-[-5px]">
              <MdOutlineVerifiedUser />
            </span>
          </button>
        )}
        {/* ---------------- FILTER ---------------- */}
        <div className="w-full flex justify-center">
          <div className="w-[70%] pt-12 flex items-center justify-around">
            {/* SEARCH */}
            <div className="flex-grow mb-8">
              <div className="relative flex flex-col md:flex-row items-center w-full m-auto my-[15px] justify-center">
                <input
                  type="search"
                  id="default-search"
                  className="block w-full md:w-2/3 p-2 text-lg text-[#21e786] border rounded-lg bg-transparent"
                  placeholder="Search Nft by Title, ID or Author address..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  required
                />
                <button
                  type="submit"
                  className="shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] text-[20px] md:text-[24px] py-2 px-[45px] ml-5 mt-6 md:mt-0
                    cursor-pointer font-Bakbak"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* ---------------- ARTWORKS ---------------- */}

        {collectionsInfo.length > 0 && (
          <div>
            <h1 className="text-center py-12 text-[#fff] cursor-default text-5xl">
              COLLECTIONS
            </h1>
            <CustomScroll>
              <div className="overflow-x-auto overflow-y-auto max-h-[480px] px-4">
                <table className="min-w-full bg-[#0c0c0c] text-white border border-[#0c0c0c]">
                  <thead>
                    <tr className="border-b border-t border-[#21e786]">
                      <th className="px-2 py-3 text-center">Image</th>
                      <th className="px-2 py-3 text-center">Name</th>
                      <th className="px-2 py-3 text-center">Count NFTs</th>
                      <th className="px-2 py-3 text-center">Floor price</th>
                      <th className="px-2 py-3 text-center">Owner</th>
                      <th className="px-2 py-3 text-center">DUBX</th>
                      <th className="px-2 py-3 text-center">Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filterCollectionsBySearchTerm(
                      collectionsInfo,
                      searchTerm
                    ).map((collection, index) => (
                      <tr key={index} className="border-b">
                        <td className="px-2 py-3 w-24 h-24">
                          <Link
                            to={`/collection/${collection.contract}`}
                            className="block"
                          >
                            <img
                              src={`${collection.imageURI}`}
                              alt={`Collection ${index}`}
                            />
                          </Link>
                        </td>
                        <td className="px-2 py-3 text-center">
                          <Link to={`/collection/${collection.contract}`}>
                            {collection.title}
                          </Link>
                        </td>
                        <td className="px-2 py-3  text-center">
                          <Link to={`/collection/${collection.contract}`}>
                            {collection.nftCount}
                          </Link>
                        </td>
                        <td className="px-2 py-3 text-center">
                          <Link
                            to={`/collection/${collection.contract}`}
                            className="block"
                          >
                            {collection.floor.toFixed(4)}
                          </Link>
                        </td>
                        <td className="px-2 py-3 text-center">
                          <Link
                            to={`/collection/${collection.contract}`}
                            className="block"
                          >
                            {window.innerWidth < 768
                              ? shortenAddress(collection.owner)
                              : collection.owner}
                          </Link>
                        </td>
                        <td className="px-2 py-3 text-center">
                          <Link
                            to={`/collection/${collection.contract}`}
                            className="block"
                          >
                            {collection.totalCost.toFixed(4)}
                          </Link>
                        </td>
                        <td className="px-2 py-3 text-center">
                          <Link
                            to={`/collection/${collection.contract}`}
                            className="block"
                          >
                            {moment(collection.date * 1000).format(
                              "DD.MMM.YY HH:mm:ss"
                            )}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CustomScroll>
          </div>
        )}

        <h1 className="text-center pt-12 text-[#fff] cursor-default text-5xl">
          NFTs
        </h1>

        <div className="w-full flex flex-col sm:flex-row text-white mt-12 mb-12">
          {nfts.length > 0 && (
            <div className="flex">
              <style>{rangeInputStyles}</style>
              <nav className="flex flex-col shadow-md filterVideo">
                {/*  ---------------- FILTER ---------------- */}

                <div className="w-full sm:w-64">
                  <div className="px-8 sm:px-4 flex flex-col items-center">
                    {/* CATEGORY */}
                    <div className="flex-grow w-full">
                      <label
                        htmlFor="category"
                        className="text-sm font-medium text-white"
                      >
                        Category
                      </label>
                      <select
                        id="category"
                        className="block w-full text-sm text-slate-500 mt-2 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                        name="category"
                        onChange={handleCategoryChange}
                        value={category}
                        required
                      >
                        <option value="all">Select Category</option>
                        <option value="Illustration">Illustration</option>
                        <option value="Art">Art</option>
                        <option value="Sport">Sport</option>
                        <option value="Music">Music</option>
                        <option value="Animation">Animation</option>
                        <option value="Gaming">Gaming</option>
                        <option value="3D">3D</option>
                        <option value="real-estate">Real Estate</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>

                    {/* TAGS */}
                    <div className="flex-grow w-full my-8">
                      <label
                        htmlFor="tags"
                        className="text-sm font-medium text-white"
                      >
                        Tags
                      </label>
                      <select
                        id="tags"
                        className="block w-full mt-2 text-sm text-slate-500 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                        name="tags"
                        onChange={(e) => setTags(e.target.value)}
                        value={tags}
                        required
                      >
                        <option value="all">Select Tag</option>
                        <option value="Cyber">Cyber</option>
                        <option value="Cosmic">Cosmic</option>
                        <option value="Digital">Digital</option>
                        <option value="Magic">Magic</option>
                        <option value="Electric">Electric</option>
                        <option value="Animal">Animal</option>
                        <option value="Pixel">Pixel</option>
                        <option value="VirtualReality">VirtualReality</option>
                        <option value="Nature">Nature</option>
                        <option value="Underwater">Underwater</option>
                        <option value="VinylRecords">VinylRecords</option>
                        <option value="Icons">Icons</option>
                        <option value="Extreme">Extreme</option>
                        <option value="Portrait">Portrait</option>
                        <option value="Legend">Legend</option>
                        <option value="Retro">Retro</option>
                        <option value="Anime">Anime</option>
                        <option value="Fantasy">Fantasy</option>
                        <option value="Abstract">Abstract</option>
                        <option value="Characters">Characters</option>
                        <option value="Interactive">Interactive</option>
                        <option value="Virtual">Virtual</option>
                        <option value="Mistery">Mistery</option>
                        <option value="Food">Food</option>
                        <option value="AI">AI</option>
                        <option value="Recipe">Recipe</option>
                        <option value="Clothes">Clothes</option>
                        <option value="Landscape">Landscape</option>
                        <option value="Celebrities">Celebrities</option>
                        <option value="Robot">Robot</option>
                        <option value="Vehicles">Vehicles</option>
                        <option value="Luxury">Luxury</option>
                        <option value="Accessory">Accessory</option>
                        <option value="Toys">Toys</option>
                        <option value="Fitness">Fitness</option>
                        <option value="Remix">Remix</option>
                      </select>
                    </div>

                    {/* SORT BY */}
                    <div className="flex-grow w-full mb-8">
                      <label
                        htmlFor="sort"
                        className="text-sm font-medium text-white"
                      >
                        Sort by
                      </label>
                      <select
                        id="sort"
                        className="block w-full text-sm mt-2 text-slate-500 border rounded-lg focus:outline-none focus:ring-0 p-[7px]"
                        name="sort"
                        onChange={(e) => setSortBy(e.target.value)}
                        value={sortBy}
                        required
                      >
                        <option value="PostingDate">
                          Posting Date (Newest)
                        </option>
                        <option value="lowToHigh">Price Low to High</option>
                        <option value="highToLow">Price High to Low</option>
                        <option value="IsListedForSale">Listed For Sale</option>
                        <option value="IsRemovedFromSale">
                          Removed From Sale
                        </option>
                      </select>
                    </div>

                    {/* PRICE RANGE */}
                    <div className="range-slider">
                      <p className="text-white">Price Range</p>
                      <span className="text-white" id="rangeValues"></span>
                      <input
                        value={sliderValues.slide1}
                        min={minMax.min}
                        max={minMax.max}
                        step="0.0001"
                        type="range"
                        onChange={(e) => handleSliderChange(e, "slide1")}
                      />
                      <input
                        value={sliderValues.slide2}
                        min={minMax.min}
                        max={minMax.max}
                        step="0.0001"
                        type="range"
                        onChange={(e) => handleSliderChange(e, "slide2")}
                      />
                      <div className="flex justify-between mt-[10px]">
                        <span className="text-sm text-gray-500">
                          {sliderValues.slide1} DUBX
                        </span>
                        <span className="text-sm text-gray-500">
                          {sliderValues.slide2} DUBX
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          )}
          {/* ---------------- NFTS ---------------- */}
          <div className="w-full flex flex-col lg:flex-row items-center justify-center flex-wrap">
            {authorNftsFiltered.length > 0 ? (
              authorNftsFiltered.map((nft) => {
                return (
                  <div
                    key={nft.PK + nft.SK}
                    className="w-[330px] mt-6 flex flex-col mx-4 relative"
                    style={{ minHeight: "330px" }}
                  >
                    {nft.collection_image && nft.collection_contract && (
                      <div
                        className="w-[70px] h-[70px] bg-transparent absolute right-[-30px] top-[-30px]"
                        style={{ zIndex: "5" }}
                      >
                        <Link to={`/collection/${nft.collection_contract}`}>
                          <figure className="max-h-[70px] max-w-[70px] rounded-md">
                            <LazyLoadImage
                              src={nft.collection_image}
                              loading="lazy"
                              decoding="async"
                              alt={nft.PK + nft.SK}
                              className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105 rounded-md"
                            />
                          </figure>
                        </Link>
                      </div>
                    )}
                    <div className="block w-full h-full tf-work tf-product style-2 overflow-hidden bg-[#141b22] shadow-lg p-[5px] group ">
                      <Link to={`/n/${nft.contract}/${nft.SK}`}>
                        <figure className="coverIMG">
                          <LazyLoadImage
                            src={nft.imageURI}
                            loading="lazy"
                            decoding="async"
                            alt={nft.title}
                            className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105"
                          />
                        </figure>
                      </Link>
                      <div className="flex mt-3 border-b">
                        <h2 className="font-bold mt-2 text-xl mb-3 text-center text-[#21e786]">
                          <Link to={`/n/${nft.contract}/${nft.SK}`}>
                            <span>
                              {nft.title.length > maxTitleLength
                                ? nft.title.substring(0, maxTitleLength) + "..."
                                : nft.title}
                            </span>{" "}
                          </Link>
                          <Link to={`/author/${nft.owner}`}>
                            <span>#{nft.SK}</span>
                          </Link>
                        </h2>
                      </div>

                      <div className="tooltip-container">
                        <div className="flex mt-3 justify-between border-b pb-2">
                          <Link to={`/author/${nft.owner}`}>
                            <p className="text-white font-Bakbak">
                              {shortenAddress(nft.owner)}
                            </p>
                          </Link>
                          <Link to={`/n/${nft.contract}/${nft.SK}`}>
                            <p className="text-white">
                              {nft.purchaseCost} DUBX
                            </p>
                          </Link>
                        </div>
                        <div className="tooltip-content">{nft.owner}</div>
                      </div>
                      <Link to={`/n/${nft.contract}/${nft.SK}`}>
                        <div className="text-center text-lg mt-3 bg-[#21e786] text-black font-Bakbak hover:bg-[#f10cea] hover:text-white transition-all duration-300">
                          <button className="font-Bakbak py-2">DETAILS</button>
                        </div>
                      </Link>
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="text-4xl font-semibold">No NFTs available</p>
            )}
          </div>
        </div>
        {!allNftsLoaded ? (
          <div className="flex items-center justify-center mt-8">
            <button
              className="shadow-xl shadow-black text-[#000] hover:text-white
              bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-3 px-[15px]
                cursor-pointer font-Bakbak w-[250px]"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default UserInfo;
