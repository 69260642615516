import React, { useState, useEffect } from "react";
import { useGlobalState } from "../store";
import { Link } from "react-router-dom";
import { shortenAddress } from "../Blockchain.Services";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import CookingLoader from "./CookingLoader";

const NftsTape = () => {
  const [nfts, setNfts] = useGlobalState("nfts");
  const [isLoadCooking, setIsLoadCooking] = useState(true);
  const [maxTitleLength, setMaxTitleLength] = useState(20);

  useEffect(() => {
    const initSlider = () => {
      if (nfts && nfts.length > 0) {
        // console.log(nfts);
        setIsLoadCooking(false);
      }
    };
    initSlider();
  }, [nfts]);

  return (
    <section className="ticker flex-wrap">
      {/* <div className="ticker-tape">
        <div className="ticker-tape-scroll">
          <div className="ticker-tape-collection"> */}
      {nfts && nfts.length > 0 && (
        <>
          {nfts.slice(0, 20).map((nfts) => (
            <div
              className="ticker-tape-story relative"
              key={`${nfts.contract}/${nfts.SK}`}
            >
              {nfts.collection_image && nfts.collection_contract && (
                <div
                  className="w-[70px] h-[70px] bg-transparent absolute right-[-30px] top-[-30px]"
                  style={{ zIndex: "5" }}
                >
                  <Link to={`/collection/${nfts.collection_contract}`}>
                    <figure className="max-h-[70px] max-w-[70px] rounded-md">
                      <LazyLoadImage
                        src={nfts.collection_image}
                        loading="lazy"
                        decoding="async"
                        alt={nfts.collectionId}
                        className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105 rounded-md"
                      />
                    </figure>
                  </Link>
                </div>
              )}
              <div className="block w-full h-full tf-work tf-product style-2 overflow-hidden bg-[#141b22] shadow-lg p-[5px] group">
                <Link to={`/n/${nfts.contract}/${nfts.SK}`}>
                  <figure className="coverIMG">
                    <LazyLoadImage
                      src={nfts.imageURI}
                      loading="lazy"
                      decoding="async"
                      alt={nfts.title}
                      className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105"
                    />
                  </figure>
                  <div className="flex mt-3 border-b justify-center">
                    <h2 className="font-Bakbak mt-2 text-xl mb-3 text-center text-[#21e786] max-w-[300px] overflow-hidden break-words twoCol">
                      <span>
                        {nfts.title.length > maxTitleLength
                          ? nfts.title.substring(0, maxTitleLength) + "..."
                          : nfts.title}
                      </span>{" "}
                      <span>#{nfts.SK}</span>
                    </h2>
                  </div>
                </Link>
                <div className="tooltip-container">
                  <Link to={`/author/${nfts.owner}`}>
                    <div className="flex mt-3 justify-between border-b pb-2">
                      <p className="text-white font-Bakbak">
                        {shortenAddress(nfts.owner)}
                      </p>
                      <p className="text-white">
                        {nfts.purchaseCost.toFixed(4)} DUBX
                      </p>
                    </div>
                  </Link>
                  <div
                    className="tooltip-content"
                    style={{
                      left: "38% !important",
                      bottom: "46px",
                    }}
                  >
                    {nfts.owner}
                  </div>
                </div>
                <Link to={`/n/${nfts.contract}/${nfts.SK}`}>
                  <div className="text-center text-lg mt-3 bg-[#21e786] text-black font-Bakbak hover:bg-[#f10cea] hover:text-white transition-all duration-300">
                    <button type="button" className="py-2">
                      DETAILS
                    </button>
                  </div>
                </Link>
              </div>
            </div>
          ))}
        </>
      )}
      {/* </div>
        </div>
      </div> */}
    </section>
  );
};

export default NftsTape;
