import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import RocketIMG from "../assets/rocket.png";
import PlayButtonImg from "../assets/playIconMini.png";
import PlanetImg from "../assets/planetMini.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useInView } from "react-intersection-observer";

const About = () => {
  const [totalItems, setTotalItems] = useState(0);
  const [whitelistedProfiles, setWhitelistedProfiles] = useState(0);
  const [ref, inView] = useInView({ triggerOnce: true });

  useEffect(() => {
    if (inView) {
      const animateNumbers = () => {
        setTotalItems(240);
        setWhitelistedProfiles(80);
      };

      animateNumbers();
    }
  }, [inView]);

  return (
    <div
      ref={ref}
      className="backgroundAboutUs border-t border-b border-[#21e786]"
    >
      {/* ROCKET */}
      <div className="absolute z-[1] left-[11%] pt-[5%] opacity-25 hidden sm:block">
        <LazyLoadImage
          effect="blur"
          src={RocketIMG}
          loading="lazy"
          decoding="async"
          alt="RocketIMG"
          className="rotateRocket"
        />
      </div>

      {/* PLAY BUTTON */}
      <div className="absolute z-[1] left-[8%] pt-[6%] userBounceIMG opacity-25">
        <LazyLoadImage
          effect="blur"
          src={PlayButtonImg}
          loading="lazy"
          decoding="async"
          alt="PlayButton"
          className="rotateRocket"
        />
      </div>
      {/* PLANET MINI */}
      <div className="absolute z-[1] left-[85%] pt-[12%] userBounceIMG opacity-25">
        <LazyLoadImage
          effect="blur"
          src={PlanetImg}
          loading="lazy"
          decoding="async"
          alt="PlanetIMG"
          className="rotateRocket"
        />
      </div>
      <div className="text-center text-white w-full flex flex-col justify-around items-center m-auto cursor-default z-50">
        <h1 className="text-5xl font-bold text-[#21e786] mt-16">ABOUT US</h1>
        <p className="w-[80%] md:1/3 py-12 text-xl font-semibold">
          Introducing DUBX NFT MARKETPLACE, where premium NFT artworks are
          created and traded. We provide a secure environment for fast and
          reliable transactions. Explore unique collections and join the dynamic
          NFT community.
        </p>
        <div className="xl:w-1/3 flex text-center justify-around pb-32 pt-12">
          <div className="flex flex-col">
            <h2 className="font-bold text-xl text-left">Total Items</h2>
            <CountUp
              className="text-5xl font-bold imgGlow text-[#fff]"
              end={totalItems}
              duration={4}
            />
          </div>
          <div>
            <h2 className="font-bold text-xl text-left">Artists</h2>
            <CountUp
              className="text-5xl font-bold imgGlow text-[#fff]"
              end={whitelistedProfiles}
              duration={4}
            />
          </div>
        </div>
        <div className="flex flex-col xl:flex-row justify-around pb-12 cursor-pointer containe items-center">
          {/* ---------------- 1 ---------------- */}
          <div className="w-[300px] md:w-[370px]">
            <div className="tf-work style-2 wow fadeInUp h-[300px] myshadow">
              <h2 className="text-8xl text-[#21e786] font-bold pt-2 mb-[-120px]">
                01
              </h2>
              <h3 className="text-center text-white font-bold text-2xl pt-24 pb-6 imgHover">
                HIGH QUALITY
              </h3>
              <p className="px-12">
                Every NFT item is of high quality, offering exceptional
                craftsmanship and artistic value.
              </p>
            </div>
          </div>
          {/* ---------------- 2 ---------------- */}
          <div className="w-[300px] md:w-[370px] lg:mx-3">
            <div className="tf-work style-2 wow fadeInUp h-[300px] myshadow">
              <h2 className="text-8xl text-[#21e786] font-bold pt-2 mb-[-120px]">
                02
              </h2>
              <h3 className="text-center text-white font-bold text-2xl pt-24 pb-6 imgHover">
                SECURE
              </h3>
              <p className="px-12">
                Secure transactions priority. Robust security measures ensure
                protected environment NFT tx.
              </p>
            </div>
          </div>
          {/* ---------------- 3 ---------------- */}
          <div className="w-[300px] md:w-[370px]">
            <div className="tf-work style-2 wow fadeInUp h-[300px] myshadow">
              <h2 className="text-8xl text-[#21e786] font-bold pt-2 mb-[-120px]">
                03
              </h2>
              <h3 className="text-center text-white font-bold text-2xl pt-24 pb-6 imgHover">
                INSTANT
              </h3>
              <p className="px-12">
                Instant payment ensures immediate funds for seamless
                transactions. Secure and hassle-free.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
