import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import detectEthereumProvider from "@metamask/detect-provider";
import logo from "../assets/gradientLogo.webp";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { checkIsAccVerified } from "../Blockchain.Services";
import {
  useGlobalState,
  truncate,
  setGlobalState,
  getGlobalState,
} from "../store";
import MessageModal from "./MessageModal";

import { Breadcrumbs } from "@material-tailwind/react";
import ProfileSelectNav from "./ProfileSelectNav";

const Header = () => {
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const [nav, setNav] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);
  const [chainIdChangedState, setChainIdChangedState] = useState(false);
  const [isAccVerified, setIsAccVerified] = useGlobalState("isAccountVerified");
  const { ethereum } = window;
  const handleNav = () => {
    setNav(!nav);
  };
  const handleShowMessageModal = (newMessage) => {
    setInfoMessage(newMessage);
    setShowMessageModal(true);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };
  useEffect(() => {
    const handleAccountChange = (accounts) => {
      const newAccount = accounts[0]?.toLowerCase() || null;
      setGlobalState("connectedAccount", newAccount);
      setConnectedAccount(newAccount);
    };
    if (ethereum) {
      window.ethereum.on("accountsChanged", handleAccountChange);
      return () => {
        window.ethereum.off("accountsChanged", handleAccountChange);
      };
    } else {
      console.warn("MetaMask (window.ethereum) not available.");
    }
  }, [connectedAccount]);
  useEffect(() => {
    const handleChainIdChange = () => {
      // try {
      //   const provider = await detectEthereumProvider();
      //   console.log(provider);

      //   if (provider) {
      //     const chainId = await window.ethereum.request({
      //       method: "eth_chainId",
      //     });
      //     console.log(chainId);
      //   }} catch (error) {
      //   console.error("Error during provider detection:", error);
      // }
      if (window.ethereum.chainId === "0xcc5") {
        setChainIdChangedState(false);
      } else {
        setChainIdChangedState(true);
      }
    };
    if (ethereum) {
      window.ethereum.on("chainChanged", handleChainIdChange);

      return () => {
        ethereum.off("chainChanged", handleChainIdChange);
      };
    } else {
      console.warn("MetaMask (ethereum) not available.");
    }
    // }, [window.location.pathname]);
  }, []);

  const addDubxNetwork = async () => {
    try {
      const result = await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [
          {
            chainId: "0xcc5",
            rpcUrls: ["https://rpcmain.arabianchain.org"],
            chainName: "Dubx",
            nativeCurrency: {
              name: "DUBX",
              symbol: "DUBX",
              decimals: 18,
            },
            blockExplorerUrls: ["https://explorer.arabianchain.org/#/"],
          },
        ],
      });

      // console.log("Dubx network added to MetaMask:", result);
      if (result === null) {
        console.log("Dubx network added to MetaMask.");
        return true;
      } else {
        console.log("Failed to add to Dubx network.");
        return false;
      }
    } catch (error) {
      if (error.code === 4902 || error.code === -32603) {
        console.log("not added");
        return false;
      } else if (error.code === 4001) {
        console.log("user rejected");
        console.log("Error adding Dubx network to MetaMask:", error);
        return false;
      } else {
        console.log("Error connecting to wallet:", error);
        return false;
      }
    }
  };
  const switchDubxNetwork = async () => {
    try {
      const result = await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: "0xcc5",
          },
        ],
      });

      // console.log("Switched to Dubx network.", result);
      if (result === null) {
        console.log("Dubx network added to MetaMask.");
        return true;
      } else {
        console.log("Failed to switch to Dubx network.");
        return false;
      }
    } catch (error) {
      if (error.code === 4902 || error.code === -32603) {
        console.log("not added");
        return false;
      } else if (error.code === 4001) {
        console.log("user rejected");
        console.log("Error adding Dubx network to MetaMask:", error);
        return false;
      } else {
        console.log("Error connecting to wallet:", error);
        return false;
      }
    }
  };

  const handleConnectWallet = async () => {
    try {
      if (ethereum) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId === "0xcc5") {
          const accounts = await ethereum.request({
            method: "eth_requestAccounts",
          });
          if (accounts && accounts.length > 0) {
            const myAccount = accounts[0].toLowerCase();
            setGlobalState("connectedAccount", myAccount);
            setConnectedAccount(myAccount);
            handleShowMessageModal(`Account ${myAccount} is connected.`, false);
          }
        } else {
          let switchedDubxNetwork = false;
          try {
            switchedDubxNetwork = await switchDubxNetwork();
          } catch (switchError) {
            console.log("Failed to switch to Dubx network:", switchError);
          }
          if (!switchedDubxNetwork) {
            console.log("Dubx network not available, trying to add it.");
            try {
              const addedDubxNetwork = await addDubxNetwork();
              if (addedDubxNetwork) {
                console.log("Dubx network added to MetaMask.");
                handleShowMessageModal(
                  "Added Dubx network. Click Connect Wallet again and carefully check which account is connected.",
                  false
                );
              } else {
                console.log("Failed to add Dubx network to MetaMask.");
                handleShowMessageModal(
                  "Failed to add Dubx network to MetaMask.",
                  false
                );
              }
            } catch (addError) {
              console.log("Error adding Dubx network to MetaMask:", addError);
              handleShowMessageModal(
                "Error adding Dubx network to MetaMask.",
                false
              );
            }
          } else {
            handleShowMessageModal(
              "Switched to Dubx network. Click Connect Wallet again and carefully check which account is connected.",
              false
            );
          }
          // if (chainId === "0xcc5") {
          //   const accounts = await ethereum.request({
          //     method: "eth_requestAccounts",
          //   });
          //   if (accounts && accounts.length > 0) {
          //     const myAccount = accounts[0].toLowerCase();
          //     setGlobalState("connectedAccount", myAccount);
          //     setConnectedAccount(myAccount);
          //     console.log("account is connected with switch");
          //   }
          // } else {
          //   const addedDubxNetwork = await addDubxNetwork();
          //   console.log(addedDubxNetwork);
          //   if (chainId === "0xcc5") {
          //     const accounts = await ethereum.request({
          //       method: "eth_requestAccounts",
          //     });
          //     if (accounts && accounts.length > 0) {
          //       const myAccount = accounts[0].toLowerCase();
          //       setGlobalState("connectedAccount", myAccount);
          //       setConnectedAccount(myAccount);
          //       console.log("account is connected with add");
          //     }
          //   } else {
          //     console.log("some error occured, try again!");
          //     handleShowMessageModal("Some error occured, try again.", false);
          //   }
          // }
        }
      } else {
        handleShowMessageModal("Please install MetaMask", false);
      }
    } catch (error) {
      if (error.code === 4902 || error.code === -32603) {
        console.log("not added");
        handleShowMessageModal("Wallet not added.", false);
      } else if (error.code === 4001) {
        console.log("user rejected");
        handleShowMessageModal("User rejected the request.", false);
      } else {
        console.log("Error connecting to wallet:", error);
        handleShowMessageModal("Error connecting to wallet.", false);
      }
    }
  };

  const checkVerifiedAcc = async () => {
    try {
      if (ethereum && connectedAccount) {
        const result = await checkIsAccVerified();

        setGlobalState("isAccountVerified", result);
        setIsAccVerified(result);
      } else {
        setGlobalState("isAccountVerified", false);
        setIsAccVerified(false);
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };

  useEffect(() => {
    const checkIfConnected = async () => {
      if (!ethereum) {
        setGlobalState("connectedAccount", "");
        return;
      } else if (ethereum) {
        const chainId = await ethereum.request({ method: "eth_chainId" });

        if (
          ethereum &&
          ethereum._state.isUnlocked === true &&
          chainId === "0xcc5" &&
          ethereum._state.accounts[0]
        ) {
          setGlobalState(
            "connectedAccount",
            ethereum._state.accounts[0].toLowerCase()
          );
        } else {
          setGlobalState("connectedAccount", "");
        }
      }
      //   console.log("1");
      //   if (ethereum && ethereum._state.isUnlocked === true) {
      //     console.log("2");
      //     const chainId = await ethereum.request({ method: "eth_chainId" });
      //     console.log(chainId);
      //     if (chainId === "0xcc5") {
      //       console.log("3");
      //       const accounts = await ethereum.request({ method: "eth_accounts" });
      //       if (accounts && accounts.length > 0) {
      //         console.log("4");
      //         // Korisnik je već povezan, ažuriraj stanje aplikacije
      //         setGlobalState("connectedAccount", accounts[0].toLowerCase());
      //       }
      //     } else {
      //       // Korisnik nije na odgovarajućem lancu, možete prikazati poruku ili preusmjeriti korisnika
      //       console.log("Korisnik nije na odgovarajućem lancu.");
      //     }
      //   } else {
      //     console.log("Korisnik nije povezan.");
      //   }
    };

    checkIfConnected();
  }, []);

  useEffect(() => {
    if (ethereum && connectedAccount) {
      checkVerifiedAcc();
    }
  }, [connectedAccount]);

  const navigateToPage = (path) => {
    setNav(false);
    window.location.href = path;
  };
  return (
    <div className="flex justify-between items-center h-24 mx-auto text-white fixed z-50 w-full bg-[#151c25]">
      {/* LOGO */}
      <div className="flex justify-start w-[800px] items-center">
        <Link to="/">
          <div className="flex lg:flex-[0.5] justify-center items-center">
            <LazyLoadImage
              effect="blur"
              loading="lazy"
              decoding="async"
              alt="logo"
              src={logo}
              className="w-16 ml-4"
            />
            <h2
              className="text-xl md:text-md xl:text-[30px] ml-6 font-bold logoText flex headerTittleHidden"
              style={{ lineHeight: "2rem" }}
            >
              DUBX NFT MARKETPLACE
            </h2>
          </div>
        </Link>
      </div>

      {/* SEARCH I NAV DESKTOP */}
      <div className="w-full hidden lg:flex items-center justify-end">
        <Breadcrumbs className="bg-[#151c25] py-0">
          {/* NAV DESKTOP */}
          <ul className="hidden md:flex justify-center items-center">
            <li className="mx-0 lg:mx-2 text-white hover:text-[#21e786] transition-all duration-300 cursor-pointer text-[12px] lg:text-[24px] border-l border-r px-4 lg:px-3">
              <Link to="/">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
              </Link>
            </li>
            <li className="font-Bakbak z-50 relative mx-0 lg:mx-1 text-white hover:text-[#21e786] transition-all duration-300 cursor-pointer text-[20px] lg:text-[24px] border-r pr-4 pl-2 group">
              {/* <Link to="/explore/all"> */}
              <span>Explore</span>
              {/* </Link> */}
              <div className="hidden group-hover:block absolute top-full left-0 bg-[#151c25] border border-[#21e786]-200 py-2 mt-[6px] rounded-lg">
                <Link
                  to="/explore/all"
                  className="block px-4 py-2 pb-4 text-white border-b hover:text-[#21e786]"
                >
                  NFT
                </Link>
                <Link
                  to="/explore/collections"
                  className="block px-4 py-4 text-white hover:text-[#21e786]"
                >
                  Collections
                </Link>
                <Link
                  to="/explore/authors"
                  className="block px-4 py-4 text-white hover:text-[#21e786] border-t"
                >
                  Authors
                </Link>
              </div>
            </li>
            <li className="font-Bakbak relative mx-0 lg:mx-1 text-white hover:text-[#21e786] transition-all duration-300 cursor-pointer text-[20px] lg:text-[24px] border-r pr-4 pl-2 group">
              {/* <Link to="/explore/all"> */}
              <span>Create</span>
              {/* </Link> */}
              <ul className="hidden group-hover:block absolute top-full left-0 bg-[#151c25] border border-[#21e786]-200 py-2 mt-[6px] rounded-lg">
                <li className="mx-0 lg:mx-1 text-white hover:text-[#21e786] transition-all duration-300 cursor-pointer text-[20px] lg:text-[24px] border-b px-4 pb-4 pt-2">
                  <Link to="/create-nft">
                    <span>NFT</span>
                  </Link>
                </li>
                <li className="mx-0 lg:mx-1 text-white hover:text-[#21e786] transition-all duration-300 cursor-pointer text-[20px] lg:text-[24px] px-4 py-4">
                  <Link to="/create-collection">
                    <span>Collection</span>
                  </Link>
                </li>
              </ul>
            </li>

            <li className="mx-0 lg:mx-1 text-white hover:text-[#21e786] transition-all duration-300 cursor-pointer text-[20px] lg:text-[24px] px-4">
              <Link to="/contact">
                <span>Contact</span>
              </Link>
            </li>

            <li className="hidden lg:block mx-1 lg:mx-2 cursor-pointer text-[12px] lg:text-[20px]">
              {!chainIdChangedState &&
              connectedAccount &&
              connectedAccount.trim() ? (
                <button
                  className="shadow-xl shadow-black text-[#000]
                  bg-[#21e786] hover:bg-[#f10cea] hover:text-white md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak"
                >
                  {truncate(connectedAccount, 4, 4, 11)}
                  {isAccVerified && (
                    <span className="inline-block p-1 text-sm font-semibold leading-none text-white bg-[#483d8b] hover:bg-[#322a61] rounded-full align-top ml-1 mt-[-5px]">
                      <MdOutlineVerifiedUser />
                    </span>
                  )}
                </button>
              ) : (
                <button
                  className="shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-2 lg:py-3 px-[12px]
                    cursor-pointer font-Bakbak"
                  onClick={handleConnectWallet}
                >
                  Connect Wallet
                </button>
              )}
            </li>
            <li className="hidden lg:block mx-1 lg:mx-2 cursor-pointer text-[12px] lg:text-[20px] list-none relative py-2 pl-0.625 pb-1 navAcc w-12">
              <ProfileSelectNav account={connectedAccount} />
            </li>
          </ul>
        </Breadcrumbs>
      </div>

      {/* NAV MOBILE */}
      <div onClick={handleNav} className="block lg:hidden z-10 mr-[20px]">
        {nav ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      <ul
        className={
          nav
            ? "fixed left-0 top-0 w-full max-h-screen overflow-y-auto border-r border-r-gray-900 bg-[#151c25] ease-in-out duration-500"
            : "ease-in-out duration-500 fixed left-[-100%]"
        }
      >
        <h1 className="w-full text-3xl font-bold text-[#21e786] m-4">
          DUBX NFT
        </h1>
        <Link to="/" className="flex justify-center mt-12">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-8 w-8"
            viewBox="0 0 20 20"
            fill="currentColor"
            onClick={() => navigateToPage("/")}
          >
            <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
          </svg>
        </Link>

        <li
          className="p-3 text-center text-3xl font-bold"
          onClick={() => navigateToPage("/#/explore/all")}
        >
          Explore NFT
        </li>

        <li
          className="p-3 text-center text-3xl font-bold"
          onClick={() => navigateToPage("/#/explore/collections")}
        >
          Explore Collections
        </li>
        <li
          className="p-3 text-center text-3xl font-bold"
          onClick={() => navigateToPage("/#/explore/authors")}
        >
          Explore Authors
        </li>
        <li
          className="p-3 text-center text-3xl font-bold"
          onClick={() => navigateToPage("/#/create-nft")}
        >
          Create NFT
        </li>

        <li
          className="p-3 text-center text-3xl font-bold"
          onClick={() => navigateToPage("/#/create-collection")}
        >
          Create Collection
        </li>

        <li
          className="p-3 text-center text-3xl font-bold"
          onClick={() => navigateToPage("/#/contact")}
        >
          Contact
        </li>
        <li className="py-3 mx-1 lg:mx-2 cursor-pointer text-[20px] flex justify-center">
          {!chainIdChangedState &&
          connectedAccount &&
          connectedAccount.trim() ? (
            <button
              className="shadow-xl shadow-black text-[#000]
                  bg-[#21e786] hover:bg-[#f10cea] hover:text-white md:text-[24px] py-2 lg:py-3 px-[15px]
                    cursor-pointer font-Bakbak"
            >
              {truncate(connectedAccount, 4, 4, 11)}
              {isAccVerified && (
                <span className="inline-block p-1 text-sm font-semibold leading-none text-white bg-[#483d8b] hover:bg-[#322a61] rounded-full align-top ml-1 mt-[-5px]">
                  <MdOutlineVerifiedUser />
                </span>
              )}
            </button>
          ) : (
            <button
              className="shadow-xl shadow-black text-[#000] hover:text-white
                  bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-2 lg:py-3 px-[12px]
                    cursor-pointer font-Bakbak"
              onClick={handleConnectWallet}
            >
              Connect Wallet
            </button>
          )}
        </li>
        <li className="flex justify-center mx-1 lg:mx-2 cursor-pointer text-[16px] lg:text-[20px] list-none relative py-2 pl-0.625 pb-1 w-full">
          <div className="w-16">
            <ProfileSelectNav account={connectedAccount} closeNav={handleNav} />
          </div>
        </li>
      </ul>
      <div>
        <MessageModal
          message={infoMessage}
          show={showMessageModal}
          onClose={handleCloseMessageModal}
        />
      </div>
    </div>
  );
};

export default Header;
