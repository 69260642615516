import React, { useEffect, useState } from "react";
import moment from "moment/moment";
import { shortenAddress } from "../Blockchain.Services";
import BubblesFire from "../components/BubblesFire";
import { LazyLoadImage } from "react-lazy-load-image-component";
import ItemLoader from "../components/ItemLoader";
import TopSeller6 from "../assets/ai5.jpg";

import { Link } from "react-router-dom";

const Authors = () => {
  const [creators, setCreators] = useState([]);
  const [loading, setLoading] = useState(true);
  const [maxTitleLength, setMaxTitleLength] = useState(18);
  const [allCreatorsLoaded, setAllCreatorsLoaded] = useState(false);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const getNewAllAuthorsFromDB = async (limit) => {
    try {
      const isLoadingAuthors = true;
      let getAllAuthorsUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/author?limit=${limit}`;

      if (lastEvaluatedKey) {
        const { account, AUTHOR_SORT_KEY } = lastEvaluatedKey;
        const key = `lastElement=${account}:${AUTHOR_SORT_KEY}`;
        getAllAuthorsUrl = getAllAuthorsUrl + ";" + key;
      }

      const response = await fetch(getAllAuthorsUrl, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("data", data);
        if (lastEvaluatedKey) {
          const mergedCreators = [...creators, ...data.Items];
          setCreators(mergedCreators);
        } else {
          setCreators(data.Items);
        }
        setLastEvaluatedKey(data.LastEvaluatedKey);
        if (data.Items.length < limit) {
          setAllCreatorsLoaded(true);
        }
      } else {
        console.error(
          "Failed to get getAllAuthorsFromDB:",
          response.statusText
        );
        setCreators([]);
      }
    } catch (error) {
      console.error("Error getting getAllAuthorsFromDB:", error.message);
      setCreators([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getNewAllAuthorsFromDB(40);
    };
    fetchData();
  }, []);

  const handleLoadMore = () => {
    setLoading(true);
    getNewAllAuthorsFromDB(40);
  };

  return (
    <div className="min-h-screen h-full">
      <h1 className="mt-64 text-center font-Bakbak text-5xl font-bold text-[#21e786] hover:text-white transition-all duration-200 cursor-pointer">
        AUTHORS
      </h1>
      <div className=" my-32 w-full shadow-lg p-[5px] group  flex justify-around items-center flex-wrap">
        {loading && <ItemLoader />}
        {creators.length === 0 && !loading && (
          <div>
            <h2 className="min-h-screen underline pt-24 font-Bakbak text-5xl font-bold text-[#21e786] hover:text-white transition-all duration-200 cursor-pointer">
              No Authors
            </h2>
          </div>
        )}
        {creators.map((authors, index) => (
          <Link to={`/author/${authors.account}`} key={index}>
            <div className="relative myshadow">
              <p
                style={{ zIndex: "1" }}
                className="absolute top-[-22px] right-[-12px] bg-[#f10cea] text-white py-1 px-2 rounded-full text-xs font-Bakbak flex justify-center items-center"
              >
                NFTS:{" "}
                <span className="text-[25px] ml-[5px]">{authors.nftCount}</span>
              </p>
              <div
                className="w-[330px] mt-6 flex flex-col tf-work tf-product style-2 overflow-hidden"
                style={{ minHeight: "330px" }}
              >
                <figure className="coverIMG">
                  <LazyLoadImage
                    src={authors.authorPhoto || TopSeller6}
                    loading="lazy"
                    decoding="async"
                    alt={authors.name}
                    className="object-cover w-full h-full transition-transform transform-gpu group-hover:scale-105"
                  />
                </figure>

                <p className="authorTitle pt-3 font-Bakbak text-[24px]">
                  {authors.name.length > maxTitleLength
                    ? authors.name.substring(0, maxTitleLength) + "..."
                    : authors.name || shortenAddress(authors.account)}
                </p>
                <p className="text-white pt-3 font-bold">
                  {shortenAddress(authors.account)}
                </p>

                <div className="text-[#21e786] pt-3 font-bold relative flex justify-center items-center">
                  <span className="mr-2">Collections: </span>
                  <BubblesFire count={authors.collectionCount} />
                </div>

                <p className="text-white pt-3 font-bold">
                  Joined -
                  {moment(authors.timestamp * 1000).format(
                    " DD.MMM.YY HH:mm:ss"
                  )}{" "}
                </p>
              </div>
            </div>
          </Link>
        ))}
        {!allCreatorsLoaded ? (
          <div className="flex items-center justify-center mt-8 w-full">
            <button
              className="shadow-xl shadow-black text-[#000] hover:text-white
            bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-3 px-[15px]
              cursor-pointer font-Bakbak w-[250px] z-[20]"
              onClick={handleLoadMore}
            >
              Load More
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Authors;
