import React from "react";
import logo from "../assets/gradientLogo.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { FaGithub } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import TermsAndConditions from "../pages/TermsAndConditions";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#151c25] w-full flex justify-center flex-col border-t">
      {/* ------------------- FOOTER ------------------- */}
      <div className="w-full flex md:justify-center justify-between items-center flex-col p-4 gradient-bg-footer">
        <div className="w-full flex md:flex-row flex-col justify-between items-center my-4">
          <div className="flex lg:flex-[0.5] justify-center items-center">
            <LazyLoadImage
              effect="blur"
              loading="lazy"
              decoding="async"
              alt="logo"
              src={logo}
              className="w-32 floating-image3"
            />
          </div>
          <div className="flex flex-1 flex-col min-[400px]:flex-row min-[400px]:justify-evenly items-center content-center flex-wrap sm:mt-0 mt-5 w-full">
            <div className="text-white text-base min-[400px]:text-left mx-2 cursor-pointer mt-6 md:mt-0 max-[400px]:w-full text-center">
              <a
                href="https://arabianchain.org/"
                target="_blank"
                rel="noreferrer"
              >
                <p>Website</p>
              </a>
              <a
                href="https://explorer.arabianchain.org/#/"
                target="_blank"
                rel="noreferrer"
              >
                <p className="pt-3">Explorer</p>
              </a>
              <a
                href="https://galaxy.arabianchain.org"
                target="_blank"
                rel="noreferrer"
              >
                <p className="py-3">Galaxy Wallet</p>
              </a>

              <a
                href="http://dubxpool.arabianchain.org/"
                target="_blank"
                rel="noreferrer"
              >
                <p>Pool</p>
              </a>
            </div>
            <div className="text-white text-base min-[400px]:text-left mx-2 cursor-pointer mt-6 md:mt-0 max-[400px]:w-full text-center">
              <Link to={"/terms-and-conditions"}>
                <p className="py-3">Terms and Conditions</p>
              </Link>
              <Link to={"/privacy-policy"}>
                <p className="py-3">Privacy Policy</p>
              </Link>
              <Link to={"/doc"}>
                <p className="py-3">Doc</p>
              </Link>
            </div>
            <div className="text-white text-base text-center md:text-left mx-2 cursor-pointer mt-6 md:mt-0">
              <p className="flex flex-row w-[300px] justify-center md:justify-start mt-2">
                <a
                  href="https://github.com/DUBXCOIN"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaGithub className="mr-[40px] text-[30px] text-white" />
                </a>
                <a
                  href="https://twitter.com/arabianchaintec"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter className="mr-[30px] text-[30px] text-white" />
                </a>
                <a
                  href="https://web.telegram.org/k/#@DUBXCOIN_GROUP"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTelegramPlane className="text-[30px] text-white" />
                </a>
              </p>
              <p className="pt-3">info@arabianchain.org</p>
              <p className="pt-3">nftsupport@arabianchain.org</p>
            </div>
          </div>
        </div>

        <div className="sm:w-[90%] w-full h-[0.25px] bg-gray-400 mt-5 " />

        <div className="sm:w-[90%] w-full flex justify-between items-center mt-3">
          <p className="text-white text-left text-xs flex items-center">
            <span className="text-[15px] mr-[10px]">© </span> Arabianchain 2024
          </p>
          <p className="text-white text-right text-xs">All rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
