import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import OfferModal from "../components/OfferModal";
import ChangePriceModal from "../components/ChangePriceModal";
import { FaExternalLinkAlt } from "react-icons/fa";
import { MdOutlineVerifiedUser } from "react-icons/md";
import { PiHeartLight, PiHeartFill, PiEyeBold } from "react-icons/pi";
import { IoReload } from "react-icons/io5";
import moment from "moment/moment";
import { useParams } from "react-router-dom";
import ItemLoader from "../components/ItemLoader";
import MessageModal from "../components/MessageModal";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import {
  getSingleNFT,
  shortenAddress,
  getOfferForNFTById,
  getUnlockablebyTokenId,
  convertToDubx,
  getNftTx,
  checkIsAccVerified,
  checkIsAuthorVerified,
  convertToWei,
  getEthersContract,
  getOfferData,
  getProvider,
  getRegistryContract,
  getNftByKey,
} from "../Blockchain.Services";
import {
  useGlobalState,
  setGlobalState,
  getGlobalState,
  truncate,
} from "../store";
import abi from "../abis/DubxNFTMarketplace.json";
import { useNavigate } from "react-router-dom";
import ConfettiContainer from "../components/ConfettiContainer";
import ReactAudioPlayer from "react-audio-player";
import ReactPlayer from "react-player";

const ItemDetail = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const [connectedAccount, setConnectedAccount] =
    useGlobalState("connectedAccount");
  const { contractaddress, tokenId } = useParams();
  const [maxTitleLength, setMaxTitleLength] = useState(47);
  const [nftData, setNftData] = useState(null);
  const [imageData, setImageData] = useState(null);
  const [contractAddr, setContractAddr] = useState(null);
  const [traits, setTraits] = useState([]);
  const [category, setCategory] = useState(null);
  const [externalLink, setExternalLink] = useState(null);
  const [tags, setTags] = useState([]);
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false);
  const [isChangePriceModalOpen, setIsChangePriceModalOpen] = useState(false);
  const [changedPrice, setChangedPrice] = useState(null);
  const [isListedNFTForSale, setIsListedNFTForSale] = useState(true);
  const [isOfferForNFT, setIsOfferForNFT] = useState(false);
  const [whatIsVisible, setWhatIsVisible] = useState("image");
  const [unlockableLink, setUnlockableLink] = useState("");
  const [isAuthorVerified, setIsAuthorVerified] = useState(false);
  const [isAccountVerified, setIsAccountVerified] =
    useGlobalState("isAccountVerified");
  const [showUnlockable, setShowUnlockable] = useState(false);
  const [nfts, setNfts] = useGlobalState("nfts");
  const [transactions, setTransactions] = useState([]);
  const [isTransactionHistoryOpen, setTransactionHistoryOpen] = useState(true);
  const [isUnlockableButtonDisabled, setIsUnlockableButtonDisabled] =
    useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const [countLikes, setCountLikes] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const [infoMessage, setInfoMessage] = useState(false);
  const [showUlMessageModal, setShowUlMessageModal] = useState(true);
  const [showConfMessageModal, setShowConfMessageModal] = useState(false);
  const [showConfettiContainer, setShowConfettiContainer] = useState(false);
  const [notExist, setNotExist] = useState(false);
  const [visibleTransactions, setVisibleTransactions] = useState(5);
  const [isInitialRender, setIsInitialRender] = useState(false);
  const [currentCollection, setCurrentCollection] = useState([]);
  const [audioExist, setAudioExist] = useState(false);
  const [audioExternalExist, setAudioExternalExist] = useState(false);
  const [audioLink, setAudioLink] = useState("");
  const [audioExternalLink, setAudioExternalLink] = useState("");
  const [rumble, setRumble] = useState(false);
  const [videoExist, setVideoExist] = useState(false);
  const [videoExternalExist, setVideoExternalExist] = useState(false);
  const [videoLink, setVideoLink] = useState(false);
  const [videoExternalLink, setVideoExternalLink] = useState(false);
  const [isDeletedNft, setIsDeletedNft] = useState(false);
  const [updatedNft, setUpdatedNft] = useState({
    contract: "",
    collectionId: "",
    owner: "",
    title: "",
    description: "",
    metadataURI: "",
    imageURI: "",
    purchaseCost: "",
    royaltyReceivers: "",
    royaltyFee: "",
    originalArtist: "",
    mintingCost: "",
    isListedForSale: true,
    externalLink: "",
    category: "",
    traits: "",
    tags: "",
    timestamp: "",
    nftViews: "",
    collection_contract: "",
    collection_image: "",
    collection_title: "",
  });
  const navigate = useNavigate();

  const [activeOffer, setActiveOffer] = useState({
    amount: "",
    buyer: "",
    expirationTime: "",
    timestamp: "",
    isActive: false,
  });

  const { ethereum } = window;
  const handleShowMessageModal = (
    newMessage,
    showUl = true,
    showConf = false
  ) => {
    setInfoMessage(newMessage);
    setShowMessageModal(true);
    setShowUlMessageModal(showUl);
    setShowConfMessageModal(showConf);
  };
  const handleCloseMessageModal = () => {
    setShowMessageModal(false);
  };

  // const getCollectionInfo = async () => {
  //   if (
  //     contractaddress.toLowerCase() === abi.networks[3270].address.toLowerCase()
  //   ) {
  //     setCurrentCollection([]);
  //   } else {
  //     const registryContract = await getRegistryContract();
  //     const getData = await registryContract.getContractByAddress(
  //       contractaddress
  //     );
  //     setCurrentCollection(getData);
  //   }
  // };

  // useEffect(() => {
  //   getCollectionInfo();
  // }, [contractaddress]);

  const checkExpiration = (expirationTimestamp) => {
    const currentTimeUnix = Math.floor(new Date().getTime() / 1000);
    setIsExpired(currentTimeUnix > expirationTimestamp);
  };

  useEffect(() => {
    const handleAccountChange = (accounts) => {
      setUnlockableLink("");
      setShowUnlockable(false);
      checkAccVerification();
      checkIsLiked();
    };

    if (ethereum) {
      window.ethereum.on("accountsChanged", handleAccountChange);
      return () => {
        ethereum.off("accountsChanged", handleAccountChange);
      };
    } else {
      console.warn("MetaMask (ethereum) not available.");
    }

    // const handleConnectWallet = async () => {
    //   try {
    //     const accounts = await ethereum.request({
    //       method: "eth_requestAccounts",
    //     });
    //     setGlobalState("connectedAccount", accounts[0].toLowerCase());
    //     window.ethereum.on("accountsChanged", handleAccountChange);
    //   } catch (error) {
    //     console.error("Error connecting wallet:", error);
    //   }
    // };

    if (!connectedAccount && ethereum) {
      console.warn("MetaMask (ethereum) not available.");
    }

    return () => {
      if (ethereum) {
        ethereum.off("accountsChanged", handleAccountChange);
      }
    };
  }, [connectedAccount]);

  const getTx = async () => {
    try {
      const txns = await getNftTx(tokenId, contractaddress);
      if (txns && txns.length > 0) {
        setTransactions(txns);
      } else {
        setTransactions([]);
      }
    } catch (error) {
      setTransactions([]);
      console.error("Error fetching transactions:", error);
    }
  };
  useEffect(() => {
    getTx();
  }, [tokenId, contractaddress]);

  useEffect(() => {
    const getNftByIDFromDB = async () => {
      try {
        const data = await getNftByKey({ PK: contractaddress, SK: tokenId });
        if (data) {
          // console.log("this nft", data);
          setUpdatedNft(data.Item);
          setNotExist(false);
          // if (
          //   contractaddress.toLowerCase() ===
          //   abi.networks[3270].address.toLowerCase()
          // ) {
          //   setCurrentCollection([]);
          // } else {
          //   setCurrentCollection(data.Item.collection_contract);
          // }
          if (
            contractaddress.toLowerCase() ===
            abi.networks[3269].address.toLowerCase()
          ) {
            setCurrentCollection([]);
          } else {
            setCurrentCollection(data.Item.collection_contract);
          }
        } else {
          setNotExist(true);
          setUpdatedNft({});
          console.error("Error getting previous nft");
        }
      } catch (error) {
        setNotExist(true);
        setUpdatedNft({});
        console.error("Error getting requests:", error.message);
      }
    };
    getNftByIDFromDB();
  }, [tokenId, contractaddress]);

  const params = useParams();

  const fetchSingleNft = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const sNFT = await getSingleNFT(tokenId, contractaddress);
        if (sNFT.singleNft === null) {
          return;
        }
        const {
          id,
          title,
          description,
          royaltyFee,
          royaltyReceiver,
          timestamp,
          purchaseCost,
          metadataURI,
          isListedForSale,
          owner,
        } = sNFT.singleNft;

        const metadataUrl = `${process.env.REACT_APP_DOMAIN}/ipfs/${sNFT.singleNft.metadataURI}`;
        const metadataResponse = await fetch(metadataUrl);
        const metadata = await metadataResponse.json();
        setTraits(metadata.attributes);
        setCategory(metadata.category);
        setExternalLink(metadata.external_link);
        setTags(metadata.tags);

        if (metadata.audio) {
          const audioResponse = await fetch(metadata.audio);
          const audioBlob = await audioResponse.blob();
          setAudioExist(true);
          setAudioLink(URL.createObjectURL(audioBlob));
        }
        if (metadata.audio_external) {
          setAudioExternalExist(true);
          setAudioExternalLink(metadata.audio_external);
        }

        if (metadata.video) {
          const videoResponse = await fetch(metadata.video);
          const videoBlob = await videoResponse.blob();
          setVideoExist(true);
          setVideoLink(URL.createObjectURL(videoBlob));
        }
        if (metadata.video_external) {
          setVideoExternalExist(true);
          setVideoExternalLink(metadata.video_external);
          if (metadata.video_external.includes("rumble.com")) {
            setRumble(true);
          }
        }

        setNftData({
          id,
          title,
          description,
          royaltyFee,
          royaltyReceiver,
          timestamp,
          owner,
          purchaseCost,
          metadataURI,
          isListedForSale,
        });

        const imageUrl = metadata.image;
        setIsListedNFTForSale(isListedForSale);
        setChangedPrice(purchaseCost);
        const imageResponse = await fetch(imageUrl);
        const imageBlob = await imageResponse.blob();
        setImageData(URL.createObjectURL(imageBlob));
        resolve({
          id,
          title,
          description,
          royaltyFee,
          royaltyReceiver,
          timestamp,
          owner,
          purchaseCost,
          metadataURI,
          isListedForSale,
        });
        setLoading(false);
      } catch (error) {
        console.error("Error fetching NFT data:", error);
        setNotExist(true);
        setLoading(false);
        reject(error);
      }
    });
  };

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const nftDataResult = await fetchSingleNft();
        handleGetOfferPrice();
        checkAccVerification();
        checkIsLiked();
        checkCountLiked();
        getIsAuthorVerified();

        setIsUnlockableButtonDisabled(false);
        setContractAddr(contractaddress);
      } catch (error) {
        console.error("Error fetching NFT data:", error);
      }
    };
    fetchAll();
  }, [
    tokenId,
    changedPrice,
    isListedNFTForSale,
    isOfferForNFT,
    isAccountVerified,
  ]);

  const makeOfferById = async (id, cost, offerDuration, contrAdd) => {
    try {
      const contract = await getEthersContract(contrAdd);
      const offerPrice = convertToWei(cost);
      const timestampDuration = offerDuration.toString();

      const result = await contract.makeOffer(
        id,
        offerPrice,
        timestampDuration,
        {
          value: offerPrice,
          gasPrice: 20000000000,
        }
      );
      handleShowMessageModal("Making offer in progress...", true);
      await result.wait();
      const offerData = await getOfferData(id, contrAdd);
      if (offerData) {
        return offerData;
      }
    } catch (error) {
      console.error("Error getting makeOfferById", error.message);
      return false;
    }
  };

  const handleMakeOffer = async () => {
    if (!ethereum) {
      handleShowMessageModal("Please install metamask", false);
    } else if (
      (ethereum && ethereum._state.isUnlocked === false) ||
      (ethereum && !connectedAccount)
    ) {
      handleShowMessageModal("Please connect or unlock account.", false);
    } else if (
      ethereum &&
      ethereum._state.isUnlocked === true &&
      connectedAccount
    ) {
      const chainId = await ethereum.request({ method: "eth_chainId" });
      if (chainId === "0xcc5") {
        setIsOfferModalOpen(true);
      } else {
        handleShowMessageModal("Please switch to DUBX network", false);
      }
    } else {
      handleShowMessageModal(
        "Some error occured, try to connect wallet.",
        false
      );
    }
  };
  const changePriceNFT = async (id, cost, contrA) => {
    try {
      const contract = await getEthersContract(contrA);
      const offerPrice = convertToWei(cost);

      const result = await contract.changePrice(id, offerPrice, {
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Changing price...", true);
      return result;
    } catch (error) {
      console.error("Error getting changePriceNFT:", error.message);
      return false;
    }
  };

  const openChangePriceModal = async () => {
    if (!ethereum) {
      handleShowMessageModal("Please install metamask", false);
    } else if (
      (ethereum && ethereum._state.isUnlocked === false) ||
      (ethereum && !connectedAccount)
    ) {
      handleShowMessageModal("Please connect account", false);
    } else if (ethereum && ethereum._state.isUnlocked === true) {
      const chainId = await ethereum.request({ method: "eth_chainId" });
      if (chainId === "0xcc5") {
        setIsChangePriceModalOpen(true);
      } else {
        handleShowMessageModal("Please switch to DUBX network", false);
      }
    } else {
      setIsChangePriceModalOpen(true);
    }
  };
  useEffect(() => {
    const updateNftInDb = async () => {
      try {
        const nftUpdateUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft`;
        // console.log(updatedNft);
        const response = await fetch(nftUpdateUrl, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(updatedNft),
        });
        // console.log(response);
        if (response.ok) {
          const responseSingleItem = await response.json();
          // console.log(responseSingleItem);
        }
      } catch (error) {
        console.error("Error updating NFT in DB:", error);
      }
    };
    if (isInitialRender) {
      updateNftInDb();
      setIsInitialRender(false);
    }
  }, [updatedNft, isInitialRender]);
  const handleChangePrice = async (tokenId, newPrice) => {
    try {
      if (
        connectedAccount &&
        nftData &&
        connectedAccount.toLowerCase() === nftData.owner.toLowerCase()
      ) {
        const result = await changePriceNFT(tokenId, newPrice, contractaddress);
        if (result) {
          const receipt = await result.wait();
          if (receipt.status === 1) {
            // console.log("newPrice", newPrice);
            setChangedPrice(newPrice);
            setUpdatedNft((prevNft) => ({
              ...prevNft,
              purchaseCost: newPrice,
            }));
            setIsInitialRender(true);
            handleShowMessageModal("Price successfully updated:", false, true);
          } else {
            console.error("Transaction failed:", receipt);
            handleShowMessageModal("Some error occured, try again.", false);
          }
        }
      } else {
        console.log("You are not the owner of this NFT.");
        handleShowMessageModal("Some error occured, try again.", false);
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again.", false);
    }
  };

  const checkAccVerification = async () => {
    if (connectedAccount) {
      const result = await checkIsAuthorVerified(connectedAccount);
      if (result === true) {
        setIsAccountVerified(true);
        setGlobalState("isAccountVerified", true);
      } else {
        setIsAccountVerified(false);
      }
    } else {
      setIsAccountVerified(false);
    }
  };

  const getIsAuthorVerified = async () => {
    try {
      if (nftData && nftData.owner) {
        const result = await checkIsAuthorVerified(nftData.owner);
        if (result === true) {
          setIsAuthorVerified(true);
        } else {
          setIsAuthorVerified(false);
        }
      }
    } catch (error) {
      console.error("Error during verification:", error);
    }
  };

  const handleOfferSubmit = async (tokenId, offerPrice, offerDuration) => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install metamask", false);
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect or unlock a wallet", false);
      } else {
        const account = connectedAccount.toLowerCase();
        const owner = nftData.owner.toLowerCase();
        if (account && account !== owner) {
          const result = await makeOfferById(
            tokenId,
            offerPrice,
            offerDuration,
            contractaddress
          );
          if (result && result.isActive) {
            const priceOffer = result.amount.toString();
            setActiveOffer({
              amount: convertToDubx(priceOffer),
              buyer: result.buyer,
              expirationTime: result.expirationTime.toString(),
              timestamp: result.timestamp.toString(),
              isActive: result.isActive,
            });
            setIsOfferForNFT(true);
            setIsExpired(false);
            handleShowMessageModal("Offer in made successfully.", false, true);
          } else {
            handleShowMessageModal("Some error occured, try again.", false);
          }
        } else if (account && account === owner) {
          handleShowMessageModal(
            "You can not send offer you your own NFT.",
            false
          );
        } else {
          handleShowMessageModal("Some error occured, try again.", false);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again.", false);
    }
  };
  const closeOfferModal = () => {
    setIsOfferModalOpen(false);
  };
  const closeChangePriceModal = () => {
    setIsChangePriceModalOpen(false);
  };

  const listNFTbyTokenId = async (tokenid, contrA) => {
    try {
      const contract = await getEthersContract(contrA);
      const listing = await contract.listTokenForSale(tokenid, {
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Listing for sale...", true);
      await listing.wait();
      const isForSale = await contract.isTokenListedForSale(tokenid);
      return isForSale;
    } catch (error) {
      console.error("Error getting listNFTbyTokenId:", error.message);
      handleShowMessageModal("Some error occured, try again.", false);
    }
  };

  const handleListToken = async () => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install metamask");
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect wallet");
      } else if (ethereum && ethereum._state.isUnlocked === true) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId === "0xcc5") {
          if (
            getGlobalState("connectedAccount").toLowerCase() &&
            getGlobalState("connectedAccount").toLowerCase() ===
              nftData.owner.toLowerCase()
          ) {
            const result = await listNFTbyTokenId(tokenId, contractaddress);

            if (result === true) {
              setIsListedNFTForSale(true);
              setUpdatedNft((prevNft) => ({
                ...prevNft,
                isListedForSale: true,
              }));
              setIsInitialRender(true);
              handleShowMessageModal("Listed successfully.", false, true);
            }
          } else {
            console.log("Some error occured, try again.");
            handleShowMessageModal("Some error occured, try again.");
          }
        } else {
          handleShowMessageModal("Please switch to DUBX network", false);
        }
      } else {
        if (
          getGlobalState("connectedAccount").toLowerCase() &&
          getGlobalState("connectedAccount").toLowerCase() ===
            nftData.owner.toLowerCase()
        ) {
          const result = await listNFTbyTokenId(tokenId, contractaddress);

          if (result === true) {
            setIsListedNFTForSale(true);
            setUpdatedNft((prevNft) => ({
              ...prevNft,
              isListedForSale: true,
            }));
            setIsInitialRender(true);
            handleShowMessageModal("Listed successfully.", false, true);
          }
        } else {
          console.log("Some error occured, try again.");
          handleShowMessageModal("Some error occured, try again.");
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again.");
    }
  };

  const deleteNftByIDFromDB = async () => {
    const getNftUrlDelete = `${process.env.REACT_APP_AWS_API_GATEWAY}/nft?id=${contractaddress}:${tokenId}`;
    try {
      const response = await fetch(getNftUrlDelete, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        // console.log("Deleted NFT from DB:", data);
      } else {
        setNotExist(true);
        console.error("No nft");
      }
    } catch (error) {
      setNotExist(true);
      console.error("Error getting requests:", error.message);
    }
  };

  const removeNFTbyTokenId = async (tokenid, contrA) => {
    try {
      const contract = await getEthersContract(contrA);
      const listing = await contract.removeTokenForSale(tokenid, {
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Removing from sale...", true);
      await listing.wait();
      const isForSale = await contract.isTokenListedForSale(tokenid);
      return isForSale;
    } catch (error) {
      console.error("Error getting removeNFTbyTokenId:", error.message);
      handleShowMessageModal("Some error occured, try again.", false);
    }
  };
  const burningNft = async (tokenid, contrA) => {
    try {
      const contract = await getEthersContract(contrA);
      const burnToken = await contract.burn(tokenid, {
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Burning token...", true);
      const result = await burnToken.wait();
      if (result.status === 1) {
        deleteNftByIDFromDB();
      }
      return result;
    } catch (error) {
      console.error("Error getting removeNFTbyTokenId:", error.message);
      handleShowMessageModal("Some error occured, try again.", false);
    }
  };
  const deletNft = async () => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install metamask");
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect wallet");
      } else {
        if (
          getGlobalState("connectedAccount").toLowerCase() &&
          getGlobalState("connectedAccount").toLowerCase() ===
            nftData.owner.toLowerCase()
        ) {
          const result = await burningNft(tokenId, contractaddress);
          if (result && result.status === 1) {
            setIsDeletedNft(true);
            handleShowMessageModal("NFT deleted successfully.", false, true);
            await new Promise((resolve) => setTimeout(resolve, 3000));
            handleCloseMessageModal();
            navigate(`/author/${connectedAccount}`, { replace: true });
          }
        } else {
          console.log("Some error occured, try again.");
          handleShowMessageModal("Some error occured, try again.");
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again.");
    }
  };
  const handleremoveListToken = async () => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install metamask");
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect wallet");
      } else if (ethereum && ethereum._state.isUnlocked === true) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId === "0xcc5") {
          if (
            connectedAccount &&
            connectedAccount.toLowerCase() === nftData.owner.toLowerCase()
          ) {
            const result = await removeNFTbyTokenId(tokenId, contractaddress);
            if (result === false) {
              setIsListedNFTForSale(false);
              setUpdatedNft((prevNft) => ({
                ...prevNft,
                isListedForSale: false,
              }));
              setIsInitialRender(true);
              handleShowMessageModal(
                "Removed from sale successfully.",
                false,
                true
              );
            }
          } else {
            console.log("Some error occured, try again.");
            handleShowMessageModal("Some error occured, try again.", false);
          }
        } else {
          handleShowMessageModal("Please switch to DUBX network", false);
        }
      } else {
        if (
          connectedAccount &&
          connectedAccount.toLowerCase() === nftData.owner.toLowerCase()
        ) {
          const result = await removeNFTbyTokenId(tokenId, contractaddress);
          if (result === false) {
            setIsListedNFTForSale(false);
            setUpdatedNft((prevNft) => ({
              ...prevNft,
              isListedForSale: false,
            }));
            setIsInitialRender(true);
            handleShowMessageModal(
              "Removed from sale successfully.",
              false,
              true
            );
          }
        } else {
          console.log("Some error occured, try again.");
          handleShowMessageModal("Some error occured, try again.", false);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again.");
    }
  };

  const getHiddenUnlockable = async () => {
    try {
      if (nftData && nftData.id) {
        setIsUnlockableButtonDisabled(true);
        const result = await getUnlockablebyTokenId(tokenId, contractaddress);
        if (result) {
          setUnlockableLink(result);
          setShowUnlockable(true);
        } else {
          handleShowMessageModal("Some error occured, try again");
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again");
    }
  };
  const buyNFT = async (id, cost, contrA) => {
    try {
      const payingCost = convertToWei(cost);
      const contract = await getEthersContract(contrA);
      const result = await contract.payToBuy(id, {
        value: payingCost,
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Buying NFT in progress...");
      const receipt = await result.wait();
      if (receipt.status === 1) {
        const newOwner = await contract.ownerOf(id);
        return newOwner;
      } else {
        console.error("Transaction failed:", receipt);
        return false;
      }
    } catch (error) {
      console.error("Error getting buyNFT:", error.message);
      return false;
    }
  };
  const buyThisNFT = async () => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install Metamask.", false);
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect wallet.", false);
      } else if (ethereum && ethereum._state.isUnlocked === true) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId === "0xcc5") {
          if (nftData && nftData.id && changedPrice && connectedAccount) {
            const result = await buyNFT(tokenId, changedPrice, contractaddress);
            if (result) {
              // console.log(result);
              const requestUrlAuthor = `${
                process.env.REACT_APP_AWS_API_GATEWAY
              }/author?account=${result.toLowerCase()}`;
              const responseFromDbAuthor = await fetch(requestUrlAuthor, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              });
              if (responseFromDbAuthor.ok) {
                const resultAuthor = await responseFromDbAuthor.json();
                // console.log("resultAuthor", resultAuthor);
                if (!resultAuthor.Item) {
                  const currentTimestampInSeconds = Math.floor(
                    Date.now() / 1000
                  );
                  console.log("there is no author in db");
                  const requestAuthorBody = {
                    account: connectedAccount.toLowerCase(),
                    timestamp: currentTimestampInSeconds,
                    verified: false,
                    authorPhoto: "",
                    bannerImage: "",
                    name: "",
                    email: "",
                    externalLink: "",
                    smartCode: "",
                    bio: "",
                    facebook: "",
                    twitter: "",
                    instagram: "",
                    skype: "",
                    nftCount: 0,
                    collectionCount: 0,
                  };
                  const requestUrlAuthor = `${process.env.REACT_APP_AWS_API_GATEWAY}/author`;
                  const insertAuthor = await fetch(requestUrlAuthor, {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestAuthorBody),
                  });
                  if (insertAuthor.ok) {
                    console.log("author inserted");
                  }
                } else {
                  console.log("there is already author in db");
                }
              }
              // const accounts = await window.ethereum.request({
              //   method: "eth_requestAccounts",
              // });
              // const currentMetamaskAccount = accounts[0].toLowerCase();
              const currentMetamaskAccount = connectedAccount.toLowerCase();
              if (currentMetamaskAccount === result.toLowerCase()) {
                setNftData((prevNftData) => ({
                  ...prevNftData,
                  owner: result.toLowerCase(),
                }));
                setUpdatedNft((prevNft) => ({
                  ...prevNft,
                  owner: result.toLowerCase(),
                }));
                // console.log("owner", result.toLowerCase());
                setIsInitialRender(true);
                handleShowMessageModal(
                  <>
                    Congratulations! 🚀 <br />
                    <span className="text-[25px] text-[#fff]">
                      {`${nftData.title}#${tokenId}`}{" "}
                    </span>
                    <br />
                    is now yours.
                  </>,
                  false,
                  true
                );
                setShowConfettiContainer(true);
                await new Promise((resolve) => setTimeout(resolve, 3000));
                setShowConfettiContainer(false);
              }
              await getTx(nftData.id);
            } else {
              handleShowMessageModal("Some error occured, try again.", false);
            }
          } else {
            handleShowMessageModal("Some error occured, try again.", false);
          }
        } else {
          handleShowMessageModal("Please switch to DUBX network", false);
        }
      } else {
        if (nftData && nftData.id && changedPrice && connectedAccount) {
          const result = await buyNFT(tokenId, changedPrice, contractaddress);
          if (result) {
            // const accounts = await window.ethereum.request({
            //   method: "eth_requestAccounts",
            // });
            // const currentMetamaskAccount = accounts[0].toLowerCase();
            const currentMetamaskAccount = connectedAccount.toLowerCase();
            if (currentMetamaskAccount === result.toLowerCase()) {
              handleShowMessageModal(
                <>
                  Congratulations! 🚀 <br />
                  <span className="text-[25px] text-[#fff]">
                    {`${nftData.title}#${tokenId}`}{" "}
                  </span>
                  <br />
                  is now yours.
                </>,
                false,
                true
              );
              setShowConfettiContainer(true);
              await new Promise((resolve) => setTimeout(resolve, 3000));
              setShowConfettiContainer(false);
              setNftData((prevNftData) => ({
                ...prevNftData,
                owner: result.toLowerCase(),
              }));
              setUpdatedNft((prevNft) => ({
                ...prevNft,
                owner: result.toLowerCase(),
              }));
              setIsInitialRender(true);
            }
            await getTx(nftData.id);
          } else {
            handleShowMessageModal("Some error occured, try again.", false);
          }
        } else {
          handleShowMessageModal("Some error occured, try again.", false);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again.");
    }
  };
  const refundOfferById = async (id, contr) => {
    try {
      const contract = await getEthersContract(contr);
      const result = await contract.refundOffer(id, {
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Refunding in progress...", true);
      await result.wait();
      const offerData = await getOfferData(id, contr);
      const formattedTOfferData = {
        amount: convertToDubx(offerData.amount),
        buyer: offerData.buyer,
        offerSent: offerData.timestamp.toNumber(),
        expirationTime: offerData.expirationTime.toNumber(),
        isActive: offerData.isActive,
      };
      return formattedTOfferData;
    } catch (error) {
      //napraviti ako neko trazi refund pre isteka vremena
      console.error("Error getting refundOfferById:", error.message);
      handleShowMessageModal("Some error occured, try again", false);
    }
  };
  const refund = async () => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install Metamask.", false);
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect wallet.", false);
      } else if (ethereum && ethereum._state.isUnlocked === true) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId === "0xcc5") {
          const result = await refundOfferById(tokenId, contractaddress);

          if (result && result.isActive === false) {
            setIsOfferForNFT(false);
            handleShowMessageModal("Successfully refunded.", false, true);
          } else {
            handleShowMessageModal("Some error occured, try again", false);
          }
        } else {
          handleShowMessageModal("Please switch to DUBX network", false);
        }
      } else {
        const result = await refundOfferById(tokenId, contractaddress);

        if (result && result.isActive === false) {
          setIsOfferForNFT(false);
          handleShowMessageModal("Successfully refunded.", false, true);
        } else {
          handleShowMessageModal("Some error occured, try again", false);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again", false);
    }
  };
  const acceptOfferById = async (id, contr) => {
    try {
      const contract = await getEthersContract(contr);
      const offerData = await getOfferData(id, contr);
      const formattedTOfferData = {
        amount: convertToDubx(offerData.amount),
        buyer: offerData.buyer,
        offerSent: offerData.timestamp.toNumber(),
        expirationTime: offerData.expirationTime.toNumber(),
        isActive: offerData.isActive,
      };
      const currentTimeUnix = Math.floor(new Date().getTime() / 1000);
      if (currentTimeUnix > formattedTOfferData.expirationTime) {
        return "expired";
      }
      const result = await contract.acceptOffer(id, {
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Accepting offer in progress...", true);
      const receipt = await result.wait();
      if (receipt && receipt.status) {
        if (receipt.events.length > 0 && receipt.logs.length > 0) {
          return formattedTOfferData;
        } else {
          handleShowMessageModal("Offer expired, please reject offer.", false);
          return "expired";
        }
      } else {
        handleShowMessageModal("Error accepting...");
      }
    } catch (error) {
      console.error("Error getting acceptOfferById:", error.message);
      handleShowMessageModal("Some error...");
    }
  };
  const accept = async () => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install Metamask.", false);
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect wallet.", false);
      } else if (ethereum && ethereum._state.isUnlocked === true) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId === "0xcc5") {
          const result = await acceptOfferById(tokenId, contractaddress);
          if (result && result !== "expired") {
            if (result && result.buyer) {
              const requestUrlAuthor = `${
                process.env.REACT_APP_AWS_API_GATEWAY
              }/author?account=${result.buyer.toLowerCase()}`;
              const responseFromDbAuthor = await fetch(requestUrlAuthor, {
                method: "GET",
                headers: {
                  "Content-Type": "application/json",
                },
              });
              if (responseFromDbAuthor.ok) {
                const resultAuthor = await responseFromDbAuthor.json();
                // console.log("resultAuthor", resultAuthor);
                if (!resultAuthor.Item) {
                  const currentTimestampInSeconds = Math.floor(
                    Date.now() / 1000
                  );
                  // console.log("there is no author in db");
                  const requestAuthorBody = {
                    account: result.buyer.toLowerCase(),
                    timestamp: currentTimestampInSeconds,
                    verified: false,
                    authorPhoto: "",
                    bannerImage: "",
                    name: "",
                    email: "",
                    externalLink: "",
                    smartCode: "",
                    bio: "",
                    facebook: "",
                    twitter: "",
                    instagram: "",
                    skype: "",
                    nftCount: 0,
                    collectionCount: 0,
                  };
                  const requestUrlAuthor = `${process.env.REACT_APP_AWS_API_GATEWAY}/author`;
                  const insertAuthor = await fetch(requestUrlAuthor, {
                    method: "PUT",
                    headers: {
                      "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestAuthorBody),
                  });
                  if (insertAuthor.ok) {
                    console.log("author inserted");
                  }
                } else {
                  console.log("there is already author in db");
                }
              }
              setNftData((prevNftData) => ({
                ...prevNftData,
                owner: result.buyer,
                purchaseCost: result.amount,
              }));
              setUpdatedNft((prevNft) => ({
                ...prevNft,
                owner: result.buyer.toLowerCase(),
                purchaseCost: result.amount,
              }));
              setIsInitialRender(true);
              handleShowMessageModal(
                <>
                  Congratulations! 🚀 <br />
                  <span className="text-[25px] text-[#fff]">
                    {`${nftData.title}#${tokenId}`}{" "}
                  </span>
                  <br />
                  is sold to <br />
                  <span>{shortenAddress(result.buyer)}</span>.
                </>,
                false,
                true
              );
              setShowConfettiContainer(true);
              await new Promise((resolve) => setTimeout(resolve, 3000));
              setShowConfettiContainer(false);
              await getTx(nftData.id);
              setIsOfferForNFT(false);
            } else {
              handleShowMessageModal("Some error occured, try again", false);
            }
          } else if (result && result === "expired") {
            handleShowMessageModal(
              "Offer expired, please reject offer.",
              false
            );
          } else {
            handleShowMessageModal("Some error occured, try again", false);
          }
        } else {
          handleShowMessageModal("Please switch to DUBX network", false);
        }
      } else {
        const result = await acceptOfferById(tokenId, contractaddress);
        if (result && result !== "expired") {
          if (result && result.buyer) {
            setNftData((prevNftData) => ({
              ...prevNftData,
              owner: result.buyer,
              purchaseCost: result.amount,
            }));
            setUpdatedNft((prevNft) => ({
              ...prevNft,
              owner: result.buyer.toLowerCase(),
              purchaseCost: result.amount,
            }));
            setIsInitialRender(true);
            handleShowMessageModal(
              <>
                Congratulations! 🚀 <br />
                <span className="text-[25px] text-[#fff]">
                  {`${nftData.title}#${tokenId}`}{" "}
                </span>
                <br />
                is sold to <br />
                <span>{shortenAddress(result.buyer)}</span>.
              </>,
              false,
              true
            );
            setShowConfettiContainer(true);
            await new Promise((resolve) => setTimeout(resolve, 3000));
            setShowConfettiContainer(false);
            await getTx(nftData.id);
            setIsOfferForNFT(false);
          } else {
            handleShowMessageModal("Some error occured, try again", false);
          }
        } else if (result && result === "expired") {
          handleShowMessageModal("Offer expired, please reject offer.", false);
        } else {
          handleShowMessageModal("Some error occured, try again", false);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again", false);
    }
  };
  const rejectOfferById = async (id, contr) => {
    try {
      const contract = await getEthersContract(contr);
      const result = await contract.rejectOffer(id, {
        gasPrice: 20000000000,
      });
      handleShowMessageModal("Rejecting in progress...", true);
      const tx = await result.wait();
      const offerData = await getOfferData(id, contr);
      const formattedTOfferData = {
        amount: convertToDubx(offerData.amount),
        buyer: offerData.buyer,
        offerSent: offerData.timestamp.toNumber(),
        expirationTime: offerData.expirationTime.toNumber(),
        isActive: offerData.isActive,
      };
      return formattedTOfferData;
    } catch (error) {
      console.error("Error getting rejectOfferById:", error.message);
      handleShowMessageModal("Some error occured, try again", false);
    }
  };
  const reject = async () => {
    try {
      if (!ethereum) {
        handleShowMessageModal("Please install Metamask.", false);
      } else if (
        (ethereum && ethereum._state.isUnlocked === false) ||
        (ethereum && !connectedAccount)
      ) {
        handleShowMessageModal("Please connect wallet.", false);
      } else if (ethereum && ethereum._state.isUnlocked === true) {
        const chainId = await ethereum.request({ method: "eth_chainId" });
        if (chainId === "0xcc5") {
          const result = await rejectOfferById(tokenId, contractaddress);
          if (result && result.isActive === false) {
            setIsOfferForNFT(false);
            handleShowMessageModal("Rejected successfully.", false, true);
          }
        } else {
          handleShowMessageModal("Please switch to DUBX network", false);
        }
      } else {
        const result = await rejectOfferById(tokenId, contractaddress);
        if (result && result.isActive === false) {
          setIsOfferForNFT(false);
          handleShowMessageModal("Rejected successfully.", false, true);
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again", false);
    }
  };
  const handleGetOfferPrice = async () => {
    try {
      if (nftData && nftData.id) {
        const result = await getOfferForNFTById(tokenId, contractaddress);
        if (result) {
          setIsOfferForNFT(result.isActive);
          if (result.isActive) {
            const { amount, buyer, expirationTime, timestamp, isActive } =
              result;
            const priceOffer = amount.toString();
            setActiveOffer({
              amount: convertToDubx(priceOffer),
              buyer,
              expirationTime: expirationTime.toString(),
              timestamp: timestamp.toString(),
              isActive,
            });
            checkExpiration(expirationTime.toString());
          } else {
            console.log("No active offer for this NFT.");
          }
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
      handleShowMessageModal("Some error occured, try again.", false);
    }
  };

  const checkCountLiked = async () => {
    const requestUrlAllLiked = `${process.env.REACT_APP_AWS_API_GATEWAY}/nftlikes`;
    try {
      const response = await fetch(requestUrlAllLiked, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const smartContractAddr = params.contractaddress.toLowerCase();
      if (response.ok && smartContractAddr && tokenId) {
        const responseAllLikesArray = await response.json();
        const likedNfts = [];
        const specialId = `${tokenId}${smartContractAddr}`;
        if (responseAllLikesArray) {
          responseAllLikesArray.Items.map((item) => {
            const likeId = item.id;
            if (likeId.includes(specialId)) {
              likedNfts.push(item);
            }
          });
          setCountLikes(likedNfts.length);
        }
      } else {
        console.error("Failed to send request:", response.statusText);
      }
    } catch (error) {
      console.error("Error getting checkCountLiked:", error.message);
    }
  };
  const checkIsLiked = async () => {
    const smartContractAddr = params.contractaddress.toLowerCase();

    try {
      if (connectedAccount) {
        const userAccount = connectedAccount.toLowerCase();
        const requestUrlLiked = `${process.env.REACT_APP_AWS_API_GATEWAY}/nftlikes?id=${tokenId}${smartContractAddr}${userAccount}`;
        const response = await fetch(requestUrlLiked, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response.ok) {
          const responseSingleLikeId = await response.json();
          if (
            responseSingleLikeId &&
            responseSingleLikeId.Item &&
            responseSingleLikeId.Item.userAddress
          ) {
            setIsLiked(true);
          } else {
            setIsLiked(false);
          }
        } else {
          console.error("Failed to send request:", response.statusText);
        }
      } else {
        setIsLiked(false);
      }
    } catch (error) {
      console.error("Error getting checkIsLiked:", error.message);
      setIsLiked(false);
    }
  };

  const handleLikeClick = async () => {
    const smartContractAddr = params.contractaddress.toLowerCase();
    const userAccount = connectedAccount.toLowerCase();
    const requestUrl = `${process.env.REACT_APP_AWS_API_GATEWAY}/nftlikes`;
    const requestBody = {
      id: `${tokenId}${smartContractAddr}${userAccount}`,
      userAddress: userAccount,
    };

    const response = await fetch(requestUrl, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (response.ok) {
      const responseLike = await response.json();
      if (responseLike && responseLike.Item && responseLike.Item.userAddress) {
        setIsLiked(true);
        setCountLikes((prev) => prev + 1);
      }
    } else {
      console.error("Failed to send request:", response.statusText);
    }
  };

  const handleButtonClick = async (format) => {
    if (format === "video") {
      setWhatIsVisible("video");
    } else if (format === "image") {
      setWhatIsVisible("image");
    }
  };
  return (
    <div className="pb-96 bg-[#131722] text-white">
      {loading && <ItemLoader />}
      {notExist ? (
        <p className="py-[200px] w-full h-full flex items-center align-center justify-center">
          There is no NFT with this ID.
        </p>
      ) : (
        <div>
          {nftData && imageData && changedPrice && (
            <div className="flex flex-col lg:flex-row pt-24 w-full justify-around items-start">
              {/* LEFT SIDE */}
              <div className="w-full md:px-24 px-4 lg:px-0 lg:w-1/3 flex flex-col justify-start mt-8">
                <div className="flex justify-end items-center">
                  <button disabled={isLiked}>
                    <PiEyeBold className="text-[#fff] text-[25px]" />
                  </button>
                  {updatedNft.nftViews > 0 && (
                    <span className="mx-[10px] text-white text-[22px]">
                      {updatedNft.nftViews}
                    </span>
                  )}
                  <button onClick={handleLikeClick} disabled={isLiked}>
                    {isLiked ? (
                      <PiHeartFill className="text-[#f10cea] text-[25px]" />
                    ) : (
                      <PiHeartLight className="text-white text-[25px]" />
                    )}
                  </button>
                  {countLikes > 0 && (
                    <span className="ml-[10px] text-white text-[22px]">
                      {countLikes}
                    </span>
                  )}
                </div>
                {/* NFT IMG MAIN */}
                <div className="p-4 bs flex flex-col items-center justify-center relative">
                  {videoExist && whatIsVisible === "video" && (
                    <>
                      <iframe
                        width="560"
                        height="560"
                        src={videoLink}
                        title="Nft video"
                        frameBorder="0"
                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen
                        autoPlay={false}
                        sandbox="allow-same-origin allow-scripts"
                      ></iframe>
                    </>
                  )}
                  {videoExternalExist && whatIsVisible === "video" && (
                    <>
                      {rumble ? (
                        <iframe
                          width="560"
                          height="560"
                          src={videoExternalLink}
                          title="Nft video"
                          frameBorder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          autoPlay={false}
                          sandbox="allow-same-origin allow-scripts"
                        ></iframe>
                      ) : (
                        <ReactPlayer
                          url={videoExternalLink}
                          playing={false}
                          controls={true}
                        />
                      )}
                    </>
                  )}
                  {whatIsVisible === "image" && (
                    <LazyLoadImage
                      effect="blur"
                      src={imageData}
                      loading="lazy"
                      decoding="async"
                      alt={nftData.title}
                      className="h-[700px] w-full object-contain rounded-xl"
                    />
                  )}

                  {(videoExist || videoExternalExist) && (
                    <div className="mt-4 w-[70%] flex justify-evenly">
                      <button
                        className="bg-blue-500 py-3 text-xl text-[#fff] font-Bakbak text-center rounded-lg hover:bg-[#fff] hover:text-[#000] transition-all duration-150 w-[47%]"
                        onClick={() => handleButtonClick("image")}
                      >
                        IMAGE
                      </button>
                      <button
                        className="py-3 text-xl text-[#000] font-Bakbak text-center bg-[#21e786] rounded-lg hover:bg-[#fff] hover:text-[#000] transition-all duration-150 w-[47%]"
                        onClick={() => handleButtonClick("video")}
                      >
                        VIDEO
                      </button>
                    </div>
                  )}
                  {audioExist && (
                    <div className="w-full">
                      <>
                        <ReactAudioPlayer
                          src={audioLink}
                          controls
                          width={100}
                          height={400}
                          style={{ width: "100%", background: "#21e786" }}
                          color="#00000"
                          volumeOrientationDown
                        ></ReactAudioPlayer>
                      </>
                    </div>
                  )}
                  {audioExternalExist && (
                    <div className="w-full">
                      <>
                        <iframe
                          width="100%"
                          src={audioExternalLink}
                          title="Nft video"
                          frameBorder="0"
                          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowFullScreen
                          autoPlay={false}
                          sandbox="allow-same-origin allow-scripts"
                          style={{
                            backgroundColor: "transparent !important",
                          }}
                        ></iframe>
                      </>
                    </div>
                  )}
                </div>

                {updatedNft.collection_contract &&
                  updatedNft.collection_title && (
                    <div className="w-full flex justify-center mt-8">
                      <p className="flex flex-col items-center justify-center">
                        More NFTs from collection :{" "}
                        <Link
                          to={`/collection/${updatedNft.collection_contract}`}
                          className="mt-4"
                        >
                          <span className="p-[7px] border border-white rounded-lg text-[25px] font-Bakbak bg-[#f10cea]">
                            {updatedNft.collection_title}
                          </span>
                        </Link>
                      </p>
                    </div>
                  )}
              </div>

              {/* RIGHT SIDE */}
              <div className="w-full lg:w-1/2 mt-10 flex flex-col items-center justify-center">
                {/* TITLE */}
                <h2 className="text-4xl md:text-5xl lg:text-6xl text-[#21e786] text-center">
                  <span>
                    {nftData.title.length > maxTitleLength
                      ? nftData.title.substring(0, maxTitleLength) + "..."
                      : nftData.title}
                  </span>{" "}
                  <span>#{tokenId}</span>
                  {/* {`${nftData.title}#${tokenId}`} */}
                </h2>
                {/* BOX */}
                <div className="flex flex-col w-full px-4 md:px-0 md:w-2/3 text-center">
                  <h4 className="text-2xl lg:text-3xl font-bold pt-5">{`PRICE: ${changedPrice} DUBX`}</h4>
                  <div>
                    <div className="flex flex-col min-[1079px]:flex-row h-9 pt-4 max-[1079px]:pb-12 items-center">
                      <div className="flex">
                        <p>Owned By: </p>

                        <p>
                          <Link
                            to={`/author/${nftData.owner}`}
                            activeclassname="active-link"
                            className="text-white font-bold underline ml-2 "
                          >
                            {shortenAddress(nftData.owner)}
                          </Link>
                        </p>
                      </div>

                      <>
                        {isAuthorVerified && (
                          <button className="ml-6 border rounded-lg font-Bakbak bg-[#21e786] border-[#21e786] flex items-center px-4 py-1 text-black m-2">
                            VERIFIED{" "}
                            <span className="inline-block p-1 text-sm font-semibold leading-none text-white bg-[#483d8b] hover:bg-[#322a61] rounded-full align-top ml-1 mt-[-5px]">
                              <MdOutlineVerifiedUser />
                            </span>
                          </button>
                        )}
                      </>
                    </div>
                    <details open className="text-white mt-6 mb-2">
                      <summary className="pl-[8px]">Details</summary>
                      <div className="border py-2">
                        <div className="flex justify-between h-9 px-4">
                          <p>Contract Address: </p>
                          <p className=" ">
                            <a
                              href={`https://explorer.arabianchain.org/#/address/${contractAddr}`}
                              target="_blank"
                              rel="noreferrer"
                              className="font-bold underline"
                            >
                              {window.innerWidth < 1602
                                ? shortenAddress(contractAddr)
                                : contractAddr}
                            </a>{" "}
                          </p>
                        </div>
                        <div className="flex justify-between h-9 px-4">
                          <p>Token ID: </p>
                          <p>{nftData.id} </p>
                        </div>
                        <div className="flex justify-between h-9 px-4">
                          <p>Token Standard: </p>
                          <p>ERC-721 </p>
                        </div>
                        <div className="flex justify-between h-9 px-4">
                          <p>Created: </p>
                          <p>
                            {moment(nftData.timestamp * 1000).format(
                              "DD.MMM.YY HH:mm:ss"
                            )}{" "}
                          </p>
                        </div>
                        {nftData.royaltyFee != 0 && (
                          <div className="flex justify-between h-9 px-4">
                            <p>Creator Earnings: </p>
                            <p>{nftData.royaltyFee}%</p>
                          </div>
                        )}
                        {externalLink && (
                          <div className="flex justify-between h-9 px-4">
                            <p>External link: </p>
                            <p className="flex">
                              <a
                                href={`https://${externalLink}`}
                                target="_blank"
                                rel="noreferrer"
                                className="font-bold underline ml-2"
                              >
                                {window.innerWidth < 768
                                  ? `${externalLink.substring(0, 14)}...`
                                  : externalLink}
                              </a>{" "}
                            </p>
                          </div>
                        )}

                        <div className="flex justify-between h-9 px-4">
                          <p>Metadata: </p>
                          <p>
                            <a
                              href={`${process.env.REACT_APP_DOMAIN}/ipfs/${nftData.metadataURI}`}
                              target="_blank"
                              rel="noreferrer"
                              className="font-bold underline ml-2"
                            >
                              {window.innerWidth < 1602
                                ? truncate(nftData.metadataURI, 4, 4, 11)
                                : nftData.metadataURI}
                            </a>{" "}
                          </p>
                        </div>
                        <div className="px-4">
                          {connectedAccount === nftData.owner.toLowerCase() && (
                            <div className="flex justify-between items-end">
                              <p>Unlockable content: </p>
                              <button
                                onClick={getHiddenUnlockable}
                                className="p-1 border rounded-lg font-Bakbak bg-[#673AB7]"
                                disabled={isUnlockableButtonDisabled}
                              >
                                Get protected content
                              </button>
                            </div>
                          )}
                          {connectedAccount === nftData.owner.toLowerCase() &&
                            showUnlockable && (
                              <div className="rounded-lg bg-transparent flex justify-end">
                                {unlockableLink !== "" &&
                                unlockableLink !==
                                  "You don't have unlockable content" ? (
                                  <p className="text-[17px] underline mt-2 cursor-pointer text-[#515151] p-0">
                                    <a
                                      href={unlockableLink}
                                      target="_blank"
                                      rel="noreferrer"
                                    >
                                      {window.innerWidth < 4768
                                        ? `${unlockableLink.substring(
                                            0,
                                            10
                                          )}...`
                                        : unlockableLink}
                                    </a>
                                  </p>
                                ) : (
                                  <p className="text-[17px] mt-2 text-gray-500">
                                    You don't have any locked content for this
                                    NFT.
                                  </p>
                                )}
                              </div>
                            )}
                        </div>
                      </div>
                    </details>
                  </div>
                  <div className="rounded-lg my-6 border-[#000] hover:border-[#fff] flex flex-row justify-between">
                    {connectedAccount === nftData.owner.toLowerCase() ? (
                      <>
                        <button
                          type="button"
                          className="py-3 text-xl text-[#000] font-Bakbak text-center bg-[#21e786] rounded-lg hover:bg-[#fff] hover:text-[#000] transition-all duration-150 w-[47%]"
                          onClick={openChangePriceModal}
                        >
                          CHANGE PRICE
                        </button>
                        <div className="tooltip-container w-[47%]">
                          {isListedNFTForSale ? (
                            <button
                              type="button"
                              className={`py-3 text-xl text-[#fff] font-Bakbak text-center bg-[#f10cea] rounded-lg hover:bg-[#fff] hover:text-[#000] transition-all duration-150 w-[100%] ${
                                isOfferForNFT ? "disabled" : ""
                              }`}
                              onClick={handleremoveListToken}
                              disabled={isOfferForNFT}
                            >
                              REMOVE FROM SALE
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="py-3 text-xl text-[#fff] font-Bakbak text-center bg-[#f10cea] rounded-lg hover:bg-[#fff] hover:text-[#000] transition-all duration-150 w-[100%]"
                              onClick={handleListToken}
                            >
                              ADD TO SALE
                            </button>
                          )}
                          {isOfferForNFT && (
                            <div className="tooltip-content">
                              <p className="my-3">
                                To remove from sale, you must first accept or
                                decline the offer.
                              </p>
                            </div>
                          )}
                        </div>
                      </>
                    ) : (
                      <>
                        <button
                          type="submit"
                          className={`py-3 text-xl text-[#000] font-Bakbak text-center bg-[#21e786] rounded-lg hover:bg-[#fff] hover:text-[#000] transition-all duration-150 w-[47%] ${
                            !isListedNFTForSale ? "disabled" : ""
                          }`}
                          onClick={buyThisNFT}
                          disabled={!isListedNFTForSale}
                        >
                          {isListedNFTForSale ? "BUY" : "🔒 NOT FOR SALE"}
                        </button>
                        <button
                          type="submit"
                          className={`py-3 text-xl text-[#fff] font-Bakbak text-center bg-[#f10cea] rounded-lg hover:bg-[#fff] hover:text-[#000] transition-all duration-150 w-[47%] ${
                            (isOfferForNFT && !isExpired) || !isListedNFTForSale
                              ? "disabled"
                              : ""
                          }`}
                          onClick={() => {
                            if (isOfferForNFT && !isExpired) {
                              handleShowMessageModal(
                                "There is already an active offer.",
                                false
                              );
                            } else {
                              handleMakeOffer();
                            }
                          }}
                          disabled={
                            (isOfferForNFT && !isExpired) || !isListedNFTForSale
                          }
                        >
                          MAKE OFFER
                        </button>
                      </>
                    )}
                  </div>
                  {isOfferForNFT && (
                    <div className="flex flex-col items-center justify-center bg-[#171b26] p-[20px] mb-4 bs">
                      <div className="flex flex-col bg-transprent p-[10px] border border-[#21e786] rounded-xl w-full justify-around">
                        <h3 className="text-[#fff] font-Bakbak text-center text-xl my-3">
                          OFFER{" "}
                          <button
                            className="border p-[5px] ml-[15px] rounded-lg text-black font-Bakbak bg-[#21e786] border-[#21e786]"
                            onClick={handleGetOfferPrice}
                          >
                            {isExpired ? "expired" : <IoReload />}
                          </button>
                        </h3>
                        <div className="flex w-full justify-evenly">
                          <p className="text-[#fff] font-bold">
                            {activeOffer.amount} DUBX
                          </p>
                          <span>
                            By{" "}
                            <Link to={`/author/${activeOffer.buyer}`}>
                              <span className="ml-[5px] underline cursor-pointer">
                                {shortenAddress(activeOffer.buyer)}
                              </span>
                            </Link>
                          </span>
                        </div>
                        <div className="flex w-full justify-evenly my-2">
                          <p className="text-[#fff] mr-2">OFFER SENT:</p>
                          <p>
                            {moment(activeOffer.timestamp * 1000).format(
                              "DD.MMM.YY HH:mm:ss"
                            )}{" "}
                          </p>
                        </div>
                        <div className="flex w-full justify-evenly">
                          <p className="text-[#fff]">OFFER EXPIRE</p>
                          <p>
                            {moment(activeOffer.expirationTime * 1000).format(
                              "DD.MMM.YY HH:mm:ss"
                            )}{" "}
                          </p>
                        </div>

                        {/* Display buttons only to the owner */}
                        <div className="flex mx-4 justify-evenly mt-5">
                          {connectedAccount === nftData.owner.toLowerCase() && (
                            <>
                              <button
                                onClick={accept}
                                className="m-2 p-2 px-12 rounded-full font-Bakbak bg-[#21e786] text-black"
                              >
                                ACCEPT
                              </button>
                              <button
                                onClick={reject}
                                className="m-2 p-2 px-12 border rounded-full font-Bakbak bg-[#696969]"
                              >
                                REJECT
                              </button>
                            </>
                          )}
                          {activeOffer &&
                            connectedAccount ===
                              activeOffer.buyer.toLowerCase() && (
                              <button
                                onClick={refund}
                                className="m-2 p-2 px-12 text-black rounded-full font-Bakbak bg-[#21e786]"
                              >
                                REFUND
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div>
                    <details className="text-white my-2">
                      <summary className="pl-[8px]">Description</summary>
                      <div className="border border-[#21e786] py-2">
                        <div className="flex justify-between px-4">
                          <p>{nftData.description}</p>
                        </div>
                      </div>
                    </details>
                  </div>
                  <div>
                    <details className="text-white my-2">
                      <summary className="pl-[8px]">Traits</summary>
                      <div className="flex flex-wrap">
                        {traits.map((trait, index) => (
                          <div
                            key={index}
                            className="flex flex-col justify-start px-6 py-2 border border-[#21e786] rounded-lg mr-4 mb-4 mt-2"
                          >
                            <h5 className="text-[#21e786] font-bold text-center">
                              {trait.trait_type.toUpperCase()}
                            </h5>
                            <h5 className="pt-0 text-center">{trait.value}</h5>
                            {trait.trait_count > 0 && (
                              <h5 className="pt-0 pb-0 text-center">
                                ({trait.trait_count})
                              </h5>
                            )}
                          </div>
                        ))}
                      </div>
                    </details>
                  </div>

                  <div className="border py-2 mb-2 border-[#21e7864f]">
                    <div className="flex justify-start px-4">
                      <p>CATEGORY:</p>
                      <p className="ml-4 font-bold">{category}</p>
                    </div>
                  </div>

                  <div className="border py-2 mb-2 border-[#21e7864f]">
                    <div className="flex items-center justify-start px-4 flex-wrap">
                      <div className="flex">
                        <div>
                          <p className="text-center w-full py-2">TAGS:</p>
                        </div>
                        <div className="flex flex-wrap ml-4">
                          {tags.map((tag, index) => (
                            <div
                              key={index}
                              className="flex items-center px-4 py-2 bg-[#21e786] rounded-full text-black m-2 font-bold"
                            >
                              <p className="text-black">{tag}</p>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end items-end">
                    {connectedAccount &&
                      connectedAccount === nftData.owner.toLowerCase() && (
                        <div className="tooltip-container">
                          <button
                            onClick={() => {
                              if (!isOfferForNFT && !isListedNFTForSale) {
                                deletNft();
                              } else {
                                showMessageModal(
                                  "You can't delete this NFT until there is offer or is listed. Accept or reject an offer and remove from listing. Then you will be able to delete nft.",
                                  false
                                );
                              }
                            }}
                            className="p-1 border rounded-lg font-Bakbak bg-[#1b1b1b]"
                            disabled={isOfferForNFT || isListedNFTForSale}
                          >
                            Delete NFT
                          </button>
                          <div className="tooltip-content1">
                            <p className="">
                              In order to remove an NFT in the event of an
                              existing offer, you must first either accept or
                              decline the offer. Following that, you can press
                              the "Remove from Sale" button, and only then will
                              you be able to delete the NFT.
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          )}

          {isOfferModalOpen && (
            <OfferModal
              tokenId={tokenId}
              onClose={closeOfferModal}
              onSubmit={handleOfferSubmit}
            />
          )}
          {isChangePriceModalOpen && (
            <ChangePriceModal
              tokenId={tokenId}
              onClose={closeChangePriceModal}
              onSubmit={handleChangePrice}
            />
          )}
          <div>
            <MessageModal
              message={infoMessage}
              show={showMessageModal}
              showUl={showUlMessageModal}
              showConf={showConfMessageModal}
              onClose={handleCloseMessageModal}
            />
          </div>
          {/* HISTORY TRANSACTIONS */}
          <div className="w-full px-4 flex items-center justify-center">
            <div className="py-2 w-full lg:w-[70%] mt-12">
              <div className="mt-2">
                <h2
                  onClick={() =>
                    setTransactionHistoryOpen(!isTransactionHistoryOpen)
                  }
                  className="text-2xl text-[#fff] hover:text-[#21e786] transition-all ease-out duration-300 flex justify-center items-center cursor-pointer"
                >
                  History Transactions{" "}
                  <span className="ml-2">
                    <FaExternalLinkAlt />
                  </span>
                </h2>
                {isTransactionHistoryOpen && (
                  <div>
                    {transactions.length > 0 ? (
                      <div className="overflow-x-auto">
                        <table className="border-collapse border mt-4 rounded-lg w-full">
                          <thead>
                            <tr className="border-b">
                              <th className="px-2 py-3 text-left text-[#fff]">
                                TxID
                              </th>
                              <th className="px-2 py-3 text-left text-[#fff]">
                                Amount
                              </th>
                              <th className="px-2 py-3 text-left text-[#fff]">
                                From
                              </th>
                              <th className="px-2 py-3 text-left text-[#fff]">
                                To
                              </th>
                              <th className="px-2 py-3 text-left text-[#fff]">
                                Date
                              </th>
                              <th className="px-2 py-3 text-left text-[#fff]">
                                Time
                              </th>
                              <th className="px-2 py-3 text-left text-[#fff]">
                                Event
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {transactions.map((txn, index) => ( */}
                            {transactions
                              .slice(0, visibleTransactions)
                              .map((txn, index) => (
                                <tr
                                  key={index}
                                  className="border-b mx-2 px-4 mb-3 transition-all duration-300 hover:bg-[#21e786]"
                                >
                                  <td className="py-2 pl-4">
                                    {txn.txid}-{txn.nftlk}
                                  </td>
                                  <td className="py-2 font-bold">
                                    {txn.amount} DUBX
                                  </td>
                                  <td className="py-2 px-4">
                                    {shortenAddress(txn.from)}
                                  </td>
                                  <td className="py-2 font-bold">
                                    {shortenAddress(txn.to)}
                                  </td>
                                  <td className="py-2 pl-4">
                                    {moment(txn.timestamp * 1000).format(
                                      "DD.MMM.YY"
                                    )}
                                  </td>
                                  <td className="py-2 pl-4">
                                    {moment(txn.timestamp * 1000).format(
                                      "HH:mm:ss"
                                    )}
                                  </td>
                                  <td className="py-2 px-4">
                                    <span>/// {txn.eventName}</span>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {transactions.length > visibleTransactions && (
                          <div className="flex items-center justify-center mt-8">
                            <button
                              className="shadow-xl shadow-black text-[#000] hover:text-white
                            bg-[#21e786] hover:bg-[#f10cea] md:text-[24px] py-3 px-[15px]
                              cursor-pointer font-Bakbak w-[250px]"
                              onClick={() =>
                                setVisibleTransactions((prev) => prev + 5)
                              }
                            >
                              Load More
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <p>No transactions available.</p>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {showConfettiContainer && <ConfettiContainer />}
        </div>
      )}
    </div>
  );
};

export default ItemDetail;
