import React from "react";
import confetti from "canvas-confetti";

const ConfettiButton = () => {
  const doItNow = (evt, hard) => {
    const direction = Math.sign(lastX - evt.clientX);
    lastX = evt.clientX;
    const particleCount = hard ? r(122, 245) : r(2, 15);
    confetti({
      particleCount,
      angle: r(90, 90 + direction * 30),
      spread: r(45, 80),
      origin: {
        x: evt.clientX / window.innerWidth,
        y: evt.clientY / window.innerHeight,
      },
    });
  };

  const doIt = (evt) => {
    doItNow(evt, false);
  };

  const doItHard = (evt) => {
    doItNow(evt, true);
  };

  let lastX = 0;

  return (
    <button
      onMouseMove={doIt}
      onClick={doItHard}
      style={{
        position: "absolute",
        background: "transparent",
        border: "none",
        borderRadius: "5px",
        color: "white",
        cursor: "pointer",
        transition: "all 0.4s",
        padding: "10px 20px",
        width: "100%",
        top: "0",
        left: "0",
        height: "100%",
      }}
    ></button>
  );
};

function r(mi, ma) {
  return parseInt(Math.random() * (ma - mi) + mi);
}

export default ConfettiButton;
