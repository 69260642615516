import React, { useState } from "react";

const ChangePriceModal = ({ tokenId, onClose, onSubmit }) => {
  const [newPrice, setNewPrice] = useState("");

  const handleSubmit = () => {
    // Validate inputs (you can add more validation if needed)
    if (!newPrice) {
      // Display an error message or handle invalid input
      return;
    }

    onSubmit(tokenId, newPrice);
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-[rgba(36,42,57,0.8)] flex items-center justify-center">
      <div className="bg-[#131722] p-8 rounded-lg w-[300px]">
        <h2 className="text-3xl font-Bakbak mb-4 text-[#21e786]">
          Set New Price
        </h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-[#7b7b7b] mb-1">
            Token ID
          </label>
          <input
            type="text"
            value={tokenId}
            readOnly
            className="form-input bg-gray-800 text-white rounded-md p-2 w-full"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-[#7b7b7b] mb-1">
            New Price (DUBX)
          </label>
          <input
            type="number"
            value={newPrice}
            onChange={(e) => setNewPrice(e.target.value)}
            className="form-input bg-gray-800 text-white rounded-md p-2 w-full"
          />
        </div>

        <div className="flex justify-between mt-8">
          <button
            onClick={onClose}
            className="mr-4 px-4 py-2 text-white bg-gray-500 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChangePriceModal;
